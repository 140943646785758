import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ActionBarAllThings } from "../Layout/ActionBar";
import "../useraccount/login.css";

import { accountService, alertService } from "../../services";
import { resetCurrentUsersRoles} from '../../store/actions/loggedInUserRolesActions';
import { useDispatch} from 'react-redux';

function Login({ history, location }) {
  const initialValues = {
    email: "",
    password: "",
  };

  const dispatch =  useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  function onSubmit({ email, password }, { setSubmitting }) {
    alertService.clear();
    accountService
      .login(email, password)
      .then(() => {
        const { from } = location.state || { from: { pathname: "/items" } };
        history.push(from);
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });

      dispatch(resetCurrentUsersRoles());
  }

  return (
    <>
      {" "}
      <ActionBarAllThings pageTitle={"Login"} showFooter={false}/>
       <div className="page_container_padding">
       {/* <LoginButton/> 
         {'  '}
         <LogoutButton/> */}

       <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="card-body">
                <div className="login_field_div">
                  <label className="noselect">Email</label>
                  <Field
                    name="email"
                    type="text"
                    autoFocus={true}
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="login_field_div">
                  <label className="noselect">Password</label>
                  <Field
                    name="password"
                    type="password"
                    className={
                      "form-control" +
                      (errors.password && touched.password ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="login_row">
                  <div className="login_resgiter_div">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Login
                    </button>
                    <Link to="register" className="register noselect">
                      Register
                    </Link>
                  </div>
                  <div className="forgot_password">
                    <Link to="forgot-password" className="forgot_password noselect">
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export { Login };
