import React from 'react';
import { Modal, Button} from 'react-bootstrap';
import './dialogs.css';

function DeleteItemConfirmation ({showModal, displayText, confirmAction, cancelAction}) {

    return (

        <Modal
            show={showModal}
            onHide={cancelAction}
            animation={false}
            className={"small_modal_container"}
            centered={true}
        >
            <Modal.Header closeButton>
             <Modal.Title>
                 {'Confirm Delete'}
             </Modal.Title>
            </Modal.Header>

                <Modal.Body>
                 <p className={'display-text-block'}>
                    {displayText}
                 </p>
                 <Button className={'ml-2'} onClick={confirmAction}>{'Confirm Delete'}</Button>
                <Button  className={'ml-2'} onClick={cancelAction}>{'Cancel'}</Button>
                </Modal.Body>
        </Modal>

    )
}

export { DeleteItemConfirmation };