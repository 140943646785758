export const CHANGE_SETTINGS_CLICKED_STATE   = 'CHANGE_SETTINGS_CLICKED_STATE';

export function changeSettingsClicked(theme) {
  return dispatch => {
    dispatch(pushChangeSettingsClicked(theme))
    }
};
export const pushChangeSettingsClicked = theme => ({
  type: CHANGE_SETTINGS_CLICKED_STATE,
  payload: theme
});
