import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { FaUserEdit, FaUserMinus, FaUserPlus } from "react-icons/fa";
import { VscSave } from "react-icons/vsc";
import "./formcontrolbar.css";

export function FormControlBar({
  showEditBtn,
  showDelBtn,
  addHandler,
  deleteHandler,
  editHandler,
}) {
  return (
    <>
      <Row className="formcontrol">
        <Col>
        <Button onClick={addHandler}>
          <FaUserPlus size="2rem" />
        </Button>

        {showEditBtn ? (
               <Button onClick={editHandler}>
               <FaUserEdit size="2rem" />
             </Button>
        ) : (
          <></>
        )}

        {showDelBtn ? (
          <Button onClick={deleteHandler}>
          <FaUserMinus size="2rem" />
        </Button>
        ) : (
        <></>
        )}

        
        </Col>
      </Row>
    </>
  );
}
