import React, { useEffect, useState } from "react"; //import useState from react
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import {fetchItemLocations, deletedLocation} from '../../../store/actions/itemLocationActions';
import "./locationsComponent.css";
import { trackPromise } from "react-promise-tracker";
import {FaPlus, FaMinus} from 'react-icons/fa';
import './treecontrol.css';
import AddEditLocation from './locations/AddEditLocation';
import { DeleteItemConfirmation } from '../../Dialogs';
import { fetchService, alertService } from '../../../services';
import { BsArrowReturnRight } from 'react-icons/bs'


function LocationsComponent({ setLocationsComponentVisible, actionBarAdd, actionBarEdit, actionBarDelete, resetActionBarActions, setSelectedLocationId }) {
  const locations = useSelector(state => state.itemLocations.itemlocations);
  const loading = useSelector(state => state.itemLocations.loading);
  const error = useSelector(state => state.itemLocations.error);
  const locationsLoaded = useSelector(state => state.itemLocations.itemLocationsLoaded);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentLocationId, setCurrentLocationId] = useState(0);
  const [currentParentLocationId, setCurrentParentLocationId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalText, setDeleteModalText] = useState('');
 

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !locationsLoaded)
    {
      trackPromise(
        dispatch(fetchItemLocations())
      )
    }

  }, [locationsLoaded])

  // Going to use a side effect to watch for changes to the action bar incoming variables
  // trigger a modal on add/edit
  useEffect(() => {
    if (actionBarAdd && !showModal) {
      addLocation();
      resetActionBarActions();
    }
  }, [actionBarAdd])

  useEffect(() => {
    if (actionBarEdit && !showModal) {
      editLocation();
      resetActionBarActions();
    }
  }, [actionBarEdit])

  useEffect(() => {
    if (actionBarDelete && !showDeleteModal) {
      deleteLocation();
      resetActionBarActions();
    }
  }, [actionBarDelete])

  function setCurrLocationLocal (e, loc) {
    e.stopPropagation();
    setSelectedLocationId(loc.id);
    setCurrentLocationId(loc.id);
    setCurrentLocation(loc);
    setShowEditBtn(true);
  }

  function addLocation() {
    setCurrentParentLocationId(currentLocationId);
    setSelectedLocationId(-1);
    setCurrentLocationId(-1);
    setCurrentLocation(null);
    setShowModal(true);
  }

  function addSubItem(e, parentid) {
    e.stopPropagation();
    setSelectedLocationId(-1);
    setCurrentLocationId(-1);
    setCurrentLocation(null);
    setCurrentParentLocationId(parentid);
    setShowModal(true);
  }

  function editLocation() {
    if (currentLocation != null)
    {
      setCurrentParentLocationId(currentLocation.parentLocationId);
      setShowModal(true);
    }
  }

  function deleteLocation() {
    if (currentLocation != null)
    {
      // Set Text for the delete modal
      // show delete modal
      let deleteText = 'Are you sure you wish to permanently delete "' + currentLocation.displayName + '"?';
      if (currentLocation.childLocations.length > 0)
      {
        deleteText += " This action will result in the permanent deletion of all sub/child locations."
      }

      setDeleteModalText(deleteText);
      setShowDeleteModal(true);
    }
  }
  function deleteConfirmHandler() {
    // Actually delete the thing
    if (currentLocation != null)
    {

        // delete
        trackPromise(
            fetchService.delete('/itemlocations/' + currentLocation.id)
        )
        .then(json => {
            //Success
            dispatch(deletedLocation(currentLocation.id));
            setShowDeleteModal(false);
        })
        .catch(error => {
            alertService.error(error);
            return;
        }); 
      }
  }

  function deleteCancelHandler() {
    setShowDeleteModal(false);
  }

  if (loading) {
    return <></>
  }
  if (error) {
    return <>{error}</>
  }

  
  return (
    <>

    {
      showDeleteModal
      ?
        <DeleteItemConfirmation
          showModal={showDeleteModal}
          displayText={deleteModalText}
          confirmAction={deleteConfirmHandler}
          cancelAction={deleteCancelHandler}
        />
      :
      <></>
    }

    {
      showModal
      ?
      <AddEditLocation
        locationid={currentLocationId}
        showModal={showModal}
        setShowModal={setShowModal}
        parentLocationId={currentParentLocationId}
      />
      :
      <></>
    }
    <div className="page-container">
        <div className="item_location_page_container">
            <div className={'tree-control-outer-container'}>
              <div className={'tree-control'}>
                <ListGroup>
                  {
                    locations.map((loc, index) => (
                      <TreeListItem 
                        key={index} 
                        location={loc} 
                        addSubItem={addSubItem} 
                        setCurrLocationLocal={setCurrLocationLocal}
                        selectedLocationId={currentLocationId}
                        parentLocationId={loc.id}
                        />
                    ))
                  }
                </ListGroup>
            </div>
          </div>
        </div>
      </div>  
    </>
  );
}

function TreeListItem({location, addSubItem, setCurrLocationLocal, selectedLocationId, parentLocationId}) {
  const [expanded, setExpanded] = useState(false);

  location.parentLocationId = parentLocationId;

  return (
      <ListGroupItem  
        onClick={(e) => setCurrLocationLocal(e, location)} 
        className={'tree-control-list-item noselect' + (expanded ? ' expanded' : '') }
        >

        <div className={'tree-item-icon-text'}>
          <div className={'tree-control-expand-box'} onClick={() => setExpanded(!expanded)}>
             { expanded ? <FaMinus size={'.75rem'} /> : <FaPlus size={'.75rem'} />}
          </div>

        <span className={'tree-control-item-text' + (location.id === selectedLocationId ? ' selected-tree-list-item' : '' ) }>
          {location.displayName}
        </span>
        </div>

        
        {
        expanded
        ?
          <div className={'tree-list-children expanded'}>
          <ListGroup>
            <ListGroupItem className={'tree-control-list-item noselect'}>
              <div onClick={(e) => addSubItem(e, location.id)} className={'tree-control-add-item'}>

                <BsArrowReturnRight/>
              <span className="add_sub_location">Add Sub Location</span>

              </div>
            </ListGroupItem>
            {
              location.childLocations.map((loc, index) => (
                <TreeListItem 
                  key={index} 
                  location={loc} 
                  addSubItem={addSubItem} 
                  setCurrLocationLocal={setCurrLocationLocal}
                  selectedLocationId={selectedLocationId}
                  parentLocationId={location.id}
                />
              ))
            }
          </ListGroup>
          </div>
        :
        <></>
        }
    </ListGroupItem>
  )
}

export { LocationsComponent };
