import {
    FETCH_CURRENTUSERROLES_BEGIN,
    FETCH_CURRENTUSERROLES_SUCCESS,
    FETCH_CURRENTUSERROLES_FAILURE,
    RESET_CURRENTUSERSROLES

  } from '../actions/loggedInUserRolesActions.js';
  
  const initialState = {
    items: [],
    loading: false,
    error: null,
    dataloaded: false
  };
  
  export default function LoggedInCurrentUserRolesReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_CURRENTUSERROLES_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          dataloaded: false
        };
  
      case FETCH_CURRENTUSERROLES_SUCCESS:
        // All done: set loading "false".
        // Also, replace the items with the ones from the server
        return {
          ...state,
          loading: false,
          items: action.payload.currentuserroles,
          dataloaded: true
        };
  
      case FETCH_CURRENTUSERROLES_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have items to display anymore, so set `items` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the items around!
        // Do whatever seems right for your use case.
        
            return {
            ...state,
            loading: false,
            error: action.payload.error,
            items: []
            };
      case RESET_CURRENTUSERSROLES:
        return {
            ...state,
            loading: false,
            dataloaded: false,
            items: []
          };
      default:
         
        // ALWAYS have a default case in a reducer
        return state;
    }
  }