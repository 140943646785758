import {
    FETCH_USERROLES_BEGIN,
    FETCH_USERROLES_SUCCESS,
    FETCH_USERROLES_FAILURE,
    POST_USER_SUCCESS,
    POST_USER_BEGIN,
    POST_USER_FAILURE,
    UPDATE_USERROLES
  } from '../actions/userRolesActions';
  
  const initialState = {
    userroles: [],
    loading: false,
    isError: false,
    error: null,
    userrolesLoaded: false
  };
  
  export default function userrolesReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_USERROLES_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        
        return {
          ...state,
          loading: true,
          error: null,
          isError: false,
          userrolesLoaded: false
        };
  
      case FETCH_USERROLES_SUCCESS:
        // All done: set loading "false".
        // Also, replace the userroles with the ones from the server
        return {
          ...state,
          loading: false,
          isError: false,
          userroles: action.payload.userroles,
          userrolesLoaded: true
        };
  
      case FETCH_USERROLES_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have userroles to display anymore, so set `userroles` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the userroles around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          isError: true,
          error: action.payload.error,
          userroles: []
        };

        case POST_USER_BEGIN:
          return {
            ...state,
            error: null,
            isError: false
        }        
        case POST_USER_FAILURE:
          return {
            ...state,
            error: action.payload.error,
            isError: true
        }        

        case POST_USER_SUCCESS:
          return {
              ...state,
              loading: false,
              isError: false,
              userroles: state.userroles.concat(action.payload.user)
          }        
        case UPDATE_USERROLES:

            //  We have a new or updated User added in the software
            //  need to update the master underlying list to account for it
            let existingrole = state.userroles.find(e => e.id === action.payload.id);
            let index = state.userroles.indexOf(existingrole);

            //See if it already exists, if not add it to the list
            if (index < 0)
            {
              //If we are here we are going to add to the end of the list
              return {
                ...state,
                userroles: [...state.userroles.concat(action.payload)]
              }
            }
            else
            {
                //If we are here we are going to slice the array and insert the updated user 
                //This keeps everything clean as far as rules of immutable go
                return { 
                  ...state, 
                  userroles: [
                    ...state.userroles.slice(0,index)
                    .concat(action.payload)
                    .concat(state.userroles.slice(index+1))
                    ]
                  }
            }
  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }