import {
    FETCH_ITEMTYPES_BEGIN,
    FETCH_ITEMTYPES_SUCCESS,
    FETCH_ITEMTYPES_FAILURE
  } from '../actions/itemTypeActions';
  
  const initialState = {
    itemtypes: [],
    loading: false,
    error: null,
    itemtypesLoaded: false,
    itemPosting: false
  };
  
  export default function itemtypeReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_ITEMTYPES_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          itemtypesLoaded: false
        };
  
      case FETCH_ITEMTYPES_SUCCESS:
        // All done: set loading "false".
        // Also, replace the itemtypes with the ones from the server
        return {
          ...state,
          loading: false,
          itemtypes: action.payload.itemtypes,
          itemtypesLoaded: true
        };
  
      case FETCH_ITEMTYPES_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have itemtypes to display anymore, so set `itemtypes` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the itemtypes around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          itemtypes: []
        };
  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }