import { fetchService } from "../../services";

export const FETCH_CUSTOMER_BEGIN = 'FETCH_CUSTOMER_BEGIN';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

//#region CUSTOMER

export const fetchCustomerBegin = () => ({
    type: FETCH_CUSTOMER_BEGIN
});

export const fetchCustomerSuccess = customer => ({
    type: FETCH_CUSTOMER_SUCCESS,
    payload: { customer }
});

export const fetchCustomerFailure = error => ({
    type: FETCH_CUSTOMER_FAILURE,
    payload: { error }
});

export function fetchCustomer(custID) {
    return dispatch => {
        dispatch(fetchCustomerBegin());

        return fetchService.get(`/customers/${custID}`)
         .then(json => {
             dispatch(fetchCustomerSuccess(json));
             return json;
         })
         .catch(error => dispatch(fetchCustomerFailure(error)));
    };
};

//#endregion