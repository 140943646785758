import { BehaviorSubject } from 'rxjs';

//import config from 'config';
import { fetchService, history } from '.';

const userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));


//This is being handled within the service, so we don't need to set it here
const baseUrl = ""; // (process.env.REACT_APP_INVENTORYDASH_APIURL); 

export const accountService = {
    login,
    logout,
    register,
    verifyEmail,
    forgotPassword,
    validateResetToken,
    resetPassword,
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    getUserProperty,
    user: userSubject.asObservable(),
    get userValue () { return userSubject.value }
};

function login(email, password) {

    return fetchService.post(`${baseUrl}/login/`, { email, password })
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            // publish user to subscribers
            userSubject.next(user);

            return user;
        });
}

function logout() {
    // remove user from local storage and publish null to user subject
    localStorage.removeItem('user');
    userSubject.next(null);
    history.push('login');
}

function register(rm) {
    
    return fetchService.post(`${baseUrl}/useraccount`, rm);
}

function verifyEmail(token) {
    return fetchService.post(`${baseUrl}/verify-email`, { token });
}

function forgotPassword(email) {
    const baseurl = window.location.origin;
    return fetchService.post(`${baseUrl}/register/forgotpassword`, { email, baseurl });
}

function validateResetToken(token) {
    return fetchService.post(`${baseUrl}/validate-reset-token`, { token });
}

function resetPassword({ token, email, password, confirmPassword }) {
    return fetchService.post(`${baseUrl}/register/reset-password`, { token, email, password, confirmPassword });
}

function getAll() {
    return fetchService.get(baseUrl);
}

function getById(id) {
    return fetchService.get(`${baseUrl}/${id}`);
}

function create(params) {
    return fetchService.post(baseUrl, params);
}

function update(id, params) {
    return fetchService.put(`${baseUrl}/${id}`, params)
        .then(user => {
            // update stored user if the logged in user updated their own record
            if (user.id === userSubject.value.id) {
                // update local storage
                user = { ...userSubject.value, ...user };
                localStorage.setItem('user', JSON.stringify(user));

                // publish updated user to subscribers
                userSubject.next(user);
            }
            return user;
        });
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return fetchService.delete(`${baseUrl}/${id}`)
        .then(x => {
            // auto logout if the logged in user deleted their own record
            if (id === userSubject.value.id) {
                logout();
            }
            return x;
        });
}


function getUserProperty(propname) {
    const usr = localStorage.getItem('user');
    let retval = "";
    if (usr != null)
    {
        let usrobj = JSON.parse(usr);
        retval = usrobj[propname];
    }
    return retval;
}

