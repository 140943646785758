import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';
import { alertService, AlertType, history } from '../services';

const propTypes = {
    id: PropTypes.string,
    fade: PropTypes.bool
};

const defaultProps = {
    id: 'default-alert',
    fade: true
};

function Alert({ id, fade }) {
    const [alerts, setAlerts] = useState([]);

   

    const removeAlert = (alert) => {
        
        if (fade) {
            // fade out alert
            const alertWithFade = { ...alert, fade: true };
            setAlerts(alerts => alerts.map(x => x === alert ? alertWithFade : x));

            // remove alert after faded out
            setTimeout(() => {
                setAlerts(alerts => alerts.filter(x => x !== alertWithFade));
            }, 250);
        } else {
            // remove alert
            setAlerts(alerts => alerts.filter(x => x !== alert));
        }
    }

    useEffect((id) => {
        // subscribe to new alert notifications

        // id = getNextId();
       
        
        const subscription = alertService.onAlert(id)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    setAlerts(alerts => {
                        // filter out alerts without 'keepAfterRouteChange' flag
                        const filteredAlerts = alerts.filter(x => x.keepAfterRouteChange);

                        // remove 'keepAfterRouteChange' flag on the rest
                        filteredAlerts.forEach(x => delete x.keepAfterRouteChange);
                        return filteredAlerts;
                    });
                } else {
                    // add alert to array
                    setAlerts(alerts => ([...alerts, alert]));

                    // auto close alert if required
                    if (alert.autoClose) {
                        setTimeout(() => removeAlert(alert), 3000);
                    }
                }
            });

        // clear alerts on location change
        const historyUnlisten = history.listen(({ pathname }) => {
            // don't clear if pathname has trailing slash because this will be auto redirected again
            if (pathname.endsWith('/')) return;

            alertService.clear(id);
        });

        // clean up function that runs when the component unmounts
        return () => {
            // unsubscribe & unlisten to avoid memory leaks
            subscription.unsubscribe();
            historyUnlisten();
        };
    }, []);

  
    function cssClasses(alert) {
        if (!alert) return;

        const classes = ['alert', 'alert-dismissable'];
                
        const alertTypeClass = {
            [AlertType.Success]: 'alert alert-success',
            [AlertType.Error]: 'alert alert-danger',
            [AlertType.Info]: 'alert alert-info',
            [AlertType.Warning]: 'alert alert-warning'
        }

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            classes.push('fade');
        }

        return classes.join(' ');
    }

    if (!alerts.length) return null;
    return (
        <>
        <div className="alerts-container">
            
            <div className="m-3">
                {alerts.map((alert, index) =>
                    // <div key={index} className={cssClasses(alert)}>
                   

                    <ToastAlert
                        key={index}
                        alert={alert}
                        title={'Inventory Dash'}
                        index={index}
                        text={alert.message}
                        removeAlert={removeAlert}
                    />

                      //  {/* <Button className="close" onClick={() => removeAlert(alert)}>&times;</Button>
                       // <span dangerouslySetInnerHTML={{__html: alert.message}}></span> */}
                    // </div>
                )}
            </div>
        </div>
        </>
    );
}

function ToastAlert({alert, title, text, index, removeAlert}) {
    const [show, setShow] = useState(true);
    let alerttext = text;
    function closeAlert(alert) {
        setShow(false);
      //  removeAlert(alert);
    }

    if (typeof text === 'object')
    {
        alerttext = '';
        var keys = Reflect.ownKeys(text);
        for(var i=0;i<keys.length;i++)
        {
            alerttext = alerttext.concat(text[keys[i]]).concat(" ");
        }
    }
    
    return (
        <Toast 
        onClose={() => closeAlert(alert) }
        className={'toast-alert'}
        key={index}
        show={show}
        transition={false}
        style={{
          position: 'relative',
          top: 0,
          right: 0
        }}
      >
        <Toast.Header>
        
          <strong className="mr-auto">{title}</strong>
          
        </Toast.Header>
        <Toast.Body>{alerttext}</Toast.Body>
      </Toast>
    )
  }
  
  

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export { Alert };




// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Button } from 'react-bootstrap';
// import { alertService, AlertType } from '../services';
// import { history } from '../helpers';

// const propTypes = {
//     id: PropTypes.string,
//     fade: PropTypes.bool
// };

// const defaultProps = {
//     id: 'default-alert',
//     fade: true
// };

// function Alert({ id, fade }) {
//     const [alerts, setAlerts] = useState([]);

//     const removeAlert = (alert) => {
        
//         if (fade) {
//             // fade out alert
//             const alertWithFade = { ...alert, fade: true };
//             setAlerts(alerts => alerts.map(x => x === alert ? alertWithFade : x));

//             // remove alert after faded out
//             setTimeout(() => {
//                 setAlerts(alerts => alerts.filter(x => x !== alertWithFade));
//             }, 250);
//         } else {
//             // remove alert
//             setAlerts(alerts => alerts.filter(x => x !== alert));
//         }
//     }


//     useEffect((id) => {
//         // subscribe to new alert notifications
//         const subscription = alertService.onAlert(id)
//             .subscribe(alert => {
//                 // clear alerts when an empty alert is received
//                 if (!alert.message) {
//                     setAlerts(alerts => {
//                         // filter out alerts without 'keepAfterRouteChange' flag
//                         const filteredAlerts = alerts.filter(x => x.keepAfterRouteChange);

//                         // remove 'keepAfterRouteChange' flag on the rest
//                         filteredAlerts.forEach(x => delete x.keepAfterRouteChange);
//                         return filteredAlerts;
//                     });
//                 } else {
//                     // add alert to array
//                     setAlerts(alerts => ([...alerts, alert]));

//                     // auto close alert if required
//                     if (alert.autoClose) {
//                         setTimeout(() => removeAlert(alert), 3000);
//                     }
//                 }
//             });

//         // clear alerts on location change
//         const historyUnlisten = history.listen(({ pathname }) => {
//             // don't clear if pathname has trailing slash because this will be auto redirected again
//             if (pathname.endsWith('/')) return;

//             alertService.clear(id);
//         });

//         // clean up function that runs when the component unmounts
//         return () => {
//             // unsubscribe & unlisten to avoid memory leaks
//             subscription.unsubscribe();
//             historyUnlisten();
//         };
//     }, [removeAlert]);

  
//     function cssClasses(alert) {
//         if (!alert) return;

//         const classes = ['alert', 'alert-dismissable'];
                
//         const alertTypeClass = {
//             [AlertType.Success]: 'alert alert-success',
//             [AlertType.Error]: 'alert alert-danger',
//             [AlertType.Info]: 'alert alert-info',
//             [AlertType.Warning]: 'alert alert-warning'
//         }

//         classes.push(alertTypeClass[alert.type]);

//         if (alert.fade) {
//             classes.push('fade');
//         }

//         return classes.join(' ');
//     }

//     if (!alerts.length) return null;

//     return (
//         <div className="container">
//             <div className="m-3">
//                 {alerts.map((alert, index) =>
//                     <div key={index} className={cssClasses(alert)}>
//                         <Button className="close" onClick={() => removeAlert(alert)}>&times;</Button>
//                         <span dangerouslySetInnerHTML={{__html: alert.message}}></span>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// }

// Alert.propTypes = propTypes;
// Alert.defaultProps = defaultProps;
// export { Alert };