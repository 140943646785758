import React, { useState } from "react";
import { Tab, Nav, Row, Col, Modal, ModalDialog } from "react-bootstrap";
import { Ipsum } from "../../Ipsum";
import { ItemGroupInfo } from "./itemGroupDetails/itemGroupInfo";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';


import "./addedititemgrouppage.css";



function AddEditItemGroupPage({
  itemGroup,
  visible,
  onInputValueChanged,
  cancelHandler,
  saveHandler,
  isSubmitting,
  isEditing,
  showModal,
  keyPressed
}) {

const windowWidth = useWindowDimensions().width;


  return (
    <>
      <Modal
        show={showModal}
        onHide={cancelHandler}
        dialogClassName={"inventorydash_custom_modal_dialog"}
        centered = {windowWidth <= 576 ? false : true}
      >
        <Modal.Header
        closeButton bsPrefix={"custom_modal_header vendor_modal"}>
         
            <ActionBarAllThings
              showBackBtn
              cancelHandler={cancelHandler}
              pageTitle={"Item Groups"}
              showSaveBtn
              saveBtnText={isEditing ? "Update" : "Save"}
              showDelBtn={isEditing} //if item is being edited, delete btn shows
              saveHandler={saveHandler}
              onModal={true}
            />
         
        </Modal.Header>
        <Modal.Body bsPrefix={"custom_modal_body"}>
        <div className={'page_container_padding'}>
          <Row >
            <Col className="item_groups_col noselect">
              <ItemGroupInfo
                itemGroup={itemGroup}
                onInputValueChanged={onInputValueChanged}
                cancelHandler={cancelHandler}
                saveHandler={saveHandler}
                isSubmitting={isSubmitting}
                isEditing={isEditing}
                keyPressed={keyPressed}
              />
            </Col>
          </Row></div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { AddEditItemGroupPage };
