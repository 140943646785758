import { fetchService } from '../../services';

export const FETCH_USER_BEGIN   = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

//Fetch
//##########################################################################
export const fetchUserBegin = () => ({
  type: FETCH_USER_BEGIN
});

export const fetchUserSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  payload: { user }
});

export const fetchUserFailure = error => ({
  type: FETCH_USER_FAILURE,
  payload: { error }
});

export function fetchUser(userid) {

    if (userid === '')
    {
        // Creating a new user, special case
        userid = '000-000-000';
        
    }
    
    return dispatch => {
      dispatch(fetchUserBegin());

      return fetchService.get( "/useradmin/" + userid )
        .then(json => {
          dispatch(fetchUserSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchUserFailure(error)));
    };
  }
//End Fetch
//##########################################################################
