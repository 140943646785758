import React from "react";
import { divideDecimal, multDecimal, subtractDecimal } from "../../utilities";
import { formatCurrency } from '../../utilities/currencyUtil';
import { SalesOrderLineOptions } from "./SalesOrderLineOptions";

export default function SalesOrderViewOnlyLineItem({ 
    item, 
    expandLine, 
    receiveAll, 
    id, 
    inputref, 
    deleteInventoryItem, 
    setSelectedItemId,
}) {

    // const memoizedDetails = useMemo(() => getItemDetailArray(), [item]);
    const memoizedDetails = getItemDetailArray();

    function setActiveItem() {
        
        setSelectedItemId(item.id);
    }

    function calculatedTotal() {
        let calctotal = 0;
        if (item) {
            calctotal = multDecimal(item.quantity, item.purchasePrice);
        }

        return calctotal;
    }

    //FIXME This function is not working 
    function getItemReceivedQuantity() {
        let receivedQuanity = '';
        if (item.quantity) {
            receivedQuanity = ((item?.quantity - (item?.outstandingQuantity ? item?.outstandingQuantity : '')?.toString() + ' of ' + item?.quantity?.toString()));
        }
        return receivedQuanity;
    }



    function getItemDetailArray() {
        let detail = [
            {
                label: 'Item',
                value: item.inventoryItemName,
                className: 'purchase-order-line-item-left-align purchaseorderline_item'
            },
            {
                label: 'Qty',
                value: item.quantity,
                className: 'purchase-order-line-item-center-align purchaseorderline_qty'
            },
            {
                label: 'Price',
                value: formatCurrency(item.purchasePrice),
                className: 'purchase-order-line-item-center-align purchaseorderline_price'
            },

            {
                label: 'Total',
                // value: formatCurrency(item.calculatedTotal),
                value: formatCurrency(calculatedTotal()),
                className: 'purchase-order-line-item-center-align purchaseorderline_total'
            },
            {
                label: 'Shipped',
                value: (item?.quantity - item?.outstandingQuantity)?.toString() + ' of ' + item?.quantity?.toString(),
                className: 'purchase-order-line-item-center-align purchaseorderline_received'
            },
            {
                label: 'Status',
                value: (item?.outstandingQuantity == 0 && item?.quantity == 0 ? 'N/A' : item?.outstandingQuantity == 0 ? 'Fulfilled' : item?.outstandingQuantity == item?.quantity ? 'Unfulfilled'  : 'Partial'),
                // value: item.status,
                //REVIEW item.status was coming in as an empty string, so I modified the UI to show the status, but it is not coming in as a prop
                className: 'purchase-order-line-item-center-align purchaseorderline_status'
            },

        ];
        return detail;
    }
    return (
        <>
            {/* Make a grid layout div, with 2 rows and use column headers to display info, more info in one line */}

            <div
                id={id}
                ref={inputref}
                className={'sales-order-view-only-line-item-outer-container'}
                onClick={setActiveItem}
            >
                <div className="salesorder_line_options">
                    {/* REVIEW I dont think we need this code
                    <SalesOrderLineOptions
                        expandLine={setActiveItem}
                        deleteItem={deleteInventoryItem}
                        receiveAll={receiveAll}
                    /> */}
                </div>
                <div className={'sales-order-line-item-view-detail'}>
                    {

                        memoizedDetails.map((d, index) => (
                            <LineItemDetail
                                key={index}
                                detail={d}

                            />
                        ))
                    }
                </div>
             
            </div>
        </>
    )
}

const LineItemDetail = ({ detail }) => {

    return (
        <div className={'purchase-order-line-item-detail-container ' + (detail.className)}>
            <div className={'noselect purchase-order-line-item-detail-header ' + (detail.className)}>
                {detail.label}
            </div>
            <div className={'noselect purchase-order-line-item-detail-value ' + detail.className}>
                {detail.value}
            </div>
        </div>
    )
}