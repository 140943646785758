import { fetchService } from "../../services";

export const FETCH_CONTACT_BEGIN = 'FETCH_CONTACT_BEGIN';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_CONTACT_FAILURE = 'FETCH_CONTACT_FAILURE';
export const RESET_CONTACT_LOADED = 'RESET_CONTACT_LOADED';

//#region FETCH CONTACT

export const fetchContactBegin = () => ({
    type: FETCH_CONTACT_BEGIN
});

export const fetchContactSuccess = contact => ({
    type: FETCH_CONTACT_SUCCESS,
    payload: { contact }
});

export const fetchContactFailure = error => ({
    type: FETCH_CONTACT_FAILURE,
    payload: { error }
});


export function fetchContact(contactid) {
    return dispatch => {
        dispatch(fetchContactBegin());

        return fetchService.get(`/contacts/${contactid}`)
         .then(json => {
            dispatch(fetchContactSuccess(json));
            return json;
         })
         .catch(error => dispatch(fetchContactFailure(error)));
    };
};



export const resetContactLoaded = () => ({
    type: RESET_CONTACT_LOADED
});


export function resetContact() {
    return dispatch => {
        dispatch(resetContactLoaded());
    }
}


// Darren 11/9, this should get a contact with id and let the API decide if it is new or existing
// export function fetchNewContact() {
//     return dispatch => {
//         dispatch(fetchContactBegin());

//         return fetchService.get(`/contacts/new`)
//          .then(json => {
//             dispatch(fetchContactSuccess(json));
//             return json;
//          })
//          .catch(error => dispatch(fetchContactFailure(error)));
//     };
// };

//#endregion