import React, { useState, useEffect } from "react";
import { fetchSalesOrder } from "../../../store/actions/singleSalesOrderActions";
import { useDispatch, useSelector } from "react-redux";
import InventoryDashCheckbox from "../../Controls/InventoryDashCheckbox";
import { Col, Row, Form, Modal } from "react-bootstrap";
import { formatCurrency } from "../../utilities/currencyUtil";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import "./finalizereturns.css";
import FinalizeSummary from "./FinalizeSummary";

export default function FinalizeReturn({
  selectedSalesOrder,
  returnFee,
  setReturnFee,
  returnItemsSelected,
  setReturnItemsSelected,
  calculateQty,
  calculateSubTotal,
  calculateTotal,
  returnQtys,
  setReturnQtys,
  existingReturn,
  setMasterChecked,
  salesorder,
  loading,
}) {
  const dispatch = useDispatch();

  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;
  const isMobileWidth = windowWidth <= 576;
  const isMobileScreen = windowWidth <= 576 || windowHeight <= 576;

  useEffect(() => {
    if (selectedSalesOrder != null) {
      dispatch(fetchSalesOrder(selectedSalesOrder.id));
    }
  }, [selectedSalesOrder]);

  useEffect(() => {
    // return;
    if (salesorder == null) {
      return;
    }
    if (existingReturn == null) {
      return;
    }
    if (salesorder.salesOrderItems == null) {
      return;
    }

    var selectedItems = [];
    var quantities = {};
    existingReturn.returnItems.map((x) => {
      if (x.salesOrderItemId != null) {
        var item = salesorder.salesOrderItems.find(
          (i) => i.id == x.salesOrderItemId
        );
        item.returnQty = x.returnQty;
        selectedItems.concat(item);
        quantities[item.id] = x.returnQty;
      }
    });
    setReturnItemsSelected(selectedItems);
    setReturnQtys(quantities);
    console.log(returnItemsSelected);
  }, [salesorder, existingReturn]);

  const handleMasterCheckbox = (e) => {
    if (e.target.checked) {
      setMasterChecked(true);
      const tempReturnItems = salesorder.salesOrderItems.map((item) => {
        item.returnQty = item.quantity;
        return item;
      });
      setReturnItemsSelected(tempReturnItems);
      const tempReturn = {};
      salesorder.salesOrderItems.forEach((item) => {
        tempReturn[item.id] = item.quantity;
      });
      setReturnQtys(tempReturn);
    } else {
      setMasterChecked(false);
      setReturnItemsSelected([]);
      setReturnQtys({});
    }
  };

  //controls what happens when child checkboxes for an item is selected - if selected, the input value for the item to be returns shows as max
  //if de-selected, the value changes to zero
  const handleChildCheckboxChange = (e, item) => {
    if (e.target.checked) {
      item.returnQty = item.quantity;
      setReturnItemsSelected([...returnItemsSelected, item]);
      setReturnQtys({ ...returnQtys, [item.id]: item.quantity });
      if (returnItemsSelected.length + 1 == salesorder.salesOrderItems.length) {
        setMasterChecked(true);
      }
    } else {
      const updatedReturnItems = returnItemsSelected.filter(
        (order) => order.id != e.target.name
      );
      setReturnItemsSelected(updatedReturnItems);
      setReturnQtys({ ...returnQtys, [item.id]: 0 });
      setMasterChecked(false);
    }

  };

  const handleReturnQtyBlur = (e, item) => {
    if (Number(e.target.value) > item.quantity) {
      e.target.value = item.quantity;
    }
    if (Number(e.target.value) < 0) {
      e.target.value = 0;
    }
    const updatedReturnQty =
      Number(e.target.value) > item.quantity
        ? item.quantity
        : Number(e.target.value);
    setReturnQtys({ ...returnQtys, [item.id]: updatedReturnQty });
    const updatedReturnItems = returnItemsSelected.map((element) => {
      if (element.id === item.id) {
        element.returnQty = Number(e.target.value);
      }
      return element;
    });
    setReturnItemsSelected(updatedReturnItems);
  };

  //checking if salesorder and salesorderItems is null, then rendering the rows for each item within the sales order
  const buildReturnItem = () => {
    if (salesorder == null) {
      return "";
    }
    if (salesorder.salesOrderItems == null) {
      return "";
    }
    if (Array.isArray(salesorder.salesOrderItems)) {
      return salesorder.salesOrderItems.map((item, i) => {
        const itemFound = returnItemsSelected.find(
          (returnItem) => returnItem.id == item.id
        );
        if (item.deleted != true) {
          return (
            <Row key={i} className="returnItemRow">
              <Col className="returnCheckbox">
                <InventoryDashCheckbox
                  onChange={(e) => handleChildCheckboxChange(e, item)}
                  name={item.id}
                  checked={itemFound ? true : false}
                />
              </Col>
              <Col className="returnItemData">{item.inventoryItemName}</Col>
              <Col className="returnItemData">{item.quantity}</Col>
              <Col className="returnItemData">
                {formatCurrency(item.purchasePrice)}
              </Col>
              <Col className="returnItemData">
                <input
                  className="itemInput"
                  type="number"
                  min="0"
                  max={item.quantity}
                  onChange={(e) => handleReturnQtyBlur(e, item)}
                  disabled={itemFound ? false : true}
                  value={itemFound ? returnQtys[item.id] : 0}
                  name={item.id}
                />
              </Col>
              <Col className="returnItemData">
                {returnQtys[item.id]
                  ? formatCurrency(item.purchasePrice * returnQtys[item.id])
                  : ""}
              </Col>
            </Row>
          );
        }
      });
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <>
      <Modal.Body bsPrefix={"inventorydash_custom_modal_body"}>
        <div className="finalizeReturn">
          <div className="finalzeReturnTable">{buildReturnItem()}</div>
        </div>
      </Modal.Body>
      <Modal.Footer bsPrefix={"inventorydash_custom_modal_footer"}>
        <FinalizeSummary
          salesorder={selectedSalesOrder}
          returnItemsSelected={returnItemsSelected}
          returnFee={returnFee}
          setReturnFee={setReturnFee}
          calculateQty={calculateQty}
          calculateSubTotal={calculateSubTotal}
          calculateTotal={calculateTotal}
        />
      </Modal.Footer>
    </>
  );
}
