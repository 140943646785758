import {
    FETCH_ITEMTAGS_BEGIN,
    FETCH_ITEMTAGS_SUCCESS,
    FETCH_ITEMTAGS_FAILURE,
  } from "../actions/itemTagActions";
  
  const initialState = {
    itemtags: [],
    loading: false,
    error: null,
    itemtagsLoaded: false,
  };
  
  export default function itemtagReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_ITEMTAGS_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          itemtagsLoaded: false,
        };
  
      case FETCH_ITEMTAGS_SUCCESS:
        return {
          ...state,
          loading: false,
          itemtags: action.payload.itemtags,
          itemtagsLoaded: true,
        };
  
      case FETCH_ITEMTAGS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          itemtags: [],
        };
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }
  