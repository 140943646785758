import React, { useState } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import "./windowedtable.css";
import { useEffect } from "react";
import { useSortableData, useWindowDimensions } from "../../../hooks";
import { FaAnchor, FaSortDown, FaSortUp } from "react-icons/fa";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import ColumnSelector from "../ColumnSelector";
import { CurrencyFormat, getShortDate} from '../../utilities';

function WindowedTable({
  inputitems,
  colheaders,
  idname,
  selectedId,
  setSelectedId,
  searchText = "",
  defaultSort = "",
  showSort = null,
  showColHeader = true, //mark this as false if you do not want col header to display
   showColumnSelector,
   setShowColumnSelector,
   setColumnsList,
   tablenameidentifier
  
}) {
  const { items, requestSort, config } = useSortableData(inputitems);
  const isMobile = useWindowDimensions().width < 768;
  const [sortListPopupVisible, setSortListPopupVisible] = useState(false);
  const [ columns, setColumns] = useState([]);
  const [originalColumns, setOriginalColumns] = useState(null);
  const rowHeight = isMobile ? 16 * colheaders.length : 18;

  useEffect(() => {
    setColumns([]);
}, [colheaders])

useEffect(() => {
  if (showColumnSelector === true && originalColumns == null && colheaders && colheaders?.length > 0) {
      setOriginalColumns(JSON.parse(JSON.stringify(colheaders)));
  } else {
      if (showColumnSelector === false) {
      }
  }
}, [showColumnSelector]);

  useEffect(() => {
    if (defaultSort !== "") {
      requestSort(defaultSort);
    }
  }, []);

  function getFilteredItems() {
    if (searchText === "") {
      return items;
    } else {
      return search();
    }
  }

  function search() {
    // using the spread operator to create a new array
    let newArr = [];
    let i = 0;
    for (i = 0; i < colheaders?.length; i++) {
      if (colheaders[i].nonSearchable !== true) {
        let propname = colheaders[i].propName;
        //Search for anything in any of the columns that matches the search
        try {
          newArr.push(
            ...items.filter((c) =>
              c[propname]?.toLowerCase().includes(searchText.toLowerCase())
            )
          );
        } catch { }
      }
    }

    // The following line filters based on a specific property name
    //    newArr.push(...items.filter(c => c.displayname.toLowerCase().includes(searchText.toLowerCase())));

    //This will remove any duplicates in the array
    const ar = [...new Set(newArr)];
    return ar;
  }

  function sortListRequestSort(propName) {
    requestSort(propName);
    setSortListPopupVisible(false);
  }

    function cancelColumnModal() {
        let cols = [];
        let colhd = [...colheaders]

        originalColumns
        .sort(function (a, b) {
            return (a.displayorder - b.displayorder);
        })
        .filter(col => col.selected !== false)
        .forEach(col => {
            cols.push(
                {
                    key: col.propName,
                    width: 100,
                    title: col.displayName
                }
            )

            var item = colhd.find(x => x.propName === col.propName);
            if (item != null) {
                item.displayorder = col.displayorder;
                item.selected = col.selected;
            }
        });
        setColumns(cols);
        setColumnsList(colhd);
        setOriginalColumns(null);
        setShowColumnSelector(false);
  }

  //ANCHOR - 
  function saveColumnChanges() {
//this switch sets each table column headers in memory. Need to add the following function to parent component to be called within the colHeader useState:

//useState example: const [columnsList, setColumnsList] = useState(determineColumns());

/*functiojn example: 
function determineColumns() {
  if (!localStorage.getItem('itemstable')) {
    return colHeaders
  } else {
    return JSON.parse(localStorage.getItem('itemstable'));
  }
} 
*/

    switch (tablenameidentifier) {
      case 'itemstable':
        localStorage.setItem('itemstable', JSON.stringify(colheaders));
      break;
      case 'customers_page_display_table':
        localStorage.setItem('customers_page_display_table', JSON.stringify(colheaders));
      break;
      case 'item_salesorders':
        localStorage.setItem('item_salesorders', JSON.stringify(colheaders));
      break;
      case 'item_purchaseorders':
        localStorage.setItem('item_purchaseorders', JSON.stringify(colheaders));
      break;
    }
    setOriginalColumns(null);
    setShowColumnSelector(false);
  }

   function columnChangeHandler(col) {
    let cols = [...colheaders];
    let colLength = cols.length;
    col.selected = !col.selected;

    for (let i = 0; i < colLength; i++) {
        cols[i].activeColumn = false; }
        col.activeColumn = true;
        setColumnsList(cols);
        return cols;
   }
   
    function columnMoveUp(selCol) {
      let cols = [...colheaders];
      let colLength = cols.length;
  
      for (let i = 0; i < colLength; i++) {
        cols[i].activeColumn = false; //resets all columns to activeColumn = false
        if (
          cols[i].displayorder === selCol.displayorder &&
          selCol.displayorder > 0
        ) {
          cols[selCol.displayorder - 1].displayorder = selCol.displayorder;
          cols[i].displayorder = selCol.displayorder - 1;
          selCol.activeColumn = true; //sets selected column activeColumn = true
        }
      }
      setColumnsList(cols);
    }

      function columnMoveDown(selCol) {
          let cols = [...colheaders];
          let colLength = cols.length;
      
          for (let i = 0; i < colLength; i++) {
            cols[i].activeColumn = false; //resets all columns to activeColumn = false
            if (
              cols[i].displayorder === selCol.displayorder &&
              selCol.displayorder + 1 != colLength
            ) {
              cols[selCol.displayorder + 1].displayorder = selCol.displayorder;
              cols[i].displayorder = selCol.displayorder + 1;
              selCol.activeColumn = true; //sets selected column activeColumn = true
            }
          setColumnsList(cols);
        }
      }


  return (
    <>
     {showColumnSelector ? (
        <ColumnSelector
          inputColumns={colheaders}
          cancelDialog={cancelColumnModal}
          saveHandler={saveColumnChanges}
          showModal={showColumnSelector}
          columnChangeHandler={columnChangeHandler}
          columnMoveUp={columnMoveUp}
          columnMoveDown={columnMoveDown}
        />
      ) : (
        <></>
      )}
      <div
        className={
          showColHeader
            ? "windowed_table_container table"
            : "windowed_table_container_noHeader"
        }
      >
        {showSort ? ( //when marked as true on child component, this will display the sort button
          <div className={"windowed_table_column_sorter"}>
            {sortListPopupVisible ? (
              <div className={"windowed_table_sort_list_container"}>
                <ListGroup>
                  {colheaders
                    .filter((c) => c.displayName !== "")
                    .map((col, index) => (
                      <ListGroupItem
                        key={index}
                        onClick={() => sortListRequestSort(col.propName)}
                      >
                        {col.displayName}
                        {config?.key === col.propName ? (
                          config?.direction !== "ascending" ? (
                            <FaSortDown size="1rem" className="ml-1" />
                          ) : (
                              <FaSortUp size="1rem" className="ml-1" />
                            )
                        ) : (
                            <FaSortDown size="1rem" className="ml-1" />
                          )}
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </div>
            ) : (
                <></>
              )}
            <Button
              className={"windowed_table_floating_sort_button"}
              onClick={() => setSortListPopupVisible(!sortListPopupVisible)}
            >
              {config?.direction !== "ascending" ? (
                <FaSortDown size="1rem" className="ml-1" />
              ) : (
                  <FaSortUp size="1rem" className="ml-1" />
                )}
            </Button>
          </div>
        ) : (
            <></>
          )}

        {showColHeader ? ( //when this is marked as true on child component, it will display the column headers
          <div className={"windowed_table_table_row_header"}>
            {
              <> 
                {colheaders.filter(c=> c.selected == true).map((c, colindex) => (
                  <span
                    key={colindex}
                    className={
                      "noselect windowed_table_table_cell" +
                      (c.colclassname == null ? "" : " " + c.colclassname)
                    }
                    onClick={() => requestSort(c.propName)}
                  >
                    <div key={colindex}>
                      {c.displayName}
                      {config?.key === c.propName ? (
                        config?.direction === "ascending" ? (
                          <FaSortDown size="1rem" className="ml-1" />
                        ) : (
                            <FaSortUp size="1rem" className="ml-1" />
                          )
                      ) : (
                          <></>
                        )}
                    </div>
                  </span>
                ))}
              </>
            }
          </div>
        ) : (
            <></>
          )}

        <AutoSizer>
          {({ height, width }) => (
            <>
              <List
                className={"windowed_table_container_div"}
                height={height}
                itemCount={items.length}
                itemSize={2.25 * rowHeight}
                width={width}
                itemData={[getFilteredItems(), colheaders, setSelectedId]}
              >
                {Row}
              </List>
            </>
          )}
        </AutoSizer>
      </div>
    </>
  );

  function getSelectedId() {
    return selectedId;
  }

  function Row({ index, style, data, ...rest }) {
    const itemArray = data[0];
    const colArray = data[1];
    const selectionFunction = data[2];
    let rowdata = itemArray[index];

    if (rowdata === undefined) {
      return <></>;
    }

    let itemid = rowdata[idname];

    style = {
      ...style,
    };

    let header = "";
    if (index === 0) {
      header = (
        <div className={"windowed_table_table_row"} style={style} key={index}>
          {
            <>
              {colArray.map((c, colindex) => (
                <span
                  key={index.toString() + colindex.toString()}
                  className={
                    "windowed_table_table_cell" +
                    (c.colclassname == null ? "" : " " + c.colclassname)
                  }
                >
                  <div>{c.displayName}</div>
                </span>
              ))}
            </>
          }
        </div>
      );
    }

    

    function getRowVal(rowval, col) {
      let output = rowval;
      //function added to display data that is not available as a prop BS 11/17
      if (col.hasFunction === true) {
        return col.dataFunction(output);
      }
      return output;
    }
    function getRowVal(rowval, col, rowdata) {
      let output = rowval;
        //function added to display data that is not available as a prop BS 11/17
      if (col.hasFunction === true) {
        // return col.dataFunction(output);
        //FIXME ^^^ had to comment this out because it was breaking

      }
      if (col.iscurrency) {
          return (
              <CurrencyFormat input={rowval}
              />
          )
      }
      if (col.isdate) {
          output = getShortDate(rowval);
      }
   
      return output;
  }

    return (
      <>
        <div
          className={
            "windowed_table_table_row" +
            (itemid === getSelectedId() ? " selected" : "")
          }
          style={style}
          key={index}
          onClick={() => selectionFunction(rowdata)}
        >
          {showColHeader ? (
            <>
              {colArray.filter(c=> c.selected == true).map((c, colindex) => (
                <span
                  th-data={c.isIconCol ? c.tooltip : c.displayName}
                  key={index.toString() + colindex.toString()}
                  className={
                    "windowed_table_table_cell" +
                    (c.isIconCol
                      ? " windowed_table_table_icon_cell"
                      : " windowed_table_table_text_cell") +
                    (c.dataclassname == null ? "" : " " + c.dataclassname)
                  }
                  onClick={
                    c.onclickfunction == null
                      ? null
                      : (e) => c.onclickfunction(rowdata)
                  }
                >
                  <div>{getRowVal(rowdata[c.propName], c)}</div>
                </span>
              ))}
            </>
          ) : (
              <>
                {colArray.map((c, colindex) => (
                  <span
                    //   th-data={c.isIconCol ? c.tooltip : c.displayName}
                    key={index.toString() + colindex.toString()}
                    className={
                      "windowed_table_table_cell" +
                      (c.isIconCol
                        ? " windowed_table_table_icon_cell"
                        : showColHeader
                          ? " windowed_table_table_text_cell"
                          : "") +
                      (c.dataclassname == null ? "" : " " + c.dataclassname)
                    }
                    onClick={
                      c.onclickfunction == null
                        ? null
                        : (e) => c.onclickfunction(rowdata)
                    }
                  >
                    <div>{getRowVal(rowdata[c.propName], c)}</div>
                  </span>
                ))}
              </>
            )}
        </div>
      </>
    );
  }
}
export default WindowedTable;
