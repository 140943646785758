import React, { useEffect, useState } from "react"; //import useState from react
import {
  ListGroup,
  ListGroupItem,
  Form,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchItemLocations } from "../../../store/actions/itemLocationActions";
import { trackPromise } from "react-promise-tracker";
import { FaPlus, FaMinus } from "react-icons/fa";
import {
  IoIosCheckmarkCircleOutline,
  IoIosClose,
} from "react-icons/io";
import { BsBackspace } from "react-icons/bs";
import "./itemlocationtreecontrolselector.css";
import { LoadingPlaceholder } from "../../Controls/LoadingPlaceholder";

function ItemLocationsTreeControlSelector({ valueChanged, value, readOnly,  showLocationSelection, setShowLocationSelection }) {
  const locations = useSelector((state) => state.itemLocations.itemlocations);
  const loading = useSelector((state) => state.itemLocations.loading);
  const error = useSelector((state) => state.itemLocations.error);
  const locationsLoaded = useSelector(
    (state) => state.itemLocations.itemLocationsLoaded
  );

  const [currentLocation, setCurrentLocation] = useState(null);
  // const [showLocationSelection, setShowLocationSelection] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !locationsLoaded) {
      trackPromise(dispatch(fetchItemLocations()));
    }
  }, [locationsLoaded, dispatch, loading]);

  useEffect(() => {
    if (locationsLoaded) {
      if (currentLocation?.locationId !== value) {
        populateCurrentLocation(value);
      }
    }
    function populateCurrentLocation(locationid) {
      let location = searchForItem(locations, locationid);
      setCurrentLocation(location);
    }

  }, [value, locationsLoaded, currentLocation]);

  function getCurrentLocationDisplayName() {
    if (currentLocation != null) {
      return currentLocation.displayName;
    } else {
      return "";
    }
  }


  function searchForItem(locations, locationid) {
    if (!locations) {
      return;
    }

    for (const location of locations) {
      // Test current object
      if (location.id === locationid) {
        return location;
      }

      // Test children recursively
      const childLocation = searchForItem(location.childLocations, locationid);
      if (childLocation) {
        return childLocation;
      }
    }
  }

  function cancelSelection() {
    setShowLocationSelection(false);
  }

  function confirmSelection(location) {
    let locationid = 0;
    let locationname = "";
    if (location != null) {
      locationid = location.id;
      locationname = location.displayName;
    }
    valueChanged(locationid, locationname);
    setShowLocationSelection(false);
  }

  if (loading) {
    return <LoadingPlaceholder labeltext={"Location"} />;
  }
  if (error) {
    return <>{error}</>;
  }

  return (
    <>
      <div>
        <Form.Group>
          <Form.Label>Location</Form.Label>
          <InputGroup className="mb-3">
            {showLocationSelection ? (
              <LocationPopup
                locations={locations}
                cancelSelection={cancelSelection}
                confirmSelection={confirmSelection}
                currentLocation={currentLocation}
              />
            ) : (
                <></>
              )}

            <FormControl
              aria-label="Location"
              value={getCurrentLocationDisplayName()}
              placeholder={getCurrentLocationDisplayName()}
              readOnly={true}
            />

            {
              !readOnly
                ?
                <InputGroup.Append>
                  <Button onClick={() => setShowLocationSelection(true)}>
                    {"Select"}
                  </Button>
                </InputGroup.Append>
                :
                <></>
            }
          </InputGroup>
        </Form.Group>
      </div>
    </>
  );
}

function LocationPopup({
  locations,
  confirmSelection,
  cancelSelection,
  currentLocation,
}) {
  const [selectedLocation, setSelectedLocation] = useState(currentLocation);

  function setCurrentLocation(e, location) {
    e.stopPropagation();
    setSelectedLocation(location);
  }

  function clearSelection() {
    setSelectedLocation(null);
  }

  return (
    <>
      <div
        className={"tree-control-selector-backdrop"}
        onClick={cancelSelection}
      />
      <div className={"tree-control-selector-outer-container"}>
        <div className={"tree-control-selector-button-section"}>
          <div className={"tree-control-selector-selection-text"}>
            {"Selected: " +
              (selectedLocation?.displayName === undefined
                ? ""
                : selectedLocation?.displayName)}
          </div>
          <Button
            className={"location_selection_btn_close"}
            onClick={cancelSelection}
          >
            <IoIosClose size={"1.5rem"} />
          </Button>

          {selectedLocation === currentLocation && selectedLocation != null ? (
            <Button
              className={"location_selection_btn"}
              onClick={clearSelection}
            >
              <BsBackspace size={"1.75rem"} />
            </Button>
          ) : (
              <></>
            )}

          {selectedLocation != currentLocation ? (
            <Button
              className={"location_selection_btn"}
              onClick={() => confirmSelection(selectedLocation)}
            >
              <IoIosCheckmarkCircleOutline size={"2rem"} />
            </Button>
          ) : (
              <></>
            )}
        </div>
        <div className={"tree-control-selector-inner-container"}>
          <div className={"tree-control-selector"}>
            <ListGroup>
              {locations.map((loc, index) => (
                <TreeListItem
                  key={index}
                  location={loc}
                  setCurrLocationLocal={setCurrentLocation}
                  selectedLocationId={selectedLocation?.id}
                />
              ))}
            </ListGroup>
          </div>
        </div>
      </div>
    </>
  );
}

function TreeListItem({ location, setCurrLocationLocal, selectedLocationId }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <ListGroupItem
      onClick={(e) => setCurrLocationLocal(e, location)}
      className={
        "tree-control-select-list-item noselect" + (expanded ? " expanded" : "")
      }
    >
      <div className={"tree-item-icon-text"}>
        <div
          className={"tree-control-expand-box"}
          onClick={() => setExpanded(!expanded)}
        >
          {
            location.childLocations.length > 0 ? (
              expanded ? (
                <FaMinus size={".75rem"} />
              ) : (
                  <FaPlus size={".75rem"} />
                )
            ) : (
                <span className={"tree-control-select-list-spacer"}></span>
              )

            // <FaDotCircle size={'.75rem'} />
          }
        </div>

        <span
          className={
            "tree-control-item-text" +
            (location.id === selectedLocationId
              ? " selected-tree-list-item"
              : "")
          }
        >
          {location.displayName}
        </span>
      </div>

      {expanded ? (
        <div className={"tree-list-children expanded"}>
          <ListGroup>
            {location.childLocations.map((loc, index) => (
              <TreeListItem
                key={index}
                location={loc}
                setCurrLocationLocal={setCurrLocationLocal}
                selectedLocationId={selectedLocationId}
              />
            ))}
          </ListGroup>
        </div>
      ) : (
          //  <div className={'tree-list-children'} />
          <></>
        )}

      {/* <Button>Add Location</Button> */}
    </ListGroupItem>
  );
}

export { ItemLocationsTreeControlSelector };
