import {
    UPDATE_SELECTED_ITEM
  } from '../actions/displayTableSelectedItemActions';
  
  const initialState = {
    displayitemids: [{
        tablename: '',
        displayitemid: 0
    }]
  };
  
  export default function itemGroupReducer(state = initialState, action) {
    switch(action.type) {
      case UPDATE_SELECTED_ITEM:
        let existingdisplayitem = state.displayitemids.find(e => e.tablename === action.payload.tablename);
        let index = state.displayitemids.indexOf(existingdisplayitem);

        if (index < 0) {
          // Add the new Group to the end of the list
          return {
            ...state,
            displayitemids: [...state.displayitemids.concat(action.payload)]
          };
        } else {
          // We need to slice the array and insert the updated Group
          // This keeps everything clean as far as the rules of immutable state go

          return {
            ...state,
            displayitemids: [
              ...state.displayitemids.slice(0,index)
              .concat(action.payload)
              .concat(state.displayitemids.slice(index+1))
            ]
          };
        }


        // return {
        //   ...state,
        //   displayitemids: action.payload.id
        // };

      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }