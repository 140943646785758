import React, { useState, useEffect } from "react";
import {
  fetchItemGroups,
  updateItemGroups,
  deletedItemGroup
} from "../../../store/actions/itemGroupActions";
import { trackPromise } from "react-promise-tracker";
import { useSelector, useDispatch } from "react-redux";
import { AddEditItemGroupPage } from "./AddEditItemGroupPage";
import { fetchService, alertService } from "../../../services";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import DisplayTable from "../../Controls/DisplayTable";
import { Link } from 'react-router-dom';
import SimpleDisplayTable from "../../Controls/SimpleDisplayTable";
import WindowedTable from "../../Controls/Tables/WindowedTable";
import { DeleteItemConfirmation } from '../../Dialogs';

const colHeaders = [
  {
    displayName: "Group Name",
    propName: "displayName",
    colclassname: "",
    dataclassname: "",
    selected: true,
    displayorder: 0,
  },
];

function ItemGroupPage({ actionBarAdd, actionBarEdit, actionBarDelete, resetActionBarActions, setSelectedGroupId }) {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currGroup, setCurrGroup] = useState({
    id: 0,
    displayName: "",
  });

  const groups = useSelector((state) => state.itemGroups.itemgroups);
  const loading = useSelector((state) => state.itemGroups.loading);
  const error = useSelector((state) => state.itemGroups.error);
  const [showEditBtn, setShowEditBtn] = useState(false);
 
  const itemGroupsLoaded = useSelector(
    (state) => state.itemGroups.itemgroupsLoaded
  );
  const [editingItemGroup, setEditingItemGroup] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalText, setDeleteModalText] = useState('');
  const [currentGroupId, setCurrentGroupId] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!itemGroupsLoaded) {
      trackPromise(dispatch(fetchItemGroups()));
    }
  }, [itemGroupsLoaded, dispatch]);


   // Going to use a side effect to watch for changes to the action bar incoming variables
  // trigger a modal on add/edit
  useEffect(() => {
    if (actionBarAdd && !showModal) {
      addItemGroup();
      resetActionBarActions();
    }
  }, [actionBarAdd])

  useEffect(() => {
    if (actionBarEdit && !showModal) {
      editItemGroup();
      resetActionBarActions();
    }
  }, [actionBarEdit])

  useEffect(() => {
    if (actionBarDelete && !showDeleteModal) {
      deleteItemGroup();
      resetActionBarActions();
    }
  }, [actionBarDelete])

  function cancelDialog() {
    setShowModal(false);
  }
  
  function setSelectedGroup(group) {
    setCurrGroup(group);
    setShowEditBtn(true);
    setSelectedGroupId(group.id);
    setCurrentGroupId(group.id);
  }

  function saveGroup() {
    if (currGroup.id === undefined) {
      currGroup.id = 0;
    }

    // Determine if we are creating or updating.
    if (currGroup.id === 0) {
      createGroup();
    } else {
      updateGroup();
    }
  }

  // Create a brand new ItemGroup
  function createGroup() {
    if (currGroup !== undefined) {
      setIsSubmitting(true);
      setShowModal(true);

      fetchService
        .put("/itemgroups/", currGroup)
        .then((json) => {
          //Success
          dispatch(updateItemGroups(json));
          setIsSubmitting(false);
          cancelDialog();
        })
        .catch((error) => {
          setIsSubmitting(false);
          alertService.error(error);
          return;
        });
    }
  }

  // Update an existing ItemGroup
  function updateGroup() {
    if (currGroup !== undefined) {
      setIsSubmitting(true);
     
      fetchService
        .post(`/itemgroups/${currGroup.id}`, currGroup)
        .then((json) => {
          //Success
          dispatch(updateItemGroups(json));
          setIsSubmitting(false);
          cancelDialog();
        })
        .catch((error) => {
          setIsSubmitting(false);
          alertService.error(error);
          return;
        });
    }
  }

  function deleteItemGroup() {
    if (currGroup != null)
    {
      // Set Text for the delete modal
      // show delete modal
      let deleteText = 'Are you sure you wish to permanently delete "' + currGroup.displayName + '"?';
      setDeleteModalText(deleteText);
      setShowDeleteModal(true);
    }
  }

  function deleteGroup() {
    // Actually delete the thing
    if (currGroup != null)
    {
     trackPromise(
         fetchService.delete(`/itemgroups/${currGroup.id}`)
     )
     .then(json => {
         //Success
         dispatch(deletedItemGroup(currGroup.id));
         setShowDeleteModal(false);
     })
     .catch(error => {
         alertService.error(error);
         return;
     }); 
   }
}

function deleteCancelHandler() {
 setShowDeleteModal(false);
}
  function onInputValueChanged(e) {
    e.persist();

    let newvalue = e.target.value;

    setCurrGroup((prevState) => ({
      ...prevState,
      [e.target.name]: newvalue,
    }));
  }

  function addItemGroup() {
    let itemGroup = {
      id: 0,
      displayName: "",
    };

    setEditingItemGroup(false);
    setCurrGroup(itemGroup);
    setShowModal(true);
  }

  function editItemGroup() {
    if (currGroup === undefined) return;
    if (currGroup.id === 0) return;
    setShowModal(true);
    setEditingItemGroup(true);
  }

  // function keyPressed(e) {
  //   if (e.key === "Enter") {
  //     saveGroup();
  //   }
  // }

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <>

    {
      showDeleteModal
      ?
        <DeleteItemConfirmation
          showModal={showDeleteModal}
          displayText={deleteModalText}
          confirmAction={deleteGroup}
          cancelAction={deleteCancelHandler}
        />
      :
      <></>
    }
      {/* <ActionBarAllThings
        showAddBtn={true}
        showEditBtn={showEditBtn}
        pageTitle={"Item Groups"}
        addItem={addItemGroup}
        editItem={editItemGroup}
        showBackBtn={true}
 
      />

      <div className="item_group_page_container page-container">
        {/* <DisplayTable
          inputitems={groups}
          colheaders={colHeaders}
          selectCurrentItem={setSelectedGroup}
          currentSelectedItem={currGroup}
          idname={"id"}
        /> */}
        <div className="item_group_page_container">
        <WindowedTable
          inputitems={groups}
          colheaders={colHeaders}
          selectCurrentItem={setSelectedGroup}
          currentSelectedItem={currGroup}
          idname={"id"}
          setSelectedId={setSelectedGroup}
          selectedId={currentGroupId}
          showColHeader={false}
        />
     

      <AddEditItemGroupPage
        showModal={showModal}
        setShowModal = {setShowModal}
        cancelHandler={cancelDialog}
        itemGroup={currGroup}
        onInputValueChanged={onInputValueChanged}
        saveHandler={saveGroup}
        isSubmitting={isSubmitting}
        isEditing={editingItemGroup}
        // keyPressed={keyPressed}
      />
      </div>
    </>
  );

}

export { ItemGroupPage };
