export const CHANGE_APP_THEME_STATE   = 'CHANGE_APP_THEME_STATE';
export const SHOW_HELP_VIEW_STATE = 'SHOW_HELP_VIEW_STATE';
export const SET_HELP_VIEW_TOPIC_STATE = 'SET_HELP_VIEW_TOPIC_STATE';

export function changeAppTheme(theme) {
  return dispatch => {
    dispatch(pushChangeAppTheme(theme))
    }
};
export const pushChangeAppTheme = theme => ({
  type: CHANGE_APP_THEME_STATE,
  payload: theme
});



export function showHelpView(show) {
  return dispatch => {
    dispatch(showHelpViewArgs(show))
    }
};
export const showHelpViewArgs = show => ({
  type: SHOW_HELP_VIEW_STATE,
  payload: show
});

export function setHelpViewTopic(topic) {
  return dispatch => {
      dispatch(setHelpViewTopicArgs(topic))
    }
};
export const setHelpViewTopicArgs = topic => ({
  type: SET_HELP_VIEW_TOPIC_STATE,
  payload: topic
});



