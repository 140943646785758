import React, { useState, useEffect } from "react"; //import useState from react
import { Button, Container, Card, Row, Col, ToggleButton, Modal } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { InventoryDashModal } from "../../Layout/modal/InventoryDashModal";
import "./emailComponent.css";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { InventoryDashModalHeader } from "../../Layout/modal/InventoryDashModalHeader";
import { InventoryDashModalBody } from "./../../Layout/modal/InventoryDashModalBody";
import { InventoryDashModalFooter } from "./../../Layout/modal/InventoryDashModalFooter";
import { propTypes } from "react-bootstrap/esm/Image";
import { Prev } from "react-bootstrap/esm/PageItem";
import ModalDialog from 'react-bootstrap/ModalDialog'

//import { TopRowButtonsAddEditFilterColumns, TopRowButtonsAddEdit} from "../../Layout/ButtonTopRow";

export function EmailComponent({}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ActionBarAllThings
        pageTitle={"Email"}
        showSaveBtn={true}
        saveHandler={() => setShowModal(true)}
        saveBtnText={"open"}
      />


      
        <Modal
          show={showModal}
          dialogClassName={'inventorydash_custom_modal_dialog'}
          scrollable
          onHide={() => setShowModal(false)}
          
        >
          <Modal.Header
            bsPrefix={'inventorydash_custom_modal_header'}
            closeButton
          >
              {/* <h2>header</h2>
              <h2>header</h2> */}
              <ActionBarAllThings 
                pageTitle={'Test Modal'}
              />
              {/* <div className="actionbar_replacement"></div> */}
              <div className="modal-tabs"></div>
          </Modal.Header>
          <Modal.Body
            bsPrefix={'inventorydash_custom_modal_body'}
           
          >
            <>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            <h2>Body</h2>
            </>
          </Modal.Body>
          <Modal.Footer className='inventorydash_custom_modal_footer'>
            <h2>Footer</h2>
            <h2>Footer</h2>
          </Modal.Footer>
        </Modal>
    </>
  );
}

export default EmailComponent;
