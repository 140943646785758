import React, { useState, useEffect, useRef } from "react";
import { Tab, Nav, Form, Modal } from "react-bootstrap";
import "./addedititempage.css";
import { ItemInfo, ItemDetail, ItemVendor } from "./itemdetails";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerMeasurementTypes } from "../../../store/actions/customerMeasurementTypesActions";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { fetchItem } from "../../../store/actions/itemActions";
import { useRolePermissions } from "../../../hooks/useRolePermissions";
// import { AddEditItemPageHeader } from "./AddEditItemPageHeader";
// import { AddEditItemPageBody } from "./AddEditItemPageBody";
import { AddEditItemPageContent } from "./AddEditItemPageContent";
import {
  updateItems,
} from "../../../store/actions/itemActions";

import { alertService, fetchService } from "../../../services";

export function AddEditItemPage({
  itemId,
  visible,
  onInputValueChanged,
  cancelHandler,
  itemSavedHandler,
  saveBtnText,
  isEditing,
  showModal,
  cancelDialog
}) {
  const inputitem = useSelector((state) => state.items.item);
  const itemLoading = useSelector((state) => state.items.itemLoading);
  const itemLoaded = useSelector((state) => state.items.itemLoaded);
  const itemError = useSelector((state) => state.items.itemError);
  const [locationTreeOpened, setLocationTreeOpened] = useState(false);
  const [unitSelectionType, setUnitSelectionType] = useState(0);
  const [showUnitSelection, setShowUnitSelection] = useState(false);
  const [unitTypeSourceName, setUnitTypeSourceName] = useState("");
  const [unitTypeDisplayName, setUnitTypeDisplayName] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedTab, setSelectedTab] = useState("item");
  const detailTabRef = useRef(null);
  const itemTabRef = useRef(null);
  const vendorTabRef = useRef(null);
  const unitMeasureTypes = useSelector((state) => state.measurementtypes.items);
  const unitMeasureTypesLoading = useSelector(
    (state) => state.measurementtypes.loading
  );
  const unitMeasureTypesLoaded = useSelector(
    (state) => state.measurementtypes.itemsLoaded
  );
  const unitMeasureTypesError = useSelector(
    (state) => state.measurementtypes.error
  );


  const [editingItem, setEditingItem] = useState(isEditing);
  const [isSubmitting, setIsSubmitting] = useState(false);



  const dispatch = useDispatch();

  const [item, setCurrItem] = useState();

  const readOnly = !useRolePermissions("Manage Items");

  useEffect(() => {
    if (
      !unitMeasureTypesLoading &&
      !unitMeasureTypesLoaded &&
      !unitMeasureTypesLoading
    ) {
      trackPromise(dispatch(fetchCustomerMeasurementTypes()));
    }
  }, []);

  useEffect(() => {
    if (visible) {
      if (!itemLoading && !itemLoaded) {
        trackPromise(dispatch(fetchItem(itemId)));
      }
    }
  }, [itemId, visible, itemLoaded, itemLoading]);

  useEffect(() => {
    setCurrItem({ ...inputitem });
  }, [inputitem]);

  /*This useEffect tests if the modal is closed and if closed sets selectedTab.
 This makes the first tab active when modal is opened again*/
  useEffect(() => {
    if (!showModal) {
      setSelectedTab("item");
    }
  }, [showModal]);

  function setItemLinkLabelItems(newlyAddedTags, linkedTags) {
    item.NewLabelTags = [...newlyAddedTags];
    item.LinkedLabelTags = [...linkedTags];
  }

  function selectUnitType(sourceobj, itemtype, sourcename, displayName) {
    setUnitSelectionType(itemtype);
    setShowUnitSelection(true);
    setUnitTypeSourceName(sourcename);
    setUnitTypeDisplayName(displayName);
  }
  function cancelUnitSelection() {
    setShowUnitSelection(false);
  }
  function saveUnitSelection() {}

  function updateUnitSelection(propname, typeid) {
    item[propname] = typeid;
    setForceUpdate(!forceUpdate);
  }

  function onInputValueChanged(e) {
    e.persist();

    let newvalue = e.target.value;
    let numericInputs = [
      "quantity",
      "resalePrice",
      "height",
      "width",
      "length",
      "weight",
      "reorderQuantity",
      "vendorCost",
    ];

    if (numericInputs.indexOf(e.target.name) > -1) {
      //newvalue = newvalue.replace(/\+|-/ig, '');
      newvalue = parseFloat(newvalue.replace(",", ""));
    }
    if (e.target.name === "taxable") {
      newvalue = !item.taxable;
    }
    if (e.target.name === "defaultVendorForItem") {
      newvalue = !item.defaultVendorForItem;
    }
    setCurrItem((prevState) => ({
      ...prevState,
      [e.target.name]: newvalue,
    }));
  }

  const validateInput = () => {
    const form = document.querySelectorAll("#itemInfoForm")[0];
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    setValidated(false);
    return true;
  };

  const handleSave = () => {
    if (!validateInput()) {
      return;
    }

    saveHandler(item);
  };

  function saveHandler(item) {
    if (item !== undefined) {
      if (editingItem === true) {
        // Save updated item
        setIsSubmitting(true);
        fetchService
          .put("/items/" + item.id, item)
          .then((json) => {
            //Success
            dispatch(updateItems(json));
            setIsSubmitting(false);
            cancelDialog();
          })
          .catch((error) => {
            setIsSubmitting(false);
            alertService.error(error);
            return;
          });
      } else {
        // New item to post
        setIsSubmitting(true);
        fetchService
          .post("/items", item)

          //get id from json and pass it back up to newItemSavedHandler in the PurchaseOrder component
          .then((json) => {
            //Success
            dispatch(updateItems(json));
            setIsSubmitting(false);
            if (itemSavedHandler != null) {
              itemSavedHandler(item);
              // alert('alert');
            }
            cancelDialog();
          })
          .catch((error) => {
            setIsSubmitting(false);
            alertService.error(error);
            return;
          });
      }
    }
  }

  //TODO add validation 
  function deleteItem() {
    fetchService
    .delete("/items/" + item.id)
    .then((json) => {
      //Success
      dispatch(updateItems(json));
      cancelDialog();
    })
    .catch((error) => {
      alertService.error(error);
      return;
    });



    console.log('delete item');
  }

  if (unitMeasureTypesLoading) return "";

  if (!visible) return "";

  if (item == null) return "";


  return (
    <div className="item_modal_body modal_div">
      <Form
        autoComplete={"off"}
        id={"itemInfoForm"}
        name={"item_info_edit_form"}
        noValidate
        validated={validated}
        className="itemForm"
      >
        <Tab.Container defaultActiveKey="item" transition={false}>
        <Modal.Header closeButton bsPrefix={"inventorydash_custom_modal_header"}>
            <ActionBarAllThings
              pageTitle={
                isEditing
                  ? readOnly
                    ? "Item Detail"
                    : "Manage Item"
                  : "Add Item"
              }
              showBackBtn={true}
              showColBtn={false}
              showSaveBtn={!readOnly}
              saveBtnText={isEditing ? "Update" : "Save"}
              cancelHandler={cancelHandler}
              saveHandler={handleSave}
              showDelBtn={isEditing && !readOnly ? true : false}
              onModal={true}
              delHandler={deleteItem}
            />
            <Nav justify variant="tabs" className="item_tabs">
              <Nav.Item>
                <Nav.Link
                  eventKey="item"
                  ref={itemTabRef}
                  // className="tab_nav_link"
                  className={
                    locationTreeOpened
                      ? "tab_nav_link tab_with_locationtree_opened"
                      : "tab_nav_link"
                  }
                >
                  <span className={"noselect"}>Item</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="details"
                  ref={detailTabRef}
                  className="tab_nav_link"
                >
                  <span className={"noselect"}>Details</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="vendor"
                  ref={vendorTabRef}
                  className="tab_nav_link"
                >
                  <span className={"noselect"}>Vendor</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Modal.Header>
          <Modal.Body bsPrefix={"inventorydash_custom_modal_body item_modal"}>
        <Tab.Content className={"tab_content"}>
          <Tab.Pane eventKey="item">
            <ItemInfo
              item={item}
              onInputValueChanged={onInputValueChanged}
              cancelHandler={cancelHandler}
              saveHandler={saveHandler}
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              unitMeasureTypes={unitMeasureTypes}
              updateUnitSelection={updateUnitSelection}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              readOnly={readOnly}
              locationTreeOpened={locationTreeOpened}
              setLocationTreeOpened={setLocationTreeOpened}
              setItemLinkLabelItems={setItemLinkLabelItems}
            />
          </Tab.Pane>

          <Tab.Pane eventKey="details">
            <ItemDetail
              item={item}
              onInputValueChanged={onInputValueChanged}
              cancelHandler={cancelHandler}
              saveHandler={saveHandler}
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              unitMeasureTypes={unitMeasureTypes}
              updateUnitSelection={updateUnitSelection}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              readOnly={readOnly}
            />
          </Tab.Pane>

          <Tab.Pane eventKey="vendor">
            <ItemVendor
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              item={item}
              onInputValueChanged={onInputValueChanged}
              cancelHandler={cancelHandler}
              saveHandler={saveHandler}
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              unitMeasureTypes={unitMeasureTypes}
              updateUnitSelection={updateUnitSelection}
              readOnly={readOnly}
            />
          </Tab.Pane>
        </Tab.Content>
      </Modal.Body>
        </Tab.Container>
      </Form>
    </div>
  );
}

// export { AddEditItemPage };
