import {
  FETCH_RETURNS_BEGIN,
  FETCH_RETURNS_SUCCESS,
  FETCH_RETURNS_FAILURE,
  UPDATE_RETURNS,
  FETCH_SINGLERETURN_BEGIN,
  FETCH_SINGLERETURN_SUCCESS,
  FETCH_SINGLERETURN_FAILURE

  // POST_SINGLERETURN_BEGIN = 'POST_SINGLERETURN_BEGIN';
  // POST_SINGLERETURN_SUCCESS = 'POST_SINGLERETURN_SUCCESS';
  // export const POST_SINGLERETURN_FAILURE = 'POST_SINGLERETURN_FAILURE';

  // export const PUT_SINGLERETURN_BEGIN = 'PUT_SINGLERETURN_BEGIN';
  // export const PUT_SINGLERETURN_SUCCESS = 'PUT_SINGLERETURN_SUCCESS';
  // export const PUT_SINGLERETURN_FAILURE = 'PUT_SINGLERETURN_FAILURE';
} from "../actions/returnsActions";
import { defaultsService } from "../../services";

const initialState = {
  returns: [],
  currReturn: null,
  loading: false,
  error: null,
  currReturnLoaded: false,
  returnsLoaded: false,
  returnPosting: false,
  singleReturn: null,
  singleLoading: false,
  singleError: null,
  singleLoaded: false
};

export default function returnReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RETURNS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
        returnsLoaded: false,
      };
    case FETCH_RETURNS_SUCCESS:
      // All done: set loading "false".
      // Also, replace the returns with the ones from the server
      return {
        ...state,
        loading: false,
        returns: action.payload.returns,
        returnsLoaded: true,
      };
    case FETCH_RETURNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        returns: [],
      };

    case FETCH_SINGLERETURN_BEGIN:
      return {
        ...state,
        singleLoading: true,
        singleError: null,
        singleLoaded: false,
      };
    case FETCH_SINGLERETURN_SUCCESS:
      return {
        ...state,
        singleLoading: false,
        singleReturn: action.payload.returnObj,
        singleLoaded: true,
      };
    case FETCH_SINGLERETURN_FAILURE:
      return {
        ...state,
        singleLoading: false,
        singleError: action.payload.error,
        singleReturn: null,
      };

    case UPDATE_RETURNS:
      //  We have a new or updated Item added in the software
      //  need to update the master underlying list to account for it
      let existingReturn = state.returns.find(
        (e) => e.id === action.payload.id
      );
      let index = state.returns.indexOf(existingReturn);

      // We have a new or updated Return added in the software
      // Need to update the master underlying list to account for it
      if (index < 0) {
        // Add the new Return to the end of the list
        return {
          ...state,
          returns: [...state.returns.concat(action.payload)],
        };
      } else {
        // We need to slice the array and insert the updated Return
        // This keeps everything clean as far as the rules of immutable state go
        return {
          ...state,
          returns: [
            ...state.returns
              .slice(0, index)
              .concat(action.payload)
              .concat(state.returns.slice(index + 1)),
          ],
        };
      }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
