import { fetchService } from '../../services';

export const FETCH_ITEMGROUPS_BEGIN   = 'FETCH_ITEMGROUPS_BEGIN';
export const FETCH_ITEMGROUPS_SUCCESS = 'FETCH_ITEMGROUPS_SUCCESS';
export const FETCH_ITEMGROUPS_FAILURE = 'FETCH_ITEMGROUPS_FAILURE';

export const POST_ITEMGROUP_BEGIN = 'POST_ITEMGROUP_BEGIN';
export const POST_ITEMGROUP_SUCCESS = 'POST_ITEMGROUP_SUCCESS';
export const POST_ITEMGROUP_FAILURE = 'POST_ITEMGROUP_FAILURE';

export const PUT_ITEMGROUP_BEGIN   = 'PUT_ITEMGROUP_BEGIN';
export const PUT_ITEMGROUP_SUCCESS = 'PUT_ITEMGROUP_SUCCESS';
export const PUT_ITEMGROUP_FAILURE = 'PUT_ITEMGROUP_FAILURE';

export const UPDATE_ITEMGROUPS = 'UPDATE_ITEMGROUPS';
export const DELETED_ITEMGROUP = 'DELETED_ITEMGROUP';

//#region FETCH
export const fetchItemGroupsBegin = () => ({
  type: FETCH_ITEMGROUPS_BEGIN
});

export const fetchItemGroupsSuccess = itemgroups => ({
  type: FETCH_ITEMGROUPS_SUCCESS,
  payload: { itemgroups }
});

export const fetchItemGroupsFailure = error => ({
  type: FETCH_ITEMGROUPS_FAILURE,
  payload: { error }
});

export function fetchItemGroups() {
    return dispatch => {
      dispatch(fetchItemGroupsBegin());

      return fetchService.get("/itemgroups")
        .then(json => {
          dispatch(fetchItemGroupsSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchItemGroupsFailure(error)));
    };
  };

//#endregion

//#region POST
export const postItemGroupBegin = () => ({
  type: POST_ITEMGROUP_BEGIN
});

export const postItemGroupSuccess = itemgroup => ({
  type: POST_ITEMGROUP_SUCCESS,
  payload: { itemgroup }
});

export const postItemGroupFailure = error => ({
  type: POST_ITEMGROUP_FAILURE,
  payload: { error }
});

export function postItemGroup(itemgroup) {
  return dispatch => {
    dispatch(postItemGroupBegin());
    return fetchService.post("/itemgroups", itemgroup)
      .then(json => {
        dispatch(postItemGroupSuccess(json));
        return json;
      })
      .catch(error => dispatch(postItemGroupFailure(error)));
  }
};
//#endregion

//#region PUT
export const putItemGroupBegin = () => ({
  type: PUT_ITEMGROUP_BEGIN
});

export const putItemGroupSuccess = itemgroup => ({
  type: PUT_ITEMGROUP_SUCCESS,
  payload: { itemgroup }
});

export const putItemGroupFailure = error => ({
  type: PUT_ITEMGROUP_FAILURE,
  payload: { error }
});

export function putItemGroup(itemgroup, id) {
  return dispatch => {
    dispatch(putItemGroupBegin());
    return fetchService.put(`/itemgroups/${id}`, itemgroup)
      .then(json => {
        dispatch(putItemGroupSuccess(json));
        return json;
      })
      .catch(error => dispatch(putItemGroupFailure(error)));
  }
};
//#endregion

  // Handle HTTP errors since fetch won't.
  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }; 

  export function updateItemGroups(group) {
    return dispatch => {
      dispatch(pushUpdateItemGroups(group));
    }
  };

  export const pushUpdateItemGroups = group => ({
    type: UPDATE_ITEMGROUPS,
    payload: group
  });


  

//Delete Item From List
export function deletedItemGroup(id) {
  return dispatch => {
    dispatch(deleteItemGroupPayload(id))
    }
};

export const deleteItemGroupPayload = id => ({
  type: DELETED_ITEMGROUP,
  payload: id
});

//##########################################################################