import React, { useState } from "react";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { Modal, Button } from "react-bootstrap";
import TestModal from "../../Controls/TestModal/TestModal";

function Home() {
  const [showModal, setShowModal] = useState(null);

  function settingsIconClicked() {
    // var link=document.createElement("a");
    // link.href='/Settings/DashboardSettingsComponent';
    // document.body.append(link);
    // link.click();
    setShowModal(true);
    return;
  }

 
  return (
    <>
      {showModal ? ( //modal if settings icon is clicked
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          animation={true}
          className={"item_modal_container"}
          centered={true}
        >
          <Modal.Header closeButton>
            <ActionBarAllThings
              pageTitle={"Dashboard Settings"}
              showBackBtn
              onModal
              cancelHandler={() => setShowModal(false)}
            />
          </Modal.Header>
        </Modal>
      ) : (
        <>
          <ActionBarAllThings
            pageTitle="Dashboard"
            showFooter={false}
            showSettingsIcon={true}
            settingsHandler={settingsIconClicked}
          />
        </>
      )}


      <div className="page_container">
        

      </div>
    </>
  );
}

export { Home };
