import React from "react";
import { Button } from "react-bootstrap";
import { FiEdit2, FiTrash, FiPrinter, FiInfo, FiList } from "react-icons/fi";
import { BsLayoutThreeColumns} from 'react-icons/bs';
import { MdHelp } from 'react-icons/md';
import { RiAddFill, RiFilter3Fill } from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";
import {
  IoIosArrowBack as ExitIcon, IoMdSettings
} from "react-icons/io";
import { MdSortByAlpha } from "react-icons/md";
import "../Layout/actionbar.css";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { showHelpView, setHelpViewTopic } from "../../store/actions/uiActions";



export default function ActionBarAllThingsHeader({
  onModal = false, //when adding Actionbar to modal, set this to true
  pageTitle = "", //set the tiTle of the page that shows up as an h1
  saveIcon,
  /*buttons*/
  showBackBtn = false,
  showSaveBtn = false,
  saveBtnText = "Save", //text on btn can be changed to say "update" when editing
  showColBtn = false,
  showEditBtn = false,
  showAddBtn = false,
  showDelBtn = false,
  delBtnText = "Delete",
  showViewBtn=false,
  showSortBtn = false,
  showPrintBtn = false,
  showEmailBtn = false,
  showSettingsIcon= false,
  showFilterIcon = false,
  filterAppliedIndicator = null,

  // Added by Darren 12/20/2020
  showHelpButton = true,

  /*handlers that perform actions*/
  saveHandler = null,
  delHandler = null,
  addItem = null,
  cancelHandler = null,
  editItem = null,
  viewItem = null,
  adjustColumns = null,
  helpButtonHandler = null,
  emailHandler = null,
  printHandler = null,
  settingsHandler = null,
  filterHandler = null

}) {
  const windowWidth = useWindowDimensions().width;
  // const windowHeight = useWindowDimensions().height;

  // const isMobileScreen = windowWidth <= 576;
  // const isMobileScreen = windowWidth <= 576 || windowHeight <=600;
  const isMobileWidth = windowWidth <= 576;

  /*actiobar is broken up in sections that display on chiild component whenever
such section is marked as true*/

  return (
    //when actionbar is on modal, onModal must be marked as true.  This
    //sets proper height when on a modal
    <div className={onModal ? "actionbar_modal_header" : "actionbar_row"}>
      {/*back button*/}
      {showBackBtn && !isMobileWidth ? (
        <div className="actionbar_cancel_back_btn">
          <Button
            className="cancel_exit_button"
            variant="outline-light"
            aria-label="cancel button"
            onClick={cancelHandler == null ? null : cancelHandler}
          >
            <ExitIcon
              className="back_btn_icon"
              size={isMobileWidth ? "2rem" : "2.5rem"}
            />
          </Button>
        </div>
      ) : (
        <></>
      )}

      {/*page title*/}
      {pageTitle !== "" ? (
        <div className="actionbar_title_div noselect">
          <h2 className="actionbar_page_title_only">{pageTitle}</h2>
        </div>
      ) : (
        <></>
      )}
      <div
        className={
          onModal ? "actionbar_btn_container_modal" : "actionbar_btn_container"
        }
      >
        {/*Edit button - shows after an item is selected*/}
        {showEditBtn && !isMobileWidth ? (
          <div className="actionbar_edit_btn_div">
            <Button
              className="actionbar_edit_btn"
              arial-label="edit"
              onClick={editItem == null ? null : editItem}
            >
              {" "}
            {isMobileWidth ? "" : "Manage"}
              <FiEdit2 size="1.2rem" className="small_button_edit_icon" />
            </Button>
          </div>
        ) : (
          <></>
        )}

        {/*View button - shows after an item is selected and no permission to edit */}
        {showViewBtn && !isMobileWidth ? (
          <div className="actionbar_edit_btn_div">
            <Button
              className="actionbar_edit_btn"
              arial-label="view"
              onClick={viewItem == null ? null : viewItem}
            >
              {" "}
              {isMobileWidth ? "" : "View"}
              <FiList size="1.2rem" className="small_button_edit_icon" />
            </Button>
          </div>
        ) : (
          <></>
        )}

        {/*Add New  button*/}
        {showAddBtn && !isMobileWidth ? (
          <div className="actionbar_add_btn_div">
            <Button
              className="actionbar_add_btn"
              arial-label="add"
              onClick={addItem == null ? null : addItem}
            >
              {isMobileWidth ? "" : "Add"}
              <RiAddFill
                className="small_button_add_icon"
                size={isMobileWidth ? "2.4rem" : "1.5rem"}
              />
            </Button>
          </div>
        ) : (
          <></>
        )}

        {/*Add Filter button*/}
        {showFilterIcon ? (
              <div className="actionbar_sort_btn_div">
              <RiFilter3Fill
                className="sort_icon"
                size={"1.5rem"}
                color="var(--actionbar_font)"
                arial-label="Filters"
                onClick={filterHandler == null ? null : filterHandler}
              />
                {filterAppliedIndicator > 0 ? (
                <div className ='filterAppliedIcon'>
                {filterAppliedIndicator}
                </div>
              ) : <></>}
          </div>
        ) : (
          <></>
        )}

        {/*Sort Icon Button*/}
        {showSortBtn ? (
          <div className="actionbar_sort_btn_div">
            <MdSortByAlpha
              className="sort_icon"
              size="1.5rem"
              color="var(--actionbar_font)"
            />
          </div>
        ) : (
          <></>
        )}

        {/*column icon button - brings up column modal when clicked*/}
        {showColBtn ? (
          <div className="actionbar_col_btn_div">
            <Button
              data-tip="Adjust Columns"
              // data-tooltip="Adjust Columns"
              className="actionbar_col_btn"
              arial-label="columns"
              onClick={adjustColumns == null ? null : adjustColumns}
            >
              <BsLayoutThreeColumns
                size="1.5rem"
                className="small_button_col_icon"
                color='var(--canvas_background)'
              />
              {/* {isMobileScreen ? (
                <></>
              ) : (
                  <ReactTooltip
                    className="tooltip"
                    // overridePosition={() => ({left: 1, top: 1})}
                    backgroundColor="var(--button_small_background)"
                    textColor="var(--button_small_font)"
                    borderColor="var(--button_small_border)"
                  />
                )} */}
            </Button>
          </div>
        ) : (
          <></>
        )}

        {showEmailBtn && !isMobileWidth ? (
          <div className="actionbar_email_div">
            <AiOutlineMail size="2rem" onClick={emailHandler} />
          </div>
        ) : (
          <></>
        )}

        {showPrintBtn && !isMobileWidth ? (
          <div className="actionbar_print_div">
            <FiPrinter size="2rem" onClick={printHandler} />
          </div>
        ) : (
          <></>
        )}

        {showSettingsIcon ? ( //settings icon only shows on header
          <div className="actionbar_settings_div">
            <IoMdSettings size="2rem" onClick={settingsHandler}/>
          </div>
        ) : (
         <></> 
        )}

        {/*delete button - onyl shows after an item has been edited*/}
        {showDelBtn && !isMobileWidth ? (
          <div className="actionbar_del_div">
            <Button
              className="actionbar_del_btn"
              onClick={delHandler == null ? null : delHandler}
            >
              {windowWidth <= 576 ? (
                <FiTrash size="1.6rem" className="small_button_del_icon" />
              ) : (
                <>{delBtnText}</>
              )}
            </Button>
          </div>
        ) : (
          <></>
        )}

        {/*save button - shows after a new item is created or edited*/}
        {showSaveBtn && !isMobileWidth ? (
          <div className="actionbar_save_div">
            <Button
              className="actionbar_save_btn"
              onClick={saveHandler == null ? null : saveHandler}
            >
              {saveBtnText}
             {saveIcon}
            </Button>
          </div>
        ) : (
          <></>
        )}

        {/* Darren Added this on 12/20 for Help Support */}
        {/*help icon button - brings up help system overlay when clicked */}
        {showHelpButton ? (
          <div className={onModal ? "actionbar_help_div_modal" : "actionbar_help_div"}>
            <Button
              className="actionbar_help_btn"
              arial-label="columns"
              onClick={helpButtonHandler == null ? null : helpButtonHandler}
            >
              <MdHelp
                size={isMobileWidth ? "1.3rem" : "1.5rem"}
                className="small_button_help_icon"
              ></MdHelp>
              {isMobileWidth ? <></> : <></>}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}