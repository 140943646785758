import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { Vendorselector, PurchaseOrderSelector } from '../../itemselectors';
import './iteminfo.css';
import ItemInputUnitSelection from '../ItemInputUnitSelection';
import InventoryDashCheckbox from '../../../Controls/InventoryDashCheckbox';

function ItemVendor({ item, onInputValueChanged, unitMeasureTypes, updateUnitSelection, selectedTab, setSelectedTab, readOnly }) {
    const leftColWid = 7;
    const rightColWid = 5;
    const [forceRender, setForceRender] = useState(false);
    const vendorDropdownRef = useRef(null);


    function vendorChanged(vendorid, vendorname) {
        item.vendorId = vendorid;
        item.vendorName = vendorname;
        setForceRender(!forceRender);
    }
    function purchaseOrderChanged(poid, poname) {
        item.purchaseOrderId = poid;
        item.purchaseOrderName = poname;
        setForceRender(!forceRender);
    }

    useEffect(() => { //focuses first input field after tab is clicked
        if (selectedTab === "vendor") {
            vendorDropdownRef.current.focus();
            // setSelectedTab("");
        }
    }, [selectedTab]);


    return (
        <div className="item-form-container">
            {/* Vendors Component */}
            <Row className="add_edit_item_row" xs={1} sm={2}>
                <Col className="item_add_edit_col noselect">
                    <Vendorselector
                        valueChanged={vendorChanged}
                        value={item.vendorId}
                        inputref={vendorDropdownRef}
                        readOnly={readOnly}
                    />
                </Col>
                <Col className="item_add_edit_col noselect">

                    <ItemInputUnitSelection
                        lbltext="Vendor Cost"
                        name={"vendorCost"}
                        itemvalue={item.vendorCost}
                        unitTypeValue={item.vendorCostUnitType}
                        onInputValueChanged={onInputValueChanged}
                        unittypename={"vendorCostUnitType"}
                        unittypebasemt={6}
                        unitMeasureTypes={unitMeasureTypes}
                        updateUnitSelection={updateUnitSelection}
                        readOnly={readOnly}
                    />

                </Col>
            </Row>

            {/* Purchase Order  */}
            <Row className="add_edit_item_row" xs={1} sm={2}>
                <Col className="item_add_edit_col noselect">
                    <PurchaseOrderSelector
                        valueChanged={purchaseOrderChanged}
                        value={item.purchaseOrderId}
                        readOnly={readOnly}
                    />
                </Col>
                <Col className="item_add_edit_col noselect">

                    <ItemInputUnitSelection
                        lbltext="Reorder Quantity"
                        name={"reorderQuantity"}
                        itemvalue={item.reorderQuantity}
                        unitTypeValue={item.reorderQuantityUnitType}
                        onInputValueChanged={onInputValueChanged}
                        unittypename={"reorderQuantityUnitType"}
                        unittypebasemt={6}
                        unitMeasureTypes={unitMeasureTypes}
                        updateUnitSelection={updateUnitSelection}
                        readOnly={readOnly}
                    />
                </Col>
            </Row>

            {/* Item Type Component */}
            <Row className="add_edit_item_row" xs={1} sm={2}>
                <Col className="item_add_edit_col noselect">

                    <InventoryDashCheckbox
                        checked={item.defaultVendorForItem}
                        name="defaultVendorForItem"
                        label="Default Vendor for this item"
                        onChange={onInputValueChanged}
                        disabled={readOnly}
                    />

                </Col>
            </Row>
        </div>
    )
};

export { ItemVendor };