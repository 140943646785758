import {
    FETCH_VENDOR_BEGIN,
    FETCH_VENDOR_SUCCESS,
    FETCH_VENDOR_FAILURE
} from '../actions/singleVendorActions';

const initialState = {
    vendor: null,
    loading: false,
    error: null,
    vendorLoaded: false,
    iserror: false
};

export default function vendorReducer(state = initialState, action) {
    switch(action.type) {
        
        case FETCH_VENDOR_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                vendorLoaded: false,
                iserror: false
            };
        case FETCH_VENDOR_SUCCESS:

        
            return {
                ...state,
                loading: false,
                vendor: action.payload.vendor,
                vendorLoaded: true,
                iserror: false
            };
        case FETCH_VENDOR_FAILURE:

            return {
                ...state,
                loading: false,
                error: action.payload.error,
                vendor: null,
                iserror: true
            };
       
        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}