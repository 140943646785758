import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import ItemListSelector from "../../Controls/Selectors/ItemListSelector";
import { getShortDate, getTodaysDate, parseDecimal } from "../../utilities";
import { MdExpandLess } from "react-icons/md";
import TextInput from "../../Controls/textInput";
import ItemInputUnitSelection from "../items/ItemInputUnitSelection";
import { PurchaseOrderLineOptions } from "./PurchaseOrderLineOptions";
import InventoryDashCheckbox from "../../Controls/InventoryDashCheckbox";
import NumericUpDown from "../../Controls/numericupdown/NumericUpDown";
import "./purchaseorderlineitem.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewOnlyLineItem from "./ViewOnlyLineItem";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { AddEditItemPageContent } from "../items/AddEditItemPageContent";
import { Modal } from "react-bootstrap";

export default function PurchaseOrderLineItem({
  item,
  itemValueChanged,
  measurementTypes,
  itemAdded,
  addingNewLine,
  setItemAdded,
  setFromLineItemModal,
  collapseLineItem,
  showLineItemModal,
  addNewItemToInventory,
  newItemSavedHandler,
  newAddedItemId,
  getItemNames
}) {

  const [currentRecAmount, setCurrentRecAmount] = useState(0);
  const [isReceiving, setIsReceiving] = useState(false);

  const [ showItemModalContents, setShowItemModalContents] = useState(false);

  useEffect(() => {
    // alert(newAddedItemId);
    if (item != null){
      if (item.itemId != newAddedItemId && newAddedItemId > 0) {
        item.id = newAddedItemId; 
        itemValueChanged(item);
      }
    }
  },[newAddedItemId])

  // const [startDate, setStartDate] = useState(item.expectedDate);

  // UI For Input of Received Quantity
  function inventoryItemChanged(e) {
    if (item != null || item != undefined) {
      item.itemId = parseInt(e.target.value);
      itemValueChanged(item);
    }
  
  }

  function quantityChanged(e) {
    if (item != null || item != undefined) {
      item.quantity = parseDecimal(e.target.value);
      itemValueChanged(item);
    }
  }

  function purchasePriceChanged(e) {
    item.purchasePrice = parseDecimal(e.target.value);
    itemValueChanged(item);
  }

  function inventoryItemObjectChanged(inventoryItem) {
   if (item != null || item != undefined) {
    if (inventoryItem && inventoryItem.itemId == -1) {
      if (addNewItemToInventory != null) {
        addNewItemToInventory();
      }
      return;
    } 
    item.purchasePrice = parseDecimal(inventoryItem.vendorCost);
    item.purchasePriceUnitTypeId = parseDecimal(
      inventoryItem.vendorCostUnitType
    );
    itemValueChanged(item);
   }
  }

  function receivingCheckboxChanged(e) {
    let checked = e.target.checked;
    if (!checked) {
      item.newQuantityReceived = 0;
    } else {
      item.newQuantityReceived = item.outstandingQuantity;
    }
    setIsReceiving(checked);
  }

  function noteChanged(e) {
    item.note = e.target.value;
    itemValueChanged(item);
  }

  function updatePurchasePriceUnitSelection(
    unittypename,
    baseMeasurementUnitTypeId
  ) {
    item.purchasePriceUnitTypeId = baseMeasurementUnitTypeId;
    itemValueChanged(item);
  }

  function setCurrentAmount(amount) {
    item.newQuantityReceived = amount;
    setCurrentRecAmount(item.newQuantityReceived);
  }

  function getExpectedDate() {
    if (item) {
      return getShortDate(item.expectedDate);
    }
    return getTodaysDate();
  }

  function setExpectedDate(date) {
    item.expectedDate = date;
    itemValueChanged(item);
  }

  function getReceivedDate() {
    if (!item.newReceivedDate) {
      return getShortDate(getTodaysDate());
    }
    if (item.newReceivedDate < "2/1/2000") {
      return getShortDate(getTodaysDate());
    }
    return getShortDate(item.newReceivedDate);
  }

  function setReceivedDate(date) {
    if (date) {
      item.newReceivedDate = date;
      itemValueChanged(item);
    }
  }

  function lineItemFullyReceived() {
    if (item) {
      if (item.purchaseOrderStatus === 2) {
        return true;
      }
      if (!item.quantity || item.quantity === 0) {
        return true;
      }
    }
    return false;
  }
  // //function to receive all items called from child componenet PurchaseOrderLineOptions
  function receiveAll() {
    item.newQuantityReceived = item.outstandingQuantity;
    item.newReceivedDate = getTodaysDate();
    item.isReceiving = true;
    setIsReceiving(true);
    itemValueChanged(item);
  }

  /*function when the back btn is clicked on the line item -
  if newly added item was the line item, it will delete it, otherwise it will just close the LineItem Modal*/
  function cancelLineItem() {
    if (itemAdded === true && item != null) {
      deleteInventoryItem();
    }
    setItemAdded(false);
    collapseLineItem();
  }

  /*Save line item - whenever the Save button is clicked, it collapses the modal and 
  then the LineItem displays on the PO Modal*/
  function saveLineItem() {
    collapseLineItem();
    setItemAdded(false);
  }

  /* delete line item and close LineItem Modal - set itemsAdded to false*/
  function deleteInventoryItem() {
    if (addingNewLine != true) {
      if (item.purchaseOrderStatus !== 3) {
        item.deleted = true;
        itemValueChanged(item);
      }
    }
    collapseLineItem();
    setItemAdded(false);
  }


  return (
    <>
        <Modal.Header
          closeButton
          bsPrefix={"inventorydash_custom_modal_header"}
        >
          <ActionBarAllThings
            showSaveBtn={true}
            saveBtnText={itemAdded ? "Add" : "Update"}
            saveHandler={saveLineItem}
            showDelBtn={itemAdded ? false : true}
            delBtnText={"Remove"}
            delHandler={deleteInventoryItem}
            pageTitle={itemAdded ? "Add Line Item" : "Edit Line Item"}
            showFooter={true}
            showBackBtn={true}
            cancelHandler={cancelLineItem}
            onModal={true}
          />
        </Modal.Header>

        <Modal.Body bsPrefix={"inventorydash_custom_modal_body"}>
          <div className="grid_purchaseorder_row">
            <div className="grid_purchaseorder_name noselect">
              <ItemListSelector
                value={item?.itemId}
                onChange={inventoryItemChanged}
                onItemChanged={inventoryItemObjectChanged}
              />
            </div>

            <div className="grid_purchaseorder_qty noselect">
              <TextInput
                name="purchaseorderitemqty"
                label="Quantity"
                onChange={quantityChanged}
                value={item?.quantity}
              />
            </div>

            <div className="grid_purchaseorder_price noselect">
              <ItemInputUnitSelection
                lbltext="Purchase Price"
                name={"purchaseorderitemprice"}
                itemvalue={item?.purchasePrice}
                unitTypeValue={item?.purchasePriceUnitTypeId}
                onInputValueChanged={purchasePriceChanged}
                unittypename={"vendorCostUnitType"}
                unittypebasemt={6}
                unitMeasureTypes={measurementTypes}
                updateUnitSelection={updatePurchasePriceUnitSelection}
                showSelect={false}
              />
            </div>
          </div>

          <div className={"purchaseorder_lineitem_secondrow_grid"}>
            <div className="purchaseorder_checkbox_component">
              <InventoryDashCheckbox
                label={
                  "Receive (max of " +
                  (item ? item?.outstandingQuantity : "0") +
                  ")"
                }
                checked={isReceiving}
                disabled={lineItemFullyReceived()}
                onChange={receivingCheckboxChanged}
              />
              <div
                className={
                  "purchaseorder_lineitem_recieved_area" +
                  (isReceiving ? " enabled" : "")
                }
              >
                <Form.Group>
                  <NumericUpDown
                    name={"amountRec"}
                    value={item?.newQuantityReceived}
                    onChange={setCurrentAmount}
                    min={0}
                    max={item?.outstandingQuantity}
                    disabled={!isReceiving}
                  />
                </Form.Group>
                <div className={"purchaseorder_received_date"}>
                  <div className="datepicker-container">
                    <DatePicker
                      className="purchaseorder_datepicker_input_field datepicker_input_field"
                      onChange={isReceiving ? setReceivedDate : null}
                      value={isReceiving ? getReceivedDate() : null}
                      includeTimes={false}
                      disabled={!isReceiving}
                      popperPlacement="right"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "-60px, 0px",
                        },
                      }}
                    />
                  </div>
                </div>
              {isReceiving ? (
                    <div className="purchaserorder_qty_recieved noselect">
                    {(item?.quantity - item?.outstandingQuantity)?.toString() +
                      " of " +
                      item?.quantity?.toString() +
                      (item?.quantity !== 1 ? " items " : " item ") +
                      " received"}
                    {item?.historyItems?.map((history, index) => (
                      <div
                        className={"purchaseorder_lineitem_history"}
                        key={index}
                      >
                        {history}
                      </div>
                    ))}
                  </div>
              ) : <></>
              }
              </div>
            </div>

            <div className={"purchaseorder_expected_date noselect"}>
              <Form.Group>
                <Form.Label>{"Expected Date"}</Form.Label>
                <div className="datepicker-container">
                  <DatePicker
                    className="purchaseorder_datepicker_input_field datepicker_input_field"
                    onChange={setExpectedDate}
                    value={getExpectedDate()}
                    includeTimes={false}
                    popperPlacement="right"
                  />
                </div>
              </Form.Group>
            </div>

            <div className={"purchaseorder_note noselect"}>
              <Form.Group>
                <Form.Label>{"Notes"}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={item?.note ? item?.note : ""}
                  onChange={noteChanged}
                />
              </Form.Group>
            </div>
          </div>
          <div className={"purchaseorder_lineitem_thirdrow_grid"}></div>
        </Modal.Body>
    </>
  );
}

// As a reminder - we agreed to include an
// "expected delivery date",
// "note",
// "receive all items"
// "receive partial items"
// on the purchaseorder line?
// have a date field for when items were received as well (for reporting?)
