import { fetchService } from '../../services';

export const FETCH_ROLES_BEGIN   = 'FETCH_ROLES_BEGIN';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';

//Fetch
//##########################################################################
export const fetchRolesBegin = () => ({
  type: FETCH_ROLES_BEGIN
});

export const fetchRolesSuccess = roles => ({
  type: FETCH_ROLES_SUCCESS,
  payload: { roles }
});

export const fetchRolesFailure = error => ({
  type: FETCH_ROLES_FAILURE,
  payload: { error }
});

export function fetchRoles() {
    return dispatch => {
      dispatch(fetchRolesBegin());

      return fetchService.get( "/roles" )
        .then(json => {
          dispatch(fetchRolesSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchRolesFailure(error)));
    };
  }
//End Fetch
//##########################################################################
