import React, { useEffect, useState } from "react"; //import useState from react
import { ActionBarAllThings } from "../../Layout/ActionBar";



export default function AlertsComponent({ setAlertsComponentVisible }) {
    const [counter0, setCounter0] = useState(0);
    const [counter1, setCounter1] = useState(0);
    const [counter2, setCounter2] = useState(0);
    const [counter3, setCounter3] = useState(0);

    
    useEffect(() => {
     });
     

    useEffect(() => {
    }, []);
    
    useEffect(() => {
    }, [counter0]);

    useEffect(() => {
    }, [counter0, counter1]);

    useEffect(() => {
        
    }, [counter0, counter1, counter2]);

    // Infinite loop breaks everything
    useEffect(() => {
  //    setCounter3(counter3 => counter3 + 1)
      setCounter3(prevcount => prevcount + 1);
    }, []);


    function logCounters()
    {
        let ret = '';
        ret = ret.concat('counter 0: ', counter0, ' counter 1: ', counter1, ' counter 2: ', counter2);
        return ret;
    }
    

    return (
      
      <>
        
      {/* Pass in the functions to the component that the child will call when necessary
    one is created on this component 'Save' and 'Cancel' is just being passed along from the parent
    also passing in the page title text
     */}


 
   
      <ActionBarAllThings
        pageTitle={"Alerts"}
        showBackBtn
        cancelHandler={() => setAlertsComponentVisible(false)}
      />
      <div>
        {'Alerts'}
      </div>
    </>
  );
}


