import React, { useState, useEffect } from 'react';
import "./SideDrawer.css"
import { Link } from "react-router-dom";
import Routes from "../../routes";
import { RiLogoutBoxRLine, RiLoginCircleLine} from "react-icons/ri"
import { faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { accountService, history } from '../../services';
import { resetCurrentUsersRoles} from '../../store/actions/loggedInUserRolesActions';
import { useDispatch} from 'react-redux';


function SideDrawer(props) {
    const [user, setUser] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        const subscription = accountService.user.subscribe(x => setUser(x));
        return subscription.unsubscribe;
    }, []);

    let drawerClasses = ['side-drawer'];
    if (props.show === true) {
        drawerClasses = ['side-drawer open']
    }

    function logout() {
        dispatch(resetCurrentUsersRoles());
        accountService.logout();
    }
    
    return (
        <nav className={ drawerClasses }> 
            <div className='menu__items'>
                {
                Routes.map((route, idx) => {  
                        if (route.sideMenu)
                        {
                            if (!user && route.private)
                            {
                                return ""
                            }
                            else
                            {
                                return (
                                    <Link key={ idx } to={ route.path }>{ route.display }</Link>
                                );
                            }
                            
                        }
                        else
                        {
                            return ""
                        }
                    })
                }  

            </div>
            {
                (!user)
                ? 
                (
                    <div className="logout-button" onClick={() => history.push("login")  }>
                        <RiLoginCircleLine icon={ faSignInAlt } size="2rem" pull="left" ></RiLoginCircleLine>
                        <div className="logout-text">
                            Log In
                        </div>
                    </div>
                    )
                : (

                <div className="logout-button" onClick={ logout }>
                    <RiLogoutBoxRLine icon={ faSignOutAlt } size="2rem" pull="left" ></RiLogoutBoxRLine>
                    <div className="logout-text">
                        Log Out
                    </div>
                </div>
                )
            }
        </nav>
    );
};

export default SideDrawer;