import { fetchService } from '../../services';

export const FETCH_USERROLES_BEGIN   = 'FETCH_USERROLES_BEGIN';
export const FETCH_USERROLES_SUCCESS = 'FETCH_USERROLES_SUCCESS';
export const FETCH_USERROLES_FAILURE = 'FETCH_USERROLES_FAILURE';

export const POST_USER_BEGIN   = 'POST_USER_BEGIN';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILURE = 'POST_USER_FAILURE';

export const PUT_USER_BEGIN   = 'PUT_USER_BEGIN';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_FAILURE = 'PUT_USER_FAILURE';

export const UPDATE_USERROLES = 'UPDATE_USERROLES';

//Fetch
//##########################################################################
export const fetchUserRolesBegin = () => ({
  type: FETCH_USERROLES_BEGIN
});

export const fetchUserRolesSuccess = userroles => ({
  type: FETCH_USERROLES_SUCCESS,
  payload: { userroles }
});

export const fetchUserRolesFailure = error => ({
  type: FETCH_USERROLES_FAILURE,
  payload: { error }
});

export function fetchUserRoles() {
    return dispatch => {
      dispatch(fetchUserRolesBegin());

      return fetchService.get( "/userroles" )
        .then(json => {
          dispatch(fetchUserRolesSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchUserRolesFailure(error)));
    };
  }
//End Fetch
//##########################################################################

//Post
//##########################################################################
export const postUserBegin = () => ({
  type: POST_USER_BEGIN
});

export const postUserRolesuccess = user => ({
  type: POST_USER_SUCCESS,
  payload: { user }
  
});

export const postUserFailure = error => ({
  type: POST_USER_FAILURE,
  payload: { error }
});

export function postUser(user) {
    return dispatch => {
      dispatch(postUserBegin());
      return fetchService.post("/userroles", user)
        .then(json => {
          dispatch(postUserRolesuccess(json));
          return json;
        })
        .catch(error => dispatch(postUserFailure(error)));
    };
  }
//End Post
//##########################################################################


//Put
//##########################################################################
export const putUserBegin = () => ({
  type: PUT_USER_BEGIN
});

export const putUserRolesuccess = user => ({
  type: PUT_USER_SUCCESS,
  payload: { user }
});

export const putUserFailure = error => ({
  type: PUT_USER_FAILURE,
  payload: { error }
});

export function putUser(user, id) {
  
    return dispatch => {
      dispatch(putUserBegin());
      return fetchService.put("/userroles/" + id , user)
        .then(json => {
          dispatch(putUserRolesuccess(json));
          return json;
        })
        .catch(error => dispatch(putUserFailure(error)));
    };
  }
//End Put
//##########################################################################

//Update List

export function updateUserRoles(userrole) {
 
  return dispatch => {
    dispatch(pushUpdateUserRoles(userrole))
    }
};

export const pushUpdateUserRoles = userrole => ({
  type: UPDATE_USERROLES,
  payload: userrole
});

//##########################################################################