import { fetchService } from '../../services';
export const FETCH_ITEMLOCATIONS_BEGIN   = 'FETCH_ITEMLOCATIONS_BEGIN';
export const FETCH_ITEMLOCATIONS_SUCCESS = 'FETCH_ITEMLOCATIONS_SUCCESS';
export const FETCH_ITEMLOCATIONS_FAILURE = 'FETCH_ITEMLOCATIONS_FAILURE';

export const FETCH_ITEMLOCATION_BEGIN   = 'FETCH_ITEMLOCATION_BEGIN';
export const FETCH_ITEMLOCATION_SUCCESS = 'FETCH_ITEMLOCATION_SUCCESS';
export const FETCH_ITEMLOCATION_FAILURE = 'FETCH_ITEMLOCATION_FAILURE';

export const UPDATE_ITEMLOCATION = 'UPDATE_ITEMLOCATION';
export const DELETED_ITEMLOCATION = 'UPDATE_DELETEDLOCATION';

//Fetch
//##########################################################################
export const fetchItemLocationsBegin = () => ({
  type: FETCH_ITEMLOCATIONS_BEGIN
});

export const fetchItemLocationsSuccess = itemlocations => ({
  type: FETCH_ITEMLOCATIONS_SUCCESS,
  payload: { itemlocations }
});

export const fetchItemLocationsFailure = error => ({
  type: FETCH_ITEMLOCATIONS_FAILURE,
  payload: { error }
});

export function fetchItemLocations() {
    return dispatch => {
      dispatch(fetchItemLocationsBegin());
      return fetchService.get("/itemlocations")
        .then(json => {
          dispatch(fetchItemLocationsSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchItemLocationsFailure(error)));
    };
  }
//End Fetch
//##########################################################################



//Fetch Single
//##########################################################################
export const fetchItemLocationBegin = () => ({
  type: FETCH_ITEMLOCATION_BEGIN
});

export const fetchItemLocationSuccess = itemlocation => ({
  type: FETCH_ITEMLOCATION_SUCCESS,
  payload: { itemlocation }
});

export const fetchItemLocationFailure = error => ({
  type: FETCH_ITEMLOCATION_FAILURE,
  payload: { error }
});

export function fetchLocation(locationId) {
  
    return dispatch => {
      dispatch(fetchItemLocationBegin());
      return fetchService.get(`/itemlocations/${locationId}`)
        .then(json => {
          dispatch(fetchItemLocationSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchItemLocationFailure(error)));
    };
  }
//End Fetch Single
//##########################################################################

//Update List

export function updateLocation(location) {
 
  return dispatch => {
    dispatch(pushUpdateLocation(location))
    }
};

export const pushUpdateLocation = location => ({
  type: UPDATE_ITEMLOCATION,
  payload: location
});

//##########################################################################


//Delete Item From List
export function deletedLocation(id) {
  return dispatch => {
    dispatch(deleteLocationPayload(id))
    }
};

export const deleteLocationPayload = id => ({
  type: DELETED_ITEMLOCATION,
  payload: id
});

//##########################################################################