import React, { Component, useRef, useState } from "react";
import { Nav, Tab } from "react-bootstrap";

/*
**Created by Brandon Schaeffer 12/3/2020
This component can be used whenever tabs are used and tab navigation is needed. 
This component can be placed within a page or modal, or just about anywhere needed. 
Tab1, Tab2 are always true. Any additional tabs needs to be passed to parent component by marking them true. 
!important - Tabs must be in order (ex: you cannot have tab4 as true without also having tab3 as true too)
You can name tabs by passing up the "tab{number}Name" and setting it equal to the name of the tab (ex: tab1Name = {"String"})
*/

export function TabControl({
  tab1Name,
  tab2Name,
  tab3Name,
  tab4Name,
  tab5Name,
  tab1 = true,
  tab2 = true,
  tab3 = null,
  tab4 = null,
  tab5 = null,
}) {

  const [selectedTab, setSelectedTab] = useState("tab1");
  const [ctrlKey, setCtrlKey] = useState(false);
  const tab1Ref = useRef(null);
  const tab2Ref = useRef(null);
  const tab3Ref = useRef(null);
  const tab4Ref = useRef(null);
  const tab5Ref = useRef(null);

  function keyPressed(e) {
    if (e.key === "Enter") {
      //   handleSave();
    }

    if (e.key === "Control") {
      setCtrlKey(true);
    }

    if (e.key === "Tab" && ctrlKey == true) {
      switch (selectedTab) {
        case "tab1":
          tab2Ref.current.focus();
          setSelectedTab("tab2");
          break;
        case "tab2":
          tab3Ref.current.focus();
          setSelectedTab("tab3");
          break;

        case "tab3":
          if (tab4 === true) {
            tab4Ref.current.focus();
            setSelectedTab("tab4");
          } else {
            tab1Ref.current.focus();
            setSelectedTab("tab1");
          }
          break;

        case "tab4":
          if (tab5 === true) {
            tab5Ref.current.focus();
            setSelectedTab("tab5");
          } else {
            tab1Ref.current.focus();
            setSelectedTab("tab1");
          }
          break;

        case "tab5":
            tab1Ref.current.focus();
            setSelectedTab("tab1");
          break;
      }
    }
  }

  //whenever keyup is triggered, this function checks to see if the Ctrl key went up and if so sets the state varible to false. 
  //This helps determine when the keyboard combination of Control & Tab are pressed together
  function keyUp(e) {
    if (e.key === "Control") {
      setCtrlKey(false);
    }
  }

  function tabChanged(e) {
    setSelectedTab(e);
  }

  return (
    <>
      <div onKeyDown={keyPressed} onKeyUp={keyUp}>
        <Tab.Container
          defaultActiveKey="tab1"
          transition={false}
          onSelect={tabChanged}
        >
          <Nav justify variant="tabs" className="item_tabs">
            <Nav.Item>
              <Nav.Link eventKey="tab1" ref={tab1Ref}>
                {tab1Name}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="tab2" ref={tab2Ref}>
                {tab2Name}
              </Nav.Link>
            </Nav.Item>

            {tab3 === true ? (
              <Nav.Item>
                <Nav.Link eventKey="tab3" ref={tab3Ref}>
                  {tab3Name}
                </Nav.Link>
              </Nav.Item>
            ) : (
              <></>
            )}

            {tab4 === true ? (
              <Nav.Item>
                <Nav.Link eventKey="tab4" ref={tab4Ref}>
                  {tab4Name}
                </Nav.Link>
              </Nav.Item>
            ) : (
              <></>
            )}

            {tab5 === true ? (
              <Nav.Item>
                <Nav.Link eventKey="tab5" ref={tab5Ref}>
                  {tab5Name}
                </Nav.Link>
              </Nav.Item>
            ) : (
              <></>
            )}
          </Nav>

          <Tab.Content className={"tab_content"}>
            <Tab.Pane eventKey="tab1">Pane1</Tab.Pane>
            <Tab.Pane eventKey="tab2">Pane2</Tab.Pane>
            <Tab.Pane eventKey="tab3">Pane3</Tab.Pane>
            <Tab.Pane eventKey="tab4">Pane4</Tab.Pane>
            <Tab.Pane eventKey="tab5">Pane5</Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
}
