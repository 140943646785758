import React, { useState } from "react";
import {
  Modal,
  ListGroup,
  Button,
  Row,
  Col
} from "react-bootstrap";
import "./columnselector.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { ActionBarAllThings } from "../Layout/ActionBar";
import InventoryDashCheckbox from "./InventoryDashCheckbox";

function ColumnSelector({
  inputColumns,
  cancelDialog,
  showModal,
  saveHandler,
  columnChangeHandler,
  columnMoveDown,
  columnMoveUp
}) {


  //This function displays the list of columns and can be called after making any changes to the column order
  function displayColumnList() {
    return (
    <ListGroup>
    {inputColumns
      .sort(function (a, b) {
        return a.displayorder - b.displayorder;
      })
      .map((col, index) => (
        <ListGroup.Item key={index} className={col.activeColumn ? "active_column" : "columnItem"}>

          <Row className="column_selector_rows">
            <Col>
              <InventoryDashCheckbox
                label={col.displayName}
                onChange={() => columnChangeHandler(col)}
                checked={col.selected}
                onClick={() => columnChangeHandler(col)}
              />
            </Col>

            <Col>
              <div className="column_selector_list_button_group_div">
                <Button className="columnBtn" 
                    onClick={() => columnMoveUp(col)}
                >
                  <BsArrowUpShort
                    size={"1.5rem"}
                  />
                </Button>
                   
                <Button className="columnBtn" 
                   onClick={() => columnMoveDown(col)}
                >
                  <BsArrowDownShort
                    size={"1.5rem"}
                 />
                </Button>
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
  </ListGroup>
  )}

  return (
    <>
      <Modal
        show={showModal}
        onHide={cancelDialog}
        dialogClassName={"inventorydash_custom_modal_dialog"}
      >

      <Modal.Header closeButton bsPrefix={"custom_modal_header"}>
          <ActionBarAllThings
            pageTitle={"Adjust Columns"}
            showSaveBtn={true}
            saveHandler={() => saveHandler(inputColumns)}
            cancelHandler={cancelDialog}
            onModal={true}
          />
        </Modal.Header>

        <Modal.Body bsPrefix="inventorydash_custom_modal_body">
         {displayColumnList()}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ColumnSelector;
