import React, { Component } from 'react';
import { ActionBarAllThings } from '../../Layout/ActionBar';
import { TabControl } from '../../Layout/formControl/TabControl';
import "../../Layout/formControl/tabcontrol.css"

function help({
keyPressed, keyUp
})
{
    return(
        <>

        <ActionBarAllThings
            pageTitle={'Help'}
        />
        <div className="tab_control_test"  >
        <TabControl
            tab3 = {true}
            tab4 = {true}
            tab5 = {true}
            tab1Name = "Tab1"
            tab2Name = "Tab2"
            tab3Name = "Tab3"
            tab4Name = "Tab4"
            tab5Name = "Tab5"
        />

        </div>

        </>

    
    );
};

export default help;