import { history } from './history';
import { accountService } from './account.service';

export const fetchService = {
    get,
    post,
    put,
    delete: _delete
}

const baseEntityAPIUrl = (process.env.REACT_APP_INVENTORYDASH_APIURL); 

function get(apiroute) {
    let url = baseEntityAPIUrl + apiroute;
    const requestOptions = {
        method: 'GET',
        headers: authHeader(url)
    };

    return fetch(url, requestOptions).then(handleResponse)
    .catch(error => {
        history.push("login");
    });
}

function post(apiroute, body) {
    let url = baseEntityAPIUrl + apiroute;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function put(apiroute, body) {
    let url = baseEntityAPIUrl + apiroute;
    
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);    
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(apiroute) {
    let url = baseEntityAPIUrl + apiroute;


    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(url)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = accountService.userValue;
    const isLoggedIn = user && user.token;

//TODO - Validate that the API call is going to an approved API, but there will 
//be mutliple so this line won't cut it
//const isApiUrl = url.startsWith(config.apiUrl);
  

const isApiUrl = true;
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${user.token}` };
    } else {
        return {};
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        let data;

        if (IsValidJSONString(text))
        {
            data = text && JSON.parse(text);
        }
        else
        {
            data = text;
        }
        
        
        if (!response.ok) {
            if ([401].includes(response.status)) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                accountService.logout();
            }
            if ([403].includes(response.status)) { // This is coming from a user who is not authorized to use the function
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
               // accountService.logout();
                //alertService.alert("Unauthorized access attempted, please contact system administrator.")
                return Promise.reject("Unauthorized access attempted, please contact system administrator.");
            }

           // const error = (data && data.message) || response.statusText;
            const error = (data) || response.status;
            return Promise.reject(error);
        }

        return data;
    });

    function IsValidJSONString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}