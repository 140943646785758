import React, { useState } from "react";
import {
  RiLightbulbFlashLine,
  RiLightbulbFlashFill,
  RiHome2Line,
  RiLogoutBoxRLine,
} from "react-icons/ri";
import { AiOutlineAlert } from "react-icons/ai";
import { IoMdSettings, IoMdSettings as SettingsIcon } from "react-icons/io";
import { Link } from "react-router-dom";
import { changeAppTheme } from "../../store/actions/uiActions";
// import { AlertsComponent } from "../pages/settings/AlertsComponent";
import { resetCurrentUsersRoles } from "../../store/actions/loggedInUserRolesActions";
import { changeSettingsClicked } from "../../store/actions/userInteractionActions";
import { useDispatch, useSelector } from "react-redux";
import { accountService } from "../../services";
import { Modal } from 'react-bootstrap';
import "./profileandsettings.css";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

function ProfileAndSettings(adminUser) {
  const dispatch = useDispatch();
  const [profileExpanded, setProfileExpanded] = useState(null);
  const [alertsComponentVisible, setAlertsComponentVisible] = useState(false);

  // Get user initials
  let userInitials =
    accountService.getUserProperty("initials") === ""
      ? "ID" // Default Initials Inventory Dash ID
      : accountService.getUserProperty("initials");

  // Get selected theme from redux reducer
  const activetheme = useSelector((state) => state.uiState.theme);

  //function that determines the current state of the profile - when profile is clicked it will expand when its in a collapsed state and will collapse if in an expanded state
  function profileOptionsControl() {
    if (profileExpanded === true) {
      setProfileExpanded(false);
    }

    if (profileExpanded === false || profileExpanded === null) {
      setProfileExpanded(true);
    }
  }

  //function that is called when the light-bulb icon is clicked that toggles between light and dark themes
  function setTheme(theme) {
    dispatch(changeAppTheme(theme));
  }

  function logout(logoutAuth0) {
    dispatch(resetCurrentUsersRoles());
    accountService.logout();
    setProfileExpanded(false);
  }

  function settingsClicked() {
    dispatch(changeSettingsClicked(true));
    setProfileExpanded(false);
  }

  function collapseProlfileOptions() {
    setProfileExpanded(false);
  }

  function changeTheme() {
    if (activetheme === "light") {
      setTheme("dark");
      setProfileExpanded(true);
    } else if (activetheme === "dark") {
      setTheme("light");
      setProfileExpanded(true);
    }
  }

  return (
    <>
      <div
        className="profile_container"
      >
        <div className="profile_bubble noselect" onClick={profileOptionsControl}>
          {userInitials}
        </div>

        <Modal
        show ={profileExpanded}
        onHide={collapseProlfileOptions}  
        className={"profile_settings_modal"}

        >
         <div>
            {/* <div  className="profile_overlay" onClick={collapseProlfileOptions}></div> */}
            <div className="profile_options">
              <div className="profile_title noselect">
                Profile &amp; Settings
                <span onClick={profileOptionsControl}>X</span>
              </div>

              <div
                className="theme_toggle_container noselect"
                onClick={changeTheme}
                cursor="pointer"
              >
                <span>Theme</span>
                <div className="theme_toggle_icon">
                  <RiLightbulbFlashLine size="2.2em" aria-label="change theme"/>
                </div>
              </div>

              {/* <Link to="/" className="profile_option_link" aria-label="navigate to dashboard">
                <div
                  className="dashboard_option_container noselect"
                  onClick={collapseProlfileOptions}
                >
                  <span>Dashboard</span>
                  <div className="dashboard_option_icon">
                    <RiHome2Line
                      size="2.2em"
                      className="profile_and_settings_icons"
                    />
                  </div>
                </div>
              </Link> */}

              {/* <Link to="/Settings/Alerts" className="profile_option_link" aria-label="navigate to alertsd">
                <div
                  className="alerts_option_container noselect"
                  onClick={collapseProlfileOptions}
                >
                  <span>Alerts</span>
                  <div className="alerts_option_icon">
                    <AiOutlineAlert
                      size="2.2em"
                      // onClick={() => setAlertsComponentVisible(true)}
                      className="profile_and_settings_icons"
                    />
                  </div>
                </div>
              </Link> */}

              {adminUser ? ( //only show admin settings if user is admin
                <Link to="/Settings" className="profile_option_link" aria-label="navigate to admin settings">
                  <div
                    className="admin_settings_container noselect"
                    onClick={collapseProlfileOptions}
                  >
                    <span>Admin Settings</span>
                    <div className="admin_settings_icon">
                      <IoMdSettings
                        size="2.2em"
                        className="profile_and_settings_icons"
                      />
                    </div>
                  </div>
                </Link>
              ) : (
                <></>
              )}

              <div className="logout_container noselect" onClick={logout}>
                <span>Logout</span>
                <div className="logout_option_icon">
                  <RiLogoutBoxRLine size="2.2em" aria-label="logout of application" />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export { ProfileAndSettings };
