import { fetchService } from "../../../services";

export const FETCH_SEARCH_BEGIN = 'FETCH_SEARCH_BEGIN';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const FETCH_SEARCH_FAILURE = 'FETCH_SEARCH_FAILURE';

const fetchSearchBegin = () => ({
    type: FETCH_SEARCH_BEGIN
});

const fetchSearchSuccess = results => ({
    type: FETCH_SEARCH_SUCCESS,
    payload: { results }
});

const fetchSearchFailure = error => ({
    type: FETCH_SEARCH_FAILURE,
    payload: { error }
});

export function fetchSearch(searchtype, searchstring) {

    // https://localhost:55004/api/search/customers
    return dispatch => {
        dispatch(fetchSearchBegin());
        return fetchService.put("/search/" + searchtype, searchstring)
         .then(json => {
             dispatch(fetchSearchSuccess(json));
             return json;
         })
         .catch(error => dispatch(fetchSearchFailure(error)));
    };
};

//#endregion
