import React, { useState, useEffect } from 'react';
import { fetchVendors, updateVendors } from '../../../store/actions/vendorActions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { fetchService, alertService } from '../../../services';
import { trackPromise } from 'react-promise-tracker';
import AddEditVendorPage from './AddEditVendorsPage';
import { ActionBarAllThings } from '../../Layout/ActionBar';
import DisplayTable from '../../Controls/DisplayTable';

export default function Vendors({location}) {

  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selVendID, setSelVendID] = useState(-1);
  const [showEditBtn, setShowEditBtn] = useState(false);

  const vendors = useSelector(state => state.vendors.vendors);
  const loading = useSelector(state => state.vendors.loading);
  const error = useSelector(state => state.vendors.error);
  const vendorsLoaded = useSelector(state => state.vendors.vendorsLoaded);
  const [editingVendor, setEditingVendor] = useState(false);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [editingVendorID, setEditingVendorID] = useState(-1);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!vendorsLoaded) {
      trackPromise(dispatch(fetchVendors()));
    }
  }, [vendorsLoaded, dispatch]);

  // check here to see where we're canceling from -Brendon
  function cancelDialog() {
    setShowModal(false);
    setEditingVendorID(-1);
  }

  function saveVendor(vendor) {
    if (vendor === undefined) {
      return;
    }

    // Determine if we're creating or updating
    // Darren 9/9/2020, I switched the put and post around, they were backwards
    // also changed the API inputs to reflect them correctly
    if (vendor.id === 0) {
      createVendor(vendor);
    } else {
      updateVendor(vendor);
    }
    setEditingVendorID(-1);
  }

  // Create a brand new vendor
  function createVendor(vendor) {
    if (vendor !== undefined) {
      setIsSubmitting(true);
      fetchService.post('/vendor/', vendor)
        .then(json => {
          // Success
          dispatch(updateVendors(json));
          setIsSubmitting(false);
          cancelDialog();
        })
        .catch(error => {
          setIsSubmitting(false);
          alertService.error(error);
          return;
        })
    }
  }

  // Update an existing Vendor
  function updateVendor(vendor) {
    if (vendor !== undefined) {
      setIsSubmitting(true);
      fetchService.put(`/vendor/${vendor.id}`, vendor)
        .then(json => {
          // Success
          dispatch(updateVendors(json));
          setIsSubmitting(false);
          cancelDialog();
        })
        .catch(error => {
          setIsSubmitting(error);
          alertService.error(error);
          return;
        })
    }
  }

  function addVendor() {
    setEditingVendorID(0);
    setEditingVendor(false);
    setShowModal(true);
  }

  function editVendor() {
    if (selVendID === undefined) return;
    if (selVendID === 0) return;

    setEditingVendorID(selVendID);
    setEditingVendor(true);
    setShowModal(true);
  }

  function setSelectedVendor(vendor) {
    setSelVendID(vendor.id);
    setShowEditBtn(true); //displays edit button when a vendor is selected from table
  }

  function showColumnModal() {
    setShowColumnSelector(true);
  }

  // I just moved all the returns to one place
  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  let vendorId = undefined;

    if (location != null) {
    if (location.state != null) {
      if (location.state.vendorId != null) {
        vendorId = location.state.vendorId;
      }
    }
  }

    if (vendorId != null) {
        // Set the current Item and show the display Modal
        // this is for a call that is coming from other pages
        
        if (vendorId !== 0) {
          if (selVendID !== vendorId) {
            setSelVendID(vendorId);
            setEditingVendorID(vendorId);
            setShowModal(true);
            history.replace(location.pathName, []);
            vendorId = undefined;
          }
        }
      }

  const colHeaders = [
    {
      displayName: "Vendor Name",
      propName: "displayName",
      colclassname: "",
      dataclassname: '',
      selected: true,
      displayorder: 0
    }
  ];


  return (
    <>

      <ActionBarAllThings
        pageTitle={'Vendors'}
        showEditBtn={showEditBtn}
        showAddBtn={true}
        saveHandler={saveVendor}
        addItem={addVendor}
        editItem={editVendor}
        showColBtn={true}
        adjustColumns={showColumnModal}
      />

      {/* Only render if it is visible, otherwise just save the resources - Darren 10/8/2020 */}
      {
        showModal
          ?
          <AddEditVendorPage
            currVendID={editingVendorID}
            visible={showModal}
            cancelHandler={cancelDialog}
            saveHandler={saveVendor}
            isSubmitting={isSubmitting}
            isEditing={editingVendor}
            showModal={showModal}
          />
          :
          <></>
      }

      <div className="page_container">
        <DisplayTable
          tablenameidentifier={'vendors_page_display_table'}
          inputitems={vendors}
          colheaders={colHeaders}
          selectCurrentItem={setSelectedVendor}
          idname={'id'}
          showColumnSelector={showColumnSelector}
          setShowColumnSelector={setShowColumnSelector}
          // setColumnsList={setColumnsList}
        />
      </div>
    </>
  );
};