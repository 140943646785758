import {
  FETCH_ITEMS_BEGIN,
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_FAILURE,
  POST_ITEM_SUCCESS,
  POST_ITEM_BEGIN,
  POST_ITEM_FAILURE,
  FETCH_ITEM_BEGIN,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_SUCCESS,
  UPDATE_ITEMS,
  CLEAR_CURRENT_ITEM,
  CLEAR_NEW_ITEM
} from "../actions/itemActions";

const initialState = {
  items: [],
  loading: false,
  error: null,
  itemsLoaded: false,
  itemPosting: false,
  item: {},
  itemLoading: false,
  itemError: null,
  itemLoaded: false,
  newItem: 0
};

export default function itemReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITEMS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
        itemsLoaded: false,
      };

    case FETCH_ITEMS_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        items: action.payload.items,
        itemsLoaded: true,
      };

    case FETCH_ITEMS_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case FETCH_ITEM_BEGIN:
      return {
        ...state,
        itemLoading: true,
        itemError: null,
        itemLoaded: false,
      };

    case FETCH_ITEM_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        item: action.payload.item,
        itemLoaded: true,
      };

    case FETCH_ITEM_FAILURE:
      return {
        ...state,
        itemLoading: false,
        itemError: action.payload.error,
        item: {},
      };

    case CLEAR_CURRENT_ITEM:
      return {
        ...state,
        itemLoading: false,
        itemError: null,
        itemLoaded: false,
        item: {},
      };

    case POST_ITEM_BEGIN:
      return {
        ...state,
        error: null,
        itemPosting: true,
      };
    case POST_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        itemPosting: false,
      };

    case POST_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        itemPosting: false,
        items: state.items.concat(action.payload.item),
      };
    case UPDATE_ITEMS:
      //  We have a new or updated Item added in the software
      //  need to update the master underlying list to account for it
      let existingitem = state.items.find(
        (e) => e.itemid === action.payload.itemid
      );
      let index = state.items.indexOf(existingitem);

      //See if it already exists, if not add it to the list
      if (index < 0) {
        //If we are here we are going to add to the end of the list
        return {
          ...state,
          items: [...state.items.concat(action.payload)],
          newItem: action.payload.id
        };
      } else {
        //If we are here we are going to slice the array and insert the updated item
        //This keeps everything clean as far as rules of immutable go
        return {
          ...state,
          items: [
            ...state.items
              .slice(0, index)
              .concat(action.payload)
              .concat(state.items.slice(index + 1)),
          ],
        };
      }
    case CLEAR_NEW_ITEM:
      return {
        ...state,
        newItem: 0
      }


    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
