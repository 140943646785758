import { CHANGE_DROP_MENU_STATE } from "../../constants/action-types";

const initialState = {
  dropmenustate: false
};

export default function dropmenustateReducer(state = initialState, action) {
    switch(action.type)
    {
        case CHANGE_DROP_MENU_STATE:
          return Object.assign({}, state, {
            dropmenustate: !state.dropmenustate
          });

        default :
            return state;
    };
}
