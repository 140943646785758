import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomer } from '../../../store/actions/singleCustomerActions';
import { Modal, Form } from 'react-bootstrap';
import { CustomerInfo } from './customerDetails/customerInfo';
import { ActionBarAllThings } from '../../Layout/ActionBar';
import { trackPromise } from 'react-promise-tracker';
import "./addeditcustomerpage.css";
import AddEditContactPage from '../contacts/AddEditContactPage';

export default function AddEditCustomerPage({ currCustID, visible, cancelHandler, saveHandler, isSubmitting, isEditing, showModal }) {
    
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();

    // Changed this over to a single redux reducer to keep the single customer -- Darren 10/8/2020
    // clean and away from the customers reducer
    // not necessary but just keeps things simpler/cleaner and easier to manage
    // the loading was affecting the parent component and causing some odd behaviors
    const custLoaded = useSelector(state => state.customer.customerLoaded);
    const customer = useSelector(state => state.customer.customer);
    const loading = useSelector(state => state.customer.loading);
    const error = useSelector(state => state.customer.error);
    const iserror = useSelector(state => state.customer.iserror);
    const [ fromContactModal, setFromContactModal ] = useState(null);
    const [currCust, setCurrCust] = useState(customer);
    const [currBillingAddress, setCurrBillingAddress] = useState(customer?.billingAddress);
    const [currShippingAddress, setCurrShippingAddress] = useState(customer?.shippingAddress);
    const [editContactId, setEditContactId] = useState(null);
    const [addingOrEditingContact, setAddingOrEditingContact] = useState(false);

    const [contactList, setContactList] = useState(customer?.contacts == null ? null : JSON.parse(JSON.stringify(customer?.contacts)));

    useEffect(() => {
        if (!custLoaded && !loading ){  // Adding the loading check to prevent double api hits
            trackPromise(dispatch(fetchCustomer(currCustID)));
        }
    }, [custLoaded, dispatch, currCustID, loading]);

    // Added this useEffect to track changes to visible and customerid and reload the data
    // when necessary -- Darren 10/8/2020
    useEffect(() => {
        if (visible)
        {
            if (customer != null)
            {
                if (customer.id !== currCustID)
                {
                    trackPromise(dispatch(fetchCustomer(currCustID)));
                }
            }
            else
            {
                if (!loading)
                {
                    trackPromise(dispatch(fetchCustomer(currCustID)));
                }
            }
        }
        
    }, [currCustID, visible, customer, dispatch, loading]);
    
    // Added this useEffect to track when any new customer loads and move it into the local state variable
    // need this so that we can update the customer object and have it reflected in state
    // otherwise it would need to be pushed back to the redux state to refresh -- Darren 10/8/2020
    useEffect(() => {
        if (customer != null)
        {
            if (currCust == null || customer?.id !== currCust?.id)
            {
                setCurrCust(customer);
                setCurrShippingAddress(customer?.shippingAddress);
                setCurrBillingAddress(customer?.billingAddress);
                setContactList(JSON.parse(JSON.stringify(customer?.contacts)));
            }
        }
        
    }, [customer, custLoaded]);

    const validateInput = () => {
        const form = document.querySelectorAll('#customerInfoForm')[0];
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }

        setValidated(false);
        return true;
    }

    const handleSave = () => {
        if (!validateInput()) {
            return;
        }

        currCust.shippingAddress = currShippingAddress;
        currCust.billingAddress = currBillingAddress;
        currCust.contacts = contactList;

        saveHandler(currCust);
    }

    function onCustomerValueChanged(e) {
        e.persist();

        let newValue = e.target.value;

        if (e.target.name === 'shippingSameAsBilling'){
            newValue = !currCust.shippingSameAsBilling;
        }
        
        setCurrCust((prevState) => ({
            ...prevState,
            [e.target.name]: newValue
            })
        );
    }

    function onBillingAddressValueChanged(e) {
        e.persist();

        let newValue = e.target.value;
        setCurrBillingAddress((prevState) => ({
            ...prevState,
            [e.target.name]: newValue
            })
        );
    }

    function onShippingAddressValueChanged(e) {
        e.persist();

        let newValue = e.target.value;
        setCurrShippingAddress((prevState) => ({
            ...prevState,
            [e.target.name]: newValue
            })
        );
    }

    // adding contact
    function addContact(contact) {
        setEditContactId(0);
        setAddingOrEditingContact(true);
    }

    function updateContact(contactid) {
        setEditContactId(contactid);
        setAddingOrEditingContact(true);
    }

    function cancelContact() {
        setEditContactId(-1);
        setAddingOrEditingContact(false);
    }
    
    // add to customer list here - Brendon
    function saveContact(newContact) {
        let newContacts = contactList;

        if (newContact.id <= 0){
            // brand new: add contact to contacts array
            setCurrCust((prevState) => ({
               ...prevState,
               customer: {
                   ...prevState,
                   contacts: newContacts.push(newContact)
               }
            }));
        } else {
            // already exists
            newContacts.forEach(function(contact, i) { if (contact.id === newContact.id) newContacts[i] = newContact; });
        }

        setAddingOrEditingContact(false);
    }

    if (!visible) {
        return <></>;
    }

    if (loading || !custLoaded)
    {
        return <></>
    }

    if (iserror)
    {
        return <div>{error}</div>
    }

    if (currCust == null)
    {
        return <></>
    }

    if (showModal && addingOrEditingContact) {
        return (
            <AddEditContactPage
                contactid={editContactId}
                cancelHandler={cancelContact}
                saveHandler={saveContact}
                fromContactModal={fromContactModal}
                setFromContactModal={setFromContactModal}
                addingOrEditingContact={addingOrEditingContact}
            />
        );
    }


    return (
        <>
            {/* Modal form to edit Customers */}
            {/* <Modal
                show={showModal}
                onHide={cancelHandler}
                dialogClassName={"custom_modal_dialog"}
            >
            <div>
            <Modal.Header closeButton bsPrefix={"custom_modal_header"}>
                    <ActionBarAllThings
                        pageTitle={(isEditing ? "Edit Customer" : "Add Customer")}
                        showBackBtn = {true}
                        showSaveBtn = {true}
                        showDelBtn = {(isEditing ? true : false)}
                        cancelHandler = {cancelHandler}
                        saveHandler = {handleSave}
                        onModal={true}
                        saveBtnText={(isEditing ? "Update" : "Save")}
                    />
                </Modal.Header>

                <Modal.Body bsPrefix={"custom_modal_body"}> */}
                    
                    {/* Form element : Needed for 'required' element in TextInputs */}
                    <Form
                        autoComplete={'off'}
                        id={'customerInfoForm'}
                        name={'customer_info_edit_form'}
                        noValidate
                        validated={validated}
                        className="form-container"
                    >

                        {/* Customer control */}
                        <CustomerInfo
                            customer={ currCust }
                            contactList = { contactList }
                            billingAddress={ currBillingAddress }
                            shippingAddress={ currShippingAddress }
                            cancelHandler = { cancelHandler }
                            saveHandler = { saveHandler }
                            isSubmitting = { isSubmitting }
                            isEditing = { isEditing }
                            onCustomerValueChanged = { onCustomerValueChanged }
                            onBillingAddressChanged = { onBillingAddressValueChanged }
                            onShippingAddressChanged ={ onShippingAddressValueChanged }
                            addContact = { addContact }
                            editContact = { updateContact }
                            saveCustomer = { handleSave}
                            fromContactModal={fromContactModal}
                            setFromContactModal={setFromContactModal}
                        />
                     </Form>
                    {/*
                </Modal.Body>
            </div>
                
            </Modal> */}
        </>
    );
}