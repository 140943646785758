import React, { useState, useEffect } from "react"; //import useState from react
import { Button, Card, Row, Col, ListGroup } from "react-bootstrap";
import "./usersAndRolesComponent.css";
import {fetchUsers} from '../../../store/actions/usersActions';
import { useSelector, useDispatch} from 'react-redux';
import { trackPromise} from 'react-promise-tracker';
import { alertService } from "../../../services";
import {  ActionBarAllThings} from '../../Layout/ActionBar';
import { UserModal} from './users/UserModal';

function UsersAndRolesComponent({ setUserComponentVisible }) {
  
  const users = useSelector(state => state.users.users);
  const loading = useSelector(state => state.users.loading);
  const error = useSelector(state => state.users.error);
  const isError = useSelector(state => state.users.isError);
  const usersLoaded = useSelector(state => state.users.usersLoaded);


  const dispatch = useDispatch();

  const [currUser, setCurrUser] = useState(null);
  const [addingUser, setAddingUser] = useState(false);
  const [editingUser, setEditingUser] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [currUserId, setCurrUserId] = useState('');
  

  useEffect(() => {
    if (!usersLoaded && !loading)
    {
      trackPromise(
        dispatch(fetchUsers())
      );
    }
  }, [usersLoaded, loading])
  
  function saveThisPage() {
    // Do whatever save means here 


    // hide the page
    setUserComponentVisible(false);
  }

  function addUser() {
    setCurrUserId('');
    setAddingUser(true);
    setEditingUser(false);
    //setCurrUser(getNewUser());
    setShowModal(true);
  }

  function editUser() {

    if (currUserId != '')
    {
        setAddingUser(false);
        setEditingUser(true); 
        setShowModal(true);
        return;
    }
    alertService.info('Please select a user before attempting to edit.');
  }

  function cancelDialog() {
    setShowModal(false);
  }


  return (
    <>

      <ActionBarAllThings
        showBackBtn={true}
        pageTitle={'Users'}
        showSaveBtn={false}
        showColBtn={false}
        showEditBtn={true}
        showAddBtn={true}
        cancelHandler={() => setUserComponentVisible(false)} 
        saveHandler={saveThisPage} 
        addItem={addUser}
        editItem={editUser}
        showSortBtn={true}
      ></ActionBarAllThings>
   <div className="page_container user_page_container">
   {
      users.map((u, index) =>
      <Card className={'user_cards noselect' + ((currUserId === u.id)? ' users_selected_user' : '')} key={u.id} onClick={() => setCurrUserId(u.id)}>
        <div className="card_header">{u.displayName}</div>
        <div className="cardRow cardRowName"><div><b>{"Last: "}</b>{u.lastName}</div><div><b>{"First: "}</b>{u.firstName}</div></div>
        <div className="cardRow"><b>{"Email: "}</b>{u.email}</div>
        <div className="cardRow"><b>{"Role: "}</b>{u.roleId}</div>
        {/*TODO ^^^ Need to show Role Name instead of Id*/}
      </Card> 
      )
    }
     
   </div>
    
      
 
      {
        !showModal ?
        <></>
        :
        <UserModal
          cancelDialog={cancelDialog}
          showModal={showModal}
          userid={currUserId}
          addingUser={addingUser}
          editingUser={editingUser}
          
        />
      }
    </>
  );
}

function addButton(text, icon, clickHandler) {
  return (
    <Button
      variant="outline-secondary"
      aria-label={text}
      className="mr-2 quick-button"
      onClick={clickHandler}
    >
      {icon}
      <div>{text}</div>
    </Button>
  );
}

export { UsersAndRolesComponent };
