import React, { useState, useEffect } from 'react';
import { fetchCustomers, updateCustomers } from '../../../store/actions/customerActions';
import { useSelector, useDispatch } from 'react-redux';
import { fetchService, alertService } from '../../../services';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from "react-router-dom";
import AddEditCustomerPage from './AddEditCustomerPage';
import { ActionBarAllThings } from '../../Layout/ActionBar';
import DisplayTable from '../../Controls/DisplayTable';
import WindowedTable from '../../Controls/Tables/WindowedTable';

function Customers({location}) {

    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selCustID, setSelCustID] = useState(-1);
    const [showEditBtn, setShowEditBtn] = useState(false);
    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const [columnsList, setColumnsList] = useState(colHeaders);
    const customers = useSelector(state => state.customers.customers);
    const loading = useSelector(state => state.customers.loading);
    const error = useSelector(state => state.customers.error);
    const customersLoaded = useSelector(state => state.customers.customersLoaded);
    const [editingCustomer, setEditingCustomer] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!customersLoaded) {
            trackPromise(dispatch(fetchCustomers()));
        }
    }, [customersLoaded, dispatch]);

    // check here to see where we're canceling from -Brendon
    function cancelDialog() {
        setShowModal(false);
    }

    function showColumnModal() {
        setShowColumnSelector(true);
      }

    function saveCustomer(customer) {
        if (customer === undefined) {
            return;
        }

        // Determine if we're creating or updating
        // Darren 9/9/2020, I switched the put and post around, they were backwards
        // also changed the API inputs to reflect them correctly
        if (customer.id === 0) {
            createCustomer(customer);
        } else {
            updateCustomer(customer);
        }
    }

    // Create a brand new customer
    function createCustomer(customer) {
        if (customer !== undefined) {
            setIsSubmitting(true);
            fetchService.post('/customers/', customer)
                .then(json => {
                    // Success
                    dispatch(updateCustomers(json));
                    setIsSubmitting(false);
                    cancelDialog();
                })
                .catch(error => {
                    setIsSubmitting(false);
                    alertService.error(error);
                    return;
                })
        }
    }

    // Update an existing Customer
    function updateCustomer(customer) {
        if (customer !== undefined) {
            setIsSubmitting(true);

            fetchService.put(`/customers/${customer.id}`, customer)
                .then(json => {
                    // Success
                    dispatch(updateCustomers(json));
                    setIsSubmitting(false);
                    cancelDialog();
                })
                .catch(error => {
                    setIsSubmitting(error);
                    alertService.error(error);
                    return;
                })
        }
    }

    function addCustomer() {
        setSelCustID(0); // Need to set the id to zero to tell the system there is a new customer being added -Darren 10/8/2020
        setEditingCustomer(false);
        setShowModal(true);
    }

    function editCustomer() {
        if (selCustID === undefined) return;
        if (selCustID === 0) return;

        setEditingCustomer(true);
        setShowModal(true);
    }

    function setSelectedCustomer(customer) {
        setSelCustID(customer.id);
        setShowEditBtn(true); //displays edit button when a customer is selected from table
    }

    // I just moved all the returns to one place
    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    let customerId = undefined;

    if (location != null) {
    if (location.state != null) {
      if (location.state.customerId != null) {
        customerId = location.state.customerId;
      }
    }
  }

    //ANCHOR
    if (customerId != null) {
        // Set the current Item and show the display Modal
        // this is for a call that is coming from other pages
        
        if (customerId !== 0) {
          if (selCustID !== customerId) {
            setSelCustID(customerId);
            setEditingCustomer(true);
            setShowModal(true);
            //FIXME - Below is broken
            history.replace(location.pathName, []);
            customerId = undefined;
          }
        }
      }

    return (
        <>

            <ActionBarAllThings
                pageTitle={'Customers'}
                showEditBtn={showEditBtn}
                showAddBtn={true}
                saveHandler={saveCustomer}
                addItem={addCustomer}
                editItem={editCustomer}
                showColBtn={true}
                helpTopic={'Customers'}
                adjustColumns={showColumnModal}

            />

            {/* Only render if it is visible, otherwise just save the resources - Darren 10/8/2020 */}
            {
                showModal
                    ?
                    <AddEditCustomerPage
                        currCustID={selCustID}
                        visible={showModal}
                        cancelHandler={cancelDialog}
                        saveHandler={saveCustomer}
                        isSubmitting={isSubmitting}
                        isEditing={editingCustomer}
                        showModal={showModal}
                    />
                    :
                    <></>
            }


            <div className="page_container">
                {/* <DisplayTable
                    tablenameidentifier={'customers_page_display_table'}
                    inputitems={customers}
                    colheaders={colHeaders}
                    selectCurrentItem={setSelectedCustomer}
                    idname={'id'}
                /> */}
                <WindowedTable
                 tablenameidentifier={'customers_page_display_table'}
                 inputitems={customers}
                 colheaders={colHeaders}
                 selectCurrentItem={setSelectedCustomer}
                 setSelectedId={setSelectedCustomer}
                 selectedId={selCustID}
                 idname={'id'}
                 setShowColumnSelector={setShowColumnSelector}
                 showColumnSelector={showColumnSelector}
                 setColumnsList={setColumnsList}
                />
            </div>

        </>
    );
};

export {Customers}

const colHeaders = [
    {
        displayName: "Customer Name",
        propName: "displayName",
        colclassname: "",
        dataclassname: '',
        selected: true,
        displayorder: 0
    },
    {
        displayName: "Address",
        propName: "billingAddress.address1",
        colclassname: "",
        dataclassname: '',
        selected: true,
        displayorder: 1
    },
    {
        displayName: "Address 2",
        propName: "",
        colclassname: "",
        dataclassname: '',
        selected: true,
        displayorder: 2
    },
    {
        displayName: "City",
        propName: "",
        colclassname: "",
        dataclassname: '',
        selected: true,
        displayorder: 3
    },
    {
        displayName: "ST",
        propName: "",
        colclassname: "",
        dataclassname: '',
        selected: true,
        displayorder: 4
    },
    {
        displayName: "Zip",
        propName: "",
        colclassname: "",
        dataclassname: '',
        selected: true,
        displayorder: 5
    }
];