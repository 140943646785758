import React from "react";
import { Tab, Nav, Modal } from "react-bootstrap";
import TextInput from "../../../Controls/textInput";
import InventoryDashCheckbox from "../../../Controls/InventoryDashCheckbox";
import Address from "../../../Address";
import ContactList from "../../../ContactList";
import { ActionBarAllThings } from '../../../Layout/ActionBar';

function VendorInfo({
  vendor,
  contactList,
  billingAddress,
  shippingAddress,
  onVendorValueChanged,
  onBillingAddressChanged,
  onShippingAddressChanged,
  addContact,
  editContact,
  saveVendor,
  cancelHandler,
  isEditing,
  handleSave,
  fromContactModal
}) {
  return (
    <Modal
      show={true}
      onHide={cancelHandler}
      dialogClassName={"inventorydash_custom_modal_dialog"}
      animation={ fromContactModal ? false : true}

    >
      <div>
        <Tab.Container defaultActiveKey={fromContactModal ? "contacts" : "vend"} transition={false}>
          <Modal.Header closeButton bsPrefix={"inventorydash_custom_modal_header"}>
            <ActionBarAllThings
              pageTitle={isEditing ? "Manage Vendor" : "Add Vendor"}
              showBackBtn={true}
              showSaveBtn={true}
              showDelBtn={isEditing ? true : false}
              cancelHandler={cancelHandler}
              saveHandler={saveVendor}
              onModal={true}
              saveBtnText={isEditing ? "Update" : "Save"}
            />

            {/* // <div className='form-container'> */}

            {/* swap active key for contact */}

            {/* Create the tab buttons */}
            <Nav justify variant="tabs" className="item_tabs">
              <Nav.Item className="tab_item">
                <Nav.Link eventKey="vend">
                  <span className="noselect">Vendor</span>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="tab_item">
                <Nav.Link eventKey="contacts">
                  <span className="noselect">Contacts</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Modal.Header>

          <Modal.Body bsPrefix={"inventorydash_custom_modal_body"}>
            <Tab.Content className={"tab_content noselect"}>
              <Tab.Pane eventKey="vend">
                <TextInput
                  name="displayName"
                  label="Name"
                  value={vendor.displayName}
                  onChange={onVendorValueChanged}
                  required={true}
                  validatefailtext="Please enter a name."
                />

                <Address
                  header="Billing Address"
                  visible={true}
                  address={billingAddress}
                  onInputValueChanged={onBillingAddressChanged}
                />

                <InventoryDashCheckbox
                  label="Same as Billing"
                  name="shippingSameAsBilling"
                  checked={vendor.shippingSameAsBilling}
                  onChange={onVendorValueChanged}
                />

                <Address
                  header="Shipping Address"
                  visible={!vendor.shippingSameAsBilling}
                  address={shippingAddress}
                  onInputValueChanged={onShippingAddressChanged}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="contacts">
                {/* Need a displayTable here to list Contacts. Need add/edit buttons? */}
                <ContactList
                  contacts={contactList}
                  addHandler={addContact}
                  editHandler={editContact}
                />
              </Tab.Pane>
            </Tab.Content>
          </Modal.Body>
        </Tab.Container>
      </div>
    </Modal>
  );
}

export { VendorInfo };
