import { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { fetchCurrentUserRoles} from '../store/actions/loggedInUserRolesActions';
import { accountService } from '../services';

function useRolePermissions(permission = '') {
  
  const rolePermissions = useSelector(state => state.loggedinuserroles.items);
  const loading = useSelector(state => state.loggedinuserroles.loading);
  const error = useSelector(state => state.loggedinuserroles.error);
  const loaded = useSelector(state => state.loggedinuserroles.dataloaded);
  const dispatch = useDispatch();
  const userAuthenticated = accountService.userValue;

  useEffect(() => {
      if (!loaded && !loading)
      {
        if (userAuthenticated)
        {
            dispatch(fetchCurrentUserRoles());
        }
      }
   
  }, [loading, loaded, dispatch, userAuthenticated]);

  if (error != null) {
    return null;
  }

  if (!loaded)
  {
      return null;
  }

  if (rolePermissions == null)
  {
    
    return false;
  }

  if (permission !== '')
  {

    // return true; // setting this to true will allow all permissions
    if (rolePermissions.indexOf('Administrator') > -1)
    {
      return true;
    }
    if (permission.includes('Manage'))
    {
      if (rolePermissions.indexOf('SuperUser') > -1)
      {
        return true;
      } 
    }
    if (permission.includes('View'))
    {
      if (rolePermissions.indexOf('SuperUser') > -1 || rolePermissions.indexOf('ViewOnly') > -1)
      {
        return true;
      } 
    }

    return rolePermissions.indexOf(permission) > -1;
  }


  
  return rolePermissions;
}

export { useRolePermissions };