
//import Items from "./components/pages/items/items";
//import Items from "./components/pages/items/items";
import { ItemPage as Items} from "./components/pages/items/ItemPage";
import {Sales} from "./components/pages/sales/Sales";
import Purchasing, { PurchaseOrders } from "./components/pages/purchasing/PurchaseOrders";
import {Customers} from "./components/pages/customers/customersPage";
import Vendors from "./components/pages/vendors/vendorsPage";
import Returns from "./components/pages/returns/returns";
import Reports from "./components/pages/reports/reports";
import { Settings } from "./components/pages/settings/Settings";
import Help from "./components/pages/help/help";
import Alerts from "./components/pages/settings/AlertsComponent";
import { Home } from "./components/pages/home/home"
import { ItemGroupPage as Groups} from "./components/pages/itemgroups/itemGroupPage";

import { Login } from './components/useraccount/login';
import { Register } from './components/useraccount/Register';
import { VerifyEmail } from './components/useraccount/VerifyEmail';
import { ForgotPassword } from './components/useraccount/ForgotPassword';
import { ResetPassword } from './components/useraccount/ResetPassword';

import { TestComponent } from './components/pages/TestComponent';
import { DashboardSettingsComponent } from "./components/pages/settings/DashboardSettingsComponent";

const routes = [  
    //all routes must be defined here, properties for sideMenu and topMenu can be used to remove/hide menu items accordingly
    { path: '/', component: Home, display: "Home", exact: true, sideMenu: false, topMenu:false, private:true },  
    { path: '/Items', component: Items, display: "Items", exact: true, sideMenu: true, topMenu: true, private: true  },
    // { path: '/Groups', component: Groups, display: "Groups", exact: true, sideMenu: true, topMenu: true, private: true},
    { path: '/Sales', component: Sales, display: "Sales", exact: true, sideMenu: true, topMenu:true, private:true  },  
    { path: '/Purchasing', component: PurchaseOrders, display: "Purchasing", exact: true, sideMenu: true, topMenu:true, private:true  },  
    { path: '/Customers', component: Customers, display: "Customers", exact: true, sideMenu: true, topMenu:true, private:true  },  
    { path: '/Vendors', component: Vendors, display: "Vendors", exact: true, sideMenu: true, topMenu:true, private:true  },  
    { path: '/Returns', component: Returns, display: "Returns", exact: true, sideMenu: true, topMenu:true, private:true  },  
    { path: '/Reports', component: Reports, display: "Reports", exact: true, sideMenu: false, topMenu:false, private:true },
    { path: '/Settings/DashboardSettingsComponent', component: DashboardSettingsComponent, display: '', exact: true, sideMenu: false, topMenu: false },
    { path: '/Settings', component: Settings, display: "Settings", exact: true, sideMenu: false, topMenu:false, private:true  },  
    { path: '/Help', component: Help, display: "Help", exact: true, sideMenu: false, topMenu:false  },  
    { path: '/Settings/Alerts', component: Alerts, display: "Alerts", exact: true, sideMenu: false, topMenu: false },
 
    // { path: '/TestComponent', component: TestComponent, display: "TEST COMPONENT", exact: true, sideMenu: true, topMenu:false  },  

    //User auth routes
    { path: '/login', component: Login, display: "Login", exact: true, sidemenu: true, topMenu: false, private: false },  
    { path: '/register', component: Register, display: "Register", exact: true, sidemenu: false, topMenu:false, private: false },  
    { path: '/verify-email', component: VerifyEmail, display: "VERIFY EMAIL", exact: true, sidemenu: false, topMenu:false, private: false },  
    { path: '/forgot-password', component: ForgotPassword, display: "FORGOT PASSWORD", exact: true, sidemenu: false, topMenu:false, private: false },  
    { path: '/reset-password', component: ResetPassword, display: "RESET PASSWORD", exact: true, sidemenu: false, topMenu:false, private: false },  
]; 


export default routes;