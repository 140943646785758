import React, { useState } from "react"; //import useState from react
import { Button, Row, Col, Container, Modal } from "react-bootstrap";
import {ActionBarAllThings} from "./../../Layout/ActionBar";


function DashboardSettingsComponent({ setDashboardSettingsComponentVisible, showModal, setShowModal }) {
  
  return (
    <>

    
        <ActionBarAllThings 
    pageTitle={'Dashboard Settings'}
    showBackBtn
    cancelHandler={() => setDashboardSettingsComponentVisible(false)}
    />


    

    {/* <ActionBarAllThings 
    pageTitle={'Dashboard Settings'}
    showBackBtn
    cancelHandler={() => setDashboardSettingsComponentVisible(false)}
    /> */}

    </>
  );
}

export { DashboardSettingsComponent };
