import { DeleteItemConfirmation } from '../../components/Dialogs';
import {
    FETCH_ITEMLOCATIONS_BEGIN,
    FETCH_ITEMLOCATIONS_SUCCESS,
    FETCH_ITEMLOCATIONS_FAILURE,
    UPDATE_ITEMLOCATION,
    DELETED_ITEMLOCATION
  } from '../actions/itemLocationActions';
  
  const initialState = {
    itemlocations: [],
    loading: false,
    error: null,
    itemLocationsLoaded: false,
    itemPosting: false
  };
  
  export default function itemLocationReducer(state = initialState, action) {

    let existingitem;
    let index;
    let itemToUpdateInArray;

    switch(action.type) {
      case FETCH_ITEMLOCATIONS_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          itemLocationsLoaded: false
        };
  
      case FETCH_ITEMLOCATIONS_SUCCESS:
        // All done: set loading "false".
        // Also, replace the itemlocations with the ones from the server
        return {
          ...state,
          loading: false,
          itemlocations: action.payload.itemlocations,
          itemLocationsLoaded: true
        };
  
      case FETCH_ITEMLOCATIONS_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have itemlocations to display anymore, so set `itemlocations` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the itemlocations around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          itemlocations: []
        };

      case UPDATE_ITEMLOCATION:
            //  We have a new or updated Item added in the software
            //  need to update the master underlying list to account for it
            existingitem = state.itemlocations.find(e => e.id === action.payload.id);
            index = state.itemlocations.indexOf(existingitem);
            itemToUpdateInArray = action.payload;

            if (index < 0 && action.payload.parentLocationId > 0) {
              // Search through all the items and their children to find the right location

              state.itemlocations.some(parlocation => {
              // state.itemlocations.forEach(parlocation => {
                if (searchChildren(parlocation, action.payload))
                {
                  index = state.itemlocations.indexOf(parlocation);
                  itemToUpdateInArray = parlocation;
                  return true;
                }
              })
            }

            //See if it already exists, if not add it to the list
            if (index < 0)
            {
              //If we are here we are going to add to the end of the list
              return {
                ...state,
                itemlocations: [...state.itemlocations.concat(itemToUpdateInArray)]
              }
            }
            else
            {
                //If we are here we are going to slice the array and insert the updated item 
                //This keeps everything clean as far as rules of immutable go
                return { 
                  ...state, 
                  itemlocations: [
                    ...state.itemlocations.slice(0,index)
                    .concat(itemToUpdateInArray)
                    .concat(state.itemlocations.slice(index+1))
                    ]
                  }
            }
  

          case DELETED_ITEMLOCATION:
                          //  We have a new or updated Item added in the software
            //  need to update the master underlying list to account for it
            existingitem = state.itemlocations.find(e => e.id === action.payload);
            index = state.itemlocations.indexOf(existingitem);
            if (index > -1) {
              // Top level item, just remove it and return state
              //If we are here we are going to slice the array and remove the delete item (just skipping over it really)1
                return { 
                  ...state, 
                  itemlocations: [
                    ...state.itemlocations.slice(0,index)
                    .concat(state.itemlocations.slice(index+1))
                    ]
                  }

            }
          
            if (index < 0) {
              // Search through all the items and their children to find the right location

              state.itemlocations.some(parlocation => {
              // state.itemlocations.forEach(parlocation => {
                if (searchChildrenAndDelete(parlocation, action.payload))
                {
                  index = state.itemlocations.indexOf(parlocation);
                  itemToUpdateInArray = parlocation;
                  return true;
                }
              })
            }

            //See if it already exists, if not add it to the list
            if (index > -1)
            {
                //If we are here we are going to slice the array and insert the updated item 
                //This keeps everything clean as far as rules of immutable go
                return { 
                  ...state, 
                  itemlocations: [
                    ...state.itemlocations.slice(0,index)
                    .concat(itemToUpdateInArray)
                    .concat(state.itemlocations.slice(index+1))
                    ]
                  }
            }
  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }

    function searchChildren(parentlocation, newlocation ) {
      if (parentlocation.id === newlocation.parentLocationId) {
        let existingitem = parentlocation.childLocations.find(e => e.id === newlocation.id);
        let index = parentlocation.childLocations.indexOf(existingitem);
        if (index < 0)
        {
          parentlocation.childLocations = [...parentlocation.childLocations.concat(newlocation)];
        }
        else
        {
          parentlocation.childLocations = [
            ...parentlocation.childLocations.slice(0,index)
            .concat(newlocation)
            .concat(parentlocation.childLocations.slice(index+1))
            ];
        }
        return true;
      }

      parentlocation.childLocations.forEach(parloc => {
        if (searchChildren(parloc, newlocation)) {
          return true;
        }
      })
    }

    function searchChildrenAndDelete(parentlocation, locationid) {
      
        let existingitem = parentlocation.childLocations.find(e => e.id === locationid);
        let index = parentlocation.childLocations.indexOf(existingitem);
        if (index > -1)
        {
          parentlocation.childLocations = [
            ...parentlocation.childLocations.slice(0,index)
            .concat(parentlocation.childLocations.slice(index+1))
            ];

            return true;
        }
        parentlocation.childLocations.forEach(parloc => {
          if (searchChildrenAndDelete(parloc, locationid)) {
            return true;
          }
        })
    }
  }