import React from 'react';
import './inventorydashcheckbox.css';

export default function InventoryDashCheckbox({
    checked,
    value,
    defaultChecked,
    onChange,
    label,
    name,
    disabled = false,
    onFocus = false,
    tabIndex,
    onKeyPress
}) {

    return (
        <div className={'inventorydash_cb_container noselect'} >
            <label className={'inventorydash_custom_checkbox '} >
                <input type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    disabled={disabled}
                />
                <span className={'noselect' + (disabled === true ? ' inventorydash_custom_checkbox_disabled_text' : '')}  >{label}</span>
            </label>
        </div>
    )
} 