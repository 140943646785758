import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useSelector, useDispatch } from "react-redux";
import { Modal} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { fetchPurchaseOrders } from "../../../store/actions/purchaseOrderActions";
import WindowedTable from "../../Controls/Tables/WindowedTable";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import AddEditPurchaseOrder from "./AddEditPurchaseOrder";
import "./purchaseorders.css";
import { AddEditItemPage } from "../items/AddEditItemPage";
import "./printview.css";

function PurchaseOrders({
  location,
  fromLineItemModal
}) {
  const purchaseOrders = useSelector(
    (state) => state.purchaseorders.purchaseorders
  );
  const loading = useSelector((state) => state.purchaseorders.loading);
  const error = useSelector((state) => state.purchaseorders.error);
  const purchaseOrdersLoaded = useSelector(
    (state) => state.purchaseorders.purchaseordersLoaded
  );
  const dispatch = useDispatch();
  const [showPOModal, setShowPOModal] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  //printView hides elements when the print icon is clicked and then returns it back to original state after printing
  const [printView, setPrintView] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPurchaseOrder, setCurrentPurchaseOrder] = useState(null);
  //determines which modal was coming from
  const [fromPO, setFromPO] = useState(false);
  const [fromItem, setFromItem] = useState(false);
  const [newAddedItemId, setNewAddedItemId] = useState(0);
  const [currentPurchaseOrderId, setCurrentPurchaseOrderId] = useState(0);
  const [inputItemId, setInputItemId] = useState(null);
  const [showLineItemModal, setShowLineItemModal] = useState(false);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [columnsList, setColumnsList] = useState(determineColumns());
  const history = useHistory();

  // useEffect(() => {

  // }, [])

  useEffect(() => {
    if (!loading && !purchaseOrdersLoaded) {
      trackPromise(dispatch(fetchPurchaseOrders()));
    }
  }, [loading, purchaseOrdersLoaded, purchaseOrders]);

  useEffect(() => {
    if (purchaseOrdersLoaded) {
      if (inputItemId != null) {
        addPurchaseOrder();
      }
    }
  }, [loading, purchaseOrdersLoaded, purchaseOrders]);

  function determineColumns() {
    if (!localStorage.getItem('item_purchaseorders')) {
      return colHeaders
    } else {
      return JSON.parse(localStorage.getItem('item_purchaseorders'));
    }
  }
  
  function setCurrPurchaseOrder(po) {
    setCurrentPurchaseOrderId(po.id);
    setCurrentPurchaseOrder(po);
    setShowEditBtn(true);
  }

  function editPurchaseOrder() {
    if (currentPurchaseOrder != null) {
      setIsEditing(true);
      setShowModal(true);
      setShowPOModal(true);
    }
  }
  function addPurchaseOrder() {
    setIsEditing(false);
    setCurrentPurchaseOrderId(-1);
    setCurrentPurchaseOrder(null);
    setShowModal(true);
    setShowPOModal(true);
  }

  function addNewItemToInventory() {
    setFromPO(true);
    setShowPOModal(false);
    setShowItemModal(true);

  }

  function closeHandler() {
    setShowModal(false);
    setShowLineItemModal(false);
  }

  function cancelItemModal() {
    setFromItem(true);
    setShowItemModal(false);
    setShowPOModal(true);
    // setShowLineItemModal(true);
  }

  function cancelAddItemModalDialog() {
    setShowItemModal(false);
    setShowPOModal(true);
    setShowLineItemModal(true);
  }

  function newItemSavedHandler(item) {

    // alert('newItemSavedHandler');
    // if (item != null) {
    //     setNewAddedItemId(item.id);
    //   }
  }

  function showColumnModal() {
    setShowColumnSelector(true);
  }

  //ANCHOR 
  function openPrintView() {
    setPrintView(true);
    setTimeout(() => { window.print(); }, 1000); 
    setTimeout(() => {setPrintView(false); }, 1000)
  }

  let itemId = undefined;
  let addingNew = undefined;

  if (location != null) {
    if (location.state != null) {
      if (location.state.itemId != null) {
        itemId = location.state.itemId;
      }
      if (location.state.addNew != null) {
        addingNew = location.state.addNew;
      }
    }
    if (addingNew != null) {
      // Set the current Item and show the display Modal
      // this is for a call that is coming from other pages
      if (addingNew === true) {
        history.replace(location.pathName, []);
        if (inputItemId != itemId) {
          setInputItemId(itemId);
        }
        itemId = null;
        addingNew = null;
      }
    }
  }

  if (error != null) {
    return <div>{"Error: " + error.message}</div>;
  }

  return (
    <>
      <ActionBarAllThings
        pageTitle={"Purchasing"}
        showAddBtn={true}
        showEditBtn={showEditBtn}
        addItem={addPurchaseOrder}
        editItem={editPurchaseOrder}
        cancelHandler={showItemModal ? cancelAddItemModalDialog : showItemModal ? showLineItemModal(false) : closeHandler}
        showColBtn
        adjustColumns={showColumnModal}
      />

      <div className = {printView ? "printView" : "page_container"}>
        {!purchaseOrdersLoaded ? (
          <></>
        ) : 
          printView ? "" : (
            <WindowedTable
            colheaders={columnsList}
            inputitems={purchaseOrders}
            currentSelectedItem={currentPurchaseOrder}
            selectCurrentItem={setCurrPurchaseOrder}
            idname={"id"}
            tablenameidentifier={"item_purchaseorders"}
            searchText={""}
            setSelectedId={setCurrPurchaseOrder}
            selectedId={currentPurchaseOrderId}
            defaultSort={"displayName"}
            setShowColumnSelector={setShowColumnSelector}
            showColumnSelector={showColumnSelector}
            setColumnsList={setColumnsList}
          />
          )
          }
      </div>
      <Modal
        show={showModal}
        onHide={() => closeHandler()}
        dialogClassName={printView ? "printViewModal" : "inventorydash_custom_modal_dialog"}
      >
       
          
          {showPOModal ? (
            <AddEditPurchaseOrder
              purchaseorderid={currentPurchaseOrderId}
              showModal={showPOModal}
              setShowModal={setShowPOModal}
              inputItemId={inputItemId}
              isEditing={isEditing}
              showLineItemModal={showLineItemModal}
              setShowLineItemModal={setShowLineItemModal}
              showItemModal={showItemModal}
              addNewItemToInventory={addNewItemToInventory}
              showModalDialog={showModal}
              setShowModalDialog={setShowModal}
              setFromItemModal={setFromItem}
              fromItemModal={setFromItem}
              newItemSavedHandler={newItemSavedHandler}
              newAddedItemId={newAddedItemId}
              openPrintView={openPrintView}
              printView={printView}
              setPrintView={setPrintView}
            />
            //show the ItemModal content
          ) : showItemModal ? (
            <AddEditItemPage
              itemId={0}
              visible={showItemModal}
              fromPO={fromPO}
              showItemModal={showItemModal}
              setShowItemModal={setShowItemModal}
              // cancelHandler={cancelItemModal}
              cancelDialog={cancelAddItemModalDialog}
              cancelHandler={cancelAddItemModalDialog}
              itemSavedHandler={newItemSavedHandler}
            />
          ) :  <></>
      }
      </Modal>
    </>
  );
}

export { PurchaseOrders };

const colHeaders = [
    {
      displayName: "PO Number",
      propName: "displayName",
      colclassname: "",
      selected: true,
      displayorder: 0,
    },
    {
      displayName: "Vendor",
      propName: "vendorName",
      colclassname: "",
      selected: true,
      displayorder: 1,
    },
    {
      displayName: "Expected",
      propName: "purchaseOrderDate",
      colclassname: "center-align",
      dataclassname: "center-align",
      selected: true,
      displayorder: 2,
      isdate: true,
    },
    {
      displayName: "Status",
      propName: "userStatus",
      colclassname: "",
      selected: true,
      displayorder: 3,
    },
    {
      displayName: "Fulfillment",
      propName: "calculatedStatus",
      colclassname: "",
      selected: true,
      displayorder: 4,
    },
    {
      displayName: "Tax",
      propName: "tax",
      colclassname: "",
      selected: true,
      displayorder: 5,
      iscurrency: true,
    },
    {
      displayName: "Freight",
      propName: "freight",
      colclassname: "",
      selected: true,
      displayorder: 6,
      iscurrency: true,
    },
    {
      displayName: "Subtotal",
      propName: "subtotal",
      colclassname: "",
      selected: true,
      displayorder: 7,
      iscurrency: true,
    },
    {
      displayName: "Total",
      propName: "total",
      colclassname: "dollar_prefix",
      selected: true,
      displayorder: 8,
      iscurrency: true,
    },
  ];

