import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {trackPromise} from 'react-promise-tracker';
import {Modal, Form} from 'react-bootstrap';
import TextInput from '../../../Controls/textInput';
import { alertService, fetchService } from '../../../../services';
import {fetchUser} from '../../../../store/actions/userActions';
import {updateUsers} from '../../../../store/actions/usersActions';
import {UserRoleSelector} from './RoleSelector';
import '../../../../App.css';
import './usermodal.css';
import { ActionBarAllThings } from '../../../Layout/ActionBar';

function UserModal ({userid, showModal, cancelDialog, onInputValueChanged, 
    addingUser, editingUser}) {

    const user = useSelector(state => state.user.user);
    const loading = useSelector(state => state.user.loading);
    const error = useSelector(state => state.user.error);
    const isError = useSelector(state => state.user.isError);
    const userLoaded = useSelector(state => state.user.userLoaded);
    const [validated, setValidated] = useState(false);
    const [currUser, setCurrUser] = useState(user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!userLoaded && !loading && userid != null)
        {
            
          trackPromise(
            dispatch(fetchUser(userid))
          );
        }
      }, [userLoaded, loading])

    useEffect(() => {
        if (!loading && user != null && currUser == null)
        {
            setCurrUser(user);
        }
        if (user != null && currUser != null)
        {
            if (user.id !== currUser.id)
            {
                setCurrUser(user);
            }
        }
      }, [user])

    useEffect(() => {
        if (user != null  && userid != null)
        {
            if (user.id != userid)
            {
            trackPromise(
                dispatch(fetchUser(userid))
                );
            }
        }
      }, [userid])

    function onInputValueChanged(e)
    {
        e.persist();
        let newvalue = e.target.value;
        setCurrUser((prevState) => ({
            ...prevState,
            [e.target.name]: newvalue
            })
        );
    }

    function roleIdChanged(roleid) {
        setCurrUser((prevState) => ({
            ...prevState,
            roleId: roleid
            })
        );
    }

    function rolesChanged(user) {
        setCurrUser(user);
    }

    const validateInput = () => {
        const form = document.querySelectorAll('#userForm')[0];
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }
        
        setValidated(false);
        return true;
    };

    function saveUser() {
        if (!validateInput()) {
            return;
        }
        
        if (currUser !== undefined)
        {
            if (editingUser === true)
            {
                // // Save updated item
                fetchService.put("/useradmin/", currUser)
                .then(json => {
                    //Success
                    dispatch(updateUsers(json));
                    // setIsSubmitting(false);
                    cancelDialog();
                })
                .catch(error => {
                    // setIsSubmitting(false);
                    alertService.error(error);
                    return;
                }); 
            }
            else
            {
                // New item to post
                //setIsSubmitting(true);
                const baseurl = window.location.origin;
                currUser.baseURL = baseurl;
                fetchService.post("/useradmin", currUser)
                .then(json => {
                    //Success
                    dispatch(updateUsers(json));
                    //setIsSubmitting(false);
                    cancelDialog();
                })
                .catch(error => {
                    //setIsSubmitting(false);
                    alertService.error(error);
                    return;
                }); 
            }
        }
    }

    if (loading) {
        return (
            <div>Loading User...</div>
        );
    }
    if (isError) {
        return <div>error</div>
    }  

    if (user == null)
    return (
        <div>Loading User...</div>
    );

    

    return (
        <>
        
            <Modal 
                show={ showModal } 
                onHide={ cancelDialog } 
                dialogClassName={"inventorydash_custom_modal_dialog"}
            >
            <Modal.Header closeButton bsPrefix={"custom_modal_header"}>
                <ActionBarAllThings
                    pageTitle={addingUser ? "Add User" : "Edit User"}
                    showSaveBtn={true}
                    saveBtnText={addingUser ? "Save" : "Update"}
                    saveHandler={saveUser}
                    onModal={true}
                />
                
            </Modal.Header>
            <Modal.Body bsPrefix={"custom_modal_body"}>
            <div className="user_form noselect">
                    <Form 
                        autoComplete={'off'} 
                        id="userForm" 
                        name={'user_edit_form'} 
                        noValidate 
                        validated={validated} 
                        >
                        <TextInput
                        name={'firstName'}
                        label={'First Name'}
                        onChange={onInputValueChanged}
                        value={currUser.firstName}
                        required={true}
                        validatefailtext={'Please enter a user first name.'}
                        />
                    <TextInput
                        name={'lastName'}
                        label={'Last Name'}
                        onChange={onInputValueChanged}
                        value={currUser.lastName}
                        required={true}
                        validatefailtext={'Please enter a user last name.'}
                        />

                    <TextInput
                        name={'email'}
                        label={'Email Address'}
                        onChange={onInputValueChanged}
                        value={currUser.email}
                        required={true}
                        validatefailtext={'Please enter a user email address.'}
                        />
                    </Form>
                    <UserRoleSelector 
                        valueChanged={roleIdChanged}
                        value={currUser.roleId}
                        required={true}
                    />
                    
                 </div>
            </Modal.Body>
            </Modal>
        </>

    );
}

export { UserModal };