import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchVendor } from '../../../store/actions/singleVendorActions';
import { Modal, Form } from 'react-bootstrap';
import { VendorInfo } from './vendorDetails/vendorInfo';
import { ActionBarAllThings } from '../../Layout/ActionBar';
import { trackPromise } from 'react-promise-tracker';
import "./addeditvendorspage.css";
import AddEditContactPage from '../contacts/AddEditContactPage';

export default function AddEditVendorPage({ currVendID, visible, cancelHandler, saveHandler, isSubmitting, isEditing, showModal }) {

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();

    const vendLoaded = useSelector(state => state.vendor.vendorLoaded);
    const vendor = useSelector(state => state.vendor.vendor);
    const loading = useSelector(state => state.vendor.loading);
    const error = useSelector(state => state.vendor.error);
    const iserror = useSelector(state => state.vendor.iserror);
    const [currVend, setCurrVend] = useState(vendor);
    const [currBillingAddress, setCurrBillingAddress] = useState(vendor?.billingAddress);
    const [currShippingAddress, setCurrShippingAddress] = useState(vendor?.shippingAddress);
    const [editContactId, setEditContactId] = useState(null);
    const [fromContactModal, setFromContactModal] = useState(null);
    const [addingOrEditingContact, setAddingOrEditingContact] = useState(false);

    const [contactList, setContactList] = useState(vendor?.contacts == null ? null : JSON.parse(JSON.stringify(vendor?.contacts)));

    useEffect(() => {
        if (!vendLoaded && !loading) {  // Adding the loading check to prevent double api hits
            trackPromise(dispatch(fetchVendor(currVendID)));
        }
    }, [vendLoaded, dispatch, currVendID]);

    // Tracking changes to visible and vendorid and reload the data
    useEffect(() => {
        if (visible) {
            if (vendor != null) {
                if (vendor.id != currVendID) {
                    trackPromise(dispatch(fetchVendor(currVendID)));
                }
            }
            else {
                if (!loading) {
                    trackPromise(dispatch(fetchVendor(currVendID)));
                }
            }
        }

    }, [currVendID, visible]);

    // Track when any new vendor loads and move it into the local state variable
    useEffect(() => {
        if (vendor != null) {
            if (currVend == null || vendor?.id != currVend?.id) {
                setCurrVend(vendor);
                setCurrShippingAddress(vendor?.shippingAddress);
                setCurrBillingAddress(vendor?.billingAddress);
                setContactList(JSON.parse(JSON.stringify(vendor?.contacts)));
            }
        }

    }, [vendor, vendLoaded]);

    const validateInput = () => {
        const form = document.querySelectorAll('#vendorInfoForm')[0];
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }

        setValidated(false);
        return true;
    }

    const handleSave = () => {
        if (!validateInput()) {
            return;
        }

        currVend.shippingAddress = currShippingAddress;
        currVend.billingAddress = currBillingAddress;
        currVend.contacts = contactList;

        saveHandler(currVend);
    }

    function onVendorValueChanged(e) {
        e.persist();

        let newValue = e.target.value;

        if (e.target.name === 'shippingSameAsBilling') {
            newValue = !currVend.shippingSameAsBilling;
        }

        setCurrVend((prevState) => ({
            ...prevState,
            [e.target.name]: newValue
        })
        );
    }

    function onBillingAddressValueChanged(e) {
        e.persist();

        let newValue = e.target.value;
        setCurrBillingAddress((prevState) => ({
            ...prevState,
            [e.target.name]: newValue
        })
        );
    }

    function onShippingAddressValueChanged(e) {
        e.persist();

        let newValue = e.target.value;
        setCurrShippingAddress((prevState) => ({
            ...prevState,
            [e.target.name]: newValue
        })
        );
    }

    // adding contact
    function addContact(contact) {
        setEditContactId(0);
        setAddingOrEditingContact(true);
    }

    function updateContact(contactid) {
        setEditContactId(contactid);
        setAddingOrEditingContact(true);
    }

    function cancelContact() {
        setEditContactId(-1);
        setAddingOrEditingContact(false);
    }

    // add to vendor list here - Brendon
    function saveContact(newContact) {
        let newContacts = contactList;

        if (newContact.id <= 0) {
            // brand new: add contact to contacts array
            setCurrVend((prevState) => ({
                ...prevState,
                vendor: {
                    ...prevState,
                    contacts: newContacts.push(newContact)
                }
            }));
        } else {
            // already exists
            newContacts.forEach(function (contact, i) { if (contact.id === newContact.id) newContacts[i] = newContact; });
        }

        setAddingOrEditingContact(false);
    }


    if (!visible) {
        return <></>;
    }

    if (loading || !vendLoaded) {
        return <></>
    }

    if (iserror) {
        return <div>{error}</div>
    }

    if (currVend == null) {
        return <></>
    }

    if (showModal && addingOrEditingContact) {
        return (
            <AddEditContactPage
                contactid={editContactId}
                cancelHandler={cancelContact}
                saveHandler={saveContact}
                fromContactModal={fromContactModal}
                setFromContactModal={setFromContactModal}
            addingOrEditingContact={addingOrEditingContact}
            />
        );
    }

    return (
        <>
            {/* Modal form to edit Vendors */}
            {/* <Modal
                show={showModal}
                onHide={cancelHandler}
                dialogClassName={"custom_modal_dialog"}
            >
            <div>
            <Modal.Header  closeButton bsPrefix={"custom_modal_header"}>
                    <ActionBarAllThings
                        pageTitle={(isEditing ? "Edit Vendor" : "Add Vendor")}
                        showBackBtn={true}
                        showSaveBtn={true}
                        showDelBtn={(isEditing ? true : false)}
                        cancelHandler={cancelHandler}
                        saveHandler={handleSave}
                        onModal={true}
                        saveBtnText={(isEditing ? "Update" : "Save")}
                    />
                </Modal.Header>

                <Modal.Body bsPrefix={"custom_modal_body"}> */}

                    {/* Form element : Needed for 'required' element in TextInputs */}
                    <Form
                        autoComplete={'off'}
                        id={'vendorInfoForm'}
                        name={'vendor_info_edit_form'}
                        noValidate
                        validated={validated}
                        className="form-container"
                    >

                        {/* Vendor control */}
                        <VendorInfo
                            vendor={currVend}
                            contactList={contactList}
                            billingAddress={currBillingAddress}
                            shippingAddress={currShippingAddress}
                            cancelHandler={cancelHandler}
                            saveHandler={saveHandler}
                            isSubmitting={isSubmitting}
                            isEditing={isEditing}
                            onVendorValueChanged={onVendorValueChanged}
                            onBillingAddressChanged={onBillingAddressValueChanged}
                            onShippingAddressChanged={onShippingAddressValueChanged}
                            addContact={addContact}
                            editContact={updateContact}
                            saveVendor = { handleSave }
                            fromContactModal={fromContactModal}
                            setFromContactModal={setFromContactModal}
                        />
                    </Form>
                {/* </Modal.Body>
            </div>
               
            </Modal> */}
        </>
    );
}