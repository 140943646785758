import {
    FETCH_USERS_BEGIN,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    POST_USER_SUCCESS,
    POST_USER_BEGIN,
    POST_USER_FAILURE,
    UPDATE_USERS
  } from '../actions/usersActions';
  
  const initialState = {
    users: [],
    loading: false,
    isError: false,
    error: null,
    usersLoaded: false,
    userPosting: false
  };
  
  export default function usersReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_USERS_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          isError: false,
          usersLoaded: false
        };
  
      case FETCH_USERS_SUCCESS:
        // All done: set loading "false".
        // Also, replace the users with the ones from the server
        return {
          ...state,
          loading: false,
          isError: false,
          users: action.payload.users,
          usersLoaded: true
        };
  
      case FETCH_USERS_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have users to display anymore, so set `users` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the users around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          isError: true,
          error: action.payload.error,
          users: []
        };

        case POST_USER_BEGIN:
          return {
            ...state,
            error: null,
            isError: false,
            userPosting: true
        }        
        case POST_USER_FAILURE:
          return {
            ...state,
            error: action.payload.error,
            isError: true,
            userPosting: false
        }        

        case POST_USER_SUCCESS:
          return {
              ...state,
              loading: false,
              userPosting: false,
              isError: false,
              users: state.users.concat(action.payload.user)
          }        
        case UPDATE_USERS:

            //  We have a new or updated User added in the software
            //  need to update the master underlying list to account for it
            let existinguser = state.users.find(e => e.id === action.payload.id);
            let index = state.users.indexOf(existinguser);

            //See if it already exists, if not add it to the list
            if (index < 0)
            {
              //If we are here we are going to add to the end of the list
              return {
                ...state,
                users: [...state.users.concat(action.payload)]
              }
            }
            else
            {
                //If we are here we are going to slice the array and insert the updated user 
                //This keeps everything clean as far as rules of immutable go
                return { 
                  ...state, 
                  users: [
                    ...state.users.slice(0,index)
                    .concat(action.payload)
                    .concat(state.users.slice(index+1))
                    ]
                  }
            }
  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }