import { fetchService } from "../../services";
export const FETCH_RETURNS_BEGIN = 'FETCH_RETURNS_BEGIN';
export const FETCH_RETURNS_SUCCESS = 'FETCH_RETURNS_SUCCESS';
export const FETCH_RETURNS_FAILURE = 'FETCH_RETURNS_FAILURE';

export const FETCH_SINGLERETURN_BEGIN = 'FETCH_SINGLERETURN_BEGIN';
export const FETCH_SINGLERETURN_SUCCESS = 'FETCH_SINGLERETURN_SUCCESS';
export const FETCH_SINGLERETURN_FAILURE = 'FETCH_SINGLERETURN_FAILURE';

export const UPDATE_RETURNS= 'UPDATE_RETURNS';

//#region FETCH

export const fetchReturnsBegin = () => ({
    type: FETCH_RETURNS_BEGIN
});

export const fetchReturnsSuccess = returns => ({
    type: FETCH_RETURNS_SUCCESS,
    payload: { returns }
});

export const fetchReturnsFailure = error => ({
    type: FETCH_RETURNS_FAILURE,
    payload: { error }
});

export function fetchReturns() {
    return dispatch => {
        dispatch(fetchReturnsBegin());

        return fetchService.get("/returns")
         .then(json => {
             dispatch(fetchReturnsSuccess(json));
             return json;
         })
         .catch(error => dispatch(fetchReturnsFailure(error)));
    };
};

//#endregion

export const fetchReturnBegin = () => ({
    type: FETCH_SINGLERETURN_BEGIN
});

export const fetchReturnSuccess = returnObj => ({
    type: FETCH_SINGLERETURN_SUCCESS,
    payload: { returnObj }
});

export const fetchReturnFailure = error => ({
    type: FETCH_SINGLERETURN_FAILURE,
    payload: { error }
});

export function fetchReturn(returnId) {
    return dispatch => {
        dispatch(fetchReturnBegin());

        return fetchService.get("/returns/" + returnId)
         .then(json => {
             dispatch(fetchReturnSuccess(json));
             return json;
         })
         .catch(error => dispatch(fetchReturnFailure(error)));
    };
};

//#region PUT

// export const putSingleReturnBegin = () => ({
//     type: PUT_SINGLERETURN_BEGIN
//   });
  
//   export const putReturnsuccess = singlereturn => ({
//     type: PUT_SINGLERETURN_SUCCESS,
//     payload: { singlereturn }
//   });
  
//   export const putSingleReturnFailure = error => ({
//     type: PUT_SINGLERETURN_FAILURE,
//     payload: { error }
//   });
  
//   export function putSingleReturn(singlereturn, id) {
//     return dispatch => {
//       dispatch(putSingleReturnBegin());
//       return fetchService.put(`/returns/${id}`, singlereturn)
//         .then(json => {
//           dispatch(putReturnsuccess(json));
//           return json;
//         })
//         .catch(error => dispatch(putSingleReturnFailure(error)));
//     }
//   };

//#endregion

//#region UPDATE

export function updateReturns(singlereturn) {
    return dispatch => {
        dispatch(pushUpdateReturns(singlereturn));
    }
};

export const pushUpdateReturns = singlereturn => ({
    type: UPDATE_RETURNS,
    payload: singlereturn
})

//#endregion
