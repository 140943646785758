import React, { useState } from "react"; //import useState from react
import { Button, Row, Col, Container } from "react-bootstrap";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { IoIosArrowBack } from "react-icons/io";

function TemplatesComponent({ setTemplatesComponentVisible }) {
  
 
  return (
    <>

    <ActionBarAllThings
    pageTitle={'Templates'}
    showBackBtn
    cancelHandler = {() => setTemplatesComponentVisible(false)}
    />

    </>
  );
}

export { TemplatesComponent };
