import { fetchService } from "../../services";

export const FETCH_SALESORDERS_BEGIN   = 'FETCH_SALESORDERS_BEGIN';
export const FETCH_SALESORDERS_SUCCESS = 'FETCH_SALESORDERS_SUCCESS';
export const FETCH_SALESORDERS_FAILURE = 'FETCH_SALESORDERS_FAILURE';

//Fetch
//##########################################################################
export const fetchSalesOrdersBegin = () => ({
  type: FETCH_SALESORDERS_BEGIN
});

export const fetchSalesOrdersSuccess = salesorders => ({
  type: FETCH_SALESORDERS_SUCCESS,
  payload: { salesorders }
});

export const fetchSalesOrdersFailure = error => ({
  type: FETCH_SALESORDERS_FAILURE,
  payload: { error }
});

export function fetchSalesOrders() {
    return dispatch => {
      dispatch(fetchSalesOrdersBegin());
      return fetchService.get("/salesorder")
        .then(json => {
          dispatch(fetchSalesOrdersSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchSalesOrdersFailure(error)));
    };
  }
//End Fetch
//##########################################################################

