import React from "react";
import { useDispatch } from "react-redux";
import {FaRegCheckCircle} from "react-icons/fa";
import "../Layout/actionbar.css";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { showHelpView, setHelpViewTopic } from "../../store/actions/uiActions";
import  ActionBarAllThingsFooter  from './ActionBarAllThingsFooter';
import ActionBarAllThingsHeader from './ActionBarAllThingsHeader';


/*
If actionbar appears on modals, mark onModal={true}
If actionbar should not appear as footer, mark showFooter={false)
*/

export function ActionBarAllThings({
  onModal = false, //when adding Actionbar to modal, set this to true
  pageTitle = "", //set the tiTle of the page that shows up as an h1
  showFooter = true,
  overRideIcon = null,
  /*buttons*/
  showBackBtn = false,
  showSaveBtn = false,
  saveBtnText = "Save", //text on btn can be changed to say "update" when editing
  delBtnText = "Delete",
  showColBtn = false,
  showEditBtn = false,
  showAddBtn = false,
  showDelBtn = false,
  showViewBtn = false,
  showSortBtn = false,
  showPrintBtn = false,
  showEmailBtn = false,
  showSettingsIcon = false,
  showFilterIcon = false,
  filterAppliedIndicator,


  /*handlers that perform actions*/
  saveHandler = null,
  delHandler = null,
  addItem = null,
  cancelHandler = null,
  editItem = null,
  viewItem = null,
  adjustColumns = null,
  settingsHandler = null,
  printHandler = null,
  filterHandler = null, 

  // Help Button Added by Darren 12/20/2020
  showHelpButton = true,
  helpTopic = "",
}) {
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;
  const isMobileWidth = windowWidth <= 576;
  const isMobileScreen = windowWidth <= 576 || windowHeight <=576;
  const dispatch = useDispatch();

  var saveIcon = (
  <FaRegCheckCircle 
  className="small_button_save_icon"
  size={isMobileScreen ? "1.5rem" : "0"}
  />
  )

  if (overRideIcon) {
    saveIcon=overRideIcon;
  }

  function showHelp() {
    dispatch(showHelpView(true));
    dispatch(setHelpViewTopic(helpTopic));
  }


  return (
    <>
      {/* {helpTopic} */}
      <ActionBarAllThingsHeader
        onModal={onModal}
        pageTitle={pageTitle}
        showBackBtn={showBackBtn}
        showSaveBtn={showSaveBtn}
        saveBtnText={saveBtnText}
        showColBtn={showColBtn}
        showEditBtn={showEditBtn}
        showSettingsIcon={showSettingsIcon}
        showPrintBtn={showPrintBtn}
        showEmailBtn={showEmailBtn}
        showAddBtn={showAddBtn}
        showDelBtn={showDelBtn}
        delBtnText={delBtnText}
        showViewBtn={showViewBtn}
        showSortBtn={showSortBtn}
        saveHandler={saveHandler}
        delHandler={delHandler}
        settingsHandler={settingsHandler}
        addItem={addItem}
        cancelHandler={cancelHandler}
        editItem={editItem}
        viewItem={viewItem}
        adjustColumns={adjustColumns}
        showFooter={showFooter}
        showHelpButton={showHelpButton}
        helpButtonHandler={showHelp}
        printHandler = {printHandler}
        showFilterIcon = {showFilterIcon}
        filterHandler={filterHandler}
        filterAppliedIndicator = {filterAppliedIndicator}

      />

      {isMobileWidth ? (
        <div className={onModal ? "actionbar_mobile_footer" : ""}>
          <ActionBarAllThingsFooter
            onModal={onModal}
            pageTitle={pageTitle}
            showBackBtn={showBackBtn}
            showSaveBtn={showSaveBtn}
            saveBtnText={saveBtnText}
            showEditBtn={showEditBtn}
            showAddBtn={showAddBtn}
            showEmailBtn={showEmailBtn}
            showSettingsIcon={showSettingsIcon}
            showPrintBtn={showPrintBtn}
            showDelBtn={showDelBtn}
            showViewBtn={showViewBtn}
            saveHandler={saveHandler}
            delHandler={delHandler}
            delBtnText={delBtnText}
            addItem={addItem}
            cancelHandler={cancelHandler}
            editItem={editItem}
            viewItem={viewItem}
            adjustColumns={adjustColumns}
            showFooter={showFooter}
            saveIcon={saveIcon}
            printHandler = {printHandler}
            filterAppliedIndicator = {filterAppliedIndicator}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}



