import React, { Component } from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../Controls/textInput";
import { formatCurrency } from '../../utilities/currencyUtil';
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import "./finalizesumary.css";

export default function FinalizeSummary({ salesorder, returnItemsSelected, returnFee, setReturnFee, calculateQty, calculateSubTotal, calculateTotal }) {

  const customers = useSelector((state) => state.customers?.customers);

  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;
  const isMobileWidth = windowWidth <= 576;
  const isMobileScreen = windowWidth <= 576 || windowHeight <=576;
  //finds customer name to display in summary
  function findCustomerName() {
    let customerName = customers.find(
      (customer) => customer.id == salesorder.customerId
    );
    if (customerName?.displayName) {
      return (
        <>
          <b>{isMobileWidth ? "" : "Customer: "}</b>
          {customerName.displayName}
        </>
      );
    }
  }

  return (
    <div className="returnSummary">
      <Row>
        <Col>
          <TextInput label="SubTotal" disabled={true}
            value={formatCurrency(calculateSubTotal())}
          />
        </Col>
        <Col>
          <TextInput label={isMobileWidth ? "Qty" : "Return Qty"} 
            disabled={true}
            value={Number(calculateQty())}
          />
        </Col>
        <Col>
          <TextInput
            label={isMobileWidth ? "Fee" : "Return Fee"}
            disabled={returnItemsSelected.length <= 0 ? true : false}
            value={returnFee}
            onChange={(e) => setReturnFee(e.target.value)}
          />
        </Col>
        <Col>
          <TextInput label={isMobileWidth ? "Refund" : "Total Refund"} 
            disabled={true}
            value={formatCurrency(calculateTotal())}
          />
        </Col>
      </Row>
      <Row>
        <Col className="SalesOrderName">
          <p>
            <b>{isMobileWidth ? "" : "Sales Order: "}</b>
            {salesorder?.displayName}
          </p>
        </Col>
        <Col className="customer">
          <p>{findCustomerName()}</p>
        </Col>
      </Row>
    </div>
  );
}
