import React, { Component, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { ActionBarAllThings } from "../../../Layout/ActionBar";
import { LocationsComponent } from "../LocationsComponent";
import { ItemGroupPage } from "../../itemgroups/itemGroupPage";
import { useRolePermissions } from '../../../../hooks';
import "./locationsanditemgroups.css";

export function LocationsAndItemGroups({
    setLocationsAndItemGroupsVisible
}) {
    const [actionbarTitle, setActionbarTitle] = useState("Location & Groups")

    const [actionBarAddLocation, setActionBarAddLocation] = useState(false);
    const [actionBarEditLocation, setActionBarEditLocation] = useState(false);
    const [actionBarDeleteLocation, setActionBarDeleteLocation] = useState(false);

    const [actionBarAddGroup, setActionBarAddGroup] = useState(false);
    const [actionBarEditGroup, setActionBarEditGroup] = useState(false);
    const [actionBarDeleteGroup, setActionBarDeleteGroup] = useState(false);

    const [selectedLocationId, setSelectedLocationId] = useState(0);
    const [selectedGroupId, setSelectedGroupId] = useState(0);

    const manageLocations = useRolePermissions('Manage Locations');
    const manageGroups = useRolePermissions('Manage Groups');

    const [viewingLocations, setViewingLocations] = useState(true);
    const [viewingGroups, setViewingGroups] = useState(false);


    function tabChanged(e) { //determines when a tab is clicked and changes actionbar title to match tab pane

        if (e === "locations") {
            setActionbarTitle("Locations")
            setViewingGroups(false);
            setViewingLocations(true);
        }
        if (e === "groups") {
            setActionbarTitle("Groups")
            setViewingGroups(true);
            setViewingLocations(false);
        }
    }

    function addLocation() {
        if (!manageLocations) {
            return;
        }
        setActionBarAddLocation(true);
    }

    function editLocation() {
        if (!manageLocations) {
            return;
        }
        setActionBarEditLocation(true);
    }

    function deleteLocation() {
        if (!manageLocations) {
            return;
        }
        setActionBarDeleteLocation(true);
    }

    function addItemGroup() {
        if (!manageGroups) {
            return;
        }
        setActionBarAddGroup(true);
    }

    function editItemGroup() {
        if (!manageGroups) {
            return;
        }
        setActionBarEditGroup(true);
    }

    function deleteItemGroup() {
        if (!manageGroups) {
            return;
        }
        setActionBarDeleteGroup(true);
    }

    function resetActionBarLocationActions() {
        setActionBarAddLocation(false);
        setActionBarEditLocation(false);
        setActionBarDeleteLocation(false);
    }
    function resetActionBarGroupActions() {
        setActionBarAddGroup(false);
        setActionBarEditGroup(false);
        setActionBarDeleteGroup(false);
    }

    return (
        <>
            <ActionBarAllThings
                pageTitle={actionbarTitle}
                cancelHandler={() => setLocationsAndItemGroupsVisible(false)}
                showBackBtn={true}
                showAddBtn={
                    viewingLocations
                        ?
                        (manageLocations) ? true : false
                        :
                        (manageGroups) ? true : false
                }
                showEditBtn={
                    (
                        viewingLocations
                            ?
                            selectedLocationId !== 0 && manageLocations
                            :
                            selectedGroupId !== 0 && manageGroups
                    )
                }
                showDelBtn={
                    (
                        viewingLocations
                            ?
                            selectedLocationId !== 0 && manageLocations
                            :
                            selectedGroupId !== 0 && manageGroups
                    )
                }
                addItem={
                    viewingLocations
                        ?
                        (
                            addLocation
                        )
                        :
                        (
                            addItemGroup
                        )
                } //need to create function on parent and send down to children
                editItem={
                    viewingLocations
                        ?
                        (
                            editLocation
                        )
                        :
                        (
                            editItemGroup
                        )
                }

                delHandler={
                    viewingLocations
                        ?
                        (
                            deleteLocation
                        )
                        :
                        (
                            deleteItemGroup
                        )
                } //need to create function on parent and send down to children
            />
            <div className="locationAndItemGroups_PageContainer page-container">
                <Tab.Container defaultActiveKey="locations" transition={false}>
                    <Nav justify variant="tabs" className="item_tabs">
                        <Nav.Item className="tab_item">
                            <Nav.Link
                                eventKey="locations"
                                onSelect={tabChanged}
                            ><span className="noselect">Locations</span>
                    </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="tab_item">
                            <Nav.Link
                                eventKey="groups"
                                onSelect={tabChanged}
                            ><span className="noselect">Item Groups</span> 
                    </Nav.Link>
                        </Nav.Item>
                    </Nav>


                    <Tab.Content className="locationAndGroup_tab_content">
                        <Tab.Pane eventKey="locations">
                            <LocationsComponent
                                actionBarAdd={actionBarAddLocation}
                                actionBarEdit={actionBarEditLocation}
                                actionBarDelete={actionBarDeleteLocation}
                                resetActionBarActions={resetActionBarLocationActions}
                                setSelectedLocationId={setSelectedLocationId}
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey="groups">
                            <ItemGroupPage 
                                actionBarAdd={actionBarAddGroup}
                                actionBarEdit={actionBarEditGroup}
                                actionBarDelete={actionBarDeleteGroup}
                                resetActionBarActions={resetActionBarGroupActions}
                                setSelectedGroupId={setSelectedGroupId}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    )
            
}