import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { Form } from 'react-bootstrap';
import { fetchItems } from '../../../store/actions/itemActions';
import { LoadingPlaceholder } from '../../Controls/LoadingPlaceholder';
import { clearNewItem } from '../../../store/actions/itemActions';
import './itemlistselector.css';

export default function ItemListSelector({ onChange, onItemChanged, value, required, inputref }) {
    const itemsList = useSelector(state => state.items.items);
    const itemsLoading = useSelector(state => state.items.loading);
    const itemsError = useSelector(state => state.items.error);
    const itemsLoaded = useSelector(state => state.items.itemsLoaded);
    const newItemAdded = useSelector(state => state.items.newItem);

    
    const dispatch = useDispatch();

    useEffect(() => {
        if (!itemsLoaded && !itemsLoading && itemsError == null) {
            trackPromise(
                dispatch(fetchItems())
            );
        }
    }, [itemsLoading, itemsLoaded, itemsError]);

    function selectionChanged(e) {
        let itemId = e.target.value;
        if (itemId == -1) {
            let item = {itemId: -1};
            if (onItemChanged != null) {
                onItemChanged(item);
            } 
            return;
        }
        onChange(e);
        let item = itemsList?.find(i => i.id === parseInt(itemId));
        if (onItemChanged != null) {
            onItemChanged(item);
        }
    }

    if (itemsError) {
        return <div>Error! {itemsError.message}</div>;
    }

    if (itemsLoading) {
        return <LoadingPlaceholder labeltext="Inventory Items" />
    }

//     if (newItemAdded > 0) {

//         console.log(newItemAdded);
//         let itemId = newItemAdded;
//         // // onChange(e);
//         let item = itemsList?.find(i => i.id === parseInt(itemId));
// console.log(item);

//         if (onItemChanged != null && item != null) {
//             //  onItemChanged(item);
//          }
//         dispatch(clearNewItem());
//     }

    return (
        <div>
            <Form.Group>
                <Form.Label>{'Inventory Item'}</Form.Label>
                <Form.Control
                    as="select"
                    onChange={selectionChanged}
                    value={value}
                    required={required}
                    ref={inputref}
                    className={'inventory_item_select_dropdown'}
                >
                    <option key={0}
                        value={0}
                    />
                       
                    <option key={-1} value={-1} className="newInventoryItem">{'[ Add New Inventory Item ]'}</option>
                    {
                        itemsList.map(item =>
                            <option key={item.id}
                                value={item.id}
                            >
                                {item.displayName}
                            </option>
                        )
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {'Please Select an Inventory Item'}
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );
}