import React from "react";
import { useState } from "react";
import { divideDecimal, multDecimal, subtractDecimal } from "../../utilities";
import { formatCurrency } from '../../utilities/currencyUtil';
import { PurchaseOrderLineOptions } from "./PurchaseOrderLineOptions";

export default function ViewOnlyLineItem({
    item,
    receiveAll,
    setSelectedPOItemId,
    inputref,
    id,
    setFromLineItemModal,


}) {

    // const memoizedDetails = useMemo(() => getItemDetailArray(), [item]);
    const memoizedDetails = getItemDetailArray();

    function setActiveItem() {
        setSelectedPOItemId(item.id);
    }

    function calculatedTotal() {
        let calctotal = 0;
        if (item) {
            calctotal = multDecimal(item.quantity, item.purchasePrice);
        }
        return calctotal;
    }

    function getItemReceivedQuantity() {
        let receivedQuanity = '';
        if (item.quantity) {
            receivedQuanity = ((item?.quantity - (item?.outstandingQuantity ? item?.outstandingQuantity : '')?.toString() + ' of ' + item?.quantity?.toString()));
        }
        return receivedQuanity;
    }


    function getItemDetailArray() {
        let detail = [
            {
                label: 'Item',
                value: item.itemName,
                className: 'purchase-order-line-item-left-align purchaseorderline_item'
            },
            {
                label: 'Qty',
                value: item.quantity,
                className: 'purchase-order-line-item-center-align purchaseorderline_qty'
            },
            {
                label: 'Price',
                value: formatCurrency(item.purchasePrice),
                className: 'purchase-order-line-item-center-align purchaseorderline_price'
            },
            {
                label: 'Total',
                // value: formatCurrency(item.calculatedTotal),
                value: formatCurrency(calculatedTotal()),
                className: 'purchase-order-line-item-center-align purchaseorderline_total'
            },
            {
                label: 'Received',
                value: (item?.quantity - item?.outstandingQuantity)?.toString() + ' of ' + item?.quantity?.toString(),
                className: 'purchase-order-line-item-center-align purchaseorderline_received'
            },
            {
                label: 'Status',
                value: item.status,
                className: 'purchase-order-line-item-center-align purchaseorderline_status'
            },

        ];


        return detail;
    }

//This will display as the 'expanded' line item from the PO
    return (
        <>
            <div className="purchase-order-view-only-line-item-outer-container"
                 id={id}
                 ref={inputref}
                 onClick={setActiveItem}
            >

                {/* <div className="purchaseorder_line-options">
                    <PurchaseOrderLineOptions
                         expandLine={setActiveItem}
                         deleteItem={deleteInventoryItem}
                         receiveAll={receiveAll}
                    />
                </div> */}

            </div>

            {/* Make a grid layout div, with 2 rows and use column headers to display info, more info in one line */}
            <div className={'purchase-order-line-item-view-detail'}>
{
                    memoizedDetails.map((d, index) => (
                        <LineItemDetail
                            key={index}
                            detail={d}
                            item={item}
                        />
                    ))
                    }
            </div>
        </>
    )
}

//this will display as the line items on the PO
const LineItemDetail = ({ detail, item }) => {
    // console.log(item)

    return ( 
    <> 
            <div className={'purchase-order-line-item-detail-container ' + (detail.className)}>
            {/* <div>{'Item ID: ' + item.id}</div> */}
                <div className={'noselect purchase-order-line-item-detail-header ' + (detail.className)}>
                    {detail.label}
                </div>
                <div className={'noselect purchase-order-line-item-detail-value ' + detail.className}>
                    {detail.value}
            </div>
        </div>
    </>
    )
}