import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { RiAddFill } from "react-icons/ri";
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import './itemsummary.css';

/*
Created by Brandon 6/2/21
This component sits at the bottom of the ItemInfo (first tab on the Item Modal)
and displays a summary of pending items on Purchase Order and pending items on Sales Orders
*/

function ItemSummary( 
  {item} 
  ) {

  const windowWidth = useWindowDimensions().width;
  const history = useHistory();

  //create purchase order
  function createPO() {
    history.push('/purchasing', { itemId: item.id, addNew: true });
   
}

//create sales order
  function createSO() {

  }

  return (
    <>
      <div className="item_summary_container">
        <Row className="purchaseorder_row">
          <Col>
            <h4 className="noselect">{windowWidth <= 620 ? 'PO:' : 'Purchase Orders:'}</h4>
          </Col>
          <Col className="pending_items">
            <h4 className="noselect">{windowWidth <= 380 ? 'Await:' : 'Awaiting:'}{item?.unfilledQuantity}</h4>
          </Col>
          <Col>
          <Button 
            aria-label="add item to new purchase order" 
            onClick={createPO}
          > 
            <RiAddFill/> 
            {windowWidth <= 380 ? '': 'Order'}
          </Button>

          </Col>
        </Row>

        <Row className="salesorder_row">
          <Col>
            <h4 className="noselect">{windowWidth <= 620 ? 'Sales:' : 'Sales Orders:'}</h4>
          </Col>
          <Col className="pending_items">
            <h4 className="noselect">{windowWidth <= 380 ? 'Pend:' : 'Pending:'}</h4>
          </Col>
          <Col>
            <Button 
              aria-label="add item to new sale"
              onClick={createSO}
            > 
              <RiAddFill/> 
              {windowWidth <= 380 ? '': 'Sale'}
            </Button>
          </Col>
        </Row>
      </div>

    </>
  );
}

export { ItemSummary }; 