import React from "react";
import { Modal, Nav } from "react-bootstrap";
import "../searchbox/searchmodal.css";
import './searchfilters.css';

export function SearchFilters({ keepSearchFocused, setSearchType }) {
  return (
    <>
        <Nav variant="tabs" defaultActiveKey="link-1">

          <Nav.Item aria-label="all" className="search_links">
            <Nav.Link
              eventKey="link-1"
              className="search_list_item"
              onMouseDown={() => keepSearchFocused()}
              onClick={() => setSearchType('all')}  // This needs to happen so the parent knows the search type
            >
              <span className="noselect">All</span>
        </Nav.Link>
          </Nav.Item>
          <Nav.Item aria-label="items" className="search_links">
            <Nav.Link
              eventKey="link-2"
              className="search_list_item"
              onMouseDown={() => keepSearchFocused()}
              onClick={() => setSearchType('items')}
            >
              <span className="noselect">Items</span>
        </Nav.Link>
          </Nav.Item>
          <Nav.Item aria-label="customers" className="search_links">
            <Nav.Link
              eventKey="link-3"
              className="search_list_item"
              onMouseDown={() => keepSearchFocused()}
              onClick={() => setSearchType('customers')}
            >
              <span className="noselect">Customer</span>
        </Nav.Link>
          </Nav.Item>
          <Nav.Item aria-label="sales" className="search_links">
            <Nav.Link
              eventKey="link-4"
              className="search_list_item"
              onMouseDown={() => keepSearchFocused()}
              onClick={() => setSearchType('sales')}
            >
              <span className="noselect">Sales</span> 
        </Nav.Link>
          </Nav.Item>
          <Nav.Item aria-label="purchasing" className="search_links">
            <Nav.Link
              eventKey="link-5"
              className="search_list_item"
              onMouseDown={() => keepSearchFocused()}
              onClick={() => setSearchType('purchasing')}
            >
             <span className="noselect">Purchasing</span> 
        </Nav.Link>
          </Nav.Item>
          <Nav.Item aria-label="vendors" className="search_links">
            <Nav.Link
              eventKey="link-6"
              className="search_list_item"
              onMouseDown={() => keepSearchFocused()}
              onClick={() => setSearchType('vendors')}
            >
             <span className="noselect">Vendors</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item aria-label="returns" className="search_links">
            <Nav.Link
              eventKey="link-7"
              className="search_list_item"
              onMouseDown={() => keepSearchFocused()}
              onClick={() => setSearchType('returns')}
            >
             <span className="noselect">Returns</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
    </>
  )
}