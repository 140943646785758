import {
  FETCH_PURCHASEORDER_BEGIN,
  FETCH_PURCHASEORDER_SUCCESS,
  FETCH_PURCHASEORDER_FAILURE,
  RESET_SINGLE_PURCHASEORDER,
} from "../actions/singlePurchaseOrderActions";

const initialState = {
  purchaseorder: [],
  loading: false,
  error: null,
  purchaseorderLoaded: false,
  itemPosting: false,
};

export default function purchaseOrderReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PURCHASEORDER_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
        purchaseorderLoaded: false,
      };

    case FETCH_PURCHASEORDER_SUCCESS:
      // All done: set loading "false".
      // Also, replace the purchaseorder with the ones from the server
      return {
        ...state,
        loading: false,
        purchaseorder: action.payload.purchaseorder,
        purchaseorderLoaded: true,
      };

    case FETCH_PURCHASEORDER_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have purchaseorder to display anymore, so set `purchaseorder` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the purchaseorder around!
      // Do whatever seems right for your use case.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        purchaseorder: [],
      };
    case RESET_SINGLE_PURCHASEORDER:
      
      return {
        ...state,
        loading: false,
        error: null,
        purchaseorder: [],
        purchaseorderLoaded: false,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
