import { fetchService } from "../../services";

export const FETCH_VENDORS_BEGIN   = 'FETCH_VENDORS_BEGIN';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_VENDORS_FAILURE = 'FETCH_VENDORS_FAILURE';

export const UPDATE_VENDORS = 'UPDATE_VENDORS';

//#region Fetch

export const fetchVendorsBegin = () => ({
  type: FETCH_VENDORS_BEGIN
});

export const fetchVendorsSuccess = vendors => ({
  type: FETCH_VENDORS_SUCCESS,
  payload: { vendors }
});

export const fetchVendorsFailure = error => ({
  type: FETCH_VENDORS_FAILURE,
  payload: { error }
});

export function fetchVendors() {
    return dispatch => {
      dispatch(fetchVendorsBegin());
      return fetchService.get("/vendor")
        .then(json => {
          dispatch(fetchVendorsSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchVendorsFailure(error)));
    };
  }

//#endregion

//#region 

export function updateVendors(vendor) {
  return dispatch => {
    dispatch(pushUpdateVendors(vendor));
  }
};

export const pushUpdateVendors = vendor => ({
  type: UPDATE_VENDORS,
  payload: vendor
});

//#endregion
