import {
    FETCH_SALESORDER_BEGIN,
    FETCH_SALESORDER_SUCCESS,
    FETCH_SALESORDER_FAILURE,
    RESET_SINGLE_SALESORDER,
  } from "../actions/singleSalesOrderActions";
  
  const initialState = {
    salesorder: [],
    loading: false,
    error: null,
    salesorderLoaded: false,
    itemPosting: false,
  };
  
  export default function salesOrderReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_SALESORDER_BEGIN:
        return {
          ...state,
          loading: true,
          error: null,
          salesorderLoaded: false,
        };
  
      case FETCH_SALESORDER_SUCCESS:
        return {
          ...state,
          loading: false,
          salesorder: action.payload.salesorder,
          salesorderLoaded: true,
        };
  
      case FETCH_SALESORDER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          salesorder: [],
        };
      case RESET_SINGLE_SALESORDER:
        
        return {
          ...state,
          loading: false,
          error: null,
          salesorder: [],
          salesorderLoaded: false,
        };
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }
  