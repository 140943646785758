import { fetchService } from "../../services";

export const FETCH_ITEMS_BEGIN = "FETCH_ITEMS_BEGIN";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_FAILURE = "FETCH_ITEMS_FAILURE";

export const FETCH_ITEM_BEGIN = "FETCH_ITEM_BEGIN";
export const FETCH_ITEM_SUCCESS = "FETCH_ITEM_SUCCESS";
export const FETCH_ITEM_FAILURE = "FETCH_ITEM_FAILURE";
export const CLEAR_CURRENT_ITEM = "CLEAR_CURRENT_ITEM";

export const POST_ITEM_BEGIN = "POST_ITEM_BEGIN";
export const POST_ITEM_SUCCESS = "POST_ITEM_SUCCESS";
export const POST_ITEM_FAILURE = "POST_ITEM_FAILURE";

export const PUT_ITEM_BEGIN = "PUT_ITEM_BEGIN";
export const PUT_ITEM_SUCCESS = "PUT_ITEM_SUCCESS";
export const PUT_ITEM_FAILURE = "PUT_ITEM_FAILURE";

export const UPDATE_ITEMS = "UPDATE_ITEMS";
export const CLEAR_NEW_ITEM = "CLEAR_NEW_ITEM";

//Fetch
//##########################################################################
const fetchItemsBegin = () => ({
  type: FETCH_ITEMS_BEGIN,
});

const fetchItemsSuccess = (items) => ({
  type: FETCH_ITEMS_SUCCESS,
  payload: { items },
});

export const fetchItemsFailure = (error) => ({
  type: FETCH_ITEMS_FAILURE,
  payload: { error },
});

export function fetchItems() {
  return (dispatch) => {
    dispatch(fetchItemsBegin());

    return fetchService
      .get("/items")
      .then((json) => {
        dispatch(fetchItemsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchItemsFailure(error)));
  };
}
//End Fetch
//##########################################################################

//Fetch Single
//##########################################################################
const fetchItemBegin = () => ({
  type: FETCH_ITEM_BEGIN,
});

const fetchItemSuccess = (item) => ({
  type: FETCH_ITEM_SUCCESS,
  payload: { item },
});

export const fetchItemFailure = (error) => ({
  type: FETCH_ITEM_FAILURE,
  payload: { error },
});

export function fetchItem(itemid) {
  return (dispatch) => {
    dispatch(fetchItemBegin());
    return fetchService
      .get(`/items/${itemid}`)
      .then((json) => {
        dispatch(fetchItemSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchItemFailure(error)));
  };
}

const clearCurrentItemArgs = () => ({
  type: CLEAR_CURRENT_ITEM,
});

export function clearCurrentItem() {
  return (dispatch) => {
    dispatch(clearCurrentItemArgs());
  };
}
//End Fetch
//##########################################################################

//Post
//##########################################################################
export const postItemBegin = () => ({
  type: POST_ITEM_BEGIN,
});

export const postItemSuccess = (item) => ({
  type: POST_ITEM_SUCCESS,
  payload: { item },
});

export const postItemFailure = (error) => ({
  type: POST_ITEM_FAILURE,
  payload: { error },
});

export function postItem(item) {
  return (dispatch) => {
    dispatch(postItemBegin());
    return fetchService
      .post("/items", item)
      .then((json) => {
        dispatch(postItemSuccess(json));
        return json;
      })
      .catch((error) => dispatch(postItemFailure(error)));
  };
}
//End Post
//##########################################################################

//Put
//##########################################################################
export const putItemBegin = () => ({
  type: PUT_ITEM_BEGIN,
});

export const putItemSuccess = (item) => ({
  type: PUT_ITEM_SUCCESS,
  payload: { item },
});

export const putItemFailure = (error) => ({
  type: PUT_ITEM_FAILURE,
  payload: { error },
});

export function putItem(item, id) {
  return (dispatch) => {
    dispatch(putItemBegin());
    return fetchService
      .put("/items/" + id, item)
      .then((json) => {
        dispatch(putItemSuccess(json));
        return json;
      })
      .catch((error) => dispatch(putItemFailure(error)));
  };
}
//End Put
//##########################################################################

//Update List

export function updateItems(item) {
  return (dispatch) => {
    dispatch(pushUpdateItems(item));
  };
}

export const pushUpdateItems = (item) => ({
  type: UPDATE_ITEMS,
  payload: item,
});

//##########################################################################
//Clear New Item

export function clearNewItem() {
  return (dispatch) => {
    dispatch(pushClearNewItem());
  };
}

export const pushClearNewItem = () => ({
  type: CLEAR_NEW_ITEM
});

//##########################################################################
