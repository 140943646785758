import { fetchService } from "../../services";

export const FETCH_VENDOR_BEGIN = 'FETCH_VENDOR_BEGIN';
export const FETCH_VENDOR_SUCCESS = 'FETCH_VENDOR_SUCCESS';
export const FETCH_VENDOR_FAILURE = 'FETCH_VENDOR_FAILURE';

//#region VENDOR

export const fetchVendorBegin = () => ({
    type: FETCH_VENDOR_BEGIN
});

export const fetchVendorSuccess = vendor => ({
    type: FETCH_VENDOR_SUCCESS,
    payload: { vendor }
});

export const fetchVendorFailure = error => ({
    type: FETCH_VENDOR_FAILURE,
    payload: { error }
});

export function fetchVendor(vendID) {
    return dispatch => {
        dispatch(fetchVendorBegin());

        return fetchService.get(`/vendor/${vendID}`)
         .then(json => {
             dispatch(fetchVendorSuccess(json));
             return json;
         })
         .catch(error => dispatch(fetchVendorFailure(error)));
    };
};

//#endregion