import React, { Component } from "react";
import { ActionBarAllThings } from "../../Layout/ActionBar";

class reports extends Component {
  render() {
    return (
      <>

      <ActionBarAllThings
        pageTitle={'Reports'}
      />  
      </>
    );
  }
}

export default reports;
