import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import ItemListSelector from "../../Controls/Selectors/ItemListSelector";
import { getShortDate, getTodaysDate, parseDecimal } from "../../utilities";
import { MdExpandLess } from "react-icons/md";
import TextInput from "../../Controls/textInput";
import ItemInputUnitSelection from "../items/ItemInputUnitSelection";
import { SalesOrderLineOptions } from "./SalesOrderLineOptions";
import InventoryDashCheckbox from "../../Controls/InventoryDashCheckbox";
import NumericUpDown from "../../Controls/numericupdown/NumericUpDown";
import "./salesorderlineitem.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShippedLog from "./ShippedLog";

export default function SalesOrderLineItem({
  item,
  shipRecords,
  itemValueChanged,
  measurementTypes,
  setShowItemRecInput,
  setSelectedItem,
  expandedItemId,
  setExpandedItemId,
  id,
  inputref,
  setCollapsed,
  visible,
}) {
  const [currentRecAmount, setCurrentRecAmount] = useState(0);
  const [isShipping, setIsShipping] = useState(false);

  // UI For Input of shipped Quantity
  function inventoryItemChanged(e) {
    item.itemId = parseInt(e.target.value);
    itemValueChanged(item);
  }
  function quantityChanged(e) {
    item.quantity = parseDecimal(e.target.value);
    itemValueChanged(item);
  }
  function salePriceChanged(e) {
    item.salePrice = parseDecimal(e.target.value);
    itemValueChanged(item);
  }
  function inventoryItemObjectChanged(inventoryItem) {
    item.inventoryItemName = inventoryItem.itemName;
    item.salePrice = parseDecimal(inventoryItem.vendorCost);
    item.salePriceUnitTypeId = parseDecimal(inventoryItem.vendorCostUnitType);
    itemValueChanged(item);
  }

  function deleteInventoryItem() {
    if (item.salesOrderStatus !== 3) {
      item.deleted = true;
      itemValueChanged(item);
    }
  }

  function shippingCheckboxChanged(e) {
    let checked = e.target.checked;
    if (!checked) {
      item.newQuantityshipped = 0;
    } else {
      item.newQuantityshipped = item.outstandingQuantity;
      item.newShippedDate = getTodaysDate();
      setshippedDate(item.newshippedDate);
    }
    setIsShipping(checked);
  }

  function noteChanged(e) {
    item.note = e.target.value;
    itemValueChanged(item);
  }

  function updatePurchasePriceUnitSelection(
    unittypename,
    baseMeasurementUnitTypeId
  ) {
    item.salePriceUnitTypeId = baseMeasurementUnitTypeId;
    itemValueChanged(item);
  }

  function setCurrentAmount(amount) {
    item.newQuantityshipped = amount;
    setCurrentRecAmount(item.newQuantityshipped);
  }

  function getExpectedDate() {
    if (item) {
      return getShortDate(item.expectedDate);
    }
    return getTodaysDate();
  }
  function setExpectedDate(date) {
    if (item) {
      item.expectedDate = date;
      itemValueChanged(item);
    }
  }

  function getshippedDate() {
    if (item) {
      if (!item.newShippedDate) {
        setshippedDate(getShortDate(getTodaysDate()));
        return getShortDate(getTodaysDate());
      }
      if (item.newShippedDate < "2/1/2000") {
        console.log(item.newshippedDate);
        return getShortDate(getTodaysDate());
      }
      return getShortDate(item.newShippedDate);
    }
    return null;
  }

  function setshippedDate(date) {
    if (date) {
      item.newShippedDate = date;
      itemValueChanged(item);
    }
  }

  function lineItemFullyshipped() {
    if (item) {
      if (item.salesOrderStatus === 2) {
        return true;
      }
      if (!item.quantity || item.quantity === 0) {
        return true;
      }
    }

    return false;
  }

  function shipAll() {
    item.newQuantityshipped = item.outstandingQuantity;
    item.newshippedDate = getTodaysDate();
    item.isShipping = true;

    setIsShipping(true);
    itemValueChanged(item);
  }

  return (
    <>
      <div
        id={id}
        ref={inputref}
        className={
          "sales-order-line-item-outer-container" + (visible ? " expanded" : "")
        }
      >
        <div className="salesorder_line_options">
          <SalesOrderLineOptions
            expandLine={setCollapsed}
            deleteItem={deleteInventoryItem}
            shipAll={shipAll}
            expanded={true}
          />
        </div>

        <>
          <div className="grid_salesorder_row">
            <div className="grid_salesorder_name noselect">
              <ItemListSelector
                value={item?.itemId}
                onChange={inventoryItemChanged}
                onItemChanged={inventoryItemObjectChanged}
              />
            </div>

            <div className="grid_salesorder_qty noselect">
              <TextInput
                name="salesorderitemqty"
                label="Quantity"
                onChange={quantityChanged}
                value={item?.quantity}
              />
            </div>

            <div className="grid_salesorder_price noselect">
              <ItemInputUnitSelection
                lbltext="Price / Unit"
                name={"salesorderitemprice"}
                itemvalue={item?.salePrice}
                unitTypeValue={item?.salePriceUnitTypeId}
                onInputValueChanged={salePriceChanged}
                unittypename={"vendorCostUnitType"}
                unittypebasemt={6}
                unitMeasureTypes={measurementTypes}
                updateUnitSelection={updatePurchasePriceUnitSelection}
                showSelect={false}
              />
            </div>
          </div>

          <div className={"salesorder_lineitem_secondrow_grid"}>
            <div className="salesorder_checkbox_component">
              <InventoryDashCheckbox
                label={
                  "Ship (max of " +
                  (item ? item?.outstandingQuantity : "0") +
                  ")"
                }
                checked={isShipping}
                disabled={lineItemFullyshipped()}
                onChange={shippingCheckboxChanged}
              />
              <div
                className={
                  "salesorder_lineitem_recieved_area" +
                  (isShipping ? " enabled" : "")
                }
              >
                <Form.Group>
                  <NumericUpDown
                    name={"amountRec"}
                    value={item?.newQuantityshipped}
                    onChange={setCurrentAmount}
                    min={0}
                    max={item?.outstandingQuantity}
                    disabled={!isShipping}
                  />
                </Form.Group>
                <div className={"salesorder_received_date"}>
                  <div className="datepicker-container">
                    <span>Ship Date</span>
                    <DatePicker
                      className="salesorder_datepicker_input_field datepicker_input_field"
                      onChange={isShipping ? setshippedDate : null}
                      value={isShipping ? getshippedDate() : null}
                      includeTimes={false}
                      disabled={!isShipping}
                      popperPlacement="right"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "-60px, 0px",
                        },
                      }}
                    />
                  </div>
                  <div className="tracking">
                    <TextInput name="tracking" label="Tracking" onChange={""} />
                  </div>
                </div>
              </div>
            </div>
            {/*NOTE - I think we should get rid of the below code - dont need expected date 11/13 BS}
                        {/* <div className={'salesorder_expected_date noselect'}>
                            <Form.Group>
                                <Form.Label>{'Expected Date'}</Form.Label>
                                <div className="datepicker-container">
                                    <DatePicker
                                        className="salesorder_datepicker_input_field datepicker_input_field"
                                        onChange={setExpectedDate}
                                        value={getExpectedDate()}
                                        includeTimes={false}
                                        popperPlacement="right"
                                    />
                                </div>
                            </Form.Group>
                        </div> */}

            <div className={"salesorder_note noselect"}>
              <Form.Group>
                <Form.Label>{"Notes"}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={item?.note ? item?.note : ""}
                  onChange={noteChanged}
                />
              </Form.Group>
            </div>
          </div>
          <div className={"salesorder_lineitem_thirdrow_grid"}>
            {/* {item?.outstandingQuantity == item?.quantity ? '' : <ShippedLog
                        
                        />} */}
            <ShippedLog
              shipRecords={shipRecords}
              itemId={item?.id}
            />
            {/* <div className="salesrorder_qty_recieved noselect">
                            {(item?.quantity - item?.outstandingQuantity)?.toString() +
                                " of " +
                                item?.quantity?.toString() +
                                (item?.quantity !== 1 ? " items have " : " item has ") +
                                "been shipped."}
                            {
                                item?.historyItems?.map((history, index) => (
                                    <div className={"salesorder_lineitem_history"} key={index}>
                                        {history}
                                    </div>
                                ))
                            }

                        </div> */}
          </div>
        </>
      </div>
    </>
  );
}
