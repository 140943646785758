import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions'

function PurchaseOrderStatusSelector({ valueChanged, value, required, inputref }) {
    const windowWidth = useWindowDimensions().width;
    const statuses = [
        {
            id: 0,
            status: 'Open'
        },
        {
            id: 1,
            status: 'Closed'
        }
    ]

    function statusValueChanged(e) {
        let typeid = parseInt(e.target.value);
        let typename = statuses.find(e => e.id === typeid)?.status;
        valueChanged(typeid, typename);
    }

    return (
        <div>
            <Form.Group>
                <Form.Label>{'Status'}</Form.Label>
                <Form.Control
                    as="select"
                    onChange={statusValueChanged}
                    value={value}
                    required={required}
                    ref={inputref}
                >

                    {
                        statuses.map(status =>
                            <option key={status.id}
                                value={status.id}
                            >
                                {status.status}
                            </option>
                        )
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please Select a Status
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );

}

export { PurchaseOrderStatusSelector };