import React, { useEffect, useState } from "react"; //import useState from react
import { Button, Row, Col } from "react-bootstrap";
import { RiRulerLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import {  AiOutlineMail} from "react-icons/ai";
import { BsLayoutTextWindow } from "react-icons/bs";
import {
  FaMoneyBill,
  FaConnectdevelop,
  FaNewspaper
} from "react-icons/fa";
import { VscGroupByRefType } from 'react-icons/vsc';
import { GoLocation } from "react-icons/go";
import { CurrencyTaxesComponent } from "./CurrencyTaxesComponent";
import { UnitofMeasureComponent } from "./UnitofMeasureComponent";
// import { AlertsComponent } from "./AlertsComponent";
import { CustomFieldsComponent } from "./CustomFieldsComponent";
// import { LocationsComponent } from "./LocationsComponent";
import { IntegrationComponent } from "./IntegrationComponent";
import { UsersAndRolesComponent } from "./UsersAndRolesComponent";
import { EmailComponent } from "./EmailComponent";
import { TemplatesComponent } from "./TemplatesComponent";
import { ActionBarAllThings} from "../../Layout/ActionBar";
// import { ItemGroupPage } from "../../pages/itemgroups/itemGroupPage";
import { LocationsAndItemGroups } from "./locationsAndItemGroups/LocationsAndItemGroups";


import "./settings.css";
import { useSelector, useDispatch } from "react-redux";
import { changeSettingsClicked } from '../../../store/actions/userInteractionActions';


// Changed the component to a React functional component to allow the use of hooks
// simple change it to a function vs class
// remove extends Component
// capitalize component name
// remove render
function Settings () {
  const settingsIconClicked = useSelector(state => state.userInteractions.settingsIconClicked);
  //Create to useState hooks to store UI state, one for user component one for alert component
  const [userComponentVisible, setUserComponentVisible] = useState(false);
  const [emailComponentVisible, setEmailComponentVisible] = useState(false);
  const [customFieldsComponentVisible, setCustomFieldsComponentVisible] = useState(false);
  const [unitofMeasureComponentVisible, setUnitofMeasureComponentVisible,] = useState(false);
  const [currencyTaxesComponentVisible, setCurrencyTaxesComponentVisible,] = useState(false);
  const [integrationComponentVisible, setIntegrationComponentVisible] = useState(false);
  const [templatesComponentVisible, setTemplatesComponentVisible] = useState(false);

  // const [itemGroupsComponentVisible, setItemGroupsComponentVisible] = useState(false);
  const [locationsAndItemGroupsVisible, setLocationsAndItemGroupsVisible] = useState(false);
  const dispatch = useDispatch();
 
  useEffect(() => {
    if (settingsIconClicked)
    {
      // reset value back to false
      dispatch(changeSettingsClicked(false));
      // set all other components visibility to false
      let ret;
      ret = userComponentVisible ? setUserComponentVisible(false) : null;
      ret = emailComponentVisible ? setEmailComponentVisible(false) : null;
      ret = customFieldsComponentVisible ? setCustomFieldsComponentVisible(false) : null;
      ret = unitofMeasureComponentVisible ? setUnitofMeasureComponentVisible(false) : null;
      ret = currencyTaxesComponentVisible ? setCurrencyTaxesComponentVisible(false) : null;
      ret = integrationComponentVisible ? setIntegrationComponentVisible(false) : null;
      ret = templatesComponentVisible ? setTemplatesComponentVisible(false) : null;
      ret = locationsAndItemGroupsVisible ? setLocationsAndItemGroupsVisible(false) : null;
    }
  }, [settingsIconClicked])
  
  //Check the value of visible components on each render
  if (userComponentVisible) {
    // If true we will only render the UserRoleComponent and end the function
    return (
      <UsersAndRolesComponent
        setUserComponentVisible={setUserComponentVisible}
      />
    );
  }

  if (emailComponentVisible) {
    return (
      <EmailComponent setEmailComponentVisible={setEmailComponentVisible} />
    );
  }

  if (customFieldsComponentVisible) {
    return (
      <CustomFieldsComponent
        setCustomFieldsComponentVisible={setCustomFieldsComponentVisible}
      />
    );
  }

  if (unitofMeasureComponentVisible) {
    return (
      <UnitofMeasureComponent
        setUnitofMeasureComponentVisible={setUnitofMeasureComponentVisible}
      />
    );
  }

  if (currencyTaxesComponentVisible) {
    return (
      <CurrencyTaxesComponent
        setCurrencyTaxesComponentVisible={setCurrencyTaxesComponentVisible}
      />
    );
  }

  if (integrationComponentVisible) {
    return (
      <IntegrationComponent
        setIntegrationComponentVisible={setIntegrationComponentVisible}
      />
    );
  }

  if (templatesComponentVisible) {
    return (
      <TemplatesComponent
        setTemplatesComponentVisible={setTemplatesComponentVisible}
      />
    );
  }

  if (locationsAndItemGroupsVisible) {
    return (
      <LocationsAndItemGroups
        setLocationsAndItemGroupsVisible={setLocationsAndItemGroupsVisible}
      />
    )
  }
  
  return (
    <>
      <ActionBarAllThings pageTitle={"Settings"} showFooter={false} />
      <div className="page_container">
        <div className="settings_btn_container">
        <Row xs={2} sm={3} md={5} >
          <Col className="setting-btn-col">
            <Button
              aria-label="user and user roles"
              className="btn_large"
              onClick={() => setUserComponentVisible(true)}
            >
              {/* Added the onclick event to set the state variable for the visibility of the User Component */}
              <div className="top-spacer"></div>
              <div>
                <FiUsers size="2.3rem" className="button_icon_font_color"/>
                <div className="tiny-spacer"></div>
                {/* <p>User &amp; Roles</p>{" "} */}
                <p>Users</p>{" "}
              </div>
            </Button>
          </Col>

          <Col className="setting-btn-col">
            <Button
              aria-label="locations and groups"
              className="btn_large"
              onClick={() => setLocationsAndItemGroupsVisible(true)}
            >
              {/* Added the onclick event to set the state variable for the visibility of the Alert Component */}
              <div className="top-spacer"></div>
              <div>
                <GoLocation size="1.6rem" className="button_icon_font_color"/>
                <VscGroupByRefType size="1.6rem" className="button_icon_font_color"/>
                <div className="spacer"></div>
                <p>Locations &amp; Groups</p>
              </div>
            </Button>
          </Col>
        
          {/* <Col className="setting-btn-col">
            <Button
              aria-label="Email"
              className="btn_large"
              onClick={() => setEmailComponentVisible(true)}
            >
              <div className="top-spacer"></div>
              <div>
                <AiOutlineMail size="2.3rem" className="button_icon_font_color"/>
                <div className="spacer"></div>
                <p>Email</p>
              </div>
            </Button>
          </Col> */}

          {/* <Col className="setting-btn-col">
            <Button
              aria-label="Custom Fields"
              className="btn_large"
              onClick={() => setCustomFieldsComponentVisible(true)}
            >
              <div className="top-spacer"></div>
              <div>
                <BsLayoutTextWindow size="2rem" className="button_icon_font_color"/>
                <div className="small-spacer"></div>
                <p>Custom Fields</p>
              </div>
            </Button>
          </Col> */}

          <Col className="setting-btn-col">
            <Button
              aria-label="Units of Measure"
              className="btn_large"
              onClick={() => setUnitofMeasureComponentVisible(true)}
            >
              <div className="top-spacer"></div>
              <div>
                <RiRulerLine size="2.3em" className="button_icon_font_color"/>
                <div className="tiny-spacer"></div>
                <p>Unit of Measure</p>
              </div>
            </Button>
          </Col>

          <Col className="setting-btn-col">
            <Button
              aria-label="Currency"
              className="btn_large"
              onClick={() => setCurrencyTaxesComponentVisible(true)}
            >
              <div className="top-spacer"></div>
              <div>
                <FaMoneyBill size="2.3rem" className="button_icon_font_color"/>
                <p>Currency &amp; Taxes</p>
              </div>
            </Button>
          </Col>

          {/* <Col className="setting-btn-col">
            <Button
              aria-label="Integration"
              className="btn_large"
              onClick={() => setIntegrationComponentVisible(true)}
            >
              <div className="top-spacer"></div>
              <div>
                <FaConnectdevelop size="2.3rem" className="button_icon_font_color"/>
                <div className="spacer"></div>
                <p>Integration</p>
              </div>
            </Button>
          </Col> */}

          {/* <Col className="setting-btn-col">
            <Button
              aria-label="Templates"
              className="btn_large"
              onClick={() => setTemplatesComponentVisible(true)}
            >
              <div className="top-spacer"></div>
              <div>
                <FaNewspaper size="2.5rem" className="button_icon_font_color"/>
                <div className="spacer"></div>
                <p>Templates</p>
              </div>
            </Button>
          </Col> */}
        </Row>
        </div>
      </div>
    </>
  );
}

export { Settings };
