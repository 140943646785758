// Theme variables laid out here any number can be implemented

const lightTheme = [
/*************************************************/
{prop: '--navbar_background', val: '#404C59'},
{prop: '--navbar_font', val: '#D0D8DC'},
{prop: '--navbar_border', val: ''},
{prop: '--navbar_search_button_background', val: 'silver'},
{prop: '--navbar_search_button_expanded_background', val: 'silver'},
{prop: '--navbar_search_button_border', val: '#282c2f'},
{prop: '--navbar_search_button_expanded_border', val: ''},
    //navbar icon colors
    {prop: '--navbar_search_button_icon', val: '#2b3e50'},
    {prop: '--navbar_search_button_expanded_scanner_icon', val: '#e9ecef'},
    {prop: '--navbar_search_button_expanded_clear_search_icon', val: ''},
    {prop: '--navbar_question_setting_icon', val: '#D0D8DC'},
    {prop: '--navbar_home_logout_icon', val: '#2E2E2E'},
    {prop: '--navbar_menu_icon', val: 'white'},
    //navbar hover  colors
    {prop: '--navbar_question_setting_hover_background', val: '#e9ecef'},
    {prop: '--navbar_question_setting_hover_font', val: '#e9ecef'},
    {prop: '--navbar_question_setting_hover_border', val: '#e9ecef'},
    {prop: '--navbar_home_logout_icon_hover_background', val: ''},
    {prop: '--navbar_link_hover_background', val: '#282c2f'},
    {prop: '--navbar_link_hover_border', val: '#e9ecef'},
    {prop: '--navbar_menu_icon_hover', val: ''},
    //navbar active  colors
    {prop: '--navbar_question_setting_active_background', val: '#e9ecef'},
    {prop: '--navbar_question_setting_active_font', val: '#e9ecef'},
    {prop: '--navbar_question_setting_active_border', val: '#e9ecef'},
    {prop: '--navbar_home_logout_icon_active_background', val: ''},
    {prop: '--navbar_link_active_background', val: '#282c2f'},
    {prop: '--navbar_link_active_border', val: '#e9ecef'},
    {prop: '--navbar_menu_icon_active', val: ''},
    

/************************************************/
/*canvas & actionbar colors*/
{prop: '--canvas_background', val: '#faf8f6'},
{prop: '--canvas_background_blur', val: 'black'},
//Selection color over canvas/ for highlighting
{prop: '--canvas_background_selection', val: 'rgba(0, 0, 0, .1)'},
{prop: '--canvas_font', val: '#004961'},
{prop: '--actionbar_background', val: '#df691a'},
{prop: '--actionbar_border', val: '#4e5d6c'},
{prop: '--actionbar_font', val: 'white'},
{prop: '--actionbar_border', val: ''},
{prop: '--actionbar_save_button_background', val: '#4e5d6c'},
{prop: '--actionbar_save_button_font', val: 'white'},
{prop: '--actionbar_save_button_border', val: '#006687'},
{prop: '--actionbar_cancel_button', val: '#E5E9EB'},
    //actionbar hover and active colors
    {prop: '--actionbar_button_hover_background', val: '#5bc0de'},
    {prop: '--actionbar_button_hover_font', val: '#4e5d6c'},
    {prop: '--actionbar_active_font', val: '#fffff'},
    {prop: '--actionbar_active_background', val: '#4e5d6c'},
    {prop: '--actionbar_button_hover_border', val: '#4e5d6c'},
    {prop: '--actionbar_button_active_background', val: '#5bc0de'},
    {prop: '--actionbar_button_active_font', val: '#4e5d6c'},
    {prop: '--actionbar_button_active_border', val: '#4e5d6c'},
    {prop: '--actionbar_button_back_hover', val: '#4e5d6c'},

//************************************************/
/*button colors*/
{prop: '--button_large_background', val: '#f0ad4e'},
{prop: '--button_large_font', val: '#ffffff'},
{prop: '--button_large_icon', val: ''},
{prop: '--button_large_border', val: '#0088b4'},
{prop: '--button_small_icon', val: '#4e5d6c'},
{prop: '--button_small_background', val: '#dbdbdb'},
{prop: '--button_small_font', val: '#4e5d6c'},
{prop: '--button_small_border', val: '#2b3e50'},
    //button hover colors
    {prop: '--button_large_hover_background', val: '#E5E9EB'},
    {prop: '--button_large_hover_font', val: '#4e5d6c'},
    {prop: '--button_large_hover_border', val: '#4e5d6c'},
    {prop: '--button_large_hover_icon', val: 'black'},
    {prop: '--button_small_hover_background', val: 'white'},
    {prop: '--button_small_hover_font', val: 'black'},
    {prop: '--button_small_hover_icon', val: '#2b3e50'},
    {prop: '--button_small_hover_border', val: '#4E5D6C'},
    //button active colors
    {prop: '--button_large_active_background', val: 'rgb(250, 155, 67)'},
    {prop: '--button_large_active_font', val: 'black'},
    {prop: '--button_large_active_border', val: 'silver'},
    {prop: '--button_large_active_icon', val: 'black'},
    {prop: '--button_small_active_background', val: ''},
    {prop: '--button_small_acitve_font', val: ''},
    {prop: '--button_small_active_icon', val: ''},
    {prop: '--button_small_active_border', val: ''},

/********************************************************************/
/*table colors*/
{prop: '--table_background', val: '#E5E9EB'},
{prop: '--table_font', val: 'black'},
{prop: '--table_border', val: '#D6DFE1'},
{prop: '--table_column_header_background', val: '#D9E3E3'},
{prop: '--table_column_header_font', val: '#003447'},
{prop: '--table_column_header_mobile_font', val: 'black'},
{prop: '--table_column_header_border', val: '#949494'},
{prop: '--table_selected_item_background', val: 'rgb(223,105,26,0.31)'},
{prop: '--table_selected_item_font', val: '#1c1c1c'},
    //table hover colors
    {prop: '--table_select_dropdown_hover_background', val: ''},
    {prop: '--table_select_dropdown_hover_font', val: ''},
    {prop: '--table_select_dropdown_hover_border', val: ''},
    //table active colors
    {prop: '--table_select_dropdown_active_background', val: ''},
    {prop: '--table_select_dropdown_active_font', val: ''},
    {prop: '--table_select_dropdown_active_border', val: ''},

/*******************************************************/
/*field properties*/
{prop: '--field_background', val: '#F5F5F5'},
{prop: '--field_label_font', val: '#454645'},
{prop: '--field_border', val: 'rgb(204, 204, 204)'},
{prop: '--field_select_dropdown_background', val: '#4e5d6c'},
{prop: '--field_select_dropdown_border', val: ''},
{prop: '--field_select_dropdown_font', val: 'white'},
    //field properties hover 
    {prop: '--field_select_hover_dropdown_background', val: ''},
    {prop: '--field_hover_background', val: ''},
    {prop: '--field_select_hover_dropdown_border', val: ''},
    {prop: '--field_select_hover_dropdown_font', val: ''},
     //field properties active 
     {prop: '--field_select_active_background', val: 'white'},
     {prop: '--field_select_active_font', val: '#4e5d6c'},
     {prop: '--field_select_active_border', val: '#004961'},

/*****************************************************/
/*tab colors*/
{prop: '--tab_background', val: '#2b3e50'},
{prop: '--tab_font', val: 'whitesmoke'},
{prop: '--tab_border', val: '#4e5d6c'},
{prop: '--tab_hover_background', val: '#4d5c6d'},
{prop: '--tab_hover_font', val: 'white'},
{prop: '--tab_hover_border', val: '#4d5c6d'},
{prop: '--tab_active_background', val: ''},
{prop: '--tab_active_font', val: 'black'},
{prop: '--tab_focus_border', val: '#f0ad4e'},
{prop: '--search_nav_active_background', val: '#e5e9eb'},
{prop: '--search_nav_hover_background', val: '#737373'},

/*****************************************************/
/*side drawer (mobile screens only*/
{prop: '--side_drawer_background', val: '#4e5d6c'},
{prop: '--side_drawer_font', val: 'white'},
{prop: '--side_drawer_border', val: ''},
{prop: '--side_drawer_link_border', val: '#abb6c2'},
{prop: '--side_drawer_hover_background', val: ''},
{prop: '--side_drawer_hover_font', val: ''},
{prop: '--side_drawer_hover_border', val: '#4971b4'},
{prop: '--side_drawer_active_background', val: ''},
{prop: '--side_drawer_active_border', val: '#4971b4'},
{prop: '--side_drawer_active_font', val: ''},

/*custom checkbox colors */
{prop: '--inventorydashcb_fore_color', val: '#222222'},  // text font color
{prop: '--inventorydashcb_border_color', val: '#ced4da'}, // border color of check box
{prop: '--inventorydashcb_background_color', val: '#fff'},  // checkbox background color
{prop: '--inventorydashcb_check_color', val: '#222222'}, // color of actual checkmark
{prop: '--inventorydashcb_toggle_bgcolor', val: '#008cba'}, // background on checking action, temporary state

/* control color properties */
{prop: '--disabled_control_background_color', val: '#e9ecef'},

/*Underlays*/
{prop: '--background_shade', val: '#dbdad8'},

/*Scrollbars*/
{prop: '--scrollbar_background', val: '#e3e2e2'},
{prop: '--scrollbar_button', val: '#C2C3C3'},
// {prop: '--scrollbar_thumb', val: '#b1b1b1'},
{prop: '--scrollbar_thumb', val: '#6b7682'},

{prop: '--scrollbar_arrow', val: '#8c8c8c'},
{prop: '--scrollbar_border', val: '#999999'},
{prop: '--scrollbar_arrow_hover', val: 'black'},
{prop: '--scrollbar_button_hover', val: '#c4c4c4'}

]


///////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////

const darkTheme = [
/*************************************************/
/*navbar colors*/
// 
{prop: '--navbar_background', val: '#3a3f44'},
{prop: '--navbar_font', val: '#e9ecef'},
{prop: '--navbar_border', val: ''},
{prop: '--navbar_search_button_background', val: '#e9ecef'},
{prop: '--navbar_search_button_expanded_background', val: '#e9ecef'},
{prop: '--navbar_search_button_border', val: '#282c2f'},
{prop: '--navbar_search_button_expanded_border', val: ''},
    //navbar icon colors
    {prop: '--navbar_search_button_icon', val: '#7a8288'},
    {prop: '--navbar_search_button_expanded_scanner_icon', val: '#e9ecef'},
    {prop: '--navbar_search_button_expanded_clear_search_icon', val: ''},
    {prop: '--navbar_question_setting_icon', val: '#7a8288'},
    {prop: '--navbar_home_logout_icon', val: '#7a8288'},
    {prop: '--navbar_menu_icon', val: 'white'},
    //navbar hover  colors
    {prop: '--navbar_question_setting_hover_background', val: '#e9ecef'},
    {prop: '--navbar_question_setting_hover_font', val: '#e9ecef'},
    {prop: '--navbar_question_setting_hover_border', val: '#e9ecef'},
    {prop: '--navbar_home_logout_icon_hover_background', val: ''},
    {prop: '--navbar_link_hover_background', val: '#282c2f'},
    {prop: '--navbar_link_hover_border', val: '#e9ecef'},
    {prop: '--navbar_menu_icon_hover', val: ''},
    //navbar active  colors
    {prop: '--navbar_question_setting_active_background', val: '#e9ecef'},
    {prop: '--navbar_question_setting_active_font', val: '#e9ecef'},
    {prop: '--navbar_question_setting_active_border', val: '#e9ecef'},
    {prop: '--navbar_home_logout_icon_active_background', val: ''},
    {prop: '--navbar_link_active_background', val: '#282c2f'},
    {prop: '--navbar_link_active_border', val: '#e9ecef'},
    {prop: '--navbar_menu_icon_active', val: ''},

/************************************************/
/*canvas & actionbar colors*/
{prop: '--canvas_background', val: '#272b30'},
{prop: '--canvas_background_blur', val: '#545353'},
//Selection color over canvas/ for highlighting
{prop: '--canvas_background_selection', val: 'rgba(255,255,255,.7)'},
{prop: '--canvas_font', val: 'silver'},
// {prop: '--actionbar_background', val: '#e9ecef'},
// {prop: '--actionbar_background', val: '#E1E1E1'},
{prop: '--actionbar_background', val: '#A0AAB2'},
{prop: '--actionbar_border', val: ''},
{prop: '--actionbar_font', val: '#272b30'},
{prop: '--actionbar_mobile_icon', val: '#272b30'},
{prop: '--actionbar_save_button_background', val: 'white'},
{prop: '--actionbar_save_button_font', val: '#006687'},
{prop: '--actionbar_save_button_border', val: '#006687'},
{prop: '--actionbar_cancel_button', val: '#3e444c'},
    //actionbar hover and active colors
    {prop: '--actionbar_button_hover_background', val: '#5bc0de'},
    {prop: '--actionbar_button_hover_font', val: '#4e5d6c'},
    {prop: '--actionbar_active_font', val: 'white'},
    {prop: '--actionbar_active_background', val: '#000'},
    {prop: '--actionbar_button_hover_border', val: '#4e5d6c'},
    {prop: '--actionbar_button_active_background', val: '#5bc0de'},
    {prop: '--actionbar_button_active_font', val: '#4e5d6c'},
    {prop: '--actionbar_button_active_border', val: '#4e5d6c'},
    {prop: '--actionbar_button_back_hover', val: 'white'},

//************************************************/
/*button colors*/
{prop: '--button_large_background', val: '#7a8288'},
{prop: '--button_large_font', val: '#000'},
{prop: '--button_large_icon', val: '#000'},
{prop: '--button_large_border', val: '#e9ecef'},
{prop: '--button_small_icon', val: '#A0AAB2'},
{prop: '--button_small_background', val: '#3e444c'},
{prop: '--button_small_font', val: '#CBCFD2'},
{prop: '--button_small_border', val: '#2b3e50'},
    //button hover colors
    {prop: '--button_large_hover_background', val: '#272b30'},
    {prop: '--button_large_hover_font', val: 'silver'},
    {prop: '--button_large_hover_border', val: 'silver'},
    {prop: '--button_large_hover_icon', val: 'silver'},
    {prop: '--button_small_hover_background', val: '#51575C'},
    {prop: '--button_small_hover_font', val: 'white'},
    {prop: '--button_small_hover_icon', val: '#7a8288'},
    {prop: '--button_small_hover_border', val: '#51575C'},
    //button active colors
    {prop: '--button_large_active_background', val: 'rgb(250, 155, 67)'},
    {prop: '--button_large_active_font', val: '#000'},
    {prop: '--button_large_active_borderr', val: 'silver'},
    {prop: '--button_large_active_icon', val: '#000'},
    {prop: '--button_small_active_background', val: '#51575C'},
    {prop: '--button_small_acitve_font', val: ''},
    {prop: '--button_small_active_icon', val: ''},
    {prop: '--button_small_active_border', val: ''},

/********************************************************************/
/*table colors*/
{prop: '--table_background', val: '#272b30'},
{prop: '--table_font', val: 'silver'},
{prop: '--table_border', val: '#32393E'},
{prop: '--table_column_header_background', val: '#50575E'},
{prop: '--table_column_header_font', val: '#D1D5D7'},
{prop: '--table_column_header_mobile_font', val: '#D1D5D7'},
{prop: '--table_column_header_border', val: 'black'},
{prop: '--table_selected_item_background', val: '#202328'},
{prop: '--table_selected_item_font', val: 'white'},
    //table hover colors
    {prop: '--table_select_dropdown_hover_background', val: ''},
    {prop: '--table_select_dropdown_hover_font', val: ''},
    {prop: '--table_select_dropdown_hover_border', val: ''},
    //table active colors
    {prop: '--table_select_dropdown_active_background', val: ''},
    {prop: '--table_select_dropdown_active_font', val: ''},
    {prop: '--table_select_dropdown_active_border', val: ''},

/*******************************************************/
/*field properties*/
{prop: '--field_background', val: '#25282D'},
{prop: '--field_label_font', val: '#e9ecef'},
{prop: '--field_border', val: 'rgb(100,100,100)'},
{prop: '--field_font', val: 'silver'},
{prop: '--field_select_dropdown_background', val: '#3e444c'},
{prop: '--field_select_dropdown_border', val: '#e9ecef'},
{prop: '--field_select_dropdown_font', val: '#e9ecef'},
    //field properties hover 
    {prop: '--field_select_hover_dropdown_background', val: ''},
    {prop: '--field_hover_background', val: '#e9ecef'},
    {prop: '--field_select_hover_dropdown_border', val: ''},
    {prop: '--field_select_hover_dropdown_font', val: ''},
     //field properties active 
     {prop: '--field_select_active_background', val: 'rgb(214,214,214)'},
    {prop: '--field_select_active_font', val: 'black'},
     {prop: '--field_select_active_border', val: 'rgb(228, 228, 228)'},

/*****************************************************/
/*tab colors*/
{prop: '--tab_background', val: '#3a3f44'},
{prop: '--tab_font', val: '#e9ecef'},
{prop: '--tab_border', val: 'white'},
{prop: '--tab_hover_background', val: '#3a3f44'},
{prop: '--tab_hover_font', val: 'black'},
{prop: '--tab_hover_border', val: '#50575E'},
{prop: '--tab_active_background', val: '--canvas_background'},
{prop: '--tab_active_font', val: 'white'},
{prop: '--tab_focus_border', val: 'white'},
{prop: '--search_nav_active_background', val: '#3D3F48'},
{prop: '--search_nav_hover_background', val: '#808493'},

/*****************************************************/
/*side drawer (mobile screens only*/
{prop: '--side_drawer_background', val: '#A0AAB2'},
{prop: '--side_drawer_font', val: '#272B30'},
{prop: '--side_drawer_border', val: 'grey'},
{prop: '--side_drawer_link_border', val: 'silver'},
{prop: '--side_drawer_hover_background', val: ''},
{prop: '--side_drawer_hover_font', val: ''},
{prop: '--side_drawer_hover_border', val: ''},
{prop: '--side_drawer_active_background', val: ''},
{prop: '--side_drawer_active_border', val: ''},
{prop: '--side_drawer_active_font', val: ''},

/*custom checkbox colors */
{prop: '--inventorydashcb_fore_color', val: '#e9ecef'},  // text font color
{prop: '--inventorydashcb_border_color', val: 'rgb(100,100,100)'}, // border color of check box
{prop: '--inventorydashcb_background_color', val: '#A7AEB4'},  // checkbox background color
{prop: '--inventorydashcb_check_color', val: 'black'}, // color of actual checkmark
{prop: '--inventorydashcb_toggle_bgcolor', val: 'rgb(100,100,100)'}, // background on checking action, temporary state


/* control color properties */
{prop: '--disabled_control_background_color', val: '#e9ecef'},

/*Underlays*/
{prop: '--background_shade', val: '#3d4044'},

/*Scrollbars*/
{prop: '--scrollbar_background', val: '#77797a'},
{prop: '--scrollbar_thumb', val: '#a1a1a1'},
{prop: '--scrollbar_button', val: '#C2C3C3'},
{prop: '--scrollbar_arrow', val: '#3d3d3e'}
]
///////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////



















const theme3 = [ //NOT CURRENTLY IN USE
/*************************************************/
/*navbar colors*/
/*navbar colors*/
{prop: '--navbar_background', val: '#008cba'},
{prop: '--navbar_font', val: '#e9ecef'},
{prop: '--navbar_border', val: ''},
{prop: '--navbar_search_button_background', val: '#dadada'},
{prop: '--navbar_search_button_expanded_background', val: '#e9ecef'},
{prop: '--navbar_search_button_border', val: '#282c2f'},
{prop: '--navbar_search_button_expanded_border', val: ''},
    //navbar icon colors
    {prop: '--navbar_search_button_icon', val: '#e9ecef'},
    {prop: '--navbar_search_button_expanded_scanner_icon', val: '#e9ecef'},
    {prop: '--navbar_search_button_expanded_clear_search_icon', val: ''},
    {prop: '--navbar_question_setting_icon', val: '#004961'},
    {prop: '--navbar_home_logout_icon', val: '#004961'},
    {prop: '--navbar_menu_icon', val: '#004961'},
    //navbar hover  colors
    {prop: '--navbar_question_setting_hover_background', val: '#e9ecef'},
    {prop: '--navbar_question_setting_hover_font', val: '#e9ecef'},
    {prop: '--navbar_question_setting_hover_border', val: '#e9ecef'},
    {prop: '--navbar_home_logout_icon_hover_background', val: ''},
    {prop: '--navbar_link_hover_background', val: '#282c2f'},
    {prop: '--navbar_link_hover_border', val: '#e9ecef'},
    {prop: '--navbar_menu_icon_hover', val: ''},
    //navbar active  colors
    {prop: '--navbar_question_setting_active_background', val: '#e9ecef'},
    {prop: '--navbar_question_setting_active_font', val: '#e9ecef'},
    {prop: '--navbar_question_setting_active_border', val: '#e9ecef'},
    {prop: '--navbar_home_logout_icon_active_background', val: ''},
    {prop: '--navbar_link_active_background', val: '#282c2f'},
    {prop: '--navbar_link_active_border', val: '#e9ecef'},
    {prop: '--navbar_menu_icon_active', val: ''},

/************************************************/
/*canvas & actionbar colors*/
{prop: '--canvas_background', val: '#fffff'},
{prop: '--canvas_background_blur', val: 'black'},
  
//Selection color over canvas/ for highlighting
{prop: '--canvas_background_selection', val: 'rgba(0,0,0,.1)'},
{prop: '--canvas_font', val: '#004961'},
// {prop: '--actionbar_background', val: '#5bc0de'},
{prop: '--actionbar_background', val: 'yellow'},
{prop: '--actionbar_border', val: '#4e5d6c'},
{prop: '--actionbar_font', val: '#004961'},
{prop: '--actionbar_border', val: '#4e5d6c'},
{prop: '--actionbar_save_button_background', val: 'white'},
{prop: '--actionbar_save_button_font', val: '#006687'},
{prop: '--actionbar_save_button_border', val: '#006687'},
{prop: '--actionbar_cancel_button', val: 'white'},
    //actionbar hover and active colors
    {prop: '--actionbar_button_hover_background', val: '#5bc0de'},
    {prop: '--actionbar_button_hover_font', val: '#4e5d6c'},
    {prop: '--actionbar_active_font', val: ''},
    {prop: '--actionbar_active_background', val: 'silver'},
    {prop: '--actionbar_button_hover_border', val: '#4e5d6c'},
    {prop: '--actionbar_button_active_background', val: '#5bc0de'},
    {prop: '--actionbar_button_active_font', val: '#4e5d6c'},
    {prop: '--actionbar_button_active_border', val: '#4e5d6c'},

//************************************************/
/*button colors*/
{prop: '--button_large_background', val: '#008cba'},
{prop: '--button_large_font', val: '#ffffff'},
{prop: '--button_large_icon', val: '#ffffff'},
{prop: '--button_large_border', val: '#0088b4'},
{prop: '--button_small_icon', val: ''},
{prop: '--button_small_background', val: '#eeeeee'},
{prop: '--button_small_font', val: ''},
{prop: '--button_small_border', val: '#2b3e50'},
    //button hover colors
    {prop: '--button_large_hover_background', val: 'rgb(250, 155, 67)'},
    {prop: '--button_large_hover_font', val: 'black'},
    {prop: '--button_large_hover_border', val: 'silver'},
    {prop: '--button_large_hover_icon', val: 'black'},
    {prop: '--button_small_hover_background', val: ''},
    {prop: '--button_small_hover_font', val: ''},
    {prop: '--button_small_hover_icon', val: ''},
    {prop: '--button_small_hover_border', val: ''},
    //button active colors
    {prop: '--button_large_active_background', val: 'rgb(250, 155, 67)'},
    {prop: '--button_large_active_font', val: 'black'},
    {prop: '--button_large_active_borderr', val: 'silver'},
    {prop: '--button_large_active_icon', val: 'black'},
    {prop: '--button_small_active_background', val: ''},
    {prop: '--button_small_acitve_font', val: ''},
    {prop: '--button_small_active_icon', val: ''},
    {prop: '--button_small_active_border', val: ''},

/********************************************************************/
/*table colors*/
{prop: '--table_background', val: 'white'},
{prop: '--table_font', val: '#004961'},
{prop: '--table_border', val: '#004961'},
{prop: '--table_column_header_background', val: ''},
{prop: '--table_column_header_font', val: ''},
{prop: '--table_column_header_border', val: ''},
{prop: '--table_selected_item_background', val: '#f3f0f5'},
{prop: '--table_selected_item_font', val: '#363087'},
    //table hover colors
    {prop: '--table_select_dropdown_hover_background', val: ''},
    {prop: '--table_select_dropdown_hover_font', val: ''},
    {prop: '--table_select_dropdown_hover_border', val: ''},
    //table active colors
    {prop: '--table_select_dropdown_active_background', val: ''},
    {prop: '--table_select_dropdown_active_font', val: ''},
    {prop: '--table_select_dropdown_active_border', val: ''},

/*******************************************************/
/*field properties*/
{prop: '--field_background', val: '--canvas_background'},
{prop: '--field_label_font', val: '#222222'},
{prop: '--field_border', val: 'pink'},
{prop: '--field_select_dropdown_background', val: ''},
{prop: '--field_select_dropdown_border', val: '#008cba'},
{prop: '--field_select_dropdown_font', val: '#008cba'},
    //field properties hover 
    {prop: '--field_select_hover_dropdown_background', val: ''},
    {prop: '--field_hover_background', val: ''},
    {prop: '--field_select_hover_dropdown_border', val: ''},
    {prop: '--field_select_hover_dropdown_font', val: ''},
     //field properties active 
     {prop: '--field_select_active_background', val: ''},
     {prop: '--field_select_active_font', val: ''},
     {prop: '--field_select_active_border', val: ''},

/*****************************************************/
/*tab colors*/
{prop: '--tab_background', val: '#dadada'},
{prop: '--tab_font', val: '#5d5c5d'},
{prop: '--tab_border', val: 'silver'},
{prop: '--tab_hover_background', val: '--canvas_background'},
{prop: '--tab_hover_font', val: 'black'},
{prop: '--tab_hover_border', val: 'none'},
{prop: '--tab_active_background', val: '--canvas_background'},
{prop: '--tab_active_font', val: 'black'},
{prop: '--tab_focus_border', val: 'none'},
{prop: '--search_nav_active_background', val: 'black'},
{prop: '--search_nav_hover_background', val: 'purple'},

/*****************************************************/
/*side drawer (mobile screens only*/
{prop: '--side_drawer_background', val: '#f0f0f0'},
{prop: '--side_drawer_border', val: '#4971b4'},
{prop: '--side_drawer_link_border', val: '#4971b4'},
{prop: '--side_drawer_font', val: '#033a6f'},
{prop: '--side_drawer_hover_background', val: ''},
{prop: '--side_drawer_hover_font', val: ''},
{prop: '--side_drawer_hover_border', val: ''},
{prop: '--side_drawer_active_background', val: ''},
{prop: '--side_drawer_active_border', val: ''},

{prop: '--side_drawer_active_font', val: ''},

/*****************************************************/
/*custom checkbox colors */
{prop: '--inventorydashcb_fore_color', val: '#222222'},  // text font color
{prop: '--inventorydashcb_border_color', val: '#ced4da'}, // border color of check box
{prop: '--inventorydashcb_background_color', val: '#fff'},  // checkbox background color
{prop: '--inventorydashcb_check_color', val: '#222222'}, // color of actual checkmark
{prop: '--inventorydashcb_toggle_bgcolor', val: '#008cba'}, // background on checking action, temporary state

]


export { lightTheme, darkTheme, theme3 };

