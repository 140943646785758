import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import QrReader from 'react-qr-reader'
import './qrscanner.css';

export default function QRScanReader({ setShowScanner }) {
    const [delay, setDelay] = useState(100);
    const [result, setResult] = useState('No Result');
    const [facingMode, setFacingMode] = useState('user');

    function handleScan(data) {
        if (data) {
            setResult(data);
        }
        // setResult(data);
    }

    function handleError(err) {
        console.error(err)
    }

    const previewStyle = {
        // height: 240,
        maxWidth: 400,
        margin: 'auto'
    }

    function switchFacingMode() {
        if (facingMode === 'environment') {
            setFacingMode('user');
        }
        else {
            setFacingMode('environment');
        }
    }

    return (
        <div className={'qr-page-container'}>
            <QrReader
                className={'qr-reader-container'}
                delay={delay}
                style={previewStyle}
                onError={handleError}
                onScan={handleScan}
                facingMode={facingMode}
            />
               <div><p><b>Result:</b> {result}</p>
            <p><b>Facing: </b> {facingMode}</p></div>
            <div>
         

            <Button onClick={() => setShowScanner(false)}>Cancel</Button>

            <Button onClick={switchFacingMode}>Switch Facing</Button>
            </div>
        </div>
    )
}