import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerMeasurementTypes,
  postCustomerMeasurementTypes,
} from "../../../store/actions/customerMeasurementTypesActions";

import {  Row} from "react-bootstrap";
import "./unitofMeasureComponent.css";
import { UnitOfMeasureSection } from "./unitofmeasure/UnitOfMeasureSection";
import { ActionBarAllThings } from "../../Layout/ActionBar";


function UnitofMeasureComponent({ setUnitofMeasureComponentVisible }) {
  const types = useSelector((state) => state.measurementtypes.items);
  const loading = useSelector((state) => state.measurementtypes.loading);
  const itemsLoaded = useSelector(
    (state) => state.measurementtypes.itemsLoaded
  );
  const error = useSelector((state) => state.measurementtypes.error);
  const [loadingItems, setLoadingItems] = useState(false);
  const dispatch = useDispatch();

  async function updateItem(item) {
    // Run this as async, fire and forget
    // if it errors, we will use a global alert message
    // and the page will show the error
    dispatch(postCustomerMeasurementTypes(item));
  }

  useEffect(() => {
    if (!loadingItems && !itemsLoaded) {
      trackPromise(dispatch(fetchCustomerMeasurementTypes()));
      setLoadingItems(true);
    }
  }, [loadingItems, itemsLoaded]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }


  //Measurement Types Enum
  // Length = 0,
  // Width = 1,
  // Height = 2,
  // Weight = 3,
  // Volume = 4,
  // Quantity = 5,
  // UnitCountType = 6

  let divs = [
    {
      header: "Units/Groups",
      measurementtype: 6,
    },
    {
      header: "Length",
      measurementtype: 0,
    },
    {
      header: "Width",
      measurementtype: 1,
    },
    {
      header: "Height",
      measurementtype: 2,
    },
    {
      header: "Weight",
      measurementtype: 3,
    },
    {
      header: "Volume",
      measurementtype: 4,
    },
  ];
  function saveThisPage() {
    // Do whatever save means here 


    // hide the page
    setUnitofMeasureComponentVisible(false);
  }

  return (
    <>

    <ActionBarAllThings
    pageTitle={'Unit of Measure'}
    showBackBtn
    cancelHandler = {() => setUnitofMeasureComponentVisible(false)}
    />
      <div className="page_container unitofmeasure_page_container">  
        <Row className="unit_of_measure_section" xs={1} sm={2} md={3} lg={6} xl={6}>
          {/* Swapped this out to just iterate through an array and build the components in a more dynamic fashion */}
          {/* There is an array declared above that can be added/changed to affect the displayed items */}
          {divs.map((sect) => (
            <UnitOfMeasureSection
              types={types.filter(
                (e) => e.baseMeasurementType === sect.measurementtype
              )}
              headerName={sect.header}
              className="check-divs"
              updateItem={updateItem}
            />
          ))}
        </Row>
      </div>     
    </>
  );
}

export { UnitofMeasureComponent };
