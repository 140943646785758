import { fetchService } from "../../services";

export const FETCH_CUSTOMERS_BEGIN = 'FETCH_CUSTOMERS_BEGIN';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export const POST_CUSTOMER_BEGIN = 'POST_CUSTOMER_BEGIN';
export const POST_CUSTOMER_SUCCESS = 'POST_CUSTOMER_SUCCESS';
export const POST_CUSTOMER_FAILURE = 'POST_CUSTOMER_FAILURE';

export const PUT_CUSTOMER_BEGIN = 'PUT_CUSTOMER_BEGIN';
export const PUT_CUSTOMER_SUCCESS = 'PUT_CUSTOMER_SUCCESS';
export const PUT_CUSTOMER_FAILURE = 'PUT_CUSTOMER_FAILURE';

export const UPDATE_CUSTOMERS= 'UPDATE_CUSTOMERS';

//#region FETCH

export const fetchCustomersBegin = () => ({
    type: FETCH_CUSTOMERS_BEGIN
});

export const fetchCustomersSuccess = customers => ({
    type: FETCH_CUSTOMERS_SUCCESS,
    payload: { customers }
});

export const fetchCustomersFailure = error => ({
    type: FETCH_CUSTOMERS_FAILURE,
    payload: { error }
});

export function fetchCustomers() {
    return dispatch => {
        dispatch(fetchCustomersBegin());

        return fetchService.get("/customers")
         .then(json => {
             dispatch(fetchCustomersSuccess(json));
             return json;
         })
         .catch(error => dispatch(fetchCustomersFailure(error)));
    };
};

//#endregion

//#region POST

export const postCustomerBegin = () => ({
    type: POST_CUSTOMER_BEGIN
});

export const postCustomerSuccess = customer => ({
    type: POST_CUSTOMER_SUCCESS,
    payload: { customer }
});

export const postCustomerFailure = error => ({
    type: POST_CUSTOMER_FAILURE,
    payload: { error }
});

export function postCustomer(customer) {
    return dispatch => {
        dispatch(postCustomerBegin());

        return fetchService.post("/customers", customer)
         .then(json => {
             dispatch(postCustomerSuccess(json));
             return json;
         })
         .catch(error => dispatch(postCustomerFailure(error)));
    }
};

//#endregion

//#region PUT

export const putCustomerBegin = () => ({
    type: PUT_CUSTOMER_BEGIN
  });
  
  export const putCustomerSuccess = customer => ({
    type: PUT_CUSTOMER_SUCCESS,
    payload: { customer }
  });
  
  export const putCustomerFailure = error => ({
    type: PUT_CUSTOMER_FAILURE,
    payload: { error }
  });
  
  export function putCustomer(customer, id) {
    return dispatch => {
      dispatch(putCustomerBegin());
      return fetchService.put(`/customers/${id}`, customer)
        .then(json => {
          dispatch(putCustomerSuccess(json));
          return json;
        })
        .catch(error => dispatch(putCustomerFailure(error)));
    }
  };

//#endregion

//#region UPDATE

export function updateCustomers(customer) {
    return dispatch => {
        dispatch(pushUpdateCustomers(customer));
    }
};

export const pushUpdateCustomers = customer => ({
    type: UPDATE_CUSTOMERS,
    payload: customer
})

//#endregion
