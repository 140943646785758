import { fetchService } from "../../../services";

export const FETCH_HELPITEM_BEGIN   = 'FETCH_HELPITEM_BEGIN';
export const FETCH_HELPITEM_SUCCESS = 'FETCH_HELPITEM_SUCCESS';
export const FETCH_HELPITEM_FAILURE = 'FETCH_HELPITEM_FAILURE';

//Fetch
//##########################################################################
export const fetchHelpItemBegin = () => ({
  type: FETCH_HELPITEM_BEGIN
});

export const fetchHelpItemSuccess = helpitem => ({
  type: FETCH_HELPITEM_SUCCESS,
  payload: { helpitem }
});

export const fetchHelpItemFailure = error => ({
  type: FETCH_HELPITEM_FAILURE,
  payload: { error }
});

export function fetchHelpItem(topic) {
    let helpRequest = {
        topic: topic
    }

    return dispatch => {
      dispatch(fetchHelpItemBegin());
    
      return fetchService.post('/help', helpRequest)
        .then(json => {
          dispatch(fetchHelpItemSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchHelpItemFailure(error)));
    };
  }
//End Fetch
//##########################################################################