import React, { useEffect, useRef, useState } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
// import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import InventoryDashPhoneInput from '../../../Controls/InventoryDashPhoneInput';
import TextInput from '../../../Controls/textInput';
import '../addeditcontactpage.css';
import 'react-phone-number-input/style.css'
// import ContactList from '../../../ContactList';
// import InventoryDashPhoneInput from '../../../Controls/InventoryDashPhoneInput';


export default function ContactInfo({ contact, onContactValueChanged, contactPhoneChanged }) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCountry, setPhoneCountry] = useState('US');
    const custNameRef = useRef(null);

    useEffect(() => {
        if (contact != null){
         
                setPhoneNumber(getPhoneNumberString());
                setPhoneProperties();
                // setPhoneCountry()
        }

    }, [contact])


    useEffect(() => {
        custNameRef.current.focus();
    }, [])

    function setPhoneProperties() {
        let stringPhone = getPhoneNumberString();
        if (typeof stringPhone === 'string') {
        // setPhoneNumber(parsePhoneNumber(stringPhone));
        // setPhoneCountry(parsePhoneNumber(stringPhone).country);
        }
    }

    function getPhoneNumberString() {
        if (contact == null)
        return '';
        if (contact.phoneNumber == null)
        return '';
        return contact.phoneNumber.toString();
    }

    function getParsedPhoneString() {
        let stringPhone =  getPhoneNumberString();
        if (typeof stringPhone === 'string') {
            // this is a string
            let phone = parsePhoneNumber(stringPhone);
            return stringPhone;
        }
        return '';
    }



    function modifyContact(e) {
        contact.phoneNumber = e;
        // setPhone(e);
        contactPhoneChanged('phoneNumber', e);
    }

    function countryChanged(e) {
        setPhoneCountry(e);
    }

    const labels = {
        "phone": "Phone"
    }

    return (
        <div className="cust-form-container noselect">
            <TextInput
                name='displayName'
                label='Name'
                value={contact?.displayName}
                onChange={onContactValueChanged}
                required={true}
                validatefailtext="Please enter a name."
                inputref={custNameRef}
            />

            <TextInput
                name='emailAddress'
                label='Email'
                value={contact?.emailAddress}
                onChange={onContactValueChanged}
                // required={true}
                isEmail={true}
            />



            {/* <InventoryDashPhoneInput></InventoryDashPhoneInput> */}

            <TextInput
                name='phoneNumber'
                label='Phone'
                value={contact?.phoneNumber}
                onChange={onContactValueChanged}
                // required={true}
                isPhone={true}
                // type={tel}
                pattern={"^\d{10}$|^(\(\d{3}\)\s*)?\d{3}[\s-]?\d{4}$"}
            />     

            {/* <PhoneInput
                international={true}
                defaultCountry="US"
                value={phoneNumber}
                country={phoneCountry}
                // onChange={onContactValueChanged}
                onChange={(e) => modifyContact(e)}
                onCountryChange={countryChanged}

                //onChange={setPhone}
                labels={labels}
                countryCallingCodeEditable={false}

            /> */}

            {/* <InventoryDashPhoneInput 
                contact={contact}
                onContactValueChanged={onContactValueChanged}
                /> */}



        </div>
    );

}