import React, { Component, useState, useEffect } from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { fetchSalesOrders } from "../../../store/actions/salesOrderActions";
import { useSelector, useDispatch } from "react-redux";
import InventoryDashCheckbox from "../../Controls/InventoryDashCheckbox";

import { alertService } from "../../../services";
import { CustomerSelector } from "../itemselectors";
import WindowedTable from "../../Controls/Tables/WindowedTable";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import "./returns.css";
import { SearchBar } from "../../Toolbar/searchbox/SearchBar";
import FinalizeReturn from "./FinalizeReturns";
import { fetchReturn } from "../../../store/actions/returnsActions";

export default function AddEditReturn({
  modalController,
  completeReturn,
  isEditing,
  showModal,
  setShowModal,
  currentReturnObject,
}) {
  const existingReturn = useSelector((state) => state.returns.singleReturn);
  const returnLoading = useSelector((state) => state.returns.singleLoading);
  const returnLoaded = useSelector((state) => state.returns.singleLoaded);

  const [salesOrderBySelectedCustomer, setSalesOrderBySelectedCustomer] =
    useState([]);
  const [customerSelected, setCustomerSelected] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const salesOrders = useSelector((state) => state.salesorders.salesorders);
  const loading = useSelector((state) => state.salesorders.loading);
  const salesOrdersLoaded = useSelector(
    (state) => state.salesorders.salesordersLoaded
  );
  const dispatch = useDispatch();
  const [currentSalesOrder, setCurrentSalesOrder] = useState(null);
  const [currentSalesOrderId, setCurrentSalesOrderId] = useState(0);
  const customers = useSelector((state) => state.customers?.customers);
  const [masterChecked, setMasterChecked] = useState(false);
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;
  const isMobileWidth = windowWidth <= 576;
  const isMobileScreen = windowWidth <= 576 || windowHeight <= 576;

  const [returnFee, setReturnFee] = useState(0);
  const [returnItemsSelected, setReturnItemsSelected] = useState([]);
  const [returnQtys, setReturnQtys] = useState({});

  const nextIcon = (
    <>
      <AiOutlineDoubleRight size={"2em"} className='nextIcon'/>
    </>
  );
  const { error, salesorder } = useSelector((state) => state.salesorder);
  const handleMasterCheckbox = (e) => {
    if (e.target.checked) {
      setMasterChecked(true);
      const tempReturnItems = salesorder.salesOrderItems.map((item) => {
        item.returnQty = item.quantity;
        return item;
      });
      setReturnItemsSelected(tempReturnItems);
      const tempReturn = {};
      salesorder.salesOrderItems.forEach((item) => {
        tempReturn[item.id] = item.quantity;
      });
      setReturnQtys(tempReturn);
    } else {
      setMasterChecked(false);
      setReturnItemsSelected([]);
      setReturnQtys({});
    }
  };

  useEffect(() => {
    if (!loading && !salesOrdersLoaded) {
      trackPromise(dispatch(fetchSalesOrders()));
    }
  }, [loading, salesOrdersLoaded, salesOrders, customerChanged]);

  useEffect(() => {
  

    if (!salesOrdersLoaded) {
      return;
    }

    if (existingReturn == null) {
      return;
    }

    var currentSalesOrder = salesOrders.find(
      (x) => x.id == existingReturn.salesOrderId
    );
    console.log(existingReturn);
    console.log(currentSalesOrder);

    customerChanged(existingReturn.customerId);
    setCurrSalesOrder(currentSalesOrder);
    setCurrentSalesOrderId(currentSalesOrder.id);
    setReturnFee(existingReturn.returnFee);

    // // return;
    // var selectedItems = [];
    // var quantities = {};
    // existingReturn.returnItems.map(x => {

    //   if (x.salesOrderItemId != null) {
    //     var item = currentSalesOrder.salesOrderItems.find(i => i.id == x.salesOrderItemId);
    //     selectedItems.concat(item);
    //     quantities[item.id] = x.quantity;
    //   }

    //   console.log(x);

    // });

    // return
    // setReturnItemsSelected(selectedItems);
    // setReturnQtys(quantities);
  }, [existingReturn, salesOrders]);

  useEffect(() => {
    if (currentReturnObject.id > 0) {
      trackPromise(dispatch(fetchReturn(currentReturnObject.id)));
    }
  }, [currentReturnObject]);

  function customerChanged(customerid, selectedSalesOrderId) {
    if (customerid) {
      setCustomerSelected(true);
    }
    if (!customerid) {
      setCustomerSelected(false);
      setSelectedCustomerId(0);
    }
    setSelectedCustomerId(customerid);
    setCurrSalesOrder(false);
    setSalesOrderBySelectedCustomer(
      salesOrders.filter(
        (salesOrderCustomer) => salesOrderCustomer.customerId === customerid
      )
    );
    return salesOrderBySelectedCustomer;
  }

  function setCurrSalesOrder(select) {
    setCurrentSalesOrderId(select.id);
    setCurrentSalesOrder(select);
  }

  function backButton() {
    if (showModal === 3) {
      setShowModal(2);
    } else if (showModal === 2) {
      setShowModal(1);
    } else if (showModal === 1) {
      //TODO need to reset the customer in the dropdown here
      setShowModal(0);
    } else setShowModal(0);
  }

  function modalController() {
    if (showModal <= 1) {
      if (showModal === 1) {
        setShowModal(2);
      }
    } else if (showModal === 2 && customerSelected) {
      setShowModal(3);
    }
  }

  function calculateQty() {
    return returnItemsSelected.reduce((total, val) => {
      return total + val.returnQty;
    }, 0);
  }

  function calculateSubTotal() {
    return returnItemsSelected.reduce((total, val) => {
      return total + val.returnQty * val.purchasePrice;
    }, 0);
  }

  function calculateTotal() {
    return calculateSubTotal() - returnFee;
  }

  function commitReturn() {
    var returnObjData = {
      returnId: 0,
      customerId: selectedCustomerId,
      subtotal: calculateSubTotal,
      returnFee: returnFee,
      total: calculateTotal(),
      returnDate: "2022-07-17T18:15:40.9744287+00:00",
      returnItems: [],
      salesOrderId: currentSalesOrderId,
      id: 0,
    };

    returnItemsSelected.map((x) => {
      returnObjData.returnItems.push({
        salesOrderItemId: x.id,
        returnQty: x.returnQty,
        price: x.purchasePrice,
        refundAmount: x.calculatedTotal,
        deleted: false,
      });
    });

    completeReturn(returnObjData);
  }

  const colHeaders = [
    {
      displayName: "SO Number",
      propName: "displayName",
      colclassname: "",
      selected: true,
      displayorder: 0,
    },
    {
      displayName: "Sale Date",
      propName: "saleDate",
      colclassname: "center-align",
      dataclassname: "center-align",
      selected: true,
      displayorder: 1,
      isdate: true,
    },
    {
      displayName: "Status",
      propName: "calculatedStatus",
      colclassname: "",
      selected: true,
      displayorder: 2,
    },
  ];

  //needed to add function that displays the header differently depending on state - this allows the table header to remain at the top and only scroll the table rows
  function returnHeader() {
    const actionBar = ( <ActionBarAllThings
      showSaveBtn={currentSalesOrderId ? true : false}
      saveBtnText={
        showModal === 2 ? "Complete" : showModal === 1 ? "Create" : ""
      }
      saveHandler={
        showModal === 2 ? () => commitReturn() : () => modalController()
      }
      pageTitle={
        showModal === 2
          ? "Manage Return"
          : isEditing
          ? "Update Return"
          : "New Return"
      }
      showBackBtn={true}
      onModal={true}
      cancelHandler={backButton}
      showEmailBtn={showModal < 2 ? false : true}
      showPrintBtn={showModal < 2 ? false : true}
      overRideIcon={nextIcon}
    />)
    if (showModal === 2) {
      return (
        <Modal.Header
          bsPrefix={"inventorydash_custom_modal_header"}
          closeButton
        >
        {actionBar}
          <span className="m-2">Select and adjust items/qty to be returned</span>
          <Row className="ReturnHeader returnItemRow">
            <Col className="masterCheckbox">
              <InventoryDashCheckbox
                checked={masterChecked}
                onChange={handleMasterCheckbox}
                className="masterCheckbox"
              />
            </Col>
            <Col className="headerCol">Item</Col>
            <Col className="headerCol">{isMobileWidth ? "Qty" : "Total Qty"}</Col>
            <Col className="headerCol">{isMobileWidth ? "$/Qty" : "Price/Qty"}</Col>
            <Col className="headerCol">{isMobileScreen ? "Return" : "Return Qty"}</Col>
            <Col className="headerCol">Refund</Col>
          </Row>
        </Modal.Header>
      );
    } else
      return (
        <Modal.Header
          bsPrefix={"inventorydash_custom_modal_header"}
          closeButton
        >
         {actionBar}
        </Modal.Header>
      );
  }

  return (
    <>
      <Modal
        show={showModal > 0 ? true : false}
        onHide={() => setShowModal(0)}
        dialogClassName={"inventorydash_custom_modal_dialog"}
      >
        {returnHeader()}
        {showModal === 1 ? (
          <Modal.Body bsPrefix={"inventorydash_custom_modal_body return_modal"}>
            <Row className="return_topRow">
              <CustomerSelector
                valueChanged={customerChanged}
                // customerLabel={customerFilterLabel}
              />
            </Row>
            <div className="returnModalBody">
              {salesOrders < 1 ? (
                <p className="padding">
                  You must have Sales Orders created to process a Return
                </p>
              ) : !customerSelected ||
                salesOrderBySelectedCustomer.length > 1 ? (
                <WindowedTable
                  colheaders={colHeaders}
                  inputitems={
                    customerSelected
                      ? salesOrderBySelectedCustomer
                      : salesOrders
                  }
                  idname={"id"}
                  tablenameidentifier={""}
                  searchText={""}
                  setSelectedId={setCurrSalesOrder}
                  selectedId={currentSalesOrderId}
                  defaultSort={"saleDate"}
                />
              ) : (
                <p className="padding">
                  This Customer does have any Sales Orders
                </p>
              )}
            </div>
            {/* {showModal === 2 ? <p>YIPPP</p> : <></>} */}
          </Modal.Body>
        ) : showModal === 2 ? (
          <FinalizeReturn
            selectedSalesOrder={currentSalesOrder}
            selectCurrentItem={setCurrSalesOrder}
            returnFee={returnFee}
            setReturnFee={setReturnFee}
            returnItemsSelected={returnItemsSelected}
            setReturnItemsSelected={setReturnItemsSelected}
            calculateQty={calculateQty}
            calculateSubTotal={calculateSubTotal}
            calculateTotal={calculateTotal}
            returnQtys={returnQtys}
            setReturnQtys={setReturnQtys}
            existingReturn={existingReturn}
            setMasterChecked={setMasterChecked}
            salesorder={salesorder}
            loading={loading}
          />
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
}
