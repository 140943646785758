import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import TextInput from "./Controls/textInput";

export default function Address({
  header,
  visible,
  address,
  onInputValueChanged,
}) {
  if (!visible) {
    return "";
  }

  return (
    <Card border="primary">
      <Card.Header>{header}</Card.Header>
      <Card.Body>
        {/* <Card.Title>
                    Title
                </Card.Title> */}
        <Card.Text>
          <TextInput
            name="address1"
            label="Address 1"
            value={address?.address1}
            onChange={onInputValueChanged}
            required={true}
            validatefailtext="Please enter an address."
          />
          <TextInput
            name="address2"
            label="Address 2"
            value={address?.address2}
            onChange={onInputValueChanged}
          />

          <Row className="city_state_zip">
            <Col className="city">
              <TextInput
                name="city"
                label="City"
                value={address?.city}
                onChange={onInputValueChanged}
                required={true}
                validatefailtext="Please enter a city."
              />
            </Col>

            <Col className="state">
              <TextInput
                name="state"
                label="State"
                value={address?.state}
                onChange={onInputValueChanged}
                required={true}
                validatefailtext="Please enter a state."
              />
            </Col>

            <Col className="zip">
              <TextInput
                name="zip"
                label="Zip"
                value={address?.zip}
                onChange={onInputValueChanged}
                required={true}
                validatefailtext="Please enter a postal code."
              />
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
