import {
    FETCH_ITEMGROUPS_BEGIN,
    FETCH_ITEMGROUPS_SUCCESS,
    FETCH_ITEMGROUPS_FAILURE,
    UPDATE_ITEMGROUPS,
    DELETED_ITEMGROUP
  } from '../actions/itemGroupActions';
  
  const initialState = {
    itemgroups: [],
    loading: false,
    error: null,
    itemgroupsLoaded: false,
    itemPosting: false
  };
  
  export default function itemGroupReducer(state = initialState, action) {
    let existingGroup;
    let index;

    switch(action.type) {
      case FETCH_ITEMGROUPS_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          itemgroupsLoaded: false
        };
  
      case FETCH_ITEMGROUPS_SUCCESS:
        // All done: set loading "false".
        // Also, replace the itemgroups with the ones from the server
        return {
          ...state,
          loading: false,
          itemgroups: action.payload.itemgroups,
          itemgroupsLoaded: true
        };
  
      case FETCH_ITEMGROUPS_FAILURE:
          
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have itemgroups to display anymore, so set `itemgroups` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the itemgroups around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          itemgroups: []
        };

      case UPDATE_ITEMGROUPS:

        // We have a new or updated Group added in the software
        // Need to update the master underlying list to account for it
        existingGroup = state.itemgroups.find(e => e.id === action.payload.id);
        index = state.itemgroups.indexOf(existingGroup);

        if (index < 0) {
          // Add the new Group to the end of the list
          return {
            ...state,
            itemgroups: [...state.itemgroups.concat(action.payload)]
          };
        } else {
          // We need to slice the array and insert the updated Group
          // This keeps everything clean as far as the rules of immutable state go

          return {
            ...state,
            itemgroups: [
              ...state.itemgroups.slice(0,index)
              .concat(action.payload)
              .concat(state.itemgroups.slice(index+1))
            ]
          };
        }
        case DELETED_ITEMGROUP:
          // We have a new or updated Group added in the software
          // Need to update the master underlying list to account for it
          existingGroup = state.itemgroups.find(e => e.id === action.payload);
          index = state.itemgroups.indexOf(existingGroup);
          if (index > -1) {
            // We need to slice the array and remove the affected group
            // This keeps everything clean as far as the rules of immutable state go
            return {
              ...state,
              itemgroups: [
                ...state.itemgroups.slice(0,index)
                .concat(state.itemgroups.slice(index+1))
              ]
            };
          }
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }