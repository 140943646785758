import React, { useEffect, useState, useCallback } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSalesOrders } from '../../../store/actions/salesOrderActions';
import { useHistory } from "react-router-dom";
import WindowedTable from '../../Controls/Tables/WindowedTable';
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { debounce, throttle } from "lodash";
import AddEditSalesOrder from "./AddEditSalesOrder";
import "./salesorders.css";



function Sales({location}) {

  const colHeaders = [
    {
      displayName: "SO Number",
      propName: "displayName",
      colclassname: "",
      selected: true,
      displayorder: 0
    },
    {
      displayName: "Date",
      propName: "saleDate",
      colclassname: "center-align",
      dataclassname: 'center-align',
      selected: true,
      displayorder: 1,
      isdate: true
    },
    {
      displayName: "Processing",
      propName: "userStatus",
      colclassname: "",
      selected: true,
      displayorder: 2
    },
    {
      displayName: "Status",
      propName: "calculatedStatus",
      colclassname: "",
      selected: true,
      displayorder: 2
    },
  ]

  const salesOrders = useSelector((state) => state.salesorders.salesorders);
  const loading = useSelector((state) => state.salesorders.loading);
  const error = useSelector((state) => state.salesorders.error);
  const salesOrdersLoaded = useSelector(
    (state) => state.salesorders.salesordersLoaded
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentSalesOrder, setCurrentSalesOrder] = useState(null);
  const [currentSalesOrderId, setCurrentSalesOrderId] = useState(0);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [columnsList, setColumnsList] = useState(determineColumns());
  
  useEffect(() => {
    if (!loading && !salesOrdersLoaded) {
      fetchData();
      trackPromise(dispatch(fetchSalesOrders()));
    }
  }, [loading, salesOrdersLoaded, salesOrders, dispatch]);

    const fetchData = useCallback(
    debounce((q) => originalFetchData(), 200),
    []
  );

  function determineColumns() {
    if (!localStorage.getItem('item_salesorders')) {
      return colHeaders
    } else {
      return JSON.parse(localStorage.getItem('item_salesorders'));
    }
  }

  function setCurrSalesOrder(so) {
    setCurrentSalesOrderId(so.id);
    setCurrentSalesOrder(so);
    setShowEditBtn(true);
  }

  function originalFetchData() {
    trackPromise(dispatch(fetchSalesOrders()));
  }

  function editSalesOrder() {
    if (currentSalesOrder != null) {
      setShowModal(true);
      setIsEditing(true);
    }
  }
  function addSalesOrder() {
    setCurrentSalesOrderId(-1);
    setCurrentSalesOrder(null);
    setShowModal(true);
    setIsEditing(false);
  }


  let salesorderId = undefined;

    if (location != null) {
    if (location.state != null) {
      if (location.state.salesorderId != null) {
        salesorderId = location.state.salesorderId;
      }
    }
  }

  //TODO below code will need to be completed after backend is done 6/4/26 BS
   if (salesorderId != null) {
        // Set the current Item and show the display Modal
        // this is for a call that is coming from other pages
        console.log('salesorder');
        
        if (salesorderId !== 0) {
          if (currentSalesOrderId !== salesorderId) {
            setCurrentSalesOrderId(salesorderId);
            // setEditingCustomer(true);
            setShowModal(true);
            // history.replace(location.pathName, []);
            salesorderId = undefined;
          }
        }
      }


  if (error != null) {
    return (
      <div>{'Error: ' + error.message}</div>
    )
  }




  function convertStatus(input) {
    if (input === 1) {
      return "Closed";
    }
    return "Open";
  }

  function showColumnModal() {
    setShowColumnSelector(true);
  }


  if (error != null) {
    return <div>{"Error: " + error.message}</div>;
  }

  for (let i=0; i> colHeaders.length; i++) {
    if (colHeaders[i].hasFunction) {
        colHeaders[i].dataFunction = convertStatus();
    }
  }


  return (
    <>
      {showModal ? (
        <AddEditSalesOrder
          salesorderid={currentSalesOrderId}
          showModal={showModal}
          setShowModal={setShowModal}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      ) : (
        <></>
      )}
      <ActionBarAllThings
        pageTitle={"Sales"}
        showAddBtn={true}
        showEditBtn={showEditBtn}
        addItem={addSalesOrder}
        editItem={editSalesOrder}
        showColBtn
        adjustColumns={showColumnModal}
      />
      <div className="page_container">
        {!salesOrdersLoaded ? (
          <></>
        ) : (
          <WindowedTable
            colheaders={columnsList}
            inputitems={salesOrders}
            currentSelectedItem={currentSalesOrder}
            selectCurrentItem={setCurrSalesOrder}
            idname={"id"}
            tablenameidentifier={"item_salesorders"}
            searchText={""}
            setSelectedId={setCurrSalesOrder}
            selectedId={currentSalesOrderId}
            defaultSort={"displayName"}
            setShowColumnSelector={setShowColumnSelector}
            showColumnSelector={showColumnSelector}
            setColumnsList={setColumnsList}
          />
        )}
      </div>
    </>

);



}



export { Sales };
