import { combineReducers } from "redux";
import items from "./itemReducer";
import drawerstate from "./drawerStateReducer";
import dropmenustate from "./dropmenureducer";
import { reducer as formReducer } from 'redux-form';
import itemGroups from './itemGroupReducer';
import itemTypes from './itemTypeReducer';
import itemLocations from './itemLocationReducer';
import vendors from './vendorReducer';
import purchaseorders from './purchaseOrderReducer';
import purchaseorder from './singlePurchaseOrderReducer';
import measurementtypes from './customerMeasurementTypesReducer';
import displaytableselecteditem from './displayTableSelectedItemReducer';
import customers from './customerReducer';
import customer from './singleCustomerReducer';
import uiState from './uiReducer';
import users from './usersReducer';
import roles from './roleReducer';
import user from './userReducer';
import userroles from './userRolesReducer';
import userrole from './userRoleReducer';
import loggedinuserroles from './loggedInUserRolesReducer';
import userInteractions from './userInteractionsReducer';
import location from './locationReducer';
import singleContact from './singleContactReducer';
import vendor from './singleVendorReducer';
import help from './help/helpReducer';
import search from './search/searchReducer';
import salesorders from './salesOrderReducer';
import salesorder from './singleSalesOrderReducer';
import returns from './returnsReducer';
import itemtags from './itemTagReducer';

export default combineReducers({
  items,
  drawerstate,
  dropmenustate,
  itemGroups,
  itemTypes,
  itemLocations,
  vendors,
  purchaseorders,
  purchaseorder,
  measurementtypes,
  displaytableselecteditem,
  customers,
  uiState,
  users,
  customer,
  roles,
  user,
  userroles,
  userrole,
  loggedinuserroles,
  userInteractions,
  location,
  singleContact,
  vendor,
  help,
  search,
  salesorders,
  salesorder,
  returns,
  itemtags,
  form: formReducer
});

