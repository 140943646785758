import {
    FETCH_SALESORDERS_BEGIN,
    FETCH_SALESORDERS_SUCCESS,
    FETCH_SALESORDERS_FAILURE
  } from '../actions/salesOrderActions';
  
//   import {
//     UPDATE_SINGLE_SALESORDER
//   } from '../actions/singleSalesOrderActions';

  const initialState = {
    salesorders: [],
    loading: false,
    error: null,
    salesordersLoaded: false,
    itemPosting: false
  };
  
  export default function salesOrderReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_SALESORDERS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null,
          salesordersLoaded: false
        };
      case FETCH_SALESORDERS_SUCCESS:


        return {
          ...state,
          loading: false,
          salesorders: action.payload.salesorders,
          salesordersLoaded: true
        };
      case FETCH_SALESORDERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          salesorders: []
        };

    //   case UPDATE_SINGLE_SALESORDER:
    //         let existingitem = state.salesorders.find(e => e.id === action.payload.id);
    //         let index = state.salesorders.indexOf(existingitem);

    //         //See if it already exists, if not add it to the list
    //         if (index < 0)
    //         {
    //           //If we are here we are going to add to the end of the list
    //           return {
    //             ...state,
    //             salesorders: [...state.salesorders.concat(action.payload)]
    //           }
    //         }
    //         else
    //         {
    //             //If we are here we are going to slice the array and insert the updated item 
    //             //This keeps everything clean as far as rules of immutable go
    //             return { 
    //               ...state, 
    //               salesorders: [
    //                 ...state.salesorders.slice(0,index)
    //                 .concat(action.payload)
    //                 .concat(state.salesorders.slice(index+1))
    //                 ]
    //               }
    //         }
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }