import { fetchService } from "../../services";

export const FETCH_PURCHASEORDERS_BEGIN   = 'FETCH_PURCHASEORDERS_BEGIN';
export const FETCH_PURCHASEORDERS_SUCCESS = 'FETCH_PURCHASEORDERS_SUCCESS';
export const FETCH_PURCHASEORDERS_FAILURE = 'FETCH_PURCHASEORDERS_FAILURE';

//Fetch
//##########################################################################
export const fetchPurchaseOrdersBegin = () => ({
  type: FETCH_PURCHASEORDERS_BEGIN
});

export const fetchPurchaseOrdersSuccess = purchaseorders => ({
  type: FETCH_PURCHASEORDERS_SUCCESS,
  payload: { purchaseorders }
});

export const fetchPurchaseOrdersFailure = error => ({
  type: FETCH_PURCHASEORDERS_FAILURE,
  payload: { error }
});

export function fetchPurchaseOrders() {
    return dispatch => {
      dispatch(fetchPurchaseOrdersBegin());
      return fetchService.get("/purchaseorder")
        .then(json => {
          dispatch(fetchPurchaseOrdersSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchPurchaseOrdersFailure(error)));
    };
  }
//End Fetch
//##########################################################################