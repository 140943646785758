import React, { useState, useRef, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import TextInput from '../../../Controls/textInput';

function ItemGroupInfo({
    itemGroup,
    onInputValueChanged,
    keyPressed,
    cancelHandler, 
    saveHandler, 
    isSubmitting, 
    isEditing}) {

    const nameRef = useRef(null);    

    useEffect(() => { //focuses input field upon load
        nameRef.current.focus();
    },[])

    return (
        <div className='item-form-container' >
            <Form>
                <TextInput
                    name='displayName'
                    label='Name'
                    required={true}
                    validatefailtext={"You must enter a group name."}
                    value={itemGroup.displayName}
                    onChange={onInputValueChanged}
                    // onKeyPress={keyPressed}
                    inputref = {nameRef}
                />
            </Form>
        </div>
    )
};

export { ItemGroupInfo };