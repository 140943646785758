import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ActionBarAllThings } from "../Layout/ActionBar";

import { accountService, alertService } from "../../services";

function ForgotPassword() {
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  function onSubmit({ email }, { setSubmitting }) {
    alertService.clear();
    accountService
      .forgotPassword(email)
      .then(() =>
        alertService.success(
          "Please check your email for password reset instructions"
        )
      )
      .catch((error) => alertService.error(error))
      .finally(() => setSubmitting(false));
  }

  return (
    <>
      <ActionBarAllThings pageTitle={"Forgot Password"} showFooter={false}/>

      <div className="page_container_padding top_padding">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="card-body">
                <div className="form-group forgot_password_form">
                  <label className="forgot_password_label noselect">Email</label>
                  <Field
                    name="email"
                    type="text"
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col forgot_password">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                    <Link to="login" className="btn btn-link">
                     <span className="noselect">Cancel</span>
                    </Link>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export { ForgotPassword };
