import { fetchService } from '../../services';

export const FETCH_CUSTOMERMEASUREMENTTYPES_BEGIN   = 'FETCH_CUSTOMERMEASUREMENTTYPES_BEGIN';
export const FETCH_CUSTOMERMEASUREMENTTYPES_SUCCESS = 'FETCH_CUSTOMERMEASUREMENTTYPES_SUCCESS';
export const FETCH_CUSTOMERMEASUREMENTTYPES_FAILURE = 'FETCH_CUSTOMERMEASUREMENTTYPES_FAILURE';


export const POST_CUSTOMERMEASUREMENTTYPES_BEGIN   = 'POST_CUSTOMERMEASUREMENTTYPES_BEGIN';
export const POST_CUSTOMERMEASUREMENTTYPES_SUCCESS = 'POST_CUSTOMERMEASUREMENTTYPES_SUCCESS';
export const POST_CUSTOMERMEASUREMENTTYPES_FAILURE = 'POST_CUSTOMERMEASUREMENTTYPES_FAILURE';

//Fetch
//##########################################################################
export const fetchCustomerMeasurementTypesBegin = () => ({
  type: FETCH_CUSTOMERMEASUREMENTTYPES_BEGIN
});

export const fetchCustomerMeasurementTypesSuccess = measurementTypes => ({
  type: FETCH_CUSTOMERMEASUREMENTTYPES_SUCCESS,
  payload: { measurementTypes }
});

export const fetchCustomerMeasurementTypesFailure = error => ({
  type: FETCH_CUSTOMERMEASUREMENTTYPES_FAILURE,
  payload: { error }
});

export function fetchCustomerMeasurementTypes() {
    
    return dispatch => {
      dispatch(fetchCustomerMeasurementTypesBegin());
      return fetchService.get("/customermeasurementtypes/GetAllTypesForAssignment")
        .then(json => {
          dispatch(fetchCustomerMeasurementTypesSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchCustomerMeasurementTypesFailure(error)));
    };
  }
//End Fetch
//##########################################################################



//Post
//##########################################################################
export const postCustomerMeasurementTypesBegin = () => ({
    type: POST_CUSTOMERMEASUREMENTTYPES_BEGIN
  });
  
  export const postCustomerMeasurementTypesSuccess = measurementTypes => ({
    type: POST_CUSTOMERMEASUREMENTTYPES_SUCCESS,
    payload: { measurementTypes }
  });
  
  export const postCustomerMeasurementTypesFailure = error => ({
    type: POST_CUSTOMERMEASUREMENTTYPES_FAILURE,
    payload: { error }
  });
  
  export function postCustomerMeasurementTypes(unitofmeasureitem) {
      return dispatch => {
        dispatch(postCustomerMeasurementTypesBegin());
        return fetchService.post("/customermeasurementtypes", unitofmeasureitem)
          .then(json => {
            dispatch(postCustomerMeasurementTypesSuccess(json));
            return json;
          })
          .catch(error => dispatch(postCustomerMeasurementTypesFailure(error)));
      };
    }
  //End Post
  //##########################################################################