import { CHANGE_DRAWER_STATE, CHANGE_DROP_MENU_STATE } from '../../constants/action-types';


export function changeDrawerState(payload) {
    return { type: CHANGE_DRAWER_STATE, payload }
};

export function changeDropMenuState(payload) {
    return { type: CHANGE_DROP_MENU_STATE, payload }
};
