export const defaultsService = {
    getDefaultCustomer,
    getDefaultVendor,
    getDefaultAddress
};

function getDefaultCustomer() {
    let customer = {
        id: 0,
        displayName: "",
        billingAddress: getDefaultAddress(),
        shippingSameAsBilling: true,
        shippingAddress: getDefaultAddress(),
        contacts: []
    };

    return customer;
}

function getDefaultVendor() {
    let vendor = {
        id: 0,
        displayName: '',
        billingAddress: getDefaultAddress(),
        shippingSameAsBilling: true,
        shippingAddress: getDefaultAddress(),
        contacts: []
    }
  
    return vendor;
};

function getDefaultAddress() {
    let address = {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: ""
    }

    return address;
};