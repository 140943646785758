import React, { useEffect, useState, useRef } from 'react';
import { Form, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import './iteminputunitselection.css';

function ItemInputUnitSelection({ lbltext, name, itemvalue, unitTypeValue, onInputValueChanged,
    unittypename, unittypebasemt, unitMeasureTypes, updateUnitSelection, showSelect = true,
    readOnly=false
}) {
    const [dropDownIsOpen, setDropDownIsOpen] = useState(false); // Track state of drop down
    const [groupWidth, setGroupWidth] = useState(null);
    const formGroupRef = useRef(null);

    useEffect(() => {
        // Track any screen size changes and update state variable
        function handleResize() {
            setInputWidth();
        }
        window.addEventListener('resize', handleResize)
    })

    useEffect(() => {
        // Use a ref to get the rendered size of the control
        setInputWidth();
    })

    useEffect(() => {
        setInputWidth();
    }, [unittypename])

    // This method sets the width of the form group item based on the rendered component
    // We store this, this is calculated by the browser
    // We will then use this value to set a static width of the group when the drop down is open
    // Control needs to be set to absolute positioning for drop down to display correctly over other controls
    // Setting it to absolute changes the control's size, so we use the calculated value to set a static size
    const setInputWidth = () => {
        if (formGroupRef && formGroupRef.current) {
            if (groupWidth !== formGroupRef.current.clientWidth) {
                setGroupWidth(formGroupRef.current.clientWidth);
            }
        }
    }


    return (
        <Form.Group
            // Add a class when a drop down event occurs
            className={['item_input_unit_selection', (dropDownIsOpen ? 'dropped' : '')]}
        >
            <Form.Label>{lbltext}</Form.Label>
            <InputGroup
                // Set static width when drop down has occurred
                style={dropDownIsOpen ? { "width": groupWidth } : {}}
                ref={formGroupRef}
                className={'item_input_unit_selection_control'}
            >
                <NumberFormat
                    className={"form-control"}
                    name={name}
                    aria-label={lbltext}
                    onChange={onInputValueChanged}
                    value={itemvalue}
                    readOnly={readOnly}

                />
                <InputGroup.Append>
                    <DropdownButton
                        disabled={readOnly}
                        name={unittypename}
                        onToggle={setDropDownIsOpen}
                        title={
                            (unitMeasureTypes.find(t => t.baseMeasurementUnitTypeId === unitTypeValue && t.baseMeasurementType === unittypebasemt) == null)
                                ? (showSelect ? "Select" : '')
                                : unitMeasureTypes?.find(t => t.baseMeasurementUnitTypeId === unitTypeValue)?.displayAbbrev
                        }
                    >
                        {
                            unitMeasureTypes
                                .filter(t => t.baseMeasurementType === unittypebasemt && t.id !== 0)
                                .map(type =>
                                    <Dropdown.Item key={type.baseMeasurementUnitTypeId}
                                        onClick={() => updateUnitSelection(unittypename, type.baseMeasurementUnitTypeId)}
                                    >
                                        {type.displayName}
                                    </Dropdown.Item>
                                )
                        }
                    </DropdownButton>
                </InputGroup.Append>
            </InputGroup>
        </Form.Group>

    );
};

export default ItemInputUnitSelection;