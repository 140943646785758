import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Html5Table, useFilter } from 'window-table';
import "./displaytable.css";
import { Component } from "react";
import { useSortableData } from '../../hooks/useSortableData';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { updateItems } from '../../store/actions/displayTableSelectedItemActions';
import { useEffect } from "react";
import ColumnSelector from "./ColumnSelector";



// Example of column headers that can be passed in 
// const colheaders = [    
//     {
//     displayName: "Name" ,           // Column Header display text
//     propName: "displayName",        // Field name of object property that corresponds to column
//     colclassname: "",               // Class that will be applied to the column header               
//     dataclassname: "",              // Class that will be applied to field data (currency, align, numeric, etc.)
//     onclickfunction: setCurrItem    // A function to fire on click event of a given cell, not necessary to function but handy
//     selected: true,                 // boolean value if it is true, then it shows otherwise not, used for user column configuration
//     displayorder: 2                 // 0 based integer value to determine ordering of columns, used for user column configuration
//     },

//     {
//     displayName: "Type" ,
//     propName: "typeName",
//     colclassname: "",
//     dataclassname: ""
//     },
// ]


export default function DisplayTable({
    tablenameidentifier, // a string identifier for this instance of the display table, needs to be unique in application, used for visual selection indicator
    inputitems, // array of items to be displayed in table, needs to correspond to column headers
    colheaders, // The column headers to show in the system, along with properties
    idname, // The field name of the id(identifier) field on an obect (id, Id, ID, ItemId, ProductID, etc..)
    selectCurrentItem, // A function passed in to be fired on a selection item change, passes the object from the list to parent
    currentSelectedItem, // The variable from the parent that holds the current object, basically here to trigger state change
    searchText = "", // Text to search within the table
    showColumnSelector,
    setShowColumnSelector,
    // columnsList, 
    setColumnsList
}) {
    //Use the custom useSortableData hook to sort the source data
    // const selectedid = useSelector(state => state.displaytableselecteditem.displayitemid);
    const { items, requestSort, config } = useSortableData(inputitems);
    const filteredItems = useFilter(search, items, searchText);
    const [columns, setColumns] = useState([]);
    const [exitingModal, setExitingModal] = useState(false);
    // const colsOnLoad = [...colheaders];
    // let colsOnLoad = [...colheaders];
    const [originalColumns, setOriginalColumns] = useState(null);
    const ref = React.createRef();
    const dispatch = useDispatch();

    useEffect(() => {
        setColumns([]);
    }, [colheaders])

    //NOTE VVV This useEffect
    useEffect(() => {
        if (showColumnSelector === true && originalColumns == null && colheaders && colheaders?.length > 0) {
            setOriginalColumns(JSON.parse(JSON.stringify(colheaders)));
        } else {
            if (showColumnSelector === false) {
                // setOriginalColumns(null);
            }
        }
    }, [showColumnSelector]);

    function setLocalCurrItem(item) {
        let displayitem = {
            tablename: tablenameidentifier,
            displayitemid: item[idname],
            id: item.id
        }

        dispatch(updateItems(displayitem));
        selectCurrentItem(item);
    }

    //Had to build this as a Class Component to make ref forwarding happy (stupid react!)
    class CustomRow extends Component {
        render() {
            const {
                index,
                ...rest
            } = this.props;

            let thisitem = inputitems[this.props.index];
            let itemid = (thisitem === undefined ? "" : thisitem[idname]);

            return (
                // Craziness and nonsense, had to create a functional component inside a class component
                // which is inside a functional component, to be able to use a state object to maintain the selectedid
                // of the currently selected item in the table, nightmare workaround
                <BuildRow itemid={itemid} thisitem={thisitem} rest={{ ...rest }} />
            )
        }
    };

    function BuildRow({ itemid, thisitem, rest }) {
        // Get all the display items for tables, could be multiple in state
        const selecteddisplayitems = useSelector(state => state.displaytableselecteditem.displayitemids)
        // Get the one we are looking for based on this tables name
        let selectedid = selecteddisplayitems?.find(e => e.tablename === tablenameidentifier)?.displayitemid;

        // if this is the item that we are looking for we need to add a class,
        // otherwise we need to strip the class that existed or may have existed 
        // to not show it as a selection
        if (thisitem != null) {
            rest.className = rest.className.replace('display_table_current_selection_row', '') + (selectedid === thisitem[idname] ? ' display_table_current_selection_row' : '');
        }
        return (
            <tr key={itemid} onClick={() => setLocalCurrItem(thisitem)}
                // It's important that we pass all the remaining props here
                {...rest}
            />
        )
    }


    class HeaderRow extends Component {
        render() {
            const {
                index,
                ...rest
            } = this.props;

            if (window.innerWidth <= 760) {
                return <></>;
            }

            return (
                <tr ref={ref} key={index}
                    {...rest}
                />
            )
        }
    };

    class HeaderCell extends Component {
        render() {
            const {
                index,
                column,
                ...rest
            } = this.props;

            let colhead = colheaders.find(e => e.propName === column.key);
            let colclassname = colhead.colclassname;
            rest.className = rest.className + " " +
                (config?.key) === colhead.propName ?
                (
                    // determine if the column is the currently sorted column, if so decorate with a sort icon 
                    (config?.direction) === 'ascending' ?
                        (colclassname === undefined ? "" : colclassname + " ") + "sorted-col-asc disable-select"
                        :
                        (colclassname === undefined ? "" : colclassname + " ") + "sorted-col-desc disable-select"
                ) : (
                    (colclassname === undefined ? "" : colclassname + " ") + "disable-select"
                )

            return (

                <th key={index} onClick={() => requestSort(colhead.propName)}
                    {...rest}
                >
                    {colhead.displayName}
                    {(config?.key) === colhead.propName ?
                        (
                            (config?.direction) === 'ascending' ?
                                <FaSortDown size="1rem" className="ml-1" />
                                :
                                <FaSortUp size="1rem" className="ml-1" />
                        ) : (
                            ""
                        )}
                </th>
            );
        };
    };


    class CustomCell extends Component {
        render() {


            const {
                index,
                column,
                row,
                ...rest
            } = this.props;

            let colhead = colheaders.find(e => e.propName === column.key);
            rest.className = rest.className + " " + colhead.dataclassname;

            if (colhead.iscurrency) {
                let inputval = row[colhead.propName];
                let formattedvalue = inputval.toString();

                if (formattedvalue != null) {
                    formattedvalue = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                        .format(formattedvalue?.replace('$', '')?.replace(',', ''));
                    row[colhead?.propName] = formattedvalue;
                }

            }
            // handle any type of numeric input
            // ??

            rest.row = row;
            return (
                <td th-data={colhead.displayName} onClick={() => (colhead.onclickfunction === undefined ? "" : colhead.onclickfunction(rest.row))}
                    // Here we call the stored state handler

                    // It's important that we pass all the remaining props here
                    {...rest}
                />
            );
        };
    };

    function updatecolumns() {
        let columns = [];
        if (colheaders != null) {
            var hdrs = [...colheaders];
            hdrs
                .sort(function (a, b) {
                    return (a.displayorder - b.displayorder);
                })
                .filter(col => col.selected !== false)
                .forEach(col => {
                    columns.push(
                        {
                            key: col.propName,
                            width: 100,
                            title: col.displayName
                        }
                    )
                })
            setColumns(columns);
        }
    }
    function search(data, filterText) {
        // using the spread operator to create a new array
        let newArr = [];
        let i = 0;
        for (i = 0; i < colheaders?.length; i++) {
            if (colheaders[i].nonSearchable !== true) {
                let propname = colheaders[i].propName;
                //Search for anything in any of the columns that matches the search
                newArr.push(...data.filter(c => c[propname]?.toString().toLowerCase().includes(filterText.toLowerCase())));

            }
        }
        //This will remove any duplicates in the array
        const ar = [...new Set(newArr)];
        return ar;

    };

    function getFilteredItems() {
        if (searchText === "") {
            //setFilteredItems(inputitems);
            return inputitems;
        }
        else {
            return search();
            //search()
        }
    }


    if (columns.length === 0) {
        updatecolumns();
    }
    if (colheaders.filter(c => c.selected === true).length !== columns.length) {
        updatecolumns();
    }

    if (filteredItems === undefined) {
        getFilteredItems();
    }

    if (inputitems == null) {
        return <></>
    }
    if (inputitems.count === 0) {
        return <></>
    }


    /*********************************************** */

    //selCol comes from onCick event and this function reorders the array's displayorder when a column is moved BS 1/4/23
    function columnMoveDown(selCol) {
        let cols = [...colheaders];
        let colLength = cols.length;

        for (let i = 0; i < colLength; i++) {
            cols[i].activeColumn = false; //resets all columns to activeColumn = false
            if (
                cols[i].displayorder === selCol.displayorder &&
                selCol.displayorder + 1 != colLength
            ) {
                cols[selCol.displayorder + 1].displayorder = selCol.displayorder;
                cols[i].displayorder = selCol.displayorder + 1;
                selCol.activeColumn = true; //sets selected column activeColumn = true

            }
        }
        setColumnsList(cols);
    }

    function columnMoveUp(selCol) {
        console.log('selCol', selCol);
        let cols = [...colheaders];
        let colLength = cols.length;

        for (let i = 0; i < colLength; i++) {
            cols[i].activeColumn = false; //resets all columns to activeColumn = false
            if (
                cols[i].displayorder === selCol.displayorder &&
                selCol.displayorder > 0
            ) {
                cols[selCol.displayorder - 1].displayorder = selCol.displayorder;
                cols[i].displayorder = selCol.displayorder - 1;
                selCol.activeColumn = true; //sets selected column activeColumn = true
            }
        }
        setColumnsList(cols);
    }


    //FIXME VVV fuction is what saves the column header changes
    function saveColumnChanges(cols) {
    }

    //NOTE This function triggers when the column checkbox is changed and resets the activeColumn property to false, removing the highlighted row from the UI
    function columnChangeHandler(col) {
        let cols = [...colheaders];
        let colLength = cols.length;
        col.selected = !col.selected;

        for (let i = 0; i < colLength; i++) {
            cols[i].activeColumn = false;
        }
        col.activeColumn = true;
        setColumnsList(cols);
        return cols;
    }

    function saveHandler() {
        setOriginalColumns(null);
        setShowColumnSelector(false);
    }

    function cancelColumnModal() {

        let cols = [];
        let colhd = [...colheaders]

        originalColumns
        .sort(function (a, b) {
            return (a.displayorder - b.displayorder);
        })
        .filter(col => col.selected !== false)
        .forEach(col => {
            cols.push(
                {
                    key: col.propName,
                    width: 100,
                    title: col.displayName
                }
            )

            var item = colhd.find(x => x.propName === col.propName);
            if (item != null) {
                item.displayorder = col.displayorder;
                item.selected = col.selected;
            }
        });
        setColumns(cols);
        setColumnsList(colhd);
        setOriginalColumns(null);
        setShowColumnSelector(false);
    }
    
    return (
        <>
            {showColumnSelector ? (
                <ColumnSelector
                    inputColumns={colheaders}
                    cancelDialog={cancelColumnModal}
                    saveHandler={saveHandler} //cancelColumnModal is actually just closing the modal - the cancel button will revert back to initial state bs 2/18/22
                    showModal={showColumnSelector}
                    columnChangeHandler={columnChangeHandler}
                    columnMoveUp={columnMoveUp}
                    columnMoveDown={columnMoveDown}
                />
            ) : (
                <></>
            )}

            <div className={"table-container"}>

                <Html5Table
                    data={filteredItems}
                    columns={columns}
                    className="table "
                    headerClassName="display_table_th"
                    classNamePrefix="display_"
                    Row={CustomRow}
                    Cell={CustomCell}
                    HeaderRow={HeaderRow}
                    HeaderCell={HeaderCell}
                />
            </div>
        </>
    );
};

export { DisplayTable };
