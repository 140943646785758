import React, { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Table} from 'react-bootstrap';
import { useSortableData } from '../../hooks/useSortableData';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import "./simpledisplaytable.css";
import { updateItems } from '../../store/actions/displayTableSelectedItemActions';


//Component to display tabular data and allow it to be sorted
//
//  inputitems - an array of items to be displayed in the table
//  colheaders - an array of header configs that match to the inputitems list
    //   {
    //    displayName: the name to be used for the column header of the table,
    //    propName: the name of the associated property to be displayed in the column,
    //    colclassname: any class/classes that will be applied to the column <th> tag,
    //    dataclassname: any class/classes that will be applied to the cell <td> tag for the item
    //    onclickfunction: callback onClick function, must follow fomat of onClick(item) in source,
    //    iscurrency: if it is a currency field, set this to true,
    //    nonSearchable: removes it from the search
    //   }
//  idname - the name of the primary key of the object list, this will not be displayed and will be used for
//functionality
//  setSelectedId - callback function passed in to track the id of the currently selected item, activated on click
//for handling events
// Search text, this should be coming from a state variable in the parent component

// export default function SimpleDisplayTable({ inputitems, colheaders, idname, setSelectedId, searchText = "" }) {

export default function SimpleDisplayTable ({ 
    tablenameidentifier, // a string identifier for this instance of the display table, needs to be unique in application, used for visual selection indicator
    inputitems, // array of items to be displayed in table, needs to correspond to column headers
    colheaders, // The column headers to show in the system, along with properties
    idname, // The field name of the id(identifier) field on an obect (id, Id, ID, ItemId, ProductID, etc..)
    selectCurrentItem, // A function passed in to be fired on a selection item change, passes the object from the list to parent
    currentSelectedItem, // The variable from the parent that holds the current object, basically here to trigger state change
    searchText = "" // Text to search within the table
})  {
    //Use the custom useSortableData hook to sort the source data
    const { items, requestSort, config } = useSortableData(inputitems);
    const selecteddisplayitems = useSelector(state => state.displaytableselecteditem.displayitemids)
    const dispatch = useDispatch();

    useEffect(() => {
        let displayitem ={
            tablename: tablenameidentifier,
            displayitemid: -1
        }
        dispatch(updateItems(displayitem));
    }, [inputitems, dispatch, tablenameidentifier])

    function search() {
        // using the spread operator to create a new array
        let newArr = [];
        let i = 0;
        for (i = 0; i < colheaders?.length; i++) {
            if (colheaders[i].nonSearchable !== true)
            {
                let propname = colheaders[i].propName;
                //Search for anything in any of the columns that matches the search
                newArr.push(...items.filter(c => c[propname].toLowerCase().includes(searchText.toLowerCase())));
            }
        }
    
        // The following line filters based on a specific property name
        //    newArr.push(...items.filter(c => c.displayname.toLowerCase().includes(searchText.toLowerCase())));
    
        //This will remove any duplicates in the array
        const ar = [...new Set(newArr)];
    
        return ar;
    
      };
    
    function getFilteredItems() {
        if (searchText === "")
        {
            return items;
        }
        else
        {
            return search();
        }
    }
    function setLocalCurrItem (item) {
        let displayitem ={
            tablename: tablenameidentifier,
            displayitemid: item[idname]
        }

        dispatch(updateItems(displayitem));
        selectCurrentItem(item);
    }
    
      let rowcount = 0;
      let rows = (
           <tbody>
            {/* iterate items and create the row tags in function */}
            {
            getFilteredItems()?.map(item => {
             
                rowcount = rowcount + 1;
                // if (rowcount > 100)
                // {
                //     return "";
                // }
                return addRow(item, colheaders, idname)
            })
            }
        </tbody>
  )

    return (
        <div className={ "simple_display_table-container"}>
        
        <Table className={"simple_display_table table"} > 
          <thead>
              <tr>
                  {/* iterate column headers and create the column header tags in function */}
                  {colheaders.map((col, index) =>
                     addColHeader(col.displayName, col.propName, col.colclassname, index) 
                    )}
            </tr>
          </thead>
          {rows}
        </Table>
        </div>
      );

      //Add column header
      // displayName = <th> value
      // propname = name of object property (id field will not be a column)
      // colclassname = class/classes to association with the column
      // key = just an index to create unique keys
      function addColHeader(displayName, propName, colclassname, key )
      {
          return (
            
            <th key={key} onClick={() => requestSort(propName)} 
                className=
                {
                (config?.key) === propName ? 
                    (
                        // determine if the column is the currently sorted column, if so decorate with a sort icon 
                        (config?.direction) === 'ascending' ?
                        (colclassname === undefined ? "" : colclassname + " ") + "sorted-col-asc disable-select"    
                        :
                        (colclassname === undefined ? "" : colclassname + " ") + "sorted-col-desc disable-select"    
                    ) : (
                        (colclassname === undefined ? "" : colclassname + " ") + "disable-select"
                )
                }
                >
                { displayName }
                {(config?.key) === propName ? 
                    (
                        (config?.direction) === 'ascending' ?
                        <FaSortAmountDown size="1rem" className="ml-1" />
                        :
                        <FaSortAmountUp size="1rem" className="ml-1"  />
                    ) : (
                        ""
                )}
            </th>
        )
      }

      function addRow(item, colheaders, idname)
      {
          let itemid = item[idname];
          let selectedid = selecteddisplayitems?.find(e => e.tablename === tablenameidentifier)?.displayitemid;
          let isselected = false;
          if (itemid === selectedid)
          {
            isselected = true;
          }
          return (
              
            /* Create the row use the item id for the key  */
            <tr key={itemid} onClick={e => setLocalCurrItem(item)} className={(isselected ? 'display_table_current_selection_row simple_display_table_row' : 'simple_display_table_row')}>
                {
                    //Iterate through the header collection to determine the fields <td> to created
                    colheaders.map((col, index) => {
                        //get the cell value, determine if it is currency or not based on input prop
                        let cellval = (
                            item[col.propName]
                        )

                        if (col.isdate)
                        {
                            let formattedvalue = item[col.propName];
                            try {
                                   formattedvalue = new Intl.DateTimeFormat("en-US").format(new Date(cellval));
                                   cellval = formattedvalue;
                            }
                            catch {
                            }

                        }

                        return (
                            (col.propName !== idname) 
                            ?(
                                //Check if we passed in an onclick function for this column 
                                //if it exists set it to the onClick event
                                (col.onclickfunction === undefined) 
                                ? 
                                <td th-data={col.displayName} key={itemid + ':' + index} className={(col.dataclassname === undefined ? "" : col.dataclassname + " ") + "simple_display_table_td"} >{cellval}</td>
                                :
                                <td th-data={col.displayName} key={itemid + ':' + index} onClick={e => col.onclickfunction(item)} className={(col.dataclassname === undefined ? "" : col.dataclassname + " ") + "simple_display_table_td"} >{cellval}</td>
                            ):
                            ""
                            );
                        
                        }
                    )
                }
            </tr>
        )
      }
}

//Go with peace

export { SimpleDisplayTable };