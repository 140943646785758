import React from 'react';
import '../searchbox/searchresult.css';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export function SearchResult({ result, setShowModal, index, openSearcheditem, searchType}) {

  const history = useHistory();

  // Get CSS class for the result type color coding
  function getTypeClass(type) {
    if (type === 'Customer') {
      return 'custBack';
    } else if (type === 'Item') {
      return 'itemBack';
    } else if (type === 'Purchasing') {
      return 'poBack';
    } else if (type === 'Sales') {
      return 'salesBack';
    } else if (type === 'Returns') {
      return 'returnBack';
    } else if (type === 'Vendor') {
      return 'vendBack';
    } else {
      return 'defaultBack';
    }
  }

  console.log('search result', result);

  return (
    <div key={index} className={'result noselect'} onClick={() => openSearcheditem(result.searchType, result.id)} >

      {/* result type */}
      <div className={searchType === 'all' ? 'result-item-type ' + getTypeClass(result.searchType) : 'no-type' }>
        {searchType === 'all' ? result.searchType : ''}
      </div>

      {/* result info */}
      <div className={'result-container'}>

        {result.displayName}

        {/* JUST ADDED THIS AS A TEMPORARY TRIGGER TO OPEN ITEM
        <Button 
          className={'ml-4'} 
          onClick={() => openSearcheditem(result.searchType, result.id)}>
          Open
        </Button> */}

      </div>

    </div>
  );
}