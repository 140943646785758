import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Card, Row, Col, Button, Nav } from "react-bootstrap";
import WindowedTable from "../../Controls/Tables/WindowedTable";
import "./shippedlog.css";

export default function ShippedLog({shipRecords, itemId}) {
  const colHeaders = [
    {
      displayName: "Ship Date",
      propName: "shipDate",
      colclassname: "",
      selected: true,
      displayorder: 0,
      isDate: true
    },
    {
      displayName: "Ship Qty",
      propName: "shippedQuantity",
      colclassname: "",
      selected: true,
      displayorder: 0,
    },
    {
      displayName: "Shipped By",
      propName: "",
      colclassname: "",
      selected: true,
      displayorder: 0,
    },
    {
      displayName: "Tracking",
      propName: "",
      colclassname: "",
      selected: true,
      displayorder: 0,
    },
  ];

  return (
    <>
      <div className="shippedlog_container">
        <WindowedTable
          colheaders={colHeaders}
          inputitems={shipRecords.filter(x => x.salesOrderItemId === itemId)}
          currentSelectedItem={""}
          selectCurrentItem={""}
          idname={""}
          tablenameidentifier={""}
          searchText={""}
          setSelectedId={""}
          selectedId={""}
          defaultSort={""}
        />
      </div>
    </>
  );
}
