import {
    FETCH_CUSTOMERS_BEGIN,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAILURE,
    UPDATE_CUSTOMERS
} from '../actions/customerActions';
import { defaultsService } from '../../services';

const initialState = {
    customers: [],
    currCust: defaultsService.getDefaultCustomer(),
    loading: false,
    error: null,
    currCustLoaded: false,
    customersLoaded: false,
    customerPosting: false,

};

export default function customerReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_CUSTOMERS_BEGIN:
            
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null,
                customersLoaded: false
            };
        case FETCH_CUSTOMERS_SUCCESS:
            
            // All done: set loading "false".
            // Also, replace the customers with the ones from the server
            return {
                ...state,
                loading: false,
                customers: action.payload.customers,
                customersLoaded: true
            };
        case FETCH_CUSTOMERS_FAILURE:

            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            // Since it failed, we don't have customers to display anymore, so set `customers` empty.
            //
            // This is all up to you and your app though:
            // maybe you want to keep the customers around!
            // Do whatever seems right for your use case.
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                customers: []
            };
        case UPDATE_CUSTOMERS:

            //  We have a new or updated Item added in the software
            //  need to update the master underlying list to account for it
            let existingCustomer = state.customers.find(e => e.id === action.payload.id);
            let index = state.customers.indexOf(existingCustomer);

            // We have a new or updated Customer added in the software
            // Need to update the master underlying list to account for it
            if (index < 0) {
                // Add the new Customer to the end of the list
                return {
                    ...state,
                    customers: [...state.customers.concat(action.payload)]
                };
            } else {
                // We need to slice the array and insert the updated Customer
                // This keeps everything clean as far as the rules of immutable state go
                return {
                    ...state,
                    customers: [
                        ...state.customers.slice(0, index)
                            .concat(action.payload)
                            .concat(state.customers.slice(index + 1))
                    ]
                };
            };
        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}