import React, { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { IoIosClose } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import HelpBackdrop from './HelpBackdrop';
import { showHelpView } from '../../store/actions/uiActions';
import './helpdisplaycomponent.css'
import { fetchHelpItem } from '../../store/actions/help/helpActions';


export default function HelpDisplayComponent() {
    const helpViewVisable = useSelector(state => state.uiState.showHelpView);

    const helpItem = useSelector(state => state.help.helpItem);
    const helpItemLoading = useSelector(state => state.help.loading);
    const helpItemError = useSelector(state => state.help.error);
    const helpItemLoaded = useSelector(state => state.help.helpItemLoaded);
    const helpItemTopic = useSelector(state => state.help.helpTopic);

    const helpTopic = useSelector(state => state.uiState.helpViewTopic);
    // const helpTopic = "Items" // "Help Topic"; /*this can be removed as soon as the above commented out code is working to pull in the help title*/
    const dispatch = useDispatch();

    useEffect(() => {
        if (helpTopic !== helpItemTopic && !helpItemLoading) {
            dispatch(fetchHelpItem(helpTopic))
        }
    }, [helpTopic, helpItemTopic, helpViewVisable])


    function closeHelp() {
        dispatch(showHelpView(false));
    }

    let backdrop = ''; /*Brandon changed from '<></>' to empty string because this was rendering characters on the screen 3/25/21*/

    if (helpViewVisable) {
        backdrop = <HelpBackdrop />
    }

    // if (!showHelpView) {
    //     return <></>
    // }

    return (
        <>
            { backdrop}
            <div className={'inventory-dash-help-container' + (helpViewVisable ? ' open' : '')} >
                <Card className={'inventory-dash-help-card'}>

                    <Card.Header
                        className={'inventory-dash-help-card-header'}
                    >
                        <Card.Title>
                            <div className={'inventory-dash-help-card-title noselect'}>
                                {helpTopic}
                            </div>
                            <Button onClick={closeHelp}>
                                <IoIosClose size={"1.75rem"} />
                            </Button>
                        </Card.Title>
                    </Card.Header>

                    <Card.Body className={'inventory-dash-help-card-body'} >
                        {/* <iframe 
                        sandbox={true}
                        src="https://docs.google.com/document/d/e/2PACX-1vQ6FGVYFyqdFjsZrUUVesl0dp1Zbt3ZFCsA9Ha-JUJmDBwnKbU27tlV6S5yWUddagevs_oaTPvLzN2A/pub?embedded=true">
                    </iframe> */}
                        {/* {
                            helpItemLoaded
                                ?

                                <iframe className={'inventory-dash-help-content'}
                                    src={helpItem?.helpLink}
                                >
                                </iframe>
                                :
                                'Loading...'
                        } */}


{/* Using Object instead of iframe, really no difference, but I think the security aspects/concerns are less with an object */}

                        {
                            helpItemLoaded
                                ?

                                <object className={'inventory-dash-help-content'}
                                    data={helpItem?.helpLink}
                                    type="text/html"
                                >
                                </object>
                                :
                                'Loading...'
                        }

                     
                    </Card.Body>
                </Card>
            </div>
        </>

    )
}