import { defaultsService } from '../../services';
import {
    FETCH_VENDORS_BEGIN,
    FETCH_VENDORS_SUCCESS,
    FETCH_VENDORS_FAILURE,
    UPDATE_VENDORS
  } from '../actions/vendorActions';
  
  const initialState = {
    vendors: [],
    currVend: defaultsService.getDefaultVendor(),
    loading: false,
    error: null,
    currVendLoaded: false,
    vendorsLoaded: false,
    vendorPosting: false,
  };
  
  export default function vendorReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_VENDORS_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          vendorsLoaded: false
        };
  
      case FETCH_VENDORS_SUCCESS:
        // All done: set loading "false".
        // Also, replace the vendors with the ones from the server
        return {
          ...state,
          loading: false,
          vendors: action.payload.vendors,
          vendorsLoaded: true
        };
  
      case FETCH_VENDORS_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have vendors to display anymore, so set `vendors` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the vendors around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          vendors: []
        };
      case UPDATE_VENDORS:
        //  We have a new or updated Item added in the software
        //  need to update the master underlying list to account for it
        let existingVendor = state.vendors.find(e => e.id === action.payload.id);
        let index = state.vendors.indexOf(existingVendor);

        // We have a new or updated Vendor added in the software
        // Need to update the master underlying list to account for it
        if (index < 0) {
          // Add the new Vendor to the end of the list
          return {
            ...state,
            vendors: [...state.vendors.concat(action.payload)]
          };
        } else {
          // We need to slice the array and insert the updated Vendor
          // This keeps everything clean as far as the rules of immutable state go
          return {
            ...state,
            vendors: [
              ...state.vendors.slice(0, index)
                .concat(action.payload)
                .concat(state.vendors.slice(index + 1))
            ]
          };
        };
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }