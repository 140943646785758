import React, { useState, Component, useRef } from "react";
import { MdMoreHoriz } from "react-icons/md";
import { FiTrash, FiCheckSquare, FiSquare } from "react-icons/fi";
import { RiInboxArchiveFill } from "react-icons/ri";
import { FaBoxOpen } from "react-icons/fa";
import { BsCalendar } from "react-icons/bs";
import { CgArrowsExpandLeft } from "react-icons/cg";
import { MdAttachMoney } from "react-icons/md";
import { BiNote } from "react-icons/bi";
import "./salesorderlineoptions.css";

export function SalesOrderLineOptions({
  deleteInventoryItem,
  expandLine,
  deleteItem,
  shipAll,
  expanded,
  deleteSalesOrder,
  title = 'Line Item Options',
  showReceiveAll = true,
  showRemoveItem = true,
  showExpand = true,
  showDeletePO = false,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef(null);
  const optionsContainerRef = useRef(null);

  function expandOptions(e) {
    //tests whether option window is expanded - expands if it isnt and collapses if it is
    if (showOptions == false) {
      setShowOptions(true);
    } else if (showOptions == true) {
      setShowOptions(false);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function collapseOptions(e) {
    //hides options window
    if (showOptions == true) {
      setShowOptions(false);
      e.preventDefault();
      e.stopPropagation();
    }
  }

  function showExpandedLine() {
    //shows expanded line item and hides option window
    expandLine();
    setShowOptions(false);
  }

  function itemShippedHandler(e) {
    shipAll();
    collapseOptions(e);
  }

  function getLocation() {

  }

  function getLeft() {
    let wid = optionsContainerRef?.current?.getBoundingClientRect().width;

    if (!wid) {
      wid=156;
    }
    if (wid === 0) {
      wid=156;
    }
    return optionsRef?.current?.getBoundingClientRect().left - wid;

  }
  function getTop() {
    return optionsRef?.current?.getBoundingClientRect()?.top + 24;
  }


  return (
    <>
    <div className="lineOptions" ref={optionsRef}>
      <MdMoreHoriz
        size="2rem"
        onClick={expandOptions}
        cursor="pointer"
      />
      </div>
      {showOptions ? (
        <>
          <div
            className="salesorder_options_overlay"
            onClick={collapseOptions}
          />
          <div style={{left: getLeft(), top:getTop()}} className="lineItem_options_container" ref={optionsContainerRef}>
            <div className="lineitem_options_header">
              {title}
              <span onClick={collapseOptions}>X</span>
            </div>
            {
              showReceiveAll
                ?
                <div className="lineitem_option_item lineitem_option_item_bottom" onClick={itemShippedHandler}>
                  <span>Ship All</span>
                  <RiInboxArchiveFill />
                </div>
                :
                <></>
            }

            {/* <div className="lineitem_option_item"><span>Receive Partial</span><FaBoxOpen/></div> */}
            {/* <div className="lineitem_option_item"><span>Add Note</span><BiNote/></div> */}

            {
              showRemoveItem
                ?
                <div className="lineitem_option_item" onClick={deleteItem}>
                  <span>Remove Item</span>
                  <FiTrash />
                </div>
                :
                <></>
            }

            {
              showDeletePO
                ?
                <div className="lineitem_option_item" onClick={deleteSalesOrder}>
                  <span>Delete P.O.</span>
                  <FiTrash />
                </div>
                :
                <></>
            }

            {/* <div className="lineitem_option_item"><span>Delivery ETA</span><BsCalendar/></div> */}
            {
              showExpand
                ?
                <div className="lineitem_option_item lineitem_option_item_bottom" onClick={showExpandedLine}>
                  <span>{(expanded ? 'Collapse' : 'Expand') + ' Options'}</span>
                  <CgArrowsExpandLeft />
                </div>
                :
                <></>
            }
          
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
