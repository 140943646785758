import React, { Component, useState, useRef } from 'react';
import { Modal, ModalBody, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ActionBarAllThings } from '../Layout/ActionBar';
import { fetchItemLocations } from "../../store/actions/itemLocationActions";
import { fetchItemTypes } from '../../store/actions/itemTypeActions';
import { fetchItemGroups } from '../../store/actions/itemGroupActions';
import { fetchVendors } from '../../store/actions/vendorActions';
import { trackPromise } from 'react-promise-tracker';
import ItemTypeSelector from './Selectors/ItemListSelector';
import TextInput from './textInput';
import './filtercontroller.css'
import { useEffect } from 'react';
import { ItemGroupSelector, ItemLocationsTreeControlSelector, Vendorselector } from '../pages/itemselectors';
import ItemInputUnitSelection from '../pages/items/ItemInputUnitSelection';


export default function FilterController({
    filterAppliedIndicator,
    //title props that can be used in different filter areas
    showTitleFilter = true,
    titleFilterLabel = "",
    titleInput,
    setTitleInput,
    titleCriteria,
    setTitleCriteria,
    //number props that can be used in different filter areas
    numberRange = false,
    numberRangeTitle = "",
    minQty,
    setMinQty,
    maxQty,
    setMaxQty,
    itemLabelFilter = false,
    labelFilterTitle = "",
    itemVendorFilter = true,
    itemTypeFilter = true,
    itemGroupFilter = true,
    setTypeFilter,
    typeFilter,
    groupFilter,
    setGroupFilter,
    vendorFilter,
    setVendorFilter,
    readOnly,
    clearFilters,
    applyFilters,
    labelInput,
    setLabelInput,
    required,
    inputref
}) {

    const dispatch = useDispatch();
    const types = useSelector(state => state.itemTypes?.itemtypes);
    const groups = useSelector(state => state.itemGroups?.itemgroups);
    const typedataloaded = useSelector(state => state.itemTypes?.itemtypesLoaded);
    const groupdataloaded = useSelector(state => state.itemGroups?.itemgroupsLoaded);
    const vendors = useSelector(state => state.vendors?.vendors);

    useEffect(() => {
        if (!typedataloaded) {
            trackPromise(
                dispatch(fetchItemTypes())
            );
        }
    }, [typedataloaded, dispatch]);

    useEffect(() => {
        if (!groupdataloaded) {
            trackPromise(
                dispatch(fetchItemGroups())
            );
        }
    }, [groupdataloaded, dispatch]);

    useEffect(() => {
        if (!typedataloaded) {
            trackPromise(
                dispatch(fetchVendors())
            );
        }
    }, [typedataloaded, dispatch]);

    function changeTitleCriteria(e) {
        setTitleCriteria(e.target.value);
    }
 

    return (
        <>

            <Modal.Header
                closeButton
                bsPrefix={"inventorydash_custom_modal_header"}

            >

                <ActionBarAllThings
                    showSaveBtn={true}
                    pageTitle='Filters'
                    onModal={true}
                    showHelpButton={false}
                    showDelBtn={filterAppliedIndicator > 0 ? true : false}
                    delHandler={clearFilters}
                    saveHandler={applyFilters}
                    filterAppliedIndicator={filterAppliedIndicator}
                    saveBtnText='Apply'
                    delBtnText='Clear'
                />
            </Modal.Header>
            <Modal.Body bsPrefix={"inventorydash_custom_modal_body filterModal"}>
                <Form.Group>
                    {showTitleFilter ? <div className='filterTitle'>
                        <p>{titleFilterLabel}</p>
                        <div className='filterOptionDropdown'>
                            <Form.Label>Criteria</Form.Label>
                            <Form.Control
                                as="select"
                                value={titleCriteria}
                                onChange={changeTitleCriteria}
                            >
                                <option key={0} value="Contains">Contains</option>
                                <option key={1} value="Begins With">Begins with</option>
                                <option key={2} value="Exact">Exact</option>

                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                                Please Select a Criteria
                            </Form.Control.Feedback>

                        </div>
                        <TextInput
                            label={titleFilterLabel}
                            onChange={(e) => setTitleInput(e.target.value)}
                            value={titleInput}

                        />
                    </div> : <></>}

                    {numberRange ? (
                        <div className="numberRange">
                            <p>{numberRangeTitle}</p>
                            <div>
                                <Form.Label>Min QTY</Form.Label>
                                <Form.Control
                                    as="input"
                                    type='number'
                                    value={minQty ? minQty : ''}
                                    onChange={(e) => setMinQty(e.target.value)}
                                    min={0}
                                />
                            </div>
                            <div>
                                <Form.Label>Max QTY</Form.Label>
                                <Form.Control
                                    as="input"
                                    type='number'
                                    value={maxQty}
                                    onChange={(e) => setMaxQty(e.target.value)}
                                    min={0}
                                />
                            </div>

                        </div>
                    ) : <></>}


                    {itemLabelFilter ? (
                        <div className="labelFilter">
                            <p>{labelFilterTitle}</p>
                            <div>
                                <Form.Label>Contains Labels</Form.Label>
                                <Form.Control
                                    as="input"
                                    value={labelInput}
                                    onChange={(e) => setLabelInput(e.target.value)}
                                />
                            </div>
                        </div>
                    ) : <></>}

                    {itemTypeFilter ? (
                        <div className="itemTypeFilter">
                            <Form.Group>
                                <Form.Label>Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => setTypeFilter(e.target.value)}
                                    value={typeFilter}

                                    ref={inputref}
                                    disabled={readOnly}
                                >
                                    <option key={0} value=""></option>
                                    {
                                        types.map(type =>
                                            <option key={type.id}
                                                value={type.id}
                                            >
                                                {type.displayName}
                                            </option>
                                        )
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please Select a Type
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    ) : <> </>}

                    {itemGroupFilter ? (
                        <div className="itemGroupFilter">
                         
                            <Form.Group>
                                <Form.Label>Group</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => setGroupFilter(e.target.value)}
                                    value={groupFilter}
                                    required={required}
                                    ref={inputref}
                                    disabled={readOnly}
                                >
                                    <option key={0} value=""></option>
                                    {
                                        groups.map(group =>
                                            <option key={group.id}
                                                value={group.id}
                                            >
                                                {group.displayName}
                                            </option>
                                        )
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please Select a Group
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    ) : <></>}






                    {itemVendorFilter ? (
                        <div className="itemVendorFilter">
                            <Form.Group>
                                <Form.Label>Vendor</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => setVendorFilter(e.target.value)}
                                    value={vendorFilter}
                                    required={required}
                                    ref={inputref}
                                    disabled={readOnly}
                                >
                                    <option key={0} value=""></option>
                                    {
                                        vendors.map(vendor =>
                                            <option key={vendor.id}
                                                value={vendor.id}
                                            >
                                                {vendor.displayName}
                                            </option>
                                        )
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please Select a Type
                                </Form.Control.Feedback>
                            </Form.Group>

                        </div>
                    ) : <></>}

                </Form.Group>
            </Modal.Body>

        </>
    )


}