import React, { useState } from "react"; //import useState from react
import { Button, Row, Col, Container } from "react-bootstrap";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { Modal } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
// import "./integrationComponent.css";

function IntegrationComponent({
  setIntegrationComponentVisible 
}) {
  const [ showModal, setShowModal ] = useState(false);
  
function modalControl(){
  console.group('add clicked');
  setShowModal(true);
}
  
  return ( 
    <>
    
    <ActionBarAllThings
    pageTitle={'Integration'}
    showBackBtn
    cancelHandler = {() => setIntegrationComponentVisible(false)}
    showAddBtn
    addItem={modalControl}
    />

    {showModal ? (
      <Modal
        show={showModal}
        className={"inventorydash_modal"}
        onhide={modalControl}
      >
        <Modal.Header>
          <div>Header</div>
        </Modal.Header>

      <Modal.Body>
        <h1>123456789,10,11,12,13,14,15,16,17,18,19,20</h1>
        <h1>1</h1>
        <h1>2</h1>
        <h1>3</h1>
        <h1>4</h1>
        <h1>5</h1>
        <h1>6</h1>
        <h1>7</h1>
        <h1>8</h1>
        <h1>9</h1>
        <h1>10</h1>
        <h1>11</h1>
        <h1>12</h1> 
        <h1>13</h1>
        <h1>14</h1>
        <h1>14</h1>


      </Modal.Body>
      <Modal.Footer >

      </Modal.Footer>

      </Modal>
    ) : (
      <></>
    )}

    </>
  );
}

export { IntegrationComponent };
