import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import { RingLoader as Loader } from 'react-spinners';
import './loadingspinner.css';
import { LoadingOverlay } from './LoadingOverlay';
 
export const LoadingSpinner = (props) => {
const { promiseInProgress } = usePromiseTracker();
 
  return (
    
    <span>
    {
        (promiseInProgress === true) ?
        <div className="full-screen">
        <LoadingOverlay />
            <div className="spinner">
                <Loader color="#404c59" /> 
            </div>
        </div>
      :
        null
    }
    </span>
    
  )
};