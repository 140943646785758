import {
    FETCH_ITEMLOCATION_BEGIN,
    FETCH_ITEMLOCATION_SUCCESS,
    FETCH_ITEMLOCATION_FAILURE
  } from '../actions/itemLocationActions';
  
  const initialState = {
    location: [],
    loading: false,
    error: null,
    locationLoaded: false
  };
  
  export default function itemLocationReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_ITEMLOCATION_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          locationLoaded: false
        };
  
      case FETCH_ITEMLOCATION_SUCCESS:
        // All done: set loading "false".
        // Also, replace the itemlocations with the ones from the server
        return {
          ...state,
          loading: false,
          location: action.payload.itemlocation,
          locationLoaded: true
        };
  
      case FETCH_ITEMLOCATION_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have itemlocations to display anymore, so set `itemlocations` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the itemlocations around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          location: []
        };
  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }