import { fetchService } from "../../services";

export const FETCH_ITEMTAGS_BEGIN = "FETCH_ITEMTAGS_BEGIN";
export const FETCH_ITEMTAGS_SUCCESS = "FETCH_ITEMTAGS_SUCCESS";
export const FETCH_ITEMTAGS_FAILURE = "FETCH_ITEMTAGS_FAILURE";


//Fetch
//##########################################################################
const fetchItemTagsBegin = () => ({
  type: FETCH_ITEMTAGS_BEGIN,
});

const fetchItemTagsSuccess = (itemtags) => ({
  type: FETCH_ITEMTAGS_SUCCESS,
  payload: { itemtags },
});

export const fetchItemTagsFailure = (error) => ({
  type: FETCH_ITEMTAGS_FAILURE,
  payload: { error },
});

export function fetchItemTags() {
  return (dispatch) => {
    dispatch(fetchItemTagsBegin());

    return fetchService
      .get("/itemtags")
      .then((json) => {
        dispatch(fetchItemTagsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchItemTagsFailure(error)));
  };
}
//End Fetch
//##########################################################################
