import {
    FETCH_CUSTOMERMEASUREMENTTYPES_BEGIN,
    FETCH_CUSTOMERMEASUREMENTTYPES_SUCCESS,
    FETCH_CUSTOMERMEASUREMENTTYPES_FAILURE,
    POST_CUSTOMERMEASUREMENTTYPES_BEGIN,
    POST_CUSTOMERMEASUREMENTTYPES_SUCCESS,
    POST_CUSTOMERMEASUREMENTTYPES_FAILURE
  } from '../actions/customerMeasurementTypesActions';

  import { alertService } from '../../services';
  
  const initialState = {
    items: [],
    loading: false,
    error: null,
    itemsLoaded: false,
    itemPosting: false
  };
  
  export default function CustomerMeasurementTypesReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_CUSTOMERMEASUREMENTTYPES_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        
        return {
          ...state,
          loading: true,
          error: null,
          itemsLoaded: false
        };
  
      case FETCH_CUSTOMERMEASUREMENTTYPES_SUCCESS:
        // All done: set loading "false".
        // Also, replace the customermeasurementtypes with the ones from the server
        return {
          ...state,
          loading: false,
          items: action.payload.measurementTypes,
          itemsLoaded: true
        };
  
      case FETCH_CUSTOMERMEASUREMENTTYPES_FAILURE:
          
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have customermeasurementtypes to display anymore, so set `customermeasurementtypes` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the customermeasurementtypes around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          items: []
        };

        case POST_CUSTOMERMEASUREMENTTYPES_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            
            return {
              ...state,
              posting: true,
              error: null
            };
      
          case POST_CUSTOMERMEASUREMENTTYPES_SUCCESS:
            return {
              ...state,
              posting: false,
              error: null
            };
      
          case POST_CUSTOMERMEASUREMENTTYPES_FAILURE:
              alertService.error(action.payload.error);
            // The request failed. It's done. So set posting to "false".
            // Save the error, so we can display it somewhere.
            return {
              ...state,
              poting: false,
              error: action.payload.error
            };
      
  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }