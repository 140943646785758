import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { lightTheme, darkTheme } from './themes';
import { changeAppTheme } from './store/actions/uiActions';

const App = () => {
  // Get selected theme from redux reducer
  const activetheme = useSelector(state => state.uiState.theme);
  const dispatch = useDispatch();
  if (activetheme == null)
  {
    // if no theme exists we are going to push the default theme to the store
    dispatch(changeAppTheme('light'));
  }
  // Set theme variables
  setTheme(activetheme);

    return (
      <Layout />
    );

 }
  function setTheme(activetheme){
    let theme = null;
    switch (activetheme)
    {
      case 'dark':
        theme = darkTheme;
        break;
      case 'light':
        theme = lightTheme;
        break;
      default:
        theme = lightTheme;
    }

    var bodyStyles = document.body.style;
    theme.map(v => 
      bodyStyles.setProperty(v.prop, v.val)
    )
 
  } 

export default App;
