function getTodaysDate() {
    let dt = new Date();
    dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 12, 0, 0, 0);
    return dt;
}

function getEndOfLastMonthDate() {
    let dt = new Date();
    dt = new Date(dt.getFullYear(), dt.getMonth(), 1, 12, 0, 0, 0);
    dt.setDate(dt.getDate() - 1);
    return dt;
}

function getShortDate(inputdate){
    let d = new Date(inputdate?.toString());
    return  d.toLocaleDateString();
}

function dateFromString(inputstring) {
    if (inputstring == null)
    {
        return getTodaysDate();
    }
    if (inputstring.toString() === '')
    {
        return getTodaysDate();
    }
    let d = new Date(inputstring?.toString());
    return d;
}


function datesAreEqual(date1, date2) {
    let d1 = dateFromString(date1);
    let d2 = dateFromString(date2);
  
    console.log(d1);
    console.log(d2);
  
  
  
    if (d1.getFullYear() !== d2.getFullYear()) {
      return false;
    }
    if (d1.getMonth() !== d2.getMonth()) {
      return false;
    }
    if (d1.getDate() !== d2.getDate()) {
      return false;
    }
    return true;
  }

function dateIsNull(inputDate) {
    let dt = new Date();
    dt = new Date(1, 0, 1, 1, 0, 0, 0);
  
    let d1 = dateFromString(inputDate);
  
    if (datesAreEqual(d1, dt)){
      return true;
    }
    return false;
  }
  

export { getTodaysDate, getShortDate, getEndOfLastMonthDate, dateFromString, dateIsNull, datesAreEqual };