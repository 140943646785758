import React from 'react';
import { Form } from 'react-bootstrap';

function LoadingPlaceholder({labeltext}) {
    return (
    <div>
        <Form.Group>
            <Form.Label>{labeltext}</Form.Label>
            <div className="field">
                <input
                type="text"
                className="form-control"
                placeholder="Loading..."
                disabled
                />
            </div>
        </Form.Group>
    </div>
    )
}

export { LoadingPlaceholder };