import React, { useState, useRef, Component } from "react";
import { Modal } from "react-bootstrap";
import "../searchbox/searchmodal.css";
import { SearchFilters } from "./SearchFilters";
import { SearchBar } from "./SearchBar";
import { useSelector, useDispatch } from "react-redux";
import { fetchSearch } from "../../../store/actions/search/searchActions";
import { SearchResult } from "./SearchResult";
import { useHistory } from "react-router-dom";
import QRScanReader from "../../Controls/Scanner/QRScanReader";

export function SearchModal({ showModal, setShowModal }) {
  const history = useHistory();

  const searchResults = useSelector((state) => state.search.results);
  const searchLoading = useSelector((state) => state.search.loading);
  const searchLoaded = useSelector((state) => state.search.loaded);
  const searchError = useSelector((state) => state.search.error);
  const [showReadCode, setShowReadCode] = useState(false);
  const [needFocused, setNeedFocused] = useState(false);

  const [searchType, setSearchType] = useState("all");

  const dispatch = useDispatch();

  /*keeps focus on searchfield after a filter item is clicked*/
  function keepSearchFocused() {
    setNeedFocused(true);
  }

  function runSearch(searchText) {
    dispatch(fetchSearch(searchType, searchText));
  }

  // Function to open the searched item in the appropriate place
  // just navigates to the page through router and passes param/params
  function openSearcheditem(type, id) {
    switch (type) {
      case "Customer":
        history.push("/customers", { customerId: id });
        break;
      case "Item":
        // Look to ItemPage.jsx, line 54 to see how/where this ends
        history.push("/items", { itemId: id });
        break;
      case "Purchasing":
        //TODO
        history.push("/purchasing", { purchaseorderId: id });
        break;
      case "Sales":
        //TODO
        history.push("/sales", { salesorderId: id });
        break;
      case "Returns":
        break;
      case "Vendor":
        history.push("/vendors", { vendorId: id });
    
        //NOTE This is not working - the filepath shows /login after hitting this case instead of /vendors
    }

    setShowModal(false);
  }

  function closeModal() {
    setShowModal(false);
    setShowReadCode(false);
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        centered="true"
        animation={true}
        dialogClassName={"inventorydash_custom_modal_dialog"}
      >
        <Modal.Header bsPrefix="search_modal_header" closeButton>
          <SearchFilters
            keepSearchFocused={keepSearchFocused}
            setSearchType={setSearchType}
          />

          <SearchBar
            needFocused={needFocused}
            setNeedFocused={setNeedFocused}
            runSearch={runSearch}
            showReadCode={showReadCode}
            setShowReadCode={setShowReadCode}
          />
        </Modal.Header>

        <Modal.Body bsPrefix="custom_modal_body modal_search_body">
          {/* THis is just a temporary spot to put search results for demo purposes only */}

          {showReadCode ? (
            <QRScanReader setShowScanner={setShowReadCode} />
          ) : searchLoaded ? (
            <div className={"search-results"}>
              {searchResults.map((result, index) => (
                <SearchResult
                  openSearcheditem={openSearcheditem}
                  result={result}
                  setShowModal={setShowModal}
                  index={index}
                  searchType={searchType}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
