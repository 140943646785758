import React from 'react';
import InventoryDashCheckbox from '../../../Controls/InventoryDashCheckbox';
import "./unitofmeasurecheck.css";

export default function UnitOfMeasureCheck({unitofmeasureitem, updateItem}) {

    function checkChanged(e)
    {
        unitofmeasureitem.selected = !unitofmeasureitem.selected;
        updateItem(unitofmeasureitem);

    }

    return (

        <div className="uom_checkbox_div">
        <InventoryDashCheckbox
            label={unitofmeasureitem.displayName} 
            onChange={checkChanged}
            checked={unitofmeasureitem.selected}
        />
        </div>


        
        // <Form.Check 
        //     className="unit_of_measure_check_div"
        //     label={unitofmeasureitem.displayName} 
        //     arial-label={ unitofmeasureitem.displayName } 
        //     defaultChecked= {unitofmeasureitem.selected }
        //     onChange={checkChanged}
        // />
        
    );
}

export { UnitOfMeasureCheck };