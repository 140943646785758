import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { Form } from 'react-bootstrap';
import { fetchVendors } from '../../../store/actions/vendorActions';
import { LoadingPlaceholder } from '../../Controls/LoadingPlaceholder';

function Vendorselector({ valueChanged, value, required, inputref, readOnly }) {
    const vendors = useSelector(state => state.vendors?.vendors);
    const loading = useSelector(state => state.vendors?.loading);
    const error = useSelector(state => state.vendors?.error);
    const typedataloaded = useSelector(state => state.vendors?.vendorsLoaded);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!typedataloaded) {
            trackPromise(
                dispatch(fetchVendors())
            );
        }
    }, [typedataloaded, dispatch]);


    function vendorValueChanged(e) {
        let typeid = parseInt(e.target.value);
        let typename = vendors.find(e => e.id === typeid)?.displayName;
        valueChanged(typeid, typename);
    }

    if (error) {
        return <div>Error! {error.message}</div>;
    }

    if (loading) {
        return <LoadingPlaceholder labeltext="Type" />
    }

    return (
        <div>
            <Form.Group>
                <Form.Label>Vendor</Form.Label>
                <Form.Control
                    as="select"
                    onChange={vendorValueChanged}
                    value={value}
                    required={required}
                    ref={inputref}
                    disabled={readOnly}
                >
                    <option key={0} value=""></option>
                    {
                        vendors.map(vendor =>
                            <option key={vendor.id}
                                value={vendor.id}
                            >
                                {vendor.displayName}
                            </option>
                        )
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please Select a Type
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );

}

export { Vendorselector };