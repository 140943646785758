import { CHANGE_SETTINGS_CLICKED_STATE } from '../actions/userInteractionActions';

const initialState = {
    settingsIconClicked: false
};

export default function uiStateReducer(state = initialState, action) {
    switch(action.type)
    {
        case CHANGE_SETTINGS_CLICKED_STATE :
            return {
                ...state,
                settingsIconClicked: action.payload
              };
        default :
            return state;
    };
}
