
import {fetchService} from '../../services';

export const FETCH_CURRENTUSERROLES_BEGIN   = 'FETCH_CURRENTUSERROLES_BEGIN';
export const FETCH_CURRENTUSERROLES_SUCCESS = 'FETCH_CURRENTUSERROLES_SUCCESS';
export const FETCH_CURRENTUSERROLES_FAILURE = 'FETCH_CURRENTUSERROLES_FAILURE';
export const RESET_CURRENTUSERSROLES = 'RESET_CURRENTUSERROLES';

//Fetch
//##########################################################################
export const fetchCurrentUserRolesBegin = () => ({
  type: FETCH_CURRENTUSERROLES_BEGIN
});

export const fetchCurrentUserRolesSuccess = currentuserroles => ({
  type: FETCH_CURRENTUSERROLES_SUCCESS,
  payload: { currentuserroles }
});

export const fetchCurrentUserRolesFailure = error => ({
  type: FETCH_CURRENTUSERROLES_FAILURE,
  payload: { error }
});

export function fetchCurrentUserRoles() {
    
    return dispatch => {
      dispatch(fetchCurrentUserRolesBegin());
      return fetchService.get(`/loginroles`)
        .then(json => {
            dispatch(fetchCurrentUserRolesSuccess(json));
        return json;
      })
      .catch(error => {
        dispatch(fetchCurrentUserRolesFailure(error));
        
      }); 
      
    };
  }
//End Fetch
//##########################################################################
//Reset

export const pushResetCurrentUsersRoles = () => ({
    type: RESET_CURRENTUSERSROLES
  });
  
export function resetCurrentUsersRoles() {
    return dispatch => {
        dispatch(pushResetCurrentUsersRoles());
    } 
};
//End Reset