import {
    FETCH_HELPITEM_BEGIN,
    FETCH_HELPITEM_SUCCESS,
    FETCH_HELPITEM_FAILURE
  } from '../../actions/help/helpActions';
  
  const initialState = {
    helpItem: [],
    loading: false,
    error: null,
    helpItemLoaded: false,
    helpTopic: ''
  };
  
  export default function helpReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_HELPITEM_BEGIN:
        return {
          ...state,
          loading: true,
          error: null,
          helpItemLoaded: false
        };
      case FETCH_HELPITEM_SUCCESS:
        return {
          ...state,
          loading: false,
          helpItem: action.payload.helpitem,
          helpItemLoaded: true,
          helpTopic: action.payload.topic
        };
      case FETCH_HELPITEM_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          helpItem: []
        };
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }