import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  Form,
  Card,
  Row,
  Col,
  Button,
  ModalBody,
} from "react-bootstrap";
import TextInput from "../../Controls/textInput";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import { useWindowDimensions } from "../../../hooks";
import {
  fetchSalesOrder,
  updateSalesOrder,
  resetSalesOrder,
} from "../../../store/actions/singleSalesOrderActions";
import { trackPromise } from "react-promise-tracker";
import { fetchService, alertService } from "../../../services";
import { CustomerSelector } from "../itemselectors";
import { RiAddFill } from "react-icons/ri";
import {
  addDecimal,
  multDecimal,
  parseDecimal,
  getTodaysDate,
} from "../../utilities";
import { fetchCustomerMeasurementTypes } from "../../../store/actions/customerMeasurementTypesActions";
import Backdrop from "../../Backdrop/Backdrop";
import NumberFormat from "react-number-format";
import SalesOrderLineItem from "./SalesOrderLineItem"; // Darren Added a Secondary Version for discussion
import { SalesOrderStatusSelector } from "./SalesOrderStatusSelector";
import SalesOrderViewOnlyLineItem from "./SalesOrderViewOnlyLineItem";

import "./addeditsalesorder.css";

function AddEditSalesOrder({
  salesorderid,
  showModal,
  setShowModal,
  setIsEditing,
  isEditing,
  showEditBtn,
  cancelDialog,
  salesOrderSavedHandler
}) {
  const salesorder = useSelector((state) => state.salesorder.salesorder);
  //NOTE salesorder.salesorderItems
  //NOTE do not delete item from already saved item - instead set a property for isDeleted on the item
  const loading = useSelector((state) => state.salesorder.loading);
  const error = useSelector((state) => state.salesorder.error);
  const salesorderLoaded = useSelector(
    (state) => state.salesorder.salesorderLoaded
  );
  const windowWidth = useWindowDimensions().width;
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const customerDropdownRef = useRef(null); //brandon 1/12/21
  const [localSalesOrder, setLocalSalesOrder] = useState([]);

  // Variable to track newly added line items, with negative Ids, descending from 0 down
  const [nextId, setNextId] = useState(0);

  //#region Unit Measurement Types
  const unitMeasureTypes = useSelector((state) => state.measurementtypes.items);
  const unitMeasureTypesLoading = useSelector(
    (state) => state.measurementtypes.loading
  );
  const unitMeasureTypesLoaded = useSelector(
    (state) => state.measurementtypes.itemsLoaded
  );
  const unitMeasureTypesError = useSelector(
    (state) => state.measurementtypes.error
  );

  // UI For Input of Received Quantity
  const [showItemRecInput, setShowItemRecInput] = useState(false);
  const [selectedSalesOrderLineItem, setSelectedSalesOrderLineItem] =
    useState(false);

  // Used to scroll and focus new item
  const lineRef = useRef(null);
  const [expandedItemId, setExpandedItemId] = useState(0);

  const [showSingleItem, setShowSingleItem] = useState(false);
  const [editingItem, setEditingItem] = useState(false);

  useEffect(() => {
    if (!unitMeasureTypesLoading && !unitMeasureTypesLoaded) {
      trackPromise(dispatch(fetchCustomerMeasurementTypes()));
    }
  }, [unitMeasureTypes, unitMeasureTypesLoading, unitMeasureTypesLoaded]);
  //#endregion

  useEffect(() => {
    if (salesorderid !== salesorder?.id && !loading) {
      trackPromise(dispatch(fetchSalesOrder(salesorderid)));
    }
  }, [salesorderid]);

  // Use the purchase order from the server to populate a local version of the purchase order
  // This will be used to interact with the UI and be saved
  useEffect(() => {
    calculateSalesOrderTotals(salesorder);
    runNextId(salesorder.salesOrderItems);
  }, [salesorder]);

  // If the purchase order updates, it may be new to a new line, if a new line item
  // needs scrolled, we will handle it here
  useEffect(() => {
    let newLine = localSalesOrder?.salesOrderItems?.find(
      (l) => l.needsScroll === true
    );
    if (newLine != null) {
      if (newLine.ref != null) {
        newLine.ref.current.scrollIntoView();
        newLine.ref = null;
        newLine.needsScroll = false;
        setExpandedItemId(newLine.id);
      }
    }
  }, [localSalesOrder]);


//function that reloads the page used when changes needs to be refreshed after changes on server side
  function refreshPage() {
    window.location.reload(false);
  }

  // Function to track newly added line items, with negative Ids, descending from 0 down
  function runNextId(lineItems) {
    let nextid = 0;
    if (lineItems !== undefined) {
      lineItems.forEach((element) => {
        if (element?.id <= nextid) {
          let lowerid = nextid - 1;
          nextid = lowerid;
        }
      });
    }
    setNextId(nextid);
  }

  function setSelectedItemId(itemid) {
    setExpandedItemId(itemid);
    setShowSingleItem(true);
  }

  //function to calculate totals when things change
  function calculateSalesOrderTotals(salesOrder) {
    let stotal = 0.0;
    let total = 0.0;

    if (salesOrder != null) {
      if (salesOrder.salesOrderItems != null) {
        salesOrder.salesOrderItems.forEach((item) => {
          let lineTotal = multDecimal(
            parseDecimal(item.quantity),
            parseDecimal(item.purchasePrice)
          );
          stotal += lineTotal;
        });

        total = parseDecimal(stotal);
        total = addDecimal(total, parseDecimal(salesOrder?.freight));
        total = addDecimal(total, parseDecimal(salesOrder?.tax));
        salesOrder.subtotal = parseDecimal(stotal, 2);
        salesOrder.total = parseDecimal(total, 2);
      }
    }
    setLocalSalesOrder({ ...salesOrder });
  }

  function nameChanged(e) {
    localSalesOrder.name = e.target.value;
    setLocalSalesOrder({ ...localSalesOrder });
  }

  function customerChanged(customerid, customername) {
    localSalesOrder.customerId = customerid;
    localSalesOrder.customerName = customername;
    setLocalSalesOrder({ ...localSalesOrder });
  }

  function orderStatusChanged(statusid, statustext) {
    localSalesOrder.userStatus = statustext;
    localSalesOrder.userSalesOrderStatus = statusid;
    setLocalSalesOrder({ ...localSalesOrder });
  }

  function freightValueChanged(e) {
    localSalesOrder.freight = e.target.value;
    calculateSalesOrderTotals(localSalesOrder);
  }
  function taxValueChanged(e) {
    let tax = e.target.value;
    localSalesOrder.tax = tax;
    calculateSalesOrderTotals(localSalesOrder);
  }

  //function that calcuates the total of the SO
  function calculateTotal() {
    let runningTotal = 0;
    runningTotal += Number(localSalesOrder.tax);
    runningTotal += Number(localSalesOrder.freight);
    runningTotal += Number(localSalesOrder.subtotal);
    return runningTotal;
  }
  // Fires anytime any detail of a line item changes
  function lineItemChangedHandler(item) {
    item.outstandingQuantity =
      (item.quantity ? item.quantity : 0) -
      (item.quantityReceived ? item.quantityReceived : 0) -
      (item.newQuantityReceived ? item.newQuantityReceived : 0);

    let lineitem = localSalesOrder.salesOrderItems.find(
      (i) => i.id === item.id
    );
    let index = localSalesOrder.salesOrderItems.indexOf(lineitem);
    if (lineitem != null) {
      localSalesOrder.salesOrderItems = [
        ...localSalesOrder.salesOrderItems
          .slice(0, index)
          .concat(item)
          .concat(localSalesOrder.salesOrderItems.slice(index + 1)),
      ];

      calculateSalesOrderTotals(localSalesOrder);
    }
  }

  //TODO Need to add a function for delete button and then flag the item as deleted
  // Add a purchase order line item
  function addNewLineItem() {
    let lineItem = {
      id: nextId,
      itemId: 0,
      quantity: 0,
      expectedDate: new Date(),
      quantityReceived: 0,
      outstandingQuantity: 0,
      purchasePrice: null,
      needsScroll: true, // Add these two properties to allow scrolling into view needsScroll and lineRef
      // The scroll is handled in a useEffect after the purchase order updates
      ref: lineRef,
    };
    localSalesOrder.salesOrderItems = [
      ...localSalesOrder.salesOrderItems.concat(lineItem),
    ];
    setShowSingleItem(true);
    setExpandedItemId(lineItem.id);
    setLocalSalesOrder({ ...localSalesOrder });
    runNextId(localSalesOrder.salesOrderItems);
    setEditingItem(false);
  }

  function SaveHandler() {
    if (!validateInput()) {
      return;
    }

    //check to ensure each item is not null or undefined - if so, alert will force user to choose an Item BS 11/27/22
    for (let x = 0; x < localSalesOrder.salesOrderItems.length; x++) {
      if (localSalesOrder.salesOrderItems[x].inventoryItemName == null || localSalesOrder.salesOrderItems[x].inventoryItemName == undefined || localSalesOrder.salesOrderItems[x].inventoryItemName.toString().length < 1) {
        return  alertService.error("There is at least one item in this order that is null/blank. Please select an Item to continue");
      } 
    }

    localSalesOrder.freight = parseDecimal(localSalesOrder.freight);
    localSalesOrder.tax = parseDecimal(localSalesOrder.tax);

    if (salesorder.id <= 0) {
      // post
      trackPromise(fetchService.post("/salesorder", localSalesOrder))
        .then((json) => {
          //Success
          dispatch(updateSalesOrder(json));
          closeHandler();
          refreshPage();
        })
        .catch((error) => {
          alertService.error(error);
          return;
        });
    } else {
      // setIsEditing(true);
      // put
      trackPromise(fetchService.put("/salesorder", localSalesOrder))
        .then((json) => {
          //Success
          dispatch(updateSalesOrder(json));
          closeHandler();
          refreshPage();
        })
        .catch((error) => {
          alertService.error(error);
          return;
        });
    }
  }

  function backBtnHandler() {
    setShowSingleItem(false);
    setEditingItem(false);
  }

  // Close the Modal and update the redux variable to empty object
  // This will force a reload even if the same SalesOrder is opened again
  function closeHandler() {
    setShowModal(false);
    dispatch(resetSalesOrder());
  }

  function deleteItem() {
    console.log("delete item");
  }

  //Show delete button on ActionBar based on different states
  function showDeleteBtn() {
    if (isEditing) {
      if (showSingleItem && editingItem) {
        return true;
      }
      if (showSingleItem && !editingItem) {
        return false;
      }
      return true;
    }
    if (showSingleItem && editingItem) {
      return true;
    } else {
      return false;
    }
  }
//Title that displays in ActionBar based on state of modal
  function actionBarTitle(){
    if (isEditing) {
      if (showSingleItem && editingItem) {
        return "Update Sales Item";
      }
      if (showSingleItem) {
        return "Add Sales Item";
      } else {

      return "Edit Sales Order";
      }
    }
    if (showSingleItem && editingItem) {
      return "Update Sales Item";
    }
    if (showSingleItem) {
      return "Add Sales Item";
    }
    else {
      return "New Sales Order";
    }
  }

  {
    /*REVIEW I dont think we need the below code*/
  }
  // function inventoryItemQuantityReceivedChanged(item, recquantity) {
  //   item.newQuantityReceived = recquantity;
  //   setLocalSalesOrder({ ...localSalesOrder });
  // }

  // function deleteSalesOrder(e) {}

  // function receiveAllItems(e) {
  //   localSalesOrder.salesOrderItems.forEach((item) => {
  //     if (item.outstandingQuantity > 0) {
  //       item.newQuantityReceived = item.outstandingQuantity;
  //       item.newReceivedDate = getTodaysDate();
  //       item.isReceiving = true;
  //       item.lineItemStatus = 2;
  //       item.status = "Fulfilled";

  //       lineItemChangedHandler(item);
  //     }
  //     // setLocalSalesOrder({ ...localSalesOrder });
  //   });
  // }

  const validateInput = () => {
    const form = document.querySelectorAll("#itemSalesOrderForm")[0];
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    setValidated(false);
    return true;
  };

  const currentItem = () => {
    let item = localSalesOrder?.salesOrderItems?.find(
      (i) => i.id === expandedItemId
    );

    return item;
  };

  const getShipRecords = () => {

    console.log(localSalesOrder);

    return localSalesOrder?.shippedRecords ?? [];
  };

  if (loading) {
    return <></>;
  }

  return (
    <Modal
      show={showModal}
      onHide={() => closeHandler()}
      animation={true}
      dialogClassName={"inventorydash_custom_modal_dialog"}
    >
      {/* {itemBackdrop} */}
      <Form
        autoComplete={"off"}
        id={"itemSalesOrderForm"}
        noValidate
        validated={validated}
      >
        {/* {backdrop} */}
        <div className="salesorder_modal_window_container">
          {/* <Modal.Header closeButton className="salesorder_header"> */}
          <Modal.Header
            closeButton
            bsPrefix={
              showSingleItem
                ? "inventorydash_custom_modal_header"
                : "custom_modal_header modal_header_with_fields"
            }
          >
            <>
              <ActionBarAllThings
                pageTitle={actionBarTitle()}
                showBackBtn={true}
                showColBtn={false}
                showSaveBtn={true}
                // showPrintBtn={showSingleItem ? false : true}
                // showEmailBtn={showSingleItem ? false : true}
                saveBtnText={
                  editingItem
                    ? "Update"
                    : isEditing
                    ? "Manage"
                    : showSingleItem
                    ? "Add"
                    : "Save"
                }
                cancelHandler={
                  showSingleItem ? () => backBtnHandler() : () => closeHandler()
                }
                saveHandler={
                  showSingleItem ? () => setShowSingleItem(false) : SaveHandler
                }
                showDelBtn={showDeleteBtn()}
                delBtnText={showSingleItem ? 'Remove' : 'Delete'}
                delHandler={deleteItem()}
                //TODO need to make the delete btn do something when showSingleItem = true
                onModal={true}
              />

              {!showSingleItem ? (
                <div className="salesorder_top_row">
                  <div className="salesorder_line_options">
                    {/*REVIEW Suggest that we remove the below code}
                      {/* <SalesOrderLineOptions
                        title={"Options"}
                        showReceiveAll={true}
                        showRemoveItem={false}
                        showExpand={false}
                        showDeleteSalesOrder={true}
                        deleteSalesOrder={deleteSalesOrder}
                        receiveAll={receiveAllItems}
                      /> */}
                  </div>
                  <div className="top_row_field_container">
                    <div className="salesorder_name noselect">
                      <TextInput
                        name="salesordername"
                        label={windowWidth > 576 ? "SO Number" : "SO #"}
                        value={localSalesOrder.name}
                        required={true}
                        onChange={nameChanged}
                        validatefailtext={
                          "You must enter a name for this sales order."
                        }
                      />
                    </div>

                    <div className="purchaseorder_status noselect">
                      <SalesOrderStatusSelector
                        valueChanged={orderStatusChanged}
                        value={localSalesOrder.userSalesOrderStatus}
                      />
                    </div>

                    <div className="salesorder_customer_selector noselect">
                      <CustomerSelector
                        valueChanged={customerChanged}
                        value={localSalesOrder.customerId}
                        inputref={customerDropdownRef}
                      />
                    </div>
                  </div>

                  <Card>
                    <Card.Header className="salesorder_card_header noselect">
                      {"Add Items to Sales Order"}
                      <Button
                        className="salesorder_add_item_btn"
                        aria-label="add new item to purchase order"
                        onClick={addNewLineItem}
                      >
                        <RiAddFill />
                        {"New Item"}
                      </Button>
                    </Card.Header>
                  </Card>
                </div>
              ) : (
                <></>
              )}
            </>
          </Modal.Header>
          {showSingleItem ? (
            <>
              <ModalBody bsPrefix={"inventorydash_custom_modal_body"}>
                <SalesOrderLineItem
                  item={currentItem()}
                  shipRecords={getShipRecords()}
                  id={"orderLine" + currentItem()?.id.toString()}
                  itemValueChanged={lineItemChangedHandler}
                  measurementTypes={unitMeasureTypes}
                  setShowItemRecInput={setShowItemRecInput}
                  setSelectedSalesOrderItem={setSelectedSalesOrderLineItem}
                  inputref={currentItem()?.ref}
                  setExpandedItemId={setExpandedItemId}
                  expandedItemId={expandedItemId}
                  setCollapsed={() => setShowSingleItem(false)}
                />
              </ModalBody>
            </>
          ) : (
            <>
              <Modal.Body bsPrefix={"inventorydash_custom_modal_body"}>
                <div>
                  <div className="salesorder">
                    <Card className="salesorder_items">
                      <Card.Body onClick={() => setEditingItem(true)}>
                        {/*
REVIEW this code will prevent the SO from saving.  Trying to get the SO to open without a defaulted blank item
{localSalesOrder?.salesOrderItems
                          ?.filter((item) => item.deleted !== true && item.itemId !== 0)
                          .map((item, index) => (
*/}

                        {localSalesOrder?.salesOrderItems
                          ?.filter((item) => item.deleted !== true)
                          .map((item, index) => (
                            <SalesOrderViewOnlyLineItem
                              item={item}
                              id={"orderLine" + item.id.toString()}
                              itemValueChanged={lineItemChangedHandler}
                              measurementTypes={unitMeasureTypes}
                              setShowItemRecInput={setShowItemRecInput}
                              setSelectedSalesOrderItem={
                                setSelectedSalesOrderLineItem
                              }
                              inputref={item.ref}
                              key={index}
                              setExpandedItemId={setExpandedItemId}
                              setSelectedItemId={setSelectedItemId}
                            />
                          ))}
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Modal.Body>
            </>
          )}
          {showSingleItem ? (
            <></>
          ) : (
            <Modal.Footer bsPrefix={"inventorydash_custom_modal_footer"}>
              <div className="salesorder_summary">
                <Col className="salesorder_subtotal noselect">
                  <label>Subtotal</label>
                  <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={localSalesOrder.subtotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    className="calcuatedField"
                  />
                </Col>
                <Col className="salesorder_shipping noselect">
                  <span>{windowWidth > 576 ? "$" : ""}</span>
                  <TextInput
                    name="salesorderfreight"
                    label="Freight"
                    value={
                      localSalesOrder.freight ? localSalesOrder.freight : ""
                    }
                    onChange={freightValueChanged}
                  />
                </Col>
                <Col className="salesorder_tax noselect">
                  <span>{windowWidth > 576 ? "$" : ""}</span>
                  <TextInput
                    name="salesordertax"
                    label="Tax"
                    value={localSalesOrder.tax ? localSalesOrder.tax : ""}
                    onChange={taxValueChanged}
                  />
                </Col>

                <Col className="salesorder_total noselect">
                  <label>Total</label>
                  <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={calculateTotal()}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    className="calcuatedField"
                  />
                </Col>
              </div>
            </Modal.Footer>
          )}
        </div>
      </Form>
    </Modal>
  );
}

export default AddEditSalesOrder;
