import {
    FETCH_CONTACT_BEGIN,
    FETCH_CONTACT_SUCCESS,
    FETCH_CONTACT_FAILURE,
    RESET_CONTACT_LOADED
} from '../actions/singleContactActions';

const initialState = {
    contact: null,
    loading: false,
    error: null,
    contactLoaded: false,
    isError: false
}

export default function contactReducer(state = initialState, action) {
    switch(action.type) {

        case FETCH_CONTACT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                contactLoaded: false,
                isError: false
            };
        case FETCH_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contact: action.payload.contact,
                contactLoaded: true,
                isError: false
            };
        case FETCH_CONTACT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                contactLoaded: false,
                isError: true
            };

        case RESET_CONTACT_LOADED: 

            return {
                ...state,
                contactLoaded: false
            }
        default:

            return state;
    }
}