export const UPDATE_SELECTED_ITEM   = 'UPDATE_SELECTED_ITEM';

export function updateItems(displayitem) {
  return dispatch => {
    dispatch(pushUpdateItems(displayitem))
    }
};

export const pushUpdateItems = displayitem => ({
  type: UPDATE_SELECTED_ITEM,
  payload: displayitem
});
