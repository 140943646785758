import { fetchService } from "../../services";

export const FETCH_PURCHASEORDER_BEGIN = "FETCH_PURCHASEORDER_BEGIN";
export const FETCH_PURCHASEORDER_SUCCESS = "FETCH_PURCHASEORDER_SUCCESS";
export const FETCH_PURCHASEORDER_FAILURE = "FETCH_PURCHASEORDER_FAILURE";

export const UPDATE_SINGLE_PURCHASEORDER = "UPDATE_SINGLE_PURCHASEORDER";

export const RESET_SINGLE_PURCHASEORDER = 'RESET_SINGLE_PURCHASEORDER';
//Fetch
//##########################################################################
export const fetchPurchaseOrderBegin = () => ({
  type: FETCH_PURCHASEORDER_BEGIN,
});

export const fetchPurchaseOrderSuccess = (purchaseorder) => ({
  type: FETCH_PURCHASEORDER_SUCCESS,
  payload: { purchaseorder },
});

export const fetchPurchaseOrderFailure = (error) => ({
  type: FETCH_PURCHASEORDER_FAILURE,
  payload: { error },
});

export function fetchPurchaseOrder(purchaseOrderId) {
  return (dispatch) => {
    dispatch(fetchPurchaseOrderBegin());
    return fetchService
      .get("/purchaseorder/" + purchaseOrderId)
      .then((json) => {
        dispatch(fetchPurchaseOrderSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchPurchaseOrderFailure(error)));
  };
}
//End Fetch
//##########################################################################

//Update List

export function updatePurchaseOrder(purchaseOrder) {
  return (dispatch) => {
    dispatch(pushUpdatePurchaseOrder(purchaseOrder));
  };
}

export const pushUpdatePurchaseOrder = (purchaseOrder) => ({
  type: UPDATE_SINGLE_PURCHASEORDER,
  payload: purchaseOrder,
});

//##########################################################################

export function resetPurchaseOrder() {
  
  return (dispatch) => {
    dispatch(resetPurchaseOrderArgs());
  };
}
const resetPurchaseOrderArgs = () => ({
  type: RESET_SINGLE_PURCHASEORDER
})