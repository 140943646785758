import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Card, Row, Col, Button, Nav } from "react-bootstrap";
import TextInput from "../../Controls/textInput";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import {
  fetchPurchaseOrder,
  updatePurchaseOrder,
  resetPurchaseOrder,
} from "../../../store/actions/singlePurchaseOrderActions";
import { fetchItems } from "../../../store/actions/itemActions";
import { trackPromise } from "react-promise-tracker";
import { fetchService, alertService } from "../../../services";
import { Vendorselector, PurchaseOrderStatusSelector } from "../itemselectors";
import ItemInputUnitSelection from "../items/ItemInputUnitSelection";
import { RiAddFill, RiInboxArchiveLine } from "react-icons/ri";
import { CurrencyFormat } from "../../utilities";
import NumberFormat from "react-number-format";
import { FiTrash, FiCheckSquare, FiSquare } from "react-icons/fi";
import ItemListSelector from "../../Controls/Selectors/ItemListSelector";
import { useWindowDimensions } from "../../../hooks";
import {
  addDecimal,
  multDecimal,
  parseDecimal,
  getTodaysDate,
} from "../../utilities";
import { fetchCustomerMeasurementTypes } from "../../../store/actions/customerMeasurementTypesActions";
import ItemReceivedQuantityInput from "./ItemReceivedQuantityInput";
import PurchaseOrderLineItem from "./PurchaseOrderLineItem"; // Darren Added a Secondary Version for discussion
import { PurchaseOrderLineOptions } from "./PurchaseOrderLineOptions";
import "./addeditpurchaseorder.css";
import ViewOnlyLineItem from "./ViewOnlyLineItem";
import { isEmpty } from "rxjs/operators";
import {
  AddEditItemPage,
  ItemModalHeaderAndBody,
} from "../items/AddEditItemPage";
import { AddEditItemPageContent } from "../items/AddEditItemPageContent";
import { ItemDetail, ItemInfo } from "../items/itemdetails";
import { ItemModalBody } from "../items/ItemModalBody";
import PrintView from "./PrintView";
import { submit } from "redux-form";

function AddEditPurchaseOrder({
  purchaseorderid,
  showModaldialog,
  setShowModalDialog,
  isEditing,
  inputItemId,
  item,
  locationTreeOpened,
  readOnly,
  cancelHandler,
  handleSave,
  itemTabRef,
  detailTabRef,
  vendorTabRef,
  addNewItemToInventory,
  fromItemModal,
  setFromItemModal,
  showLineItemModal,
  setShowLineItemModal,
  newItemSavedHandler,
  newAddedItemId,
  openPrintView,
  printView
}) {
  //for AddEditPurchaseOrder modal animation - modal does not animate when returning from the Line Item Modal
  const [fromLineItemModal, setFromLineItemModal] = useState(false);
  // controls when the addEditItemPage modal shows
  const [showItemModal, setShowItemModal] = useState(false);
  const purchaseorder = useSelector(
    (state) => state.purchaseorder.purchaseorder
  );
  const loading = useSelector((state) => state.purchaseorder.loading);
  const error = useSelector((state) => state.purchaseorder.error);
  const purchaseorderLoaded = useSelector(
    (state) => state.purchaseorder.purchaseorderLoaded
  );
  const windowWidth = useWindowDimensions().width;
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const vendorDropdownRef = useRef(null); //brandon 1/12/21
  const [localPurchaseOrder, setLocalPurchaseOrder] = useState([]);
  const [itemAdded, setItemAdded] = useState(false);

  // Variable to track newly added line items, with negative Ids, descending from 0 down
  const [nextId, setNextId] = useState(0);

  //#region Unit Measurement Types
  const unitMeasureTypes = useSelector((state) => state.measurementtypes.items);
  const unitMeasureTypesLoading = useSelector(
    (state) => state.measurementtypes.loading
  );
  const unitMeasureTypesLoaded = useSelector(
    (state) => state.measurementtypes.itemsLoaded
  );
  const unitMeasureTypesError = useSelector(
    (state) => state.measurementtypes.error
  );

  // UI For Input of Received Quantity
  const [showItemRecInput, setShowItemRecInput] = useState(false);
  const [selectedPOLineItem, setSelectedPOLineItem] = useState(false);

  // Used to scroll and focus new item
  const lineRef = useRef(null);
  const [expandedPOItemId, setExpandedPOItemId] = useState(0);

  //These allow the item name to display on the PO Line item
  const itemsList = useSelector((state) => state.items.items);
  const itemsLoading = useSelector((state) => state.items.loading);
  const itemsError = useSelector((state) => state.items.error);
  const itemsLoaded = useSelector((state) => state.items.itemsLoaded);

  useEffect(() => {
    if (!itemsLoaded && !itemsLoading && itemsError == null) {
      trackPromise(dispatch(fetchItems()));
    }
  }, [itemsLoading, itemsLoaded, itemsError]);

  useEffect(() => {
    if (!unitMeasureTypesLoading && !unitMeasureTypesLoaded) {
      trackPromise(dispatch(fetchCustomerMeasurementTypes()));
    }
  }, [unitMeasureTypes, unitMeasureTypesLoading, unitMeasureTypesLoaded]);
  //#endregion

  useEffect(() => {
    if (purchaseorderid !== purchaseorder?.id && !loading) {
      trackPromise(dispatch(fetchPurchaseOrder(purchaseorderid)));
    }
  }, [purchaseorderid]);

  // Use the purchase order from the server to populate a local version of the purchase order
  // This will be used to interact with the UI and be saved
  useEffect(() => {
    calculatePOTotals(purchaseorder);
    runNextId(purchaseorder.purchaseOrderItems);
  }, [purchaseorder]);

  // If the purchase order updates, it may be new to a new line, if a new line item
  // needs scrolled, we will handle it here
  useEffect(() => {
    let newLine = localPurchaseOrder?.purchaseOrderItems?.find(
      (l) => l.needsScroll === true
    );
    if (newLine != null) {
      if (newLine.ref != null) {
        // newLine.ref.current.scrollIntoView();  //need this to work - causing errors if PO is created from AddEditItem modal and another is added thereafter
        newLine.ref = null;
        newLine.needsScroll = false;
        setExpandedPOItemId(newLine.id);
      }
    }
  }, [localPurchaseOrder]);

  useEffect(() => {
    if (localPurchaseOrder != null && localPurchaseOrder.length > 0) {
      if (inputItemId && inputItemId > 0) {
        if (!itemAdded) {
        }
      }
    }
  }, [localPurchaseOrder, inputItemId]);

  // Function to track newly added line items, with negative Ids, descending from 0 down
  function runNextId(lineItems) {
    let nextid = 0;
    if (lineItems !== undefined) {
      lineItems.forEach((element) => {
        if (element?.id <= nextid) {
          let lowerid = nextid - 1;
          nextid = lowerid;
        }
      });
    }
    setNextId(nextid);
  }

  function calculateTotal() {
    let runningTotal = 0;
    runningTotal += Number(localPurchaseOrder.tax);
    runningTotal += Number(localPurchaseOrder.freight);
    runningTotal += Number(localPurchaseOrder.subtotal);
    return runningTotal;
  }

  function setSelectedPOItemId(itemid) {
    setExpandedPOItemId(itemid);
    setShowLineItemModal(true);
  }

  //function to calculate totals when things change
  function calculatePOTotals(purchaseOrder) {
    let stotal = 0.0;
    let total = 0.0;

    if (purchaseOrder != null) {
      if (purchaseOrder.purchaseOrderItems != null) {
        purchaseOrder.purchaseOrderItems.forEach((item) => {
          let lineTotal = multDecimal(
            parseDecimal(item.quantity),
            parseDecimal(item.purchasePrice)
          );
          stotal += lineTotal;
        });

        total = parseDecimal(stotal);
        total = addDecimal(total, parseDecimal(purchaseOrder?.freight));
        total = addDecimal(total, parseDecimal(purchaseOrder?.tax));

        purchaseOrder.subtotal = parseDecimal(stotal, 2);
        purchaseOrder.total = parseDecimal(total, 2);
      }
    }
    setLocalPurchaseOrder({ ...purchaseOrder });
  }

  function vendorChanged(vendorid, vendorname) {
    let tempPurchaseOrder = { ...localPurchaseOrder };
    tempPurchaseOrder.vendorId = vendorid;
    tempPurchaseOrder.vendorName = vendorname;
    setLocalPurchaseOrder(tempPurchaseOrder);
  }

  function poStatusChanged(statusid, statustext) {
    localPurchaseOrder.userStatus = statustext;
    localPurchaseOrder.userPurchaseOrderStatus = statusid;
    setLocalPurchaseOrder({ ...localPurchaseOrder });
  }

  function freightValueChanged(e) {
    localPurchaseOrder.freight = e.target.value;
    calculatePOTotals(localPurchaseOrder);
    calculateTotal();
  }
  function taxValueChanged(e) {
    let tax = e.target.value;
    localPurchaseOrder.tax = tax;
    calculatePOTotals(localPurchaseOrder);
    calculateTotal();
  }
  // Fires anytime any detail of a line item changes
  function lineItemChangedHandler(item) {
    item.outstandingQuantity =
      (item.quantity ? item.quantity : 0) -
      (item.quantityReceived ? item.quantityReceived : 0) -
      (item.newQuantityReceived ? item.newQuantityReceived : 0);

    let lineitem = localPurchaseOrder.purchaseOrderItems.find(
      (i) => i.id === item.id
    );

    let index = localPurchaseOrder.purchaseOrderItems.indexOf(lineitem);
    if (lineitem != null) {
      localPurchaseOrder.purchaseOrderItems = [
        ...localPurchaseOrder.purchaseOrderItems
          .slice(0, index)
          .concat(item)
          .concat(localPurchaseOrder.purchaseOrderItems.slice(index + 1)),
      ];

      calculatePOTotals(localPurchaseOrder);
    }
  }

  // Add a purchase order line item
  function addNewLineItem() {
    if (_.isEmpty(localPurchaseOrder)) {
      return;
    }

    let itemId = 0;

    if (inputItemId && inputItemId > 0 && !itemAdded) {
      itemId = inputItemId;
      localPurchaseOrder.purchaseOrderItems = [];
    }

    let lineItem = {
      id: nextId,
      itemId: itemId,
      quantity: 0,
      expectedDate: new Date(),
      quantityReceived: 0,
      outstandingQuantity: 0,
      purchasePrice: null,
      needsScroll: true, // Add these two properties to allow scrolling into view needsScroll and lineRef
      // The scroll is handled in a useEffect after the purchase order updates
      ref: lineRef,
      itemName: "",
    };
    localPurchaseOrder.purchaseOrderItems = [
      ...localPurchaseOrder.purchaseOrderItems.concat(lineItem),
    ];
    setItemAdded(true);
    // setFromLineItemModal(true);
    setShowLineItemModal(true);
    setExpandedPOItemId(lineItem.id);
    setLocalPurchaseOrder({ ...localPurchaseOrder });
    runNextId(localPurchaseOrder.purchaseOrderItems);
  }

  function SaveHandler() {
    if (!validateInput()) {
      return;
    }

    //check to ensure each line item name is not null or undefined - if so, alert will force user to choose an Item BS 11/27/22
    for (let x = 0; x < localPurchaseOrder.purchaseOrderItems.length; x++) {
      if (
        localPurchaseOrder.purchaseOrderItems[x].itemName == null ||
        localPurchaseOrder.purchaseOrderItems[x].itemName == undefined ||
        localPurchaseOrder.purchaseOrderItems[x].itemName.toString().length < 1
      ) {
        return alertService.error(
          "There is at least one item in this order that is null/blank. Please select an Item to continue"
        );
      }
    }

    localPurchaseOrder.freight = parseDecimal(localPurchaseOrder.freight);
    localPurchaseOrder.tax = parseDecimal(localPurchaseOrder.tax);
    if (purchaseorder.id <= 0) {
      // post
      trackPromise(fetchService.post("/purchaseorder", localPurchaseOrder))
        .then((json) => {
          //Success
          dispatch(updatePurchaseOrder(json));
          closeHandler();
        })
        .catch((error) => {
          alertService.error(error);
          return;
        });
    } else {
      // setIsEditing(true);
      // put
      trackPromise(fetchService.put("/purchaseorder", localPurchaseOrder))
        .then((json) => {
          //Success
          dispatch(updatePurchaseOrder(json));
          closeHandler();
        })
        .catch((error) => {
          alertService.error(error);
          return;
        });
    }
  }

  // Close the Modal and update the redux variable to empty object
  // This will force a reload even if the same PO is opened again
  function closeHandler() {
    setShowModalDialog(false);
    dispatch(resetPurchaseOrder());
    setItemAdded(false);
    // setFromLineItemModal(false);
  }

  function inventoryItemQuantityReceivedChanged(item, recquantity) {
    item.newQuantityReceived = recquantity;
    setLocalPurchaseOrder({ ...localPurchaseOrder });
  }

  //TODO need to program the delete btn
  function deletePurchaseOrder(e) {
  }

  function receiveAllItems(e) {
    localPurchaseOrder.purchaseOrderItems.forEach((item) => {
      if (item.outstandingQuantity > 0) {
        item.newQuantityReceived = item.outstandingQuantity;
        item.newReceivedDate = getTodaysDate();
        item.isReceiving = true;
        item.lineItemStatus = 2;
        item.status = "Fulfilled";
        lineItemChangedHandler(item);
      }
    });
  }

  const validateInput = () => {
    const form = document.querySelectorAll("#itemPurchaseOrderForm")[0];
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    setValidated(false);
    return true;
  };

  const currentItem = () => {
    let item = localPurchaseOrder?.purchaseOrderItems?.find(
      (i) => i.id === expandedPOItemId
    );
    return item;
  };

  if (loading) {
    return <></>;
  }

  if (inputItemId && inputItemId > 0 && !itemAdded) {
    addNewLineItem();
  }

  function getItemNames() {
    if (localPurchaseOrder.purchaseOrderItems != null) {
      localPurchaseOrder.purchaseOrderItems.forEach((item) => {
        if (item.itemName == null || item.itemName === "") {
          item.itemName = itemsList.find(
            (i) => i.id == item.itemId
          )?.displayName;
        }
      });
    }
  }

  function collapseLineItem() {
    setShowLineItemModal(false);
  }

  if (itemsLoaded === true) {
    if (localPurchaseOrder != null) {
      getItemNames();
    }
  }

  function purchaseOrderPageTitle() {
    if (printView) {
      return "Purchase Order # " + purchaseorder.poNumber;
    } else if (isEditing) {
      if (windowWidth > 800) {
        return "Edit Purchase Order";
      } else {
        return "Edit P.O.";
      }
    } else {
      if (windowWidth > 800) {
        return "New Purchase Order";
      } else {
        return "New P.O.";
      }
    }
  }

  return (
    <>
      {showLineItemModal ? (
        <PurchaseOrderLineItem
          item={currentItem()}
          id={"orderLine" + currentItem()?.id.toString()}
          itemValueChanged={lineItemChangedHandler}
          measurementTypes={unitMeasureTypes}
          setShowItemRecInput={setShowItemRecInput}
          setSelectedPOLineItem={setSelectedPOLineItem}
          inputref={currentItem()?.ref}
          itemAdded={itemAdded}
          setItemAdded={setItemAdded}
          setFromLineItemModal={setFromLineItemModal}
          fromLineItemModal={fromLineItemModal}
          collapseLineItem={collapseLineItem}
          setShowLineItemModal={setShowLineItemModal}
          showLineItemModal={showLineItemModal}
          addNewItemToInventory={addNewItemToInventory}
          newItemSavedHandler={newItemSavedHandler}
          newAddedItemId={newAddedItemId}
        />
      ) : (
        <Form
          autoComplete={"off"}
          id={"itemPurchaseOrderForm"}
          noValidate
          validated={validated}
        >
          <div className={printView ? "printView_purchaseorder_modal_window_container" :  "purchaseorder_modal_window_container"}>
            <Modal.Header
              closeButton={printView ? false : true}
              bsPrefix={"inventorydash_custom_modal_header"}
            >
              <>
                <ActionBarAllThings
                  pageTitle={purchaseOrderPageTitle()}
                  showBackBtn={printView ? false : true}
                  showColBtn={false}
                  showSaveBtn={printView ? false : true}
                  showPrintBtn={printView ? false : true}
                  saveBtnText={isEditing ? "Update" : "Save"}
                  cancelHandler={() => closeHandler()}
                  saveHandler={SaveHandler}
                  showDelBtn={printView ? false : isEditing ? true : false}
                  onModal={true}
                  showHelpButton={printView ? false : true}
                  printHandler={openPrintView}
                  delHandler={deletePurchaseOrder()}
                />
                <div className="purchaseorder_top_row">
                  <div className="purchaseorder_line_options">
                    {printView ? (
                      ""
                    ) : (
                      <PurchaseOrderLineOptions
                        title={"P.O. Options"}
                        showReceiveAll={true}
                        showRemoveItem={false}
                        showExpand={false}
                        showDeletePO={true}
                        deletePurchaseOrder={deletePurchaseOrder}
                        receiveAll={receiveAllItems}
                      />
                    )}
                  </div>
                  <div className="top_row_field_container">
                    <div className="purchaseorder_number noselect">
                     {printView ? "" : (
                       <TextInput
                       name="purchaseorder"
                       label={windowWidth > 576 ? "PO Number" : "PO #"}
                       value={purchaseorder.poNumber}
                       readOnly={true}
                       required={true}
                       validatefailtext={
                         "You must enter a name for this purchaseorder."
                       }
                     />
                     )}
                    </div>

                    <div className="purchaseorder_status noselect">
                     {printView ? "" : (
                       <PurchaseOrderStatusSelector
                       valueChanged={poStatusChanged}
                       value={localPurchaseOrder.userPurchaseOrderStatus}
                     />
                     )}
                    </div>

                    <div className= {printView ? "printViewVendor" : "purchaseorder_vendor_selector noselect"} >
                       <Vendorselector
                       valueChanged={vendorChanged}
                       value={localPurchaseOrder.vendorId}
                       inputref={vendorDropdownRef}
                       printView={printView}
                    
                     />
                    </div>
                  </div>

                  {printView ? (
                    ""
                  ) : (
                    <Card>
                      <Card.Header className="purchaseorder_card_header noselect">
                        {"Add Items to P.O."}
                        <Button
                          className="purchaseorder_add_item_btn"
                          aria-label="add new item to purchase order"
                          onClick={addNewLineItem}
                        >
                          <RiAddFill />
                          {"New Item"}
                        </Button>
                      </Card.Header>
                    </Card>
                  )}
                </div>
              </>
            </Modal.Header>

            <Modal.Body bsPrefix={"inventorydash_custom_modal_body"}>
              <div className="">
                <div className="purchaseorder">
                  <Card className={printView ? "printView_purchaseorder_items" : "purchaseorder_items"}>
                    <Card.Body className={printView ? "printView_purchaseorder_items" : ""}>
                      {localPurchaseOrder?.purchaseOrderItems
                        ?.filter((item) => item.deleted !== true)
                        .map((item, index) => (
                          /*ViewOnlyLineItem is the list of line items shown on the PO Modal*/
                          <ViewOnlyLineItem
                            item={item}
                            id={"poLine" + item.id.toString()}
                            itemValueChanged={lineItemChangedHandler}
                            measurementTypes={unitMeasureTypes}
                            setShowItemRecInput={setShowItemRecInput}
                            setSelectedPOItem={setSelectedPOLineItem}
                            inputref={item.ref}
                            key={index}
                            setExpandedPOItemId={setExpandedPOItemId}
                            expandedPOItemId={expandedPOItemId}
                            setSelectedPOItemId={setSelectedPOItemId}
                            setFromLineItemModal={setFromLineItemModal}
                          />
                        ))}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer bsPrefix={printView ? "printView_inventorydash_custom_modal_footer" : "inventorydash_custom_modal_footer"}>
              <div className="purchaseorder_summary">
                <Col className="purchaseorder_subtotal noselect">
                  <label>Subtotal</label>
                  <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={localPurchaseOrder.subtotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    className="calcuatedField"
                  />
                </Col>
                <Col className="purchaseorder_shipping noselect">
                  <span>{windowWidth > 576 ? "$" : ""}</span>
                  <TextInput
                    name="purchaseorderfreight"
                    label="Freight"
                    value={
                      localPurchaseOrder.freight
                        ? localPurchaseOrder.freight
                        : ""
                    }
                    onChange={freightValueChanged}
                  />
                </Col>
                <Col className="purchaseorder_tax noselect">
                  <span>{windowWidth > 576 ? "$" : ""}</span>
                  <TextInput
                    name="purchaseordertax"
                    label="Tax"
                    value={localPurchaseOrder.tax ? localPurchaseOrder.tax : ""}
                    onChange={taxValueChanged}
                  />
                </Col>

                <Col className="purchaseorder_total noselect">
                  <label>Total</label>
                  <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={calculateTotal()}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    className="calcuatedField"
                  />
                </Col>
              </div>
            </Modal.Footer>
          </div>
        </Form>
      )}
    </>
  );
}

export default AddEditPurchaseOrder;
