import {Decimal} from 'decimal.js-light';

export function parseDecimal(input, decimalPlaces = 2) {
    
    let d = new Decimal(0);
    try {
        d = new Decimal(input.toString().replace(/\,/g, '').replace('$', ''));
    }
    catch {

        // alertService.error("Unable to convert input to decimal.");
    }
    
    d.decimalPlaces = decimalPlaces;
    d = new Decimal(d.toDecimalPlaces(decimalPlaces).toPrecision());
    return d.toNumber();
    
//     Rounding Mode Enumerator
// Property	Value	Description
// ROUND_UP	0	Rounds away from zero
// ROUND_DOWN	1	Rounds towards zero
// ROUND_CEIL	2	Rounds towards Infinity
// ROUND_FLOOR	3	Rounds towards -Infinity
// ROUND_HALF_UP	4	Rounds towards nearest neighbour.
// If equidistant, rounds away from zero
// ROUND_HALF_DOWN	5	Rounds towards nearest neighbour.
// If equidistant, rounds towards zero
// ROUND_HALF_EVEN	6	Rounds towards nearest neighbour.
// If equidistant, rounds towards even neighbour
// ROUND_HALF_CEIL	7	Rounds towards nearest neighbour.
// If equidistant, rounds towards Infinity
// ROUND_HALF_FLOOR	8	Rounds towards nearest neighbour.
// If equidistant, rounds towards -Infinity
}

export function multDecimal(base, multiplier) {
    if (!base) {
        return parseDecimal(0);
    }
    if (!multiplier) {
        return parseDecimal(0);
    }
    let d = new Decimal(parseDecimal(base));
    return parseDecimal(d.mul(multiplier));
}

export function divideDecimal(base, divider) {
    if (!base) {
        return parseDecimal(0);
    }
    if (!divider) {
        return parseDecimal(0);
    }
    let d = new Decimal(parseDecimal(base));
    return parseDecimal(d.div(divider));
}

export function multDecimalPercentages(base, percentageMultiplier){
    if (!base) {
        return parseDecimal(0);
    }
    if (!percentageMultiplier) {
        return parseDecimal(0);
    }
    let d = new Decimal(parseDecimal(base));
    let dMult = new Decimal(parseDecimal(percentageMultiplier.toString().replace(/\%/g, '')));
    dMult = new Decimal(divideDecimal(dMult, 100));
    return parseDecimal(d.mul(dMult));
}

export function addDecimal(valx, valy) {
    if (!valx) {
        return parseDecimal(valy);
    }
    if (!valy) {
        return parseDecimal(valx);
    }
    let x = new Decimal(parseDecimal(valx));
    let y = new Decimal(parseDecimal(valy));
    return parseDecimal(x.add(y));
}

export function subtractDecimal(valx, valy){
    if (!valx) {
        return parseDecimal(valy);
    }
    if (!valy) {
        return parseDecimal(valx);
    }
    let x = new Decimal(parseDecimal(valx));
    let y = new Decimal(parseDecimal(valy));
    return parseDecimal(x.minus(y));
}

export function differenceDecimals(valx, valy){
    if (!valx) {
        return parseDecimal(0);
    }
    if (!valy) {
        return parseDecimal(0);
    }
    let x = new Decimal(parseDecimal(valx));
    let y = new Decimal(parseDecimal(valy));
    return parseDecimal(Math.abs(x - y));
}