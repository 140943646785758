import { fetchService } from '../../services';

export const FETCH_USERROLE_BEGIN   = 'FETCH_USERROLE_BEGIN';
export const FETCH_USERROLE_SUCCESS = 'FETCH_USERROLE_SUCCESS';
export const FETCH_USERROLE_FAILURE = 'FETCH_USERROLE_FAILURE';

//Fetch
//##########################################################################
export const fetchUserRoleBegin = () => ({
  type: FETCH_USERROLE_BEGIN
});

export const fetchUserRoleSuccess = userrole => ({
  type: FETCH_USERROLE_SUCCESS,
  payload: { userrole }
});

export const fetchUserRoleFailure = error => ({
  type: FETCH_USERROLE_FAILURE,
  payload: { error }
});

export function fetchUserRole(roleid) {
    
    return dispatch => {
      dispatch(fetchUserRoleBegin());

      return fetchService.get( "/userroles/" + roleid)
        .then(json => {
          dispatch(fetchUserRoleSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchUserRoleFailure(error)));
    };
  }
//End Fetch
//##########################################################################
