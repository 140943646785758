import {
    FETCH_USERROLE_BEGIN,
    FETCH_USERROLE_SUCCESS,
    FETCH_USERROLE_FAILURE
  } from '../actions/userRoleActions';
  
  const initialState = {
    userrole: [],
    loading: false,
    isError: false,
    error: null,
    userroleLoaded: false
  };
  
  export default function userRoleReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_USERROLE_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          isError: false,
          userroleLoaded: false
        };
  
      case FETCH_USERROLE_SUCCESS:
        // All done: set loading "false".
        // Also, replace the userrole with the ones from the server
        return {
          ...state,
          loading: false,
          isError: false,
          userrole: action.payload.userrole,
          userroleLoaded: true
        };
  
      case FETCH_USERROLE_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have userrole to display anymore, so set `userrole` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the userrole around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          isError: true,
          error: action.payload.error,
          userrole: []
        };
  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }