import { CHANGE_DRAWER_STATE } from "../../constants/action-types";

const initialState = {
  drawerstate: false
};

export default function drawerstateReducer(state = initialState, action) {
    switch(action.type)
    {
        case CHANGE_DRAWER_STATE :
            return Object.assign({}, state, {
                drawerstate: !state.drawerstate
              });

        default :
            return state;
    };
}
