import { fetchService } from '../../services';

export const FETCH_USERS_BEGIN   = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const POST_USER_BEGIN   = 'POST_USER_BEGIN';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILURE = 'POST_USER_FAILURE';

export const PUT_USER_BEGIN   = 'PUT_USER_BEGIN';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_FAILURE = 'PUT_USER_FAILURE';

export const UPDATE_USERS = 'UPDATE_USERS';

//Fetch
//##########################################################################
export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});

export const fetchUsersSuccess = users => ({
  type: FETCH_USERS_SUCCESS,
  payload: { users }
});

export const fetchUsersFailure = error => ({
  type: FETCH_USERS_FAILURE,
  payload: { error }
});

export function fetchUsers() {
    return dispatch => {
      dispatch(fetchUsersBegin());

      return fetchService.get( "/useradmin" )
        .then(json => {
          dispatch(fetchUsersSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchUsersFailure(error)));
    };
  }
//End Fetch
//##########################################################################

//Post
//##########################################################################
export const postUserBegin = () => ({
  type: POST_USER_BEGIN
});

export const postUserSuccess = user => ({
  type: POST_USER_SUCCESS,
  payload: { user }
  
});

export const postUserFailure = error => ({
  type: POST_USER_FAILURE,
  payload: { error }
});

export function postUser(user) {
    return dispatch => {
      dispatch(postUserBegin());
      return fetchService.post("/users", user)
        .then(json => {
          dispatch(postUserSuccess(json));
          return json;
        })
        .catch(error => dispatch(postUserFailure(error)));
    };
  }
//End Post
//##########################################################################


//Put
//##########################################################################
export const putUserBegin = () => ({
  type: PUT_USER_BEGIN
});

export const putUserSuccess = user => ({
  type: PUT_USER_SUCCESS,
  payload: { user }
});

export const putUserFailure = error => ({
  type: PUT_USER_FAILURE,
  payload: { error }
});

export function putUser(user, id) {
  
    return dispatch => {
      dispatch(putUserBegin());
      return fetchService.put("/users/" + id , user)
        .then(json => {
          dispatch(putUserSuccess(json));
          return json;
        })
        .catch(error => dispatch(putUserFailure(error)));
    };
  }
//End Put
//##########################################################################

//Update List

export function updateUsers(user) {
 
  return dispatch => {
    dispatch(pushUpdateUsers(user))
    }
};

export const pushUpdateUsers = user => ({
  type: UPDATE_USERS,
  payload: user
});

//##########################################################################