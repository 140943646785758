import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { Form } from 'react-bootstrap';
import { fetchCustomers } from '../../../store/actions/customerActions';
import { LoadingPlaceholder } from '../../Controls/LoadingPlaceholder';

function CustomerSelector({ valueChanged, value, required, inputref, readOnly }) {
    const customers = useSelector(state => state.customers?.customers);
    const loading = useSelector(state => state.customers?.loading);
    const error = useSelector(state => state.customers?.error);
    const typedataloaded = useSelector(state => state.customers?.customersLoaded);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!typedataloaded) {
            trackPromise(
                dispatch(fetchCustomers())
            );
        }
    }, [typedataloaded, dispatch]);


    function customerValueChanged(e) {
        let typeid = parseInt(e.target.value);
        let typename = customers.find(e => e.id === typeid)?.displayName;
        valueChanged(typeid, typename);
    }

    if (error) {
        return <div>Error! {error.message}</div>;
    }

    if (loading) {
        return <LoadingPlaceholder labeltext="Type" />
    }

    return (
        <div>
            <Form.Group>
                <Form.Label>Customer</Form.Label>
                <Form.Control
                    as="select"
                    onChange={customerValueChanged}
                    value={value}
                    required={required}
                    ref={inputref}
                    disabled={readOnly}
                >
                    <option key={0} value=""></option>
                    {
                        customers.map(customer =>
                            <option key={customer.id}
                                value={customer.id}
                            >
                                {customer.displayName}
                            </option>
                        )
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please Select a Type
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );

}

export { CustomerSelector };