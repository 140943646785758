import React from "react";
import Form from "react-bootstrap/Form";

const TextInput = ({
  name,
  onChange,
  placeholder,
  label,
  value,
  id,
  key,
  required,
  validatefailtext,
  disabled,
  readOnly,
  onFocus,
  onKeyPress,
  inputref,
  isEmail = false,
  isPhone = false
}) => {
  function onFocusHandler(event) {
    if (onFocus != null) {
      onFocus(event);
    } else {
      event.target.setAttribute("autocomplete", "new-password");
    }
  }

  return (
    <div className="form-group">
      <input
        type="password"
        autoComplete={"new-password"}
        style={{
          width: 0,
          height: 0,
          visibility: "hidden",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
      <Form.Label aria-label={label} htmlFor={name}>
        {label}
      </Form.Label>
      <div className="field">
        <input
          autoComplete={"new-input-" + name}
          id={id}
          key={key}
          type={isEmail ? "email" : isPhone ? "tel" : "text"}
          aria-label={label}
          name={name}
          className="form-control"
          placeholder={placeholder}
          value={value ?? ""}
          onChange={onChange}
          required={ required ? true : isEmail && (value ?? '') != '' ? true : false }
          disabled={disabled}
          readOnly={readOnly}
          ref={inputref}
        />
        <Form.Control.Feedback type="invalid">
          {validatefailtext}
        </Form.Control.Feedback>
      </div>
    </div>
  );
};

export default TextInput;
