import React from 'react';
import { Form, InputGroup, Button, FormControl } from 'react-bootstrap';
import { FiMinus, FiPlus } from 'react-icons/fi';
// import { BsCaretDown, BsCaretUp } from 'react-icons/bs';
// import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import './numericupdown.css';

export default function NumericUpDown({ label = '', name, value, onChange, min, max, disabled }) {

    function valueChanged(e) {
        onChange(getValidatedValue(e.target.value));


        // onChange(e);
    }

    function decreaseValue() {
        onChange(getValidatedValue(value - 1));
    }
    function increaseValue() {
        onChange(getValidatedValue(value + 1));
    }

    function getValidatedValue(val) {
        let newval = parseInt(val);
        if (newval > max) {
            newval = max;
        }
        if (newval < min) {
            newval = min;
        }
        if (isNaN(newval)) {
            newval = '';
        }
        return newval;
    }



    return (
            <div className="form-group ">
            <input
                type="password"
                autoComplete={"new-password"}
                style={{
                    width: 0,
                    height: 0,
                    visibility: "hidden",
                    position: "absolute",
                    left: 0,
                    top: 0,
                }}
            />
            {
                label === ''
                    ?
                    <></>
                    :
                    <Form.Label aria-label={label} htmlFor={name}>
                        {label}
                    </Form.Label>
            }

            <div className="input_form_container">
            <InputGroup className="mb-3 numericUpDownContainer">
            <Button
                        className={'decrease-button'}
                        onClick={decreaseValue}
                        disabled={disabled}
                        aria-label={"decrease qty by 1"}
                    ><FiMinus/> {/*minus sign*/}
                                 {/* <IoMdArrowDropdown /> */}
                    </Button>
                <FormControl
                    aria-label={label}
                    value={value == null ? '' : value}
                    onChange={valueChanged}
                    disabled={disabled}
                    className={"numericUpDown_input"}
                />
                <InputGroup.Append>
              
                <Button
                        className={'increment-button'}
                        onClick={increaseValue}
                        disabled={disabled}
                        aria-label={"increase qty by 1"}
                    ><FiPlus/> {/*plus sign*/}
                        {/* <IoMdArrowDropup /> */}
                    </Button>
                   
          
                </InputGroup.Append>
            </InputGroup>
            </div>
                   </div>
           )
}