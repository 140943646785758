import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Toolbar from './Toolbar/Toolbar';
import SideDrawer from './SideDrawer/SideDrawer';
import { Router } from "react-router-dom";
import {
  Switch,
  Route,
} from "react-router-dom";

import store from '../store';
import { changeDrawerState, changeDropMenuState } from '../store/actions';
import { changeSettingsClicked } from '../store/actions/userInteractionActions';
import Routes from '../routes';
import { history } from '../services';
import Backdrop from "./Backdrop/Backdrop";
// import DropMenuPanel from "./dropmenu/dropmenupanel";
import { PrivateRoute } from './PrivateRoute';
import { LoadingSpinner } from './loading/LoadingSpinner';
import { Alert } from './Alert';
import { useSelector } from 'react-redux';
import HelpDisplayComponent from './Help/HelpDisplayComponent';
import InventoryDashModal from '../components/Layout/modal/InventoryDashModal';

const Layout = () => {
  const sideDrawerOpen = useSelector(state => state.drawerstate.drawerstate);
  const [dropMenuState, setDropMenuState] = useState(store.getState().dropmenustate);
  const dispatch = useDispatch();


  // This use effect listens for a navigation to occur,
  // when it does it closes the side drawer
  // effectively cause the hamburger menu to close once you select an item/link
  useEffect(() => {
    // UseEffect returns an unsubscribe to event listener
    // so when we create the listener we return its result
    // which is an unsubscribe
    // once it is done it is removed from the pipeline
    return history.listen(location => {
      if (sideDrawerOpen) {
        // special handling if we are going to settings from the side menu
        // this will ensure that the settings page closes/hides any child 
        // components that may currently be displayed
        // this is the scenario of going to settings, choosing a sub-page
        // and then clicking settings again, this forces it to revert to base page
        // also a little hacky doing this here
        if (location.pathname === '/Settings') {
          dispatch(changeSettingsClicked(true));
        }
        dispatch(changeDrawerState(!sideDrawerOpen));
      }
    });
  }, [sideDrawerOpen, dispatch]);

  function drawerToggleClickHandler() {
    store.dispatch(changeDrawerState());
    // setSideDrawerOpen(!sideDrawerOpen);
  };

  function dropMenuClickHandler() {
    store.dispatch(changeDropMenuState());
    setDropMenuState(!dropMenuState);
  };

  let mainclasses = ["main-app-space"];
  let backdrop = "";

  if (sideDrawerOpen === true) {
    mainclasses = ["main-app-space open"];
    backdrop = <Backdrop backdropClicked={drawerToggleClickHandler}  ></Backdrop>
  }


  return (
    <div className="App">

      <HelpDisplayComponent />

      <LoadingSpinner />
      <Alert />
      <Router history={history}>
        <Toolbar
          dropmenustate={dropMenuState}
          changeDropMenuState={dropMenuClickHandler}
          drawerClickHandler={drawerToggleClickHandler}
          drawerOpen={sideDrawerOpen}
        />
        {backdrop}
        <SideDrawer show={sideDrawerOpen} />




        {/* <InventoryDashModal show={showModal}/> */}





        <main className="main">
          {/* <DropMenuPanel /> */}
          <div className={mainclasses}>
            <Switch>
              {
                /* All Routes are configured in the routes.js file in the source, adding a route to that file will also add it to the main menus */
                Routes.map((route, idx) => {
                  if (route.private === true) {
                    return route.component ? (
                      <PrivateRoute
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component {...props} />
                        )} />
                    ) : (null);

                  }
                  else {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component {...props} />
                        )} />
                    ) : (null);
                  }
                })
              }
            </Switch>
          </div>
        </main>
      </Router>
    </div>
  );
}

export default Layout;