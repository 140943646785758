import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FormControl from "react-bootstrap/Form";
import Form from "react-bootstrap/Form";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import TextInput from "../../Controls/textInput";
import "./currencyTaxes.css";
import InventoryDashCheckbox from "../../Controls/InventoryDashCheckbox";

function CurrencyTaxesComponent({ setCurrencyTaxesComponentVisible, placeholder }) {

  function handleSave() {
  }

  return (
    <>
      <ActionBarAllThings
        pageTitle={'Currency & Taxes'}
        showBackBtn
        cancelHandler={() => setCurrencyTaxesComponentVisible(false)}
        showSaveBtn={true}
        saveHandler={handleSave}
      />

      <Container fluid className="settings_taxes_currency_form_container">
        <div class="default_currency_div">
          <FormControl />
          <Row>
            <Form.Label className="default_currency_label noselect">
              Default Currency
            </Form.Label>
          </Row>
          <Form.Control
            className="default-currency-dropdown"
            as="select"
            aria-label="Choose default currency"
          >
            <option>US Dollar</option>
            <option>CAN Dollar</option>
            <option>Yen</option>
            <option>AUD</option>
            <option>Euro</option>
          </Form.Control>
        </div>

        <div class="currency_taxes_tax_rate_div">
          <Row>
            <Col className="tax-rate noselect">
              {/* <Form.Label className="tax_rate_label">Tax Rate</Form.Label> */}
              {/* <InputGroup>

              
                <input placeholder="0.00"></input>
                <div>%</div>
              </InputGroup> */}

              <TextInput
                name="taxRate"
                label="Tax Rate"
                placeholder="0.00"

              >

              </TextInput>

            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <div class="currency_taxes_currency_use_div">
          <Row >
            <Col>
              <Form.Label className="currency_used_label noselect">Select Currency that is used</Form.Label>

              <InventoryDashCheckbox
                label="US Dollar"
              />

              <InventoryDashCheckbox
                label="CAN Dollar"
              />

              <InventoryDashCheckbox
                label="Yen"
              />

              <InventoryDashCheckbox
                label="AUD"
              />

              <InventoryDashCheckbox
                label="Euro"
              />

            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export { CurrencyTaxesComponent };
