import React, { useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { useState, useRef } from "react";
import "./itemdetail.css";
import { FiCamera } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ItemInputUnitSelection from "../ItemInputUnitSelection";

function ItemDetail({
  item,
  onInputValueChanged,
  unitMeasureTypes,
  updateUnitSelection,
  selectedTab,
  setSelectedTab,
  readOnly
}) {
  // const [sizeUnit, setSizeUnit] = useState("In");
  // const [sizeWeightUnit, setWeightUnit] = useState("lbs");
  const [expirationDate, setExpirationDate] = useState(null);
  const detailRef = useRef(null);
  // const leftColWid = 7;
  // const rightColWid = 5;
  // const threeColWid = 4;

  useEffect(() => { //focuses first input field after tab is clicked
    if (selectedTab === "details") {
      detailRef.current.focus();
      // setSelectedTab("");
    }
  },[selectedTab])

  function setExpirationDateOnItem(e) {
    setExpirationDate(e);
    item.expirationDate = e;
  }

  return (
    <>
      <div className="item-form-container">
          <Row className="item_description_image_row" xs={1} sm={1} md={1}>
            <Col
               lg={9}
               md={8}
              className="item_add_edit_col noselect"
            >
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  aria-label="Item Description"
                  as="textarea"
                  rows="3"
                  name="description"
                  onChange={onInputValueChanged}
                  value={item.description ? item.description : ""}
                  ref={detailRef}
                  readOnly={readOnly}
                />
              </Form.Group>
            </Col>
            <Col 
              lg={2}
               md={3}
              className="item-detail-image-col noselect"
            >
                {
                  item.itemDetailImage === undefined ? (
                   <div className="item_camera_icon_small_div">
                    <FiCamera
                      className="item_camera_icon"
                      stroke="#ced4da"
                      size="3rem"
                    ></FiCamera></div>
                  ) : (
                    <Image src="" roundedCircle />
                  ) /*Need to show the image from the incoming object*/
                }
                {/* <FileDialog /> */}
            </Col>
          </Row>

          <Row className="add_edit_item_row" xs={1} sm={2} md={2}>
            <Col className="item_add_edit_col noselect">
              <ItemInputUnitSelection
                lbltext="Size (Length)"
                name={"length"}
                itemvalue={item.length}
                unitTypeValue={item.lengthUnitType}
                onInputValueChanged={onInputValueChanged}
                unittypename={"lengthUnitType"}
                unittypebasemt={0}
                unitMeasureTypes={unitMeasureTypes}
                updateUnitSelection={updateUnitSelection}
                readOnly={readOnly}
              />
            </Col>
            <Col className="item_add_edit_col noselect">
              <ItemInputUnitSelection
                lbltext="Size (Width)"
                name={"width"}
                itemvalue={item.width}
                unitTypeValue={item.widthUnitType}
                onInputValueChanged={onInputValueChanged}
                unittypename={"widthUnitType"}
                unittypebasemt={1}
                unitMeasureTypes={unitMeasureTypes}
                updateUnitSelection={updateUnitSelection}
                readOnly={readOnly}
              />
            </Col>

            <Col className="item_add_edit_col noselect">
              <ItemInputUnitSelection
                lbltext="Size (Height)"
                name={"height"}
                itemvalue={item.height}
                unitTypeValue={item.heightUnitType}
                onInputValueChanged={onInputValueChanged}
                unittypename={"heightUnitType"}
                unittypebasemt={2}
                unitMeasureTypes={unitMeasureTypes}
                updateUnitSelection={updateUnitSelection}
                readOnly={readOnly}
              />
            </Col>

            <Col className="item_add_edit_col noselect">
              <ItemInputUnitSelection
                lbltext="Size (Weight)"
                name={"weight"}
                itemvalue={item.weight}
                unitTypeValue={item.weightUnitType}
                onInputValueChanged={onInputValueChanged}
                unittypename={"weightUnitType"}
                unittypebasemt={3}
                unitMeasureTypes={unitMeasureTypes}
                updateUnitSelection={updateUnitSelection}
                readOnly={readOnly}
              />
            </Col>

            <Col className="item_add_edit_col noselect">
              <Form.Group>
                <Form.Label>SKU</Form.Label>
                <Form.Control
                  as="input"
                  aria-label="SKU"
                  name="sku"
                  onChange={onInputValueChanged}
                  value={item.sku ? item.sku : ""}
                  disabled={readOnly}
                />
              </Form.Group>
            </Col>

            <Col className="item_add_edit_col noselect">
              <Form.Group   className="item_expiration_date_picker">
                <Form.Label>Expiration Date</Form.Label>
                <div className="datepicker-container">
                  <DatePicker
                    className="datepicker_input_field"
                    selected={expirationDate}
                    onChange={setExpirationDateOnItem}
                    value={item.expirationDate}
                    dateFormat="MM/d/yyyy"
                    readOnly={readOnly}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
      </div>
    </>
  );
}

export { ItemDetail };
