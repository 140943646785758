import React from 'react';
import { useDispatch } from 'react-redux';
import { showHelpView } from '../../store/actions/uiActions';
import './helpdisplaycomponent.css';

function HelpBackdrop() {
    const dispatch = useDispatch();
    function hideHelpComponent() {
        dispatch(showHelpView(false));
    }

    return (
        <div className="inventory-dash-help-backdrop" onClick={ hideHelpComponent } />
    )
    
}   

export default HelpBackdrop; 