import {
    FETCH_PURCHASEORDERS_BEGIN,
    FETCH_PURCHASEORDERS_SUCCESS,
    FETCH_PURCHASEORDERS_FAILURE
  } from '../actions/purchaseOrderActions';
  
  import {
    UPDATE_SINGLE_PURCHASEORDER
  } from '../actions/singlePurchaseOrderActions';

  const initialState = {
    purchaseorders: [],
    loading: false,
    error: null,
    purchaseordersLoaded: false,
    itemPosting: false
  };
  
  export default function purchaseOrderReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_PURCHASEORDERS_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null,
          purchaseordersLoaded: false
        };
  
      case FETCH_PURCHASEORDERS_SUCCESS:
        // All done: set loading "false".
        // Also, replace the purchaseorders with the ones from the server
        return {
          ...state,
          loading: false,
          purchaseorders: action.payload.purchaseorders,
          purchaseordersLoaded: true
        };
  
      case FETCH_PURCHASEORDERS_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have purchaseorders to display anymore, so set `purchaseorders` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the purchaseorders around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          purchaseorders: []
        };

      case UPDATE_SINGLE_PURCHASEORDER:
        
            //  We have a new or updated Item added in the software
            //  need to update the master underlying list to account for it
            let existingitem = state.purchaseorders.find(e => e.id === action.payload.id);
            let index = state.purchaseorders.indexOf(existingitem);
            //See if it already exists, if not add it to the list
            if (index < 0)
            {
              //If we are here we are going to add to the end of the list
              return {
                ...state,
                purchaseorders: [...state.purchaseorders.concat(action.payload)]
              }
            }
            else
            {
                //If we are here we are going to slice the array and insert the updated item 
                //This keeps everything clean as far as rules of immutable go
                return { 
                  ...state, 
                  purchaseorders: [
                    ...state.purchaseorders.slice(0,index)
                    .concat(action.payload)
                    .concat(state.purchaseorders.slice(index+1))
                    ]
                  }
            }
  
  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }