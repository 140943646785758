import React, { useState, useRef, useEffect } from "react";
import { Modal, FormControl, InputGroup } from "react-bootstrap";
import "../searchbox/searchmodal.css";
import { RiSearch2Line } from "react-icons/ri";
import { IoMdCloseCircle, IoIosBarcode } from "react-icons/io";
import QRScanReader from '../../Controls/Scanner/QRScanReader';
import "./searchbar.css";

export function SearchBar({ needFocused, setNeedFocused, runSearch, setShowReadCode, showReadCode }) {

  const [searchText, setSearchText] = useState();
  const searchboxRef = useRef(null);
  /*showScanner variable declared and set to nothing*/

  //    [currentState,    setNewState] = useState(defaultValue);
  const [showScannerIcon, setShowScannerIcon] = useState(true);
  const [showSearchBtn, setSearchBtn] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState(false);


  /*keeps focus on searchfield after a filter item is clicked*/
  useEffect(() => {
    if (needFocused) {
      searchboxRef.current.focus();
      setNeedFocused(false);
    }
  }, [needFocused]);

  function executeSearch() {
    runSearch(searchText); // Pass the search text to parent to execute search
  }

  /*function that clears input and sets focus on input when the clear icon is clicked*/
  function clearInput() {
    setSearchText("");
    setNeedFocused(true);
    setShowClearIcon(false);
    setShowScannerIcon(true);
    setSearchBtn(false);
  }

  function searchTextChangeHandler(e) {
    setSearchText(e.target.value)
    if (e.target.value === '') {
      setShowScannerIcon(true);
      setSearchBtn(false);
      setShowClearIcon(false);
    } else {
      setShowScannerIcon(false);
      setSearchBtn(true);
      setShowClearIcon(true);
    }
  }

  return (
    <>
      <div className="search_modal_search_header">
        <div className="search_modal_search">
          <InputGroup id="searchbox" className="search_modal_search_field">
            <FormControl
              aria-label="search"
              placeholder=" Search"
              autoFocus={true}
              ref={searchboxRef}
              value={searchText}
              onChange={searchTextChangeHandler}
              onKeyUp={(e) => e.key === "Enter" ? executeSearch() : ''}
              className="search_input"
            />

            {showClearIcon ? (
              <IoMdCloseCircle size="1.35em"
                onClick={() => clearInput()}
                className="clear_search_icon"
              />
            ) : (
                <></>
              )}
          </InputGroup>

          {showSearchBtn ? (
            <RiSearch2Line
              className="search_modal_search_icon"
              size="1.5rem"
              aria-label="search button"
              onClick={executeSearch}
            />
          ) : (
              <></>
            )}
          {showScannerIcon ? (
            <IoIosBarcode
              cursor="pointer"
              size="1.7rem"
              className="barcode_scanner_icon"
              aria-label="barcode scanner"
              onClick={() => setShowReadCode(true)}
            />
          ) : (
              <></>
            )}
        </div>
      </div>
    </>
  );
}
