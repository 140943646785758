import React, { useState } from "react"; //import useState from react
import { Button,  Row, Col } from "react-bootstrap";
import "./customFieldsComponent.css";
import {ActionBarAllThings} from "./../../Layout/ActionBar";

function CustomFieldsComponent({ setCustomFieldsComponentVisible }) {
 
  return (
    <>
      <ActionBarAllThings 
      pageTitle={'Custom Fields'}
      showBackBtn
      cancelHandler={() => setCustomFieldsComponentVisible(false)}
      />

      <div className="yellow">
        <Row className="test" sm={1} med={2} lg={5}>
          <Col>
            <Button>Click me</Button>
          </Col>
          <Col>
            <Button>Click </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export { CustomFieldsComponent };
