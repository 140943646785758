import {
    FETCH_CUSTOMER_BEGIN,
    FETCH_CUSTOMER_SUCCESS,
    FETCH_CUSTOMER_FAILURE
} from '../actions/singleCustomerActions';

const initialState = {
    customer: null,
    loading: false,
    error: null,
    customerLoaded: false,
    iserror: false
};

export default function customerReducer(state = initialState, action) {
    switch(action.type) {
        
        case FETCH_CUSTOMER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                customerLoaded: false,
                iserror: false
            };
        case FETCH_CUSTOMER_SUCCESS:

        
            return {
                ...state,
                loading: false,
                customer: action.payload.customer,
                customerLoaded: true,
                iserror: false
            };
        case FETCH_CUSTOMER_FAILURE:

            return {
                ...state,
                loading: false,
                error: action.payload.error,
                customer: null,
                iserror: true
            };
       
        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}