import React, { useState } from 'react';


export const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {

      let sortableItems = [];
      if (items != null) {
        sortableItems = [...items];
      } 

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {

          let astr = a[sortConfig.key]?.toString().toLocaleLowerCase();
          let bstr = b[sortConfig.key]?.toString().toLocaleLowerCase();
          astr = (astr === undefined) ? "" : astr;
          bstr = (bstr === undefined) ? "" : bstr;

          if (!isNaN(astr) && !isNaN(bstr))
          {
            let anum = parseFloat(astr);
            let bnum = parseFloat(bstr);
            if(anum > bnum) {
              return  (sortConfig.direction === 'ascending' ? 1 : -1)
            }
            if(anum < bnum) {
              return  (sortConfig.direction === 'ascending' ? -1 : 1)
            }
            return 0;
          }

          return (astr.localeCompare(bstr) * (sortConfig.direction === 'ascending' ? 1 : -1));

        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = key => {
        
      let direction = 'ascending';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    }
  
    return { items: sortedItems, requestSort, config: sortConfig };
  }