import {
  CHANGE_APP_THEME_STATE,
  SHOW_HELP_VIEW_STATE,
  SET_HELP_VIEW_TOPIC_STATE,
} from "../actions/uiActions";

const initialState = {
  theme: localStorage.getItem("theme"),
  showHelpView: false,
  helpViewTopic: "",
};

export default function uiStateReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_APP_THEME_STATE:
      localStorage.setItem("theme", action.payload);
      return {
        ...state,
        theme: action.payload,
      };

    case SHOW_HELP_VIEW_STATE:
      return {
        ...state,
        showHelpView: action.payload,
      };

    case SET_HELP_VIEW_TOPIC_STATE:
      return {
        ...state,
        helpViewTopic: action.payload,
      };
    default:
      return state;
  }
}
