import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { FiEdit2, FiTrash, FiPrinter, FiInfo, FiList } from "react-icons/fi";
import { BsLayoutThreeColumns } from "react-icons/bs";
import { VscSave } from "react-icons/vsc";
import { RiAddFill, RiFilter3Fill } from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";
import {
  IoIosArrowBack as ExitIcon,
  IoIosHelpCircleOutline, IoMdSettings
} from "react-icons/io";
import { MdSortByAlpha } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import "../Layout/actionbar.css";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { showHelpView, setHelpViewTopic } from "../../store/actions/uiActions";



export default function ActionBarAllThingsFooter({
  onModal = false, //when adding Actionbar to modal, set this to true
  showFooter = true, //when marked as false, the footer will not display on page
  saveIcon,
  /*buttons*/
  showBackBtn = false,
  showSaveBtn = false,
  saveBtnText = "Save", //text on btn can be changed to say "update" when editing
  showColBtn = false,
  showEditBtn = false,
  showAddBtn = false,
  showDelBtn = false,
  showViewBtn = false,
  showSortBtn = false,
  showPrintBtn = false,
  showEmailBtn = false,
  showFilterIcon = false,
  filterAppliedIndicator = null,

  /*handlers that perform actions*/
  saveHandler = null,
  delHandler = null,
  addItem = null,
  cancelHandler = null,
  editItem = null,
  viewItem = null,
  adjustColumns = null,
  printHandler = null,
  emailHandler = null,
  filterHandler = null
}) {
  const windowWidth = useWindowDimensions().width;

  const isMobileScreen = windowWidth <= 576;


 

  /*actiobar is broken up in sections that display on chiild component whenever
such section is marked as true*/

  if (showFooter === false) {
    return <></>;
  } else
    return (
      //when actionbar is on modal, onModal must be marked as true.  This
      //sets proper height when on a modal
      <div
        className={
          (onModal ? "actionbar_row_modal" : "actionbar_row") +
          " actionbar_footer"
        }
      >
        {/*back button*/}
        {showBackBtn ? (
          <div className="actionbar_cancel_back_btn">
            <Button
              type="cancel"
              className="cancel_exit_button"
              variant="outline-light"
              aria-label="cancel button"
              onClick={cancelHandler == null ? null : cancelHandler}
            >
              <ExitIcon
                className="back_btn_icon"
                size={isMobileScreen ? "2rem" : "2.5rem"}
              />
            </Button>
          </div>
        ) : (
          <></>
        )}

        <div className="actionbar_btn_container">
          {/*Edit button - shows after an item is selected*/}
          {showEditBtn ? (
            <div className="actionbar_edit_btn_div">
              <Button
                className="actionbar_edit_btn"
                arial-label="edit"
                onClick={editItem == null ? null : editItem}
              >
                {" "}
                {isMobileScreen ? "" : "Edit"}
                <FiEdit2 size="1.2rem" className="small_button_edit_icon" />
              </Button>
            </div>
          ) : (
            <></>
          )}

           {/*View button - shows after an item is selected and no edit permissions*/}
           {showViewBtn ? (
            <div className="actionbar_edit_btn_div">
              <Button
                className="actionbar_edit_btn"
                arial-label="view"
                onClick={viewItem == null ? null : viewItem}
              >
                {" "}
                {isMobileScreen ? "" : "View"}
                <FiList size="1.2rem" className="small_button_edit_icon" />
              </Button>
            </div>
          ) : (
            <></>
          )}

          {/*Add New  button*/}
          {showAddBtn ? (
            <div className="actionbar_add_btn_div">
              <Button
                className="actionbar_add_btn"
                arial-label="add"
                onClick={addItem == null ? null : addItem}
              >
                <RiAddFill className="small_button_add_icon" size={"2rem"} />
              </Button>
            </div>
          ) : (
            <></>
          )}

          {/*Add Filter Icon*/}
          {showFilterIcon ? (
          <div className="actionbar_col_btn_div">
      
              <RiFilter3Fill
                className="small_button_add_icon"
                arial-label="Filters"
                size={"2rem"}
                onClick={filterHandler == null ? null : filterHandler}
              />
              {filterAppliedIndicator > 0 ? (
                <div className ='filterAppliedIcon'>
                {filterAppliedIndicator}
                </div>
              ) : <></>}
              
          </div>
        ) : (
          <></>
        )}

          {/*Sort Icon Button*/}
          {showSortBtn ? (
            <div className="actionbar_sort_btn_div">
              <MdSortByAlpha
                className="sort_icon"
                size="1.5rem"
                color="var(--actionbar_font)"
              />
            </div>
          ) : (
            <></>
          )}

          {/*column icon button - brings up column modal when clicked*/}
          {showColBtn ? (
            <div className="actionbar_col_btn_div">
              <Button
                data-tip="Adjust Columns"
                className="actionbar_col_btn"
                arial-label="columns"
                onClick={adjustColumns == null ? null : adjustColumns}
              >
                <BsLayoutThreeColumns
                  size="1.75rem"
                  className="small_button_col_icon"
                ></BsLayoutThreeColumns>
                {isMobileScreen ? (
                  <></>
                ) : (
                  <ReactTooltip
                    place="left"
                    backgroundColor="var(--button_small_background)"
                    textColor="var(--button_small_font)"
                    borderColor="var(--button_small_border)"
                  />
                )}
              </Button>
            </div>
          ) : (
            <></>
          )}

          {showEmailBtn ? (
            <div className="actionbar_email_div">
              <AiOutlineMail size="1.5rem" onClick={emailHandler} />
            </div>
          ) : (
            <></>
          )}

          {showPrintBtn ? (
            <div className="actionbar_print_div">
              <FiPrinter size="1.5rem" onClick={emailHandler} />
            </div>
          ) : (
            <></>
          )}

          {/*delete button - onyl shows after an item has been edited*/}
          {showDelBtn ? (
            <div className="actionbar_del_div">
              <Button
                className="actionbar_del_btn"
                onClick={delHandler == null ? null : delHandler}
              >
                {isMobileScreen ? (
                  <FiTrash size="1.6rem" className="small_button_del_icon" />
                ) : (
                  <>Delete</>
                )}
              </Button>
            </div>
          ) : (
            <></>
          )}

          {/*save button - shows after a new item is created or edited*/}
          {showSaveBtn ? (
            <div className="actionbar_save_div">
              <Button
                className="actionbar_save_btn"
                onClick={saveHandler == null ? null : saveHandler}
              >
               {saveIcon}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
}
