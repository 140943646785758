import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import  TextInput  from '../../../Controls/textInput';
import { ActionBarAllThings } from "../../../Layout/ActionBar";
import { fetchLocation, updateLocation } from '../../../../store/actions/itemLocationActions'
import { trackPromise } from 'react-promise-tracker';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { fetchService, alertService} from '../../../../services';
import { InventoryDashModal } from '../../../Layout/modal/InventoryDashModal';
import { InventoryDashModalHeader } from '../../../Layout/modal/InventoryDashModalHeader';
import { InventoryDashModalBody } from '../../../Layout/modal/InventoryDashModalBody';

function AddEditLocation({locationid, showModal, setShowModal, parentLocationId}) {
    const location = useSelector(state => state.location.location);
    const loading = useSelector(state => state.location.loading);
    const error = useSelector(state => state.location.error);
    const locationLoaded = useSelector(state => state.location.locationLoaded);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [locName, setLocName] = useState(location.name);
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const windowWidth = useWindowDimensions().width;

    useEffect(() => {
        if (locationid !== location?.id && !loading) {
            trackPromise(
                dispatch(fetchLocation(locationid))
            );
        }
    }, [locationid])

    function setLocationName(e) {
        location.displayName = e.target.value;
        setLocName(location.displayName);
    }

    function SaveHandler() {
        if (!validateInput())
        {
            return;
        }
        // Make sure the parent id is set on the child for updates/inserts in the UI
        location.parentLocationId = parentLocationId;
        if (location.id <= 0)
        {
            
            // post
            setIsSubmitting(true);
            trackPromise(
                fetchService.post('/itemlocations', location)
            )
            .then(json => {
                //Success
                dispatch(updateLocation(json));
                setIsSubmitting(false);
                setShowModal(false);
            })
            .catch(error => {
                setIsSubmitting(false);
                alertService.error(error);
                return;
            }); 
           
         }
        else
        {
            // put
            setIsSubmitting(true);
            trackPromise(
                fetchService.put('/itemlocations' , location)
            )
            .then(json => {
                //Success
                dispatch(updateLocation(json));
                setIsSubmitting(false);
                setShowModal(false);
            })
            .catch(error => {
                setIsSubmitting(false);
                alertService.error(error);
                return;
            }); 
        }
    }

    // function keyPressed(e) {
    //     if (e.key === 'Enter')
    //     {
    //         SaveHandler();
    //     }
    // }

    const validateInput = () => {
        
        const form = document.querySelectorAll('#itemLocationForm')[0];
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }
        
        setValidated(false);
        return true;
    };

    if (loading) {
        return <></>
    }
    if (error != null) {
        return <div>{'Error: ' + error.message}</div>
    }

    return (
    <>
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            dialogClassName={"inventorydash_custom_modal_dialog"}
            // centered = {windowWidth <= 576 ? false : true}
            >
        <Modal.Header closeButton bsPrefix={"custom_modal_header"}>
            <ActionBarAllThings
              pageTitle="Location"
              showBackBtn={true}
              showColBtn={false}
              showSaveBtn={true}
              saveBtnText={isEditing ? "Update" : "Save"}
              cancelHandler={() => setShowModal(false)}
              saveHandler={SaveHandler}
              showDelBtn={isEditing ? true : false}
              onModal={true}
            />
        </Modal.Header>

        <Modal.Body bsPrefix={"custom_modal_body"}>
            <div className={'page_container_padding noselect'}>
            <Form
                autoComplete={"off"}
                id={"itemLocationForm"}
                noValidate
                validated={validated}
                >
                <Row className="no-border">
                    <Col>
                        <TextInput
                            name="location"
                            label="Location Name"
                            value={location.displayName}
                            onChange={setLocationName}
                            required={true}
                            validatefailtext={'You must enter a name for this location.'}
                            // onKeyPress={keyPressed}
                        />
                    </Col>
                </Row>

            </Form>
            </div>
        </Modal.Body>
      </Modal>
</>      
    )
}


// <InventoryDashModal
//             showModal={showModal}
//             setShowModal={setShowModal}
//         >
//             <InventoryDashModalHeader>
//                 <ActionBarAllThings
//                     pageTitle="Location"
//                     showBackBtn={true}
//                     showColBtn={false}
//                     showSaveBtn={true}
//                     saveBtnText={isEditing ? "Update" : "Save"}
//                     cancelHandler={() => setShowModal(false)}
//                     saveHandler={SaveHandler}
//                     showDelBtn={isEditing ? true : false}
//                     onModal={true}
//                 />
//             </InventoryDashModalHeader>
//             <InventoryDashModalBody>
//             <div className={'page_container_padding'}>
//             <Form
//                 autoComplete={"off"}
//                 id={"itemLocationForm"}
//                 noValidate
//                 validated={validated}
//                 >
//                 <Row className="no-border">
//                     <Col>
//                         <TextInput
//                             name="location"
//                             label="Location"
//                             value={location.displayName}
//                             onChange={setLocationName}
//                             required={true}
//                             validatefailtext={'You must enter a name for this location.'}
//                             onKeyPress={keyPressed}
//                         />
//                     </Col>
//                 </Row>

//             </Form>
//             </div>
//             </InventoryDashModalBody>
//         </InventoryDashModal>

export default AddEditLocation;