import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { ActionBarAllThings } from '../../Layout/ActionBar';
import ContactInfo from './contactDetails/contactInfo';
import './addeditcontactpage.css';
import { fetchContact, resetContact } from '../../../store/actions/singleContactActions';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';

export default function AddEditContactPage({
    contactid, 
    onContactValueChanged, 
    cancelHandler, 
    saveHandler, 
    fromContactModal, 
    setFromContactModal,
    addingOrEditingContact
}) {
    const contact = useSelector(state => state.singleContact.contact);
    const loading = useSelector(state => state.singleContact.loading);
    const contactLoaded = useSelector(state => state.singleContact.contactLoaded);

    // Create a local version of the contact loaded from server
    // this allows us to update the contact
    const [localContact, setLocalContact] = useState(null);

    const [validated, setValidated] = useState(false);
    // const isEditing = !(contact?.id === 0);
    const isEditing = !(contactid === 0);

    // Create a dispatch object to use for dispatching redux functions
    const dispatch = useDispatch();


    useEffect(() => {
        if (!loading && !contactLoaded) {
            trackPromise(
                dispatch(fetchContact(contactid))
            );
        }
    }, [contactid, loading, contactLoaded]);

    useEffect(() => {
        if (contact?.id !== contactid && contact != null && !loading)
        {
            trackPromise(
                dispatch(fetchContact(contactid))
            );
        }
    }, [contactid, contact, loading]);

    useEffect(() => {
        setLocalContact(contact);
    }, [contact])


    function localCancelHandler() {
        // This will ensure that next time we try to add/edit a contact
        // it will get loaded
        dispatch(resetContact());

        // Raise Cancel on Parent
        cancelHandler()
    }

    function onContactInputValueChanged(e) {
        e.persist();

        let newValue = e.target.value;
        
        setLocalContact((prevState) => ({
            ...prevState,
            [e.target.name]: newValue
            })
        );
    }

    function contactPhoneChanged(name, value) {
     

        let newValue = value;
        
        setLocalContact((prevState) => ({
            ...prevState,
            [name]: newValue
            })
        );
    }

    const validateInput = () => {
        const form = document.querySelectorAll('#contactInfoForm')[0];
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }

        setValidated(false);
        return true;
    }

    // Darren 11/9
    // The save should be occurring within this component on save, 
    // and the resulting object can be pushed to the list of contacts
    // which will force an update on the parent
    function localSaveHandler() {
        if (!validateInput())
        {
            return false;
        }
      

        saveHandler(localContact);
    }
    

    return (
        // modal form to edit Contact
        <Modal
            show={addingOrEditingContact}
            onHide={cancelHandler}
            dialogClassName={"inventorydash_custom_modal_dialog"}
            animation={false}
            onShow={() => setFromContactModal(true)}
        >
            <div>   
            <Modal.Header
                closeButton
                bsPrefix={"inventorydash_custom_modal_header"}
           >
                <ActionBarAllThings
                    pageTitle={(isEditing ? "Mange Contact" : "Add Contact")}
                    showBackBtn = {true}
                    showSaveBtn = {true}
                    showDelBtn = {(isEditing ? true : false)}
                    cancelHandler = {localCancelHandler}
                    saveHandler = {localSaveHandler}
                    onModal={true}
                    saveBtnText={(isEditing ? "Update" : "Add")}
                    
                />
            </Modal.Header>
            
            <Modal.Body bsPrefix={"inventorydash_custom_modal_body"}>
                {/* Form element : Needed for 'required' element in TextInputs */}
                <Form
                    autoComplete={'off'}
                    id={'contactInfoForm'}
                    name={'contact_info_edit_form'}
                    noValidate
                    validated={validated}
                    className="add_edit_contact_form"
                >
                    {/* Contact control */}
                    <ContactInfo
                        contact={localContact}
                        onContactValueChanged={onContactInputValueChanged}
                        contactPhoneChanged={contactPhoneChanged}
                    />
                </Form>
            </Modal.Body>
            </div>
        </Modal>
    );

}