import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { Form } from 'react-bootstrap';
import { fetchItemGroups } from '../../../store/actions/itemGroupActions';
import { LoadingPlaceholder } from '../../Controls/LoadingPlaceholder';

function ItemGroupSelector({ valueChanged, value, required, inputref, readOnly }) {
    const groups = useSelector(state => state.itemGroups?.itemgroups);
    const loading = useSelector(state => state.itemGroups?.loading);
    const error = useSelector(state => state.itemGroups?.error);
    const groupdataloaded = useSelector(state => state.itemGroups?.itemgroupsLoaded);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!groupdataloaded) {
            trackPromise(
                dispatch(fetchItemGroups())
            );
        }
    }, [groupdataloaded, dispatch]);


    function groupValueChanged(e) {
        let groupid = parseInt(e.target.value);
        let groupname = groups.find(e => e.id === groupid)?.displayName;
        valueChanged(groupid, groupname);
    }

    if (error) {
        return <div>Error! {error.message}</div>;
    }

    if (loading) {
        return <LoadingPlaceholder labeltext="Group" />
    }

    return (
        <div>
            <Form.Group>
                <Form.Label>Group</Form.Label>
                <Form.Control
                    as="select"
                    onChange={groupValueChanged}
                    value={value}
                    required={required}
                    ref={inputref}
                    disabled={readOnly}
                >
                    <option key={0} value=""></option>
                    {
                        groups.map(group =>
                            <option key={group.id}
                                value={group.id}
                            >
                                {group.displayName}
                            </option>
                        )
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please Select a Group
            </Form.Control.Feedback>
            </Form.Group>
        </div>
    );

}

export { ItemGroupSelector };