import React from 'react';
import { UnitOfMeasureCheck } from './UnitOfMeasureCheck';
import { Form, Col } from 'react-bootstrap';
import "./../unitofMeasureComponent.css";

export default function UnitOfMeasureSection({types, headerName, className, updateItem}) {


    // Length = 0,
    // Width = 1,
    // Height = 2,
    // Weight = 3,
    // Volume = 4,
    // Quantity = 5,
    // UnitCountType =6


    return (
        <div className={className} >
            <Col className="unit_of_measure_col"><div className="uom_label_div">
            <Form.Label className="uom-form-label noselect">{headerName}</Form.Label>

            </div>
                {
                    //Just mapped the incoming types to checkboxes, they are already filtered by type
                types
                    .map(item => (
                    <UnitOfMeasureCheck unitofmeasureitem={item} updateItem={updateItem} />
                ))}
            </Col>
        </div>
    );
}

export { UnitOfMeasureSection };