import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { fetchReturns } from "../../../store/actions/returnsActions";
import { useSelector, useDispatch } from "react-redux";
import { alertService, fetchService } from "../../../services";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import "./returns.css";
import AddEditReturn from "./AddEditReturn";
import { updateReturns } from "../../../store/actions/returnsActions";
import WindowedTable from "../../Controls/Tables/WindowedTable";
import { getTodaysDate } from "../../utilities";


const colHeaders = [
  {
    displayName: "Return Date",
    propName: "returnDate",
    colclassname: "",
    dataclassname: '',
    selected: true,
    displayorder: 0,
    isdate: true
  },
  {
    displayName: "Customer",
    propName: "customerName",
    colclassname: "",
    dataclassname: '',
    selected: true,
    displayorder: 1
  },
  {
    displayName: "Return Fee",
    propName: "returnFee",
    colclassname: "dollar_prefix",
    selected: true,
    displayorder: 2,
    iscurrency: true
  },
  {
    displayName: "Subtotal",
    propName: "subtotal",
    colclassname: "dollar_prefix",
    selected: true,
    displayorder: 2,
    iscurrency: true
  },
  {
    displayName: "Total",
    propName: "total",
    colclassname: "dollar_prefix",
    selected: true,
    displayorder: 2,
    iscurrency: true
  },

  {
    displayName: "# of Items",
    propName: "",
    //TODO ^^^ Need to display number of items
    selected: true,
    displayorder: 2,
    iscurrency: true
  },
];

export default function Returns() {
  const returns = useSelector(state => state.returns.returns);
  const loading = useSelector(state => state.returns.loading);
  const error = useSelector(state => state.returns.error);
  const returnsLoaded = useSelector(state => state.returns.error);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(0);
  const [startReturn, setStartReturn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedReturnId, setSelReturn] = useState(null);
  const [showEditButton, setShowEditBtn] = useState(false);
  const [currentReturnObject, setCurrentReturnObj] = useState(null);

  const dispatch = useDispatch();

 

  useEffect(() => {
    if (!returnsLoaded) {
      trackPromise(dispatch(fetchReturns()));
    }
  }, [returnsLoaded, dispatch]);


  function showAlert() {
    alertService.info("Test Alerts");
  }

  function setSelectedReturnId(singleReturn) {
    setSelReturn(singleReturn.id);
    if (singleReturn.id != null && singleReturn.id > 0) {
      setShowEditBtn(true);
      setCurrentReturnObj(returns?.find(e => e.id === singleReturn.id));
    }
    else {
      setCurrentReturnObj(null);
    }
  }

  function completeReturn(returnData) {
    console.log("completeReturn", returnData);


    saveReturn(returnData);


    setShowModal(false);
    setStartReturn(false);
  }

  function saveReturn(singleReturn) {
    if (singleReturn === undefined) {
      return;
    }



    // Determine if we're creating or updating
    if (singleReturn.id === 0) {
      createReturn(singleReturn);
    } else {
      updateReturn(singleReturn);
    }
  }

  function addItem(){
    setIsEditing(false);
    setCurrentReturnObj(
      {
        "id": 0,
        "returnId": 0,
        "customerId": 0,
        "subtotal": 0,
        "returnFee": 0,
        "total": 0,
        "returnDate": getTodaysDate(),
        "returnItems": [],
        "salesOrderId": 0
      }
    )
    setShowModal(1);
  }

  function editItem() {
    setIsEditing(true);
    setShowModal(2);
  }

  // Create a brand new return
  function createReturn(singleReturn) {
    if (singleReturn !== undefined) {
      setIsSubmitting(true);
      fetchService
        .post("/returns/", singleReturn)
        .then((json) => {
          // Success
          dispatch(updateReturns(json));
          setIsSubmitting(false);
          setShowModal(false);
        })
        .catch((error) => {
          setIsSubmitting(false);
          alertService.error(error);
          return;
        });
    }
  }

  // Update an existing Return
  function updateReturn(singleReturn) {
    if (singleReturn !== undefined) {
      setIsSubmitting(true);

      fetchService
        .put(`/returns/${singleReturn.id}`, singleReturn)
        .then((json) => {
          // Success
          dispatch(updateReturns(json));
          setIsSubmitting(false);
          setShowModal(false);
        })
        .catch((error) => {
          setIsSubmitting(error);
          alertService.error(error);
          return;
        });
    }
  }

  return (
    <>
      <ActionBarAllThings
        pageTitle={"Returns"}
        // showSaveBtn={selectedReturnId ? true : false}
        saveBtnText="Alert"
        saveHandler={showAlert}
        showAddBtn={true}
        showEditBtn={showEditButton}
        addItem={addItem}
        editItem={editItem}
      />
      {showModal >= 1 ? (
        <AddEditReturn
          startReturn={startReturn}
          setStartReturn={setStartReturn}
          completeReturn={completeReturn}
          isEditing={isEditing}
          showModal={showModal}
          setShowModal={setShowModal}
          isSubmitting={isSubmitting}
          currentReturnObject={currentReturnObject}
        />
      ) : (
        <></>
      )}
       <div className="page_container">
       <WindowedTable
        colheaders={colHeaders}
        inputitems={returns}
        idname={"id"}
        tablenameidentifier={"returns_list"}
        searchText={""}
        setSelectedId={setSelectedReturnId}
        selectedId={selectedReturnId}
        defaultSort={"returnDate"}
      />
       </div>
      
    </>
  );
}

