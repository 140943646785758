import React from 'react';
import "./DrawerToggle.css";

const drawerToggle = props => {
    let buttonClassestop = ["toggle-button__linetop"];
    let buttonClassesmid = ["toggle-button__linemid"];
    let buttonClassesbot = ["toggle-button__linebot"];

    if (props.drawerOpen === true)
    {
        buttonClassestop = ["toggle-button__linetop open"];
        buttonClassesmid = ["toggle-button__linemid open"];
        buttonClassesbot = ["toggle-button__linebot open"];
    }

    return (
        
        <div className="toggle-button" onClick={ props.click }>
            <div className={ buttonClassestop }></div>
            <div className={ buttonClassesmid }></div>
            <div className={ buttonClassesbot }></div>
        </div>

    );
};

export default drawerToggle;