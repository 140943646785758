import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DisplayTable from './Controls/DisplayTable';
import SimpleDisplayTable from './Controls/SimpleDisplayTable';
import { FormControlBar } from '../components/Layout/formControl/FormControlBar';
import { FaPuzzlePiece } from 'react-icons/fa';
// import { fetchNewContact } from '../store/actions/singleContactActions';

export default function ContactList({contacts, addHandler, editHandler, saveHandler, deleteHandler}) {

    const [selContactID, setSelContactID] = useState(-1);

    const colHeaders = [
        {
            displayName: "Contact Name" ,
            propName: "displayName",
            colclassname: "",
            dataclassname: '', 
            selected: true,
            displayorder: 0
        },
        {
            displayName: "Email" ,
            propName: "emailAddress",
            colclassname: "",
            dataclassname: '', 
            selected: true,
            displayorder: 0
        },
        {
            displayName: "Phone" ,
            propName: "phoneNumber",
            colclassname: "",
            dataclassname: '', 
            selected: true,
            displayorder: 0
        }
    ];

    function addContact() {
        setSelContactID(0);
        addHandler();
    }

    function editContact() {
        editHandler(selContactID);
    }

    function setSelectedContact(contact) {
        setSelContactID(contact.id);
    }
    
    return (
        <>
            <FormControlBar
                showEditBtn={ selContactID >= 0 ? true : null}
                showDelBtn={ selContactID >= 0 ? true : null}
                addHandler={addContact}
                editHandler={editContact}
            /> 
{/* 
            <DisplayTable  12/6/2020
                inputitems={contacts}
                colheaders={colHeaders}
                selectCurrentItem={setSelectedContact}
                idname={'id'}
            /> */}

            <SimpleDisplayTable
                tablenameidentifier={'customer_contact_list'}
                inputitems={contacts}
                colheaders={colHeaders}
                selectCurrentItem={setSelectedContact}
                idname={'id'}
            />
        </>
    );
}
