import { fetchService } from "../../services";

export const FETCH_SALESORDER_BEGIN = "FETCH_SALESORDER_BEGIN";
export const FETCH_SALESORDER_SUCCESS = "FETCH_SALESORDER_SUCCESS";
export const FETCH_SALESORDER_FAILURE = "FETCH_SALESORDER_FAILURE";

export const UPDATE_SINGLE_SALESORDER = "UPDATE_SINGLE_SALESORDER";

export const RESET_SINGLE_SALESORDER = 'RESET_SINGLE_SALESORDER';
//Fetch
//##########################################################################
export const fetchSalesOrderBegin = () => ({
  type: FETCH_SALESORDER_BEGIN,
});

export const fetchSalesOrderSuccess = (salesorder) => ({
  type: FETCH_SALESORDER_SUCCESS,
  payload: { salesorder },
});

export const fetchSalesOrderFailure = (error) => ({
  type: FETCH_SALESORDER_FAILURE,
  payload: { error },
});

export function fetchSalesOrder(salesOrderId) {
  return (dispatch) => {
    dispatch(fetchSalesOrderBegin());
    return fetchService
      .get("/salesorder/" + salesOrderId)
      .then((json) => {
        dispatch(fetchSalesOrderSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchSalesOrderFailure(error)));
  };
}
//End Fetch
//##########################################################################

//Update List

export function updateSalesOrder(salesOrder) {
  return (dispatch) => {
    dispatch(pushUpdateSalesOrder(salesOrder));
  };
}

export const pushUpdateSalesOrder = (salesOrder) => ({
  type: UPDATE_SINGLE_SALESORDER,
  payload: salesOrder,
});

//##########################################################################

export function resetSalesOrder() {
  
  return (dispatch) => {
    dispatch(resetSalesOrderArgs());
  };
}
const resetSalesOrderArgs = () => ({
  type: RESET_SINGLE_SALESORDER
})