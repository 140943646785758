import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { Form } from 'react-bootstrap';
import { fetchUserRoles } from '../../../../store/actions/userRolesActions';
import { LoadingPlaceholder } from '../../../Controls/LoadingPlaceholder';

function UserRoleSelector({ valueChanged, value, required, inputref}) {
    const roles = useSelector(state => state.userroles.userroles);
    const loading = useSelector(state => state.userroles.loading);
    const error = useSelector(state => state.userroles.error);
    const isError = useSelector(state => state.userroles.isError);
    const rolesLoaded = useSelector(state => state.userroles.userrolesLoaded);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!rolesLoaded && !loading)
        {
         trackPromise(
           dispatch(fetchUserRoles())
         );
        }
      }, [rolesLoaded, loading, dispatch]);
   

    function groupValueChanged(e)
    {
        let roleid =  parseInt(e.target.value);
        valueChanged(roleid);
    }
  
    if (error != null && isError ) {
    return <div>Error! {error.message}</div>;
    }

    if (loading ) {
        return <LoadingPlaceholder labeltext="User Role" />
    }
  
    return (
        <div>
        <Form.Group>
            <Form.Label>User Role</Form.Label>
            <Form.Control as="select" onChange={groupValueChanged} value={value} required={true} ref={inputref}>
            <option key={-1} value=""></option>
                {
                    roles.map ((role, index) => 
                        <option key={role.id}
                            value={role.id}
                        >
                            { role.roleName }
                        </option>
                        )
                }
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please Select a User Role
            </Form.Control.Feedback>
        </Form.Group>
        </div>
    );

}

export { UserRoleSelector };