import React, { useEffect, useRef } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Modal, Form } from "react-bootstrap";
import TextInput from "../../../Controls/textInput";
import InventoryDashCheckbox from "../../../Controls/InventoryDashCheckbox";
import Address from "../../../Address";
import ContactList from "../../../ContactList";
import { ActionBarAllThings } from '../../../Layout/ActionBar';

function CustomerInfo({
  customer,
  contactList,
  billingAddress,
  shippingAddress,
  onCustomerValueChanged,
  onBillingAddressChanged,
  onShippingAddressChanged,
  cancelHandler,
  saveHandler,
  isSubmitting,
  isEditing,
  addContact,
  editContact,
  showModal,
  handleSave,
  saveCustomer,
  fromContactModal
}) {



  const custNameRef = useRef(null);

  useEffect(() => {
    custNameRef.current.focus();
  }, []);


  function handleTheSave() {
    handleSave();
  }

  return (
    <Modal
      show={true}
      onHide={cancelHandler}
      dialogClassName={"inventorydash_custom_modal_dialog"}
      animation={ fromContactModal ? false : true }
    >
      <div>
        <Tab.Container defaultActiveKey={fromContactModal ? "contacts" : "cust"} transition={false}>
          <Modal.Header closeButton bsPrefix={"inventorydash_custom_modal_header"}>
            <ActionBarAllThings
              pageTitle={isEditing ? "Manage Customer" : "Add Customer"}
              showBackBtn={true}
              showSaveBtn={true}
              showDelBtn={isEditing ? true : false}
              cancelHandler={cancelHandler}
              saveHandler = {saveCustomer}

              onModal={true}
              saveBtnText={isEditing ? "Update" : "Save"}
            />

            {/* swap active key for contact */}

            {/* Create the tab buttons */}
            <Nav justify variant="tabs" className="item_tabs">
              <Nav.Item className="tab_item">
                <Nav.Link eventKey="cust">
                  <span className="noselect">Customer</span>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="tab_item">
                <Nav.Link eventKey="contacts">
                  <span className="noselect">Contacts</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Modal.Header>

          <Modal.Body bsPrefix={"inventorydash_custom_modal_body"}>
            <Tab.Content className={"tab_content noselect"}>
              <Tab.Pane eventKey="cust">
                <TextInput
                  name="displayName"
                  label="Name"
                  value={customer.displayName}
                  onChange={onCustomerValueChanged}
                  required={true}
                  validatefailtext="Please enter a name."
                  inputref={custNameRef}
                />

                <Address
                  header="Billing Address"
                  visible={true}
                  address={billingAddress}
                  onInputValueChanged={onBillingAddressChanged}
                />

                <InventoryDashCheckbox
                  label="Shipping: Same as Billing"
                  name="shippingSameAsBilling"
                  checked={customer.shippingSameAsBilling}
                  onChange={onCustomerValueChanged}
                />

                <Address
                  header="Shipping Address"
                  visible={!customer.shippingSameAsBilling}
                  address={shippingAddress}
                  onInputValueChanged={onShippingAddressChanged}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="contacts">
                {/* Need a displayTable here to list Contacts. Need add/edit buttons? */}
                <ContactList
                  contacts={contactList}
                  addHandler={addContact}
                  editHandler={editContact}
                />
              </Tab.Pane>
            </Tab.Content>
          </Modal.Body>
        </Tab.Container>
      </div>
    </Modal>
  );
}

export { CustomerInfo };
