import { fetchService } from "../../services";

export const FETCH_ITEMTYPES_BEGIN   = 'FETCH_ITEMTYPES_BEGIN';
export const FETCH_ITEMTYPES_SUCCESS = 'FETCH_ITEMTYPES_SUCCESS';
export const FETCH_ITEMTYPES_FAILURE = 'FETCH_ITEMTYPES_FAILURE';

//Fetch
//##########################################################################
export const fetchItemTypesBegin = () => ({
  type: FETCH_ITEMTYPES_BEGIN
});

export const fetchItemTypesSuccess = itemtypes => ({
  type: FETCH_ITEMTYPES_SUCCESS,
  payload: { itemtypes }
});

export const fetchItemTypesFailure = error => ({
  type: FETCH_ITEMTYPES_FAILURE,
  payload: { error }
});

export function fetchItemTypes() {
    return dispatch => {
      dispatch(fetchItemTypesBegin());
      return fetchService.get("/itemtypes")
        .then(json => {
          dispatch(fetchItemTypesSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchItemTypesFailure(error)));
    };
  }
//End Fetch
//##########################################################################