import React, { useState, useEffect } from "react";
import DrawerToggle from "../SideDrawer/DrawerToggle";
import "./Toolbar.css";
import { Link } from "react-router-dom";
import Routes from "../../routes";
import { RiSearch2Line } from "react-icons/ri";
import { accountService } from "../../services";
import Logo from "../../resources/images/InventoryDashLogo.png";
import { useDispatch } from "react-redux";
import { SearchModal } from "../Toolbar/searchbox/SearchModal";
import { useRolePermissions } from '../../hooks/useRolePermissions';
import { changeSettingsClicked } from '../../store/actions/userInteractionActions';
import { ProfileAndSettings } from './ProfileAndSettings';


function Toolbar(props) {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState(false);
  const adminUser = useRolePermissions("Administrator");

  // #####################################################################################################################
  // Added this to get the User Initials 1/22/21 Darren
  const initials =
    accountService.getUserProperty("initials") === ""
      ? "ID" // Default Initials Inventory Dash ID
      : accountService.getUserProperty("initials");
  // Other Properties are available
  // accountService.getUserProperty('firstName');
  // accountService.getUserProperty('lastName');
  // accountService.getUserProperty('displayName');  // First and Last
  // #####################################################################################################################

  useEffect(() => {
    const subscription = accountService.user.subscribe((x) => setUser(x));
    return subscription.unsubscribe;
  }, []);

  function settingsClicked() {
    dispatch(changeSettingsClicked(true));
  }

  return (
    <header className="toolbar">
      <SearchModal
        showModal={showSearch}
        setShowModal={setShowSearch}
      ></SearchModal>

      <nav className="toolbar_navigation">
        <div className="toolbar_toggle">
          <DrawerToggle
            click={props.drawerClickHandler}
            drawerOpen={props.drawerOpen}
          />
        </div>

        <div className="toolbar_logo_div noselect">
          <Link to="/items">
            <img className="toolbar_logo noselect" src={Logo}></img>
          </Link>
        </div>

        <div className="spacer" />

        <div className="toolbar_navigation-items">
          <ul className="nav_unordered_list noselect">
            {
              /* All Routes are configured in the routes.js file in the source, adding a route to that file will also add it to the main menus */
              Routes.map((route, idx) => {
                return (
                  //Using a ternary operator here to only show routes that have a topMenu of true property
                  // (route.topMenu) ? true part : false part
                  route.topMenu ? (
                    <li key={idx}>
                      <Link key={idx} to={route.path}>
                        {route.display}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )
                );
              })
            }
          </ul>
        </div>

        <div className="menu-icons">
          <div className="toolbar_icons_container">
            <div className="search_icon_div"
            onClick = {() => setShowSearch(true)}
            >
            <RiSearch2Line className="search_icon" cursor="pointer" size="2.5rem" color= "white"
              ></RiSearch2Line>
            </div>

                {user ? ( //profile bubble only shows if logged in
                  <ProfileAndSettings/>
                ): (
                  <></>
                )}
           </div>
        </div>
      </nav>
    </header>
  );

}

export default Toolbar;
