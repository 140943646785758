import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  fetchItems,
  clearCurrentItem,
} from "../../../store/actions/itemActions";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { AddEditItemPage } from "./AddEditItemPage";
import WindowedTable from "../../Controls/Tables/WindowedTable";
import { ActionBarAllThings } from "../../Layout/ActionBar";
import "./itempage.css";
import { useRolePermissions } from "../../../hooks/useRolePermissions";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { Modal } from "react-bootstrap";
import FilterController from '../../Controls/FilterController';

function ItemPage({
  location
}) {
  const [showAddEditItemModal, setShowAddEditItemModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const items = useSelector((state) => state.items.items);
  const loading = useSelector((state) => state.items.loading);
  const itemsLoaded = useSelector((state) => state.items.itemsLoaded);
  const [editingItem, setEditingItem] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [columnsList, setColumnsList] = useState(determineColumns());
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [showEditBtn, setShowEditBtn] = useState(false);


  //FILTER STATE VARIBALES
  const [filterAppliedIndicator, setFilterAppliedIndicator] = useState(0);
  const [filterModal, setFilterModal] = useState(false);
  const [titleFilterInput, setTitleFilterInput] = useState('');
  const [titleCriteria, setTitleCriteria] = useState('Contains');
  const [minQty, setMinQty] = useState('');
  const [maxQty, setMaxQty] = useState('');
  const [labelInput, setLabelInput] = useState('');
  const [filteredItems, setFilterItems] = useState([]);
  const [typeFilter, setTypeFilter] = useState('');
  const [groupFilter, setGroupFilter] = useState('');
  const [vendorFilter, setVendorFilter] = useState('');


  const rolePermissions = useRolePermissions();
  const viewItems = useRolePermissions("View Items");
  const manageItems = useRolePermissions("Manage Items");
  const [currItem, setCurrItem] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (!itemsLoaded) {
      fetchData();
    }
  }, [itemsLoaded, dispatch]);

function determineColumns() {
  if (!localStorage.getItem('itemstable')) {
    return colHeaders
  } else {
    return JSON.parse(localStorage.getItem('itemstable'));
  }
}

  function originalFetchData() {
    trackPromise(dispatch(fetchItems()));
  }

  const fetchData = useCallback(
    debounce((q) => originalFetchData(), 200),
    []
  );

  //array that holds all the filtered items when a filter is applied
  let filteredArray = [];


  // Darren 3/5/2021
  // Check incoming props (coming from search or elsewhere?)
  // parameters added onto the route can be found in this way
  // Handle incoming props
  // #############################################################################
  let itemId = undefined;

  if (location != null) {
    if (location.state != null) {
      if (location.state.itemId != null) {
        itemId = location.state.itemId;
      }
    }
  }
  if (itemId != null) {
    // Set the current Item and show the display Modal
    // this is for a call that is coming from other pages
    if (itemId !== 0) {
      if (selectedItemId !== itemId) {
        setSelectedItemId(itemId);
        setEditingItem(true);
        setShowAddEditItemModal(true);
        history.replace(location.pathName, []);
        itemId = undefined;
      }
    }
  }
  // #############################################################################

  function setCurrItemLocal(item) {
    setSelectedItemId(item.id);
    setCurrItem(item);
    setShowEditBtn(true);
  }

  function search(e) {
    let searchstring = e.target.value;
    setSearchText(searchstring); // Set the search value in state variable
  }

  function showColumnModal() {
    setShowColumnSelector(true);
  }
  function cancelColumnModal() {
    setShowColumnSelector(false);

    //sets active property to false, so if modal is reopened any previously selected column will not be highlighted
    for (let i = 0; i < colHeaders.length; i++) {
      colHeaders[i].activeColumn = false;
    }
  }

  function cancelDialog() {
    setShowAddEditItemModal(false);
    setFilterModal(false);
    dispatch(clearCurrentItem());
  }


  function addItem() {
    // let item = newItem();
    setSelectedItemId(0);
    setEditingItem(false);
    // setCurrItem(item);
    setShowAddEditItemModal(true);
    setShowEditBtn(false); //this hides edit button after Add Item btn is clicked, which prevents editing of blank item
  }

  function editItem() {
    if (currItem !== undefined) {
      setEditingItem(true);
      setShowAddEditItemModal(true);
    }
  }

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!viewItems) {
    return (
      <div>{"Current user does not have permission to view this area"}</div>
    );
  }

  if (items == null) {
    return <></>;
  }

  //applys filters by calling all filter functions and then sets filterindicator to the number of filters set
  function applyFilters() {
    var filterItems = items;
    filterItems = titleFilters(filterItems);
    filterItems = qtyFilters(filterItems);
    filterItems = typeFilters(filterItems);
    filterItems = groupFilters(filterItems);
    filterItems = vendorFilters(filterItems);

    filterCounter();
    setFilterItems(filterItems);
    setFilterModal(false);
    cancelDialog();
  }

  function filterCounter() {
    let counter = 0;
    if (titleFilterInput) {
      counter++;
    }
    if (minQty || maxQty) {
      counter++
    }
    if (typeFilter) {
      counter++
    }
    if (groupFilter) {
      counter++
    }
    if (vendorFilter) {
      counter++
    }
    setFilterAppliedIndicator(counter);
  }

  function titleFilters(filterItems) {

    if (titleFilterInput) {
     let titleFilterInputToLower = titleFilterInput.toLowerCase();
      if (titleCriteria === "Contains") {
        filterItems = filterItems?.filter(item => (item.displayName.toString().toLowerCase().includes(titleFilterInputToLower)));
      }
      if (titleCriteria === "Begins With") {
        filterItems = filterItems?.filter(item => (item.displayName.toString().toLowerCase().startsWith(titleFilterInputToLower)));
      }
      if (titleCriteria === "Exact") {
        filterItems = filterItems?.filter(item => (item.displayName.toString().toLowerCase() === titleFilterInputToLower));
      }
    }
    return filterItems;
  }


  function qtyFilters(filterItems) {
    //Qty Filters
    if (!minQty && !maxQty) {
      return filterItems;
    }

    if (minQty || maxQty) {
      if (!maxQty) {
        filterItems = filterItems?.filter(item => (item.quantity >= minQty));
      }
      if (!minQty) {
        filterItems = filterItems?.filter(item => (item.quantity <= maxQty));
      } else if (minQty && maxQty) {
        filterItems = filterItems?.filter(item => (item.quantity >= minQty && (item.quantity <= maxQty)));
      }

      return filterItems;
    }
  }

  function typeFilters(filterItems) {
    if (typeFilter) {
      filterItems = filterItems?.filter(item => item.typeId == typeFilter);
    }
    return filterItems;
  }

  function groupFilters(filterItems) {
    if (groupFilter) {
      filterItems = filterItems?.filter(item => item.groupId == groupFilter);
    }
    return filterItems;
  }

  function vendorFilters(filterItems) {
    if (vendorFilter) {
    filterItems = filterItems?.filter(item => item.vendorId == vendorFilter);
    }
    return filterItems;
  }


  //clears all filters and removes the filterindicator that shows next to filter icon
  function clearFilters() {
    setTitleFilterInput('');
    setMinQty('');
    setMaxQty('');
    setTitleCriteria("Contains");
    setLabelInput('');
    setTypeFilter('');
    setGroupFilter('');
    setVendorFilter('');
    setFilterAppliedIndicator(0);
  }


  return (
    <>
      <ActionBarAllThings
        pageTitle={"Items"}
        showEditBtn={showEditBtn && manageItems}
        showViewBtn={showEditBtn && !manageItems}
        showColBtn
        showAddBtn={manageItems}
        addItem={addItem}
        adjustColumns={showColumnModal}
        editItem={editItem}
        viewItem={editItem}
        helpTopic={"Items"}
        showHelpButton={true}
        showFilterIcon={true}
        filterHandler={() => setFilterModal(true)}
        filterAppliedIndicator={filterAppliedIndicator}
      />



      {filterModal ? (
        <Modal
          show={filterModal}
          onHide={cancelDialog}
          dialogClassName={"inventorydash_custom_modal_dialog"}
        >
          <FilterController
            inputitems={items}
            titleFilterLabel="Item Name"
            numberRange={true}
            numberRangeTitle="Qty Range"
            labelFilter={true}
            labelFilterTitle="Labels"
            filterAppliedIndicator={filterAppliedIndicator}
            clearFilters={clearFilters}
            applyFilters={applyFilters}
            minQty={minQty}
            maxQty={maxQty}
            setMinQty={setMinQty}
            setMaxQty={setMaxQty}
            labelInput={labelInput}
            setLabelInput={setLabelInput}
            titleInput={titleFilterInput}
            setTitleInput={setTitleFilterInput}
            setTitleCriteria={setTitleCriteria}
            titleCriteria={titleCriteria}
            typeFilter={typeFilter}
            setTypeFilter={setTypeFilter}
            groupFilter={groupFilter}
            setGroupFilter={setGroupFilter}
            vendorFilter={vendorFilter}
            setVendorFilter={setVendorFilter}
          />
        </Modal>
      ) :
        showAddEditItemModal ? (
          <Modal
            show={showAddEditItemModal}
            onHide={cancelDialog}
            dialogClassName={"inventorydash_custom_modal_dialog"}
          >
            <AddEditItemPage
              itemId={selectedItemId}
              visible={showAddEditItemModal}
              setshowmodal={setShowAddEditItemModal}
              cancelHandler={cancelDialog}
              isSubmitting={isSubmitting}
              isEditing={editingItem}
              cancelDialog={cancelDialog}
            
            />
          </Modal>
        ) : (
          <></>
        )}

      <div className="page_container">
        <WindowedTable
          colheaders={columnsList}
          inputitems={filterAppliedIndicator > 0 ? filteredItems : items}
          currentSelectedItem={currItem}
          selectCurrentItem={setCurrItemLocal}
          idname={"id"}
          tablenameidentifier={"itemstable"}
          searchText={""}
          setSelectedId={setCurrItemLocal}
          selectedId={selectedItemId}
          defaultSort={"displayName"}
          setShowColumnSelector={setShowColumnSelector}
          showColumnSelector={showColumnSelector}
          setColumnsList={setColumnsList}
        />
      </div>
    </>
  );
}

export { ItemPage };

const colHeaders = [
  {
    displayName: "Name",
    propName: "displayName",
    colclassname: "",
    selected: true,
    displayorder: 0,
    activeColumn: false,
  },
  {
    displayName: "Type",
    propName: "typeName",
    colclassname: "",
    dataclassname: "",
    selected: true,
    displayorder: 1,
    activeColumn: false,
  },
  {
    displayName: "Received Qty",
    propName: "receivedQuantity",
    colclassname: "",
    dataclassname: "",
    selected: true,
    displayorder: 2,
    activeColumn: false,
  },
  {
    displayName: "Awaiting Qty",
    propName: "unfilledQuantity",
    colclassname: "",
    dataclassname: "",
    selected: true,
    displayorder: 3,
    activeColumn: false,
  },
  {
    displayName: "Resale $/Unit",
    propName: "resalePrice",
    colclassname: "",
    dataclassname: "",
    selected: true,
    displayorder: 4,
    activeColumn: false,
  },
  {
    displayName: "Description",
    propName: "description",
    selected: false,
    displayorder: 5,
    activeColumn: false,
  },
  {
    displayName: "Barcode",
    propName: "barcode",
    selected: false,
    displayorder: 6,
    activeColumn: false,
  },
  {
    displayName: "Expiration",
    propName: "expirationDate",
    selected: false,
    displayorder: 7,
    activeColumn: false,
  },
  {
    displayName: "SKU",
    propName: "sku",
    selected: false,
    displayorder: 8,
    activeColumn: false,
  },
  {
    displayName: "Taxable",
    propName: "taxable",
    selected: false,
    displayorder: 9,
    activeColumn: false,
  },
  {
    displayName: "Vendor Cost",
    propName: "vendorCost",
    selected: false,
    displayorder: 10,
    activeColumn: false,
  },

  {
    displayName: "Reorder QTY",
    propName: "reorderQuantity",
    selected: false,
    displayorder: 11,
    activeColumn: false,
  },
  {
    displayName: "Vendor",
    propName: "vendorName",
    selected: false,
    displayorder: 12,
    activeColumn: false,
  },
  {
    displayName: "Group",
    propName: "groupName",
    selected: false,
    displayorder: 13,
    activeColumn: false,
  },
  {
    displayName: "Location",
    propName: "locationName",
    selected: false,
    displayorder: 14,
    activeColumn: false,
  },
  {
    displayName: "Quantity",
    propName: "quantity",
    selected: false,
    displayorder: 15,
    activeColumn: false,
  },
];
