import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { accountService } from '../services';
const PrivateRoute = ({ props, component: Component, ...rest }) => {
    const notAuthenticated = !accountService.userValue;
    // const admin  = accountService.admin;
    if (notAuthenticated)
    {
        return (
            
            <Redirect to='/login' />
        )
    }
    else
    {
        return (
            <Route {...rest} />
        )
    }
}

export { PrivateRoute };